import styled from 'styled-components';
import { UniversalForm } from '@rainbow-modules/forms';
import { color } from 'theme';
import { Button, Slider, Textarea } from 'react-rainbow-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';

export const StyledUniversalForm = styled(UniversalForm)`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const RightColumn = styled.div`
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 36px;
`;

export const SettingsContent = styled.div`
    display: flex;
    gap: 24px;
    padding: 12px 20px 32px 20px;
`;

export const StyledSaveButton = styled(Button)`
    width: fit-content;
`;

export const SettingsLeftColumn = styled.div`
    background: ${color('background.main')};
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const SettingsRightColumn = styled.div`
    min-width: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    background: ${color('background.main')};
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    padding: 20px 16px;
    gap: 12px;
`;

export const StyledTextarea = styled(Textarea)`
    flex-grow: 1;
    
    & textarea {
        min-height: 70vh;
    }
`;

export const WebhookContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${color('background.secondary')};
    padding: 0 0 0 12px;
    border-radius: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const WebhookUrl = styled.span`
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    color: ${color('text.main')};
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    flex-shrink: 0;
`;

export const ParametersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Subtitle = styled.h2`
    font-size: 14px;
    color: ${color('text.main')};
    font-family: "Lato Bold";
`;

export const WebhookContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledSlider = styled(Slider)`
    input::-moz-range-progress {
        background: ${color('brand.main')};
    }
`;
