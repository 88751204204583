import { PageHeaderTitle } from 'components/styled';
import {
    SectionHeader, SectionDescription, ChartContainer,
} from './styled';

const VersionTopicAnalysis = () => (
    <div>
        <SectionHeader>
            <PageHeaderTitle>Topic Analysis</PageHeaderTitle>
            <SectionDescription>
                Lorem impsu dolor sit amet, consectetur adipiscing elit.
            </SectionDescription>
        </SectionHeader>
        <ChartContainer>
            Test
        </ChartContainer>
    </div>
);

export default VersionTopicAnalysis;
