import { useEffect, useState } from 'react';
import { query, orderBy } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import useVersions from 'data/firestore/model/version/useCollection';
import {
    Table, Column, Breadcrumbs, Breadcrumb, Button, RenderIf, Input, LoadingShape,
} from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import DateTimeColumn from 'components/DateTimeColumn';
import EmptyMessage from 'components/EmptyMessage';
import PageHeader from 'components/PageHeader';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import getModel from 'data/firestore/model/get';
import ActionsColumn from './actionsColumn';
import {
    Header, PageTitle,
} from '../styled';
import { Actions, StyledNavigationButtonColumn } from './styled';
import NewVersionModel from './NewVersionModal';

const Model = () => {
    const { modelId = '' } = useParams();
    const [modelName, setModelName] = useState('');
    const { data, isLoading } = useVersions(modelId as string, {
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
    });
    const navigate = useNavigate();
    const navigateToVersion = (value: any) => {
        navigate(`/models/${modelId}/version/${value.row.id}`);
    };
    const [isOpenNewVersionModal, setIsOpenNewVersionModal] = useState(false);

    const hasVersion = data?.length > 0;

    useEffect(() => {
        (async () => {
            try {
                const model = await getModel(modelId);
                setModelName(model?.name || modelId);
            } catch (error) {
                // No catch
            }
        })();
    }, [modelId]);

    return (
        <div>
            <PageHeader title={(
                <Breadcrumbs>
                    <Breadcrumb
                        label="All Models"
                        onClick={() => navigate('/models/list')}
                    />
                    <Breadcrumb
                        label={(
                            <>
                                <RenderIf isTrue={!isEmpty(modelName)}>
                                    <PageTitle>{modelName}</PageTitle>
                                </RenderIf>
                                <RenderIf isTrue={isEmpty(modelName)}>
                                    <LoadingShape />
                                </RenderIf>
                            </>
                        )}
                    />
                </Breadcrumbs>
            )}
            />
            <RenderIf isTrue={!isLoading && !hasVersion}>
                <EmptyMessage
                    title="Ready to Train Your Model?"
                    description="Before you dive into training your model with your dataset, you need to create a new version. This allows you to manage multiple iterations and improve your model effectively."
                    action={(
                        <Button
                            variant="brand"
                            borderRadius="semi-rounded"
                            onClick={() => setIsOpenNewVersionModal(true)}
                        >
                            <Plus className="rainbow-m-right_small" />
                            Create a New Version
                        </Button>
                    )}
                />
            </RenderIf>
            <RenderIf isTrue={isLoading || hasVersion}>
                <Header>
                    <Input size="small" borderRadius="semi-rounded" placeholder="Find a group..." icon={<MagnifyingGlass />} type="search" />

                    <Actions>
                        <Button
                            variant="brand"
                            size="small"
                            borderRadius="semi-rounded"
                            onClick={() => setIsOpenNewVersionModal(true)}
                        >
                            <Plus className="rainbow-m-right_small" />
                            New Version
                        </Button>
                    </Actions>
                </Header>

                <Table
                    data={data}
                    isLoading={isLoading}
                    keyField="id"
                    variant="listview"
                    className="rainbow-m-left_large rainbow-m-right_large"
                    showRowNumberColumn
                >
                    <Column
                        header="Version"
                        field="id"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={StyledNavigationButtonColumn}
                        onClick={navigateToVersion}
                    />
                    <Column
                        header="OpenAI Model"
                        field="name"
                        headerAlignment="left"
                        cellAlignment="left"
                        defaultWidth={310}
                    />
                    <Column
                        header="Created"
                        field="createdAt"
                        component={DateTimeColumn}
                        defaultWidth={180}
                        headerAlignment="left"
                    />
                    <Column
                        header="Updated"
                        field="updatedAt"
                        component={DateTimeColumn}
                        defaultWidth={180}
                        headerAlignment="left"
                    />
                    <Column header="Status" field="status" defaultWidth={120} />
                    <Column defaultWidth={50} component={ActionsColumn} />
                </Table>
            </RenderIf>
            <NewVersionModel
                isOpen={isOpenNewVersionModal}
                onRequestClose={() => setIsOpenNewVersionModal(false)}
            />
        </div>
    );
};

export default Model;
