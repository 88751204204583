import {
    KeyboardEvent, useCallback,
} from 'react';
import { ArrowRight } from '@rainbow-modules/icons';
import {
    StyledInput,
    InstructionsInputContainer,
    SendButtonContainer,
    SendButton,
} from './styled';

interface InstructionsInputProps {
    value?: string;
    disabled?: boolean;
    disableSubmit?: boolean;
    onChange?: (value: string) => void;
    onSend?: (value: string) => void;
}

const InstructionsInput = ({
    value = '',
    disabled = false,
    disableSubmit = false,
    onChange = () => {},
    onSend = () => {},
}: InstructionsInputProps) => {
    const sendMessage = useCallback(() => {
        if (value !== '') {
            onSend(value);
        }
    }, [onSend, value]);

    const handleKeyDown = useCallback(
        ({ key, shiftKey }: KeyboardEvent<HTMLInputElement>) => {
            if (key === 'Enter' && !shiftKey && !disableSubmit) {
                sendMessage();
            }
        },
        [disableSubmit, sendMessage],
    );

    return (
        <InstructionsInputContainer role="presentation" tabIndex={-1}>
            <StyledInput
                hideLabel
                value={value}
                borderRadius="semi-rounded"
                placeholder="Enter a instruction for the assistant"
                onKeyDown={handleKeyDown}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            />
            <SendButtonContainer>
                <SendButton
                    icon={<ArrowRight />}
                    assistiveText="send"
                    title="send"
                    tabIndex={-1}
                    borderRadius="semi-rounded"
                    variant="brand"
                    onClick={sendMessage}
                    disabled={disabled || disableSubmit || value?.length < 2}
                />
            </SendButtonContainer>
        </InstructionsInputContainer>
    );
};

export default InstructionsInput;
