import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateWithQuery = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return useCallback((newPath: string) => {
        const searchParams = new URLSearchParams(location.search);
        const newUrl = `${newPath}?${searchParams.toString()}`;
        navigate(newUrl);
    }, [location.search, navigate]);
};

export default useNavigateWithQuery;
