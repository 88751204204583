/* eslint-disable jsx-a11y/aria-role */
import {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Option, RenderIf, Picklist, Spinner,
} from 'react-rainbow-components';
import { confirmModal } from '@rainbow-modules/app';
import { isEmpty } from '@rainbow-modules/validation';
import {
    orderBy, query,
} from 'firebase/firestore';
import useAgentsList from 'data/firestore/agent/useCollection';
import TopBar from 'components/TopBar';
import MessageInput from 'components/MessageInput';
import useChatSession from 'hooks/useChatSession';
import { PageHeaderTitle } from 'components/styled';
import MessageMetadata from './MessageMetadata';
import {
    Container,
    Content,
    Sidebar,
    LeftContent,
    ChatSimulatorHeader,
    FormContainer,
    Divider,
    StyledButton,
    ChatSimulatorFooter,
    MessagesList,
    SectionTitle,
    SessionEndMessage,
} from './styled';
import ChatMessage from './ChatMessage';

interface PicklistValue {
    label?: string;
    name?: string | number;
    value?: any;
}
type Role = 'assistant' | 'user' | 'system' | 'event';
const getRoleForLoadingMessage = (messages: Array<{ role: Role }>): Role => {
    if (messages?.length > 0) {
        return messages[messages.length - 1].role === 'user' ? 'assistant' : 'user';
    }
    return 'assistant';
};

const getReasonMessage = (reason?: string): string | undefined => {
    if (reason === 'hang_up') return 'The user ended the session';
    if (reason === 'transferred_to_human_agent') return 'Transferred to a human agent';
    return reason;
};

const ChatSimulator = () => {
    const messagesListRef = useRef<HTMLDivElement>(null);
    const [selectedAgent, setSelectedAgent] = useState<PicklistValue>();
    const [selectedMessage, setSelectedMessage] = useState<string>();
    const { data: agents = [], isLoading: isLoadingAgents } = useAgentsList({
        listQueryFn: (ref) => query(ref, orderBy('companyName', 'asc')),
        track: [],
    });

    const {
        chatId,
        chatEnded,
        endReason,
        messages,
        isLoading,
        isSendingMessage,
        currentResponseStream,
        sendMessage,
        reset: resetChat,
    } = useChatSession(selectedAgent?.name as string);

    const agentsList = useMemo(
        () => agents.map(
            ({ id, companyName }) => <Option key={id} name={id} label={companyName} />,
        ),
        [agents],
    );

    const messagesList = useMemo(
        () => messages.map(
            (message) => (
                <ChatMessage
                    key={message.id}
                    {...message}
                    messageId={message.id}
                    onClick={setSelectedMessage}
                    selected={selectedMessage === message.id}
                />
            ),
        ),
        [messages, selectedMessage],
    );

    const pickAgent = async (agent: PicklistValue) => {
        if (!agent) return;

        if (selectedAgent?.name !== '' && agent?.name !== selectedAgent?.name && messagesList.length > 1) {
            const result = await confirmModal({
                variant: 'destructive',
                question: 'Switching to a new agent will reset the current conversation. Are you sure you want to continue?',
                okButtonLabel: 'Yes',
                cancelButtonLabel: 'No',
            });

            if (!result) return;
        }

        setSelectedAgent(agent);
        setSelectedMessage(undefined);
    };

    const hadleResetClick = async () => {
        const result = await confirmModal({
            variant: 'destructive',
            question: 'Reseting conversation will delete all messages. Are you sure you want to continue?',
            okButtonLabel: 'Yes',
            cancelButtonLabel: 'No',
        });

        if (result) {
            await resetChat();
            setSelectedMessage(undefined);
        }
    };

    useEffect(() => {
        if (messages?.length > 0) {
            setSelectedMessage(messages[messages.length - 1].id as string);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages.length]);

    useEffect(
        () => messagesListRef.current?.scrollBy(0, messagesListRef.current?.scrollHeight),
        [messages.length, isSendingMessage, currentResponseStream],
    );

    return (
        <Container>
            <TopBar />
            <Content>
                <LeftContent>
                    <ChatSimulatorHeader>
                        <PageHeaderTitle>Chat Simulator</PageHeaderTitle>
                        <Divider />
                        <FormContainer>
                            <Picklist
                                hideLabel
                                enableSearch={agentsList.length > 20}
                                placeholder="Select Agent"
                                value={selectedAgent}
                                onChange={(value) => pickAgent(value)}
                                isLoading={isLoadingAgents}
                                borderRadius="semi-rounded"
                                size="small"
                            >
                                {agentsList}
                            </Picklist>
                            <StyledButton
                                label="Reset Chat"
                                variant="brand"
                                borderRadius="semi-rounded"
                                disabled={!chatId || isLoading}
                                onClick={hadleResetClick}
                                size="small"
                            />
                        </FormContainer>
                    </ChatSimulatorHeader>
                    <MessagesList ref={messagesListRef}>
                        <RenderIf isTrue={isLoading}>
                            <Spinner variant="brand" type="arc" />
                        </RenderIf>
                        <RenderIf isTrue={chatId && !isLoading}>
                            {messagesList}
                            <RenderIf isTrue={isSendingMessage}>
                                <ChatMessage
                                    isLoading={isEmpty(currentResponseStream)}
                                    role={getRoleForLoadingMessage(messages)}
                                    content={currentResponseStream}
                                />
                            </RenderIf>
                            <RenderIf isTrue={chatEnded}>
                                <SessionEndMessage>
                                    <b>Session Ended: </b>
                                    {getReasonMessage(endReason)}
                                </SessionEndMessage>
                            </RenderIf>
                        </RenderIf>
                    </MessagesList>
                    <ChatSimulatorFooter>
                        <MessageInput
                            disabled={!chatId || isSendingMessage || chatEnded}
                            onSend={(message) => sendMessage({
                                body: {
                                    message,
                                },
                            })}
                        />
                    </ChatSimulatorFooter>
                </LeftContent>
                <RenderIf isTrue={Boolean(selectedMessage)}>
                    <Sidebar>
                        <SectionTitle>Turn Metadata</SectionTitle>
                        <MessageMetadata chatId={chatId} messageId={selectedMessage} />
                    </Sidebar>
                </RenderIf>
            </Content>
        </Container>
    );
};

export default ChatSimulator;
