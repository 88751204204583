import { ButtonIcon } from 'react-rainbow-components';
import { Pencil } from 'components/icons';
import { StyledColumnWrapper } from './styled';

interface Props {
    onEdit?: () => void;
}

const ActionsColumn = ({ onEdit = () => {} }: Props) => (
    <StyledColumnWrapper>
        <ButtonIcon
            borderRadius="semi-rounded"
            icon={<Pencil />}
            onClick={() => onEdit()}
        />
    </StyledColumnWrapper>
);

export default ActionsColumn;
