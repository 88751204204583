import { useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { StyledColumnWrapper, StyledHelpText } from './styled';

interface UsageReportStatusColumnProps {
    value?: string;
    row?: Record<string, unknown>;
}

const UsageReportStatusColumn = (props: UsageReportStatusColumnProps) => {
    const { value, row } = props;
    const color = useMemo(() => {
        if (value === 'failed') return 'error.main';
        if (value === 'succeeded') return 'success.main';
        return 'warning.main';
    }, [value]);
    return (
        <StyledColumnWrapper $color={color}>
            {value?.toUpperCase()}
            <RenderIf isTrue={value === 'failed'}>
                <StyledHelpText text={row?.error as string} />
            </RenderIf>
        </StyledColumnWrapper>
    );
};

export default UsageReportStatusColumn;
