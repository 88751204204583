import { Field, isRequired } from '@rainbow-modules/forms';
import { Input } from 'react-rainbow-components';
import { EntityName } from 'types';
import capitalize from './helpers/capitalize';
import { Container } from './styled';

interface Props {
    entity: EntityName;
}

const Form = ({ entity }: Props) => (
    <Container>
        <Field
            name="name"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Input}
            label={`${capitalize(entity)} Name`}
            required
            placeholder={`Enter a name for the ${entity}`}
            className="rainbow-m-bottom_xx-large"
            borderRadius="semi-rounded"
            labelAlignment="left"
            validate={isRequired('Please enter a name.')}
        />
    </Container>
);

export default Form;
