import {
    useCallback, useContext, useEffect, useState,
} from 'react';
import { CheckboxToggle } from 'react-rainbow-components';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import { PageHeaderTitle } from 'components/styled';
import updateTwilioConfig from 'data/firestore/twilioconfig/update';
import { SectionHeader, SectionDescription } from './styled';
import context, { Context } from '../context';
import { Container } from '../styled';

const Features = () => {
    const { twilioConfig, isLoading } = useContext<Context>(context);
    const [smsFeatureEnabled, setSmsFeatureEnabled] = useState<boolean>(false);

    useEffect(() => {
        setSmsFeatureEnabled((twilioConfig && !twilioConfig?.campaignStatus) || twilioConfig?.campaignStatus === 'verified');
    }, [twilioConfig]);

    const handleToggleSmsFeature = useCallback(async () => {
        if (!twilioConfig?.id) return;
        showAppSpinner();
        setSmsFeatureEnabled(!smsFeatureEnabled);
        try {
            const campaignStatus = (smsFeatureEnabled ? 'disabled' : 'verified');
            await updateTwilioConfig(
                twilioConfig.id,
                { campaignStatus },
            );
        } catch (error) {
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
            setSmsFeatureEnabled(smsFeatureEnabled);
        }
        hideAppSpinner();
    }, [smsFeatureEnabled, twilioConfig]);

    return (
        <>
            <SectionHeader>
                <PageHeaderTitle>Features</PageHeaderTitle>
                <SectionDescription>
                    Lorem ipsum is placeholder text commonly used in the graphic.
                </SectionDescription>
            </SectionHeader>
            <Container>
                <CheckboxToggle
                    label="SMS Messaging"
                    value={smsFeatureEnabled}
                    onChange={handleToggleSmsFeature}
                    disabled={!twilioConfig || isLoading}
                />
            </Container>
        </>
    );
};

export default Features;
