import { Input } from 'react-rainbow-components';
import { Field } from 'react-final-form';
import { StyledTextarea } from './styled';

const Fields = () => (
    <>
        <Field
            name="name"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Input}
            label="Document Title"
            labelAlignment="left"
            borderRadius="semi-rounded"
            required
            placeholder="Enter a title for the document"
            className="rainbow-m-bottom_large"
        />
        <Field
            name="content"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={StyledTextarea}
            grow
            label="Document Content"
            labelAlignment="left"
            borderRadius="semi-rounded"
            required
            rows={5}
            placeholder="Enter the document content"
            className="rainbow-p-bottom_large"
        />
    </>
);

export default Fields;
