/* eslint-disable react/no-unused-prop-types */
import { useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ButtonIcon, ImportRecordsFlow, RenderIf } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ErrorText from 'react-rainbow-components/components/Input/styled/errorText';
import validatePhoneNumber from 'data/services/phones/validate';
import { UploadFile, Trash } from 'components/icons';
import {
    ImportContainer,
    ImportLabel,
    ImportContent,
    ImportText,
    ImportIcon,
    LeftContent,
    ImportIconLarge,
    ImportedContent,
    ImportButton,
    ImportHelpText,
    ImportPlaceholder,
} from './styled';

const schema = {
    collection: 'phoneNumbers',
    attributes: {
        name: {
            type: String,
            required: true,
        },
        phone: {
            type: String,
            required: true,
        },
    },
};

const validateRecord = () => {
    const records = new Set();
    return ({ phone }: { phone: string }) => {
        const error: { phone?: string } = {};
        if (!validatePhoneNumber(phone)) {
            error.phone = 'Phone is not valid';
        } else {
            const parsedPhone = parsePhoneNumber(phone, 'US').number;
            if (records.has(parsedPhone)) {
                error.phone = 'Phone is duplicated';
            } else {
                records.add(parsedPhone);
            }
        }
        return error;
    };
};

interface Contact {
    name: string;
    phone: string;
}

interface Props {
    value?: string[];
    onChange: (contacts: Array<Contact>) => void;
    error?: string;
}

const ImportDataInput = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [metadata, setMetadata] = useState({ success: 0, fail: 0 });
    const {
        value,
        onChange,
        error,
    } = useReduxForm(props);
    const hasValue = value && value.length > 0;

    const onComplete = ({ data }: { data: Array<Contact> }) => {
        const meta = { success: 0, fail: 0 };
        let newData;
        if (data.length > 0) {
            newData = data.reduce((seed: Array<Contact>, item) => {
                const phone = String(item.phone);
                const { name } = item;
                const parsedPhone = parsePhoneNumber(phone, 'US').number;
                meta.success += 1;
                seed.push({
                    name,
                    phone: parsedPhone,
                });
                return seed;
            }, []);
            setMetadata(meta);
            onChange(newData);
        }
        setIsOpen(false);
    };

    return (
        <ImportContainer>
            <ImportLabel>
                Callee Phone Numbers
            </ImportLabel>
            <RenderIf isTrue={!hasValue}>
                <ImportContent>
                    <LeftContent>
                        <ImportIcon />
                        <ImportPlaceholder>
                            Import a CSV File
                        </ImportPlaceholder>
                    </LeftContent>
                    <ImportButton borderRadius="semi-rounded" onClick={() => setIsOpen(true)}>
                        <UploadFile className="rainbow-m-right_xx-small" />
                        Import
                    </ImportButton>
                </ImportContent>
            </RenderIf>

            <RenderIf isTrue={hasValue}>
                <ImportedContent>
                    <LeftContent>
                        <ImportIconLarge />
                        <div>
                            <ImportText>
                                {`Total: ${metadata.success + metadata.fail} phone numbers`}
                            </ImportText>
                        </div>
                    </LeftContent>
                    <ButtonIcon
                        borderRadius="semi-rounded"
                        icon={<Trash />}
                        onClick={() => onChange([])}
                        className="rainbow-m-right_x-small"
                    />
                </ImportedContent>
            </RenderIf>
            <ImportHelpText>
                <h5>
                    Check if the phone numbers have a valid format.
                    (e.g., +1-212-718-1234, +12127181234 or +1 (212) 718-1234)
                </h5>
            </ImportHelpText>
            <RenderIf isTrue={!!error}>
                <ErrorText alignSelf="center">
                    {error}
                </ErrorText>
            </RenderIf>
            <ImportRecordsFlow
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                schema={schema}
                onComplete={onComplete}
                validateRecordCallback={validateRecord()}
                actionType="add-records"
                borderRadius="semi-rounded"
            />
        </ImportContainer>
    );
};

export default ImportDataInput;
