import { query, where } from 'firebase/firestore';
import { Group } from 'data/firestore/group/types';
import useCount from 'data/firestore/conversation/useCount';
import { StyledColumnWrapper } from '../styled';

interface Props {
    row?: Group;
}

const ConversationsCountColumn = ({ row }: Props) => {
    const { count } = useCount({
        listQueryFn: (ref) => query(ref, where('groupId', '==', row?.id)),
    });
    return <StyledColumnWrapper>{count}</StyledColumnWrapper>;
};

export default ConversationsCountColumn;
