/* eslint-disable react/no-unused-prop-types */
import { CSSProperties } from 'styled-components';
import { Picklist, Option } from 'react-rainbow-components';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Agent } from 'data/firestore/agent/types';
import useModels from 'hooks/useModels';
import { useMemo } from 'react';

interface Props {
    id?: string;
    className?: string;
    style?: CSSProperties;
    value?: Agent['model'];
    onChange?: (value: Agent['model']) => void;
    required?: boolean;
    borderRadius?: 'semi-rounded' | 'square' | 'semi-square' | 'rounded' | undefined;
    error?: string;
}

const ModelPicklist = (props: Props) => {
    const {
        id,
        className,
        style,
        required,
        value,
        onChange = () => {},
        error,
        borderRadius,
    } = useReduxForm(props);
    const { data: models, isLoading: isLoadingModels } = useModels();
    const selectedModel = models?.find((model) => model.id === value?.id);
    const modelOptions = useMemo(() => models?.map(
        (model) => (
            <Option
                key={model.id}
                name={model.id}
                label={model.name}
                value={model.id}
            />
        ),
    ), [models]);

    return (
        <Picklist
            id={id}
            className={className}
            value={value?.id ? {
                name: value?.id,
                label: selectedModel?.name,
                value: value?.id,
            } : undefined}
            onChange={(newValue) => {
                const { version, ...valueWithoutVersion } = value || {};
                const newModel = {
                    ...valueWithoutVersion,
                    id: newValue?.value,
                };
                return onChange(newModel);
            }}
            // eslint-disable-next-line react/forbid-component-props
            style={style}
            label="Model"
            borderRadius={borderRadius}
            required={required}
            labelAlignment="left"
            placeholder="Select a model"
            isLoading={isLoadingModels}
            error={error}
        >
            {modelOptions}
        </Picklist>
    );
};

export default ModelPicklist;
