import { useCallback, useState } from 'react';
import { Button, Modal } from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import {
    ModalContent,
    ModalTitle,
    StyledCopyToClipboardButton,
    UrlContainer,
    WarningMeessage,
    WarningIcon,
} from './styled';

const BillonkLinkModal = ({
    url,
    isOpen = false,
    onRequestClose = () => {},
}: {
    url?: string;
    isOpen?: boolean;
    onRequestClose?: () => void;
}) => (
    <Modal
        borderRadius="semi-rounded"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={<ModalTitle>Agent Billing Link</ModalTitle>}
    >
        <ModalContent>
            <p>Copy the link bellow to send the billing link to the customer.</p>
            <UrlContainer>
                <span>{url}</span>
                <StyledCopyToClipboardButton value={url} borderRadius="semi-rounded" variant="outline-brand" />
            </UrlContainer>
            <WarningMeessage>
                <WarningIcon />
                Notice this billing link have a expiration time.
            </WarningMeessage>
        </ModalContent>
    </Modal>
);

const CreateBillingLink = ({
    agentId,
    priceLookupKey,
}: {
    agentId: string,
    priceLookupKey: string,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        data,
        isLoading,
        mutateAsync: createSubscription,
    } = useHttpMutation<Record<string, string>, Record<string, string>>({
        pathname: '/subscriptions/checkout-session',
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
    });

    const handleCreateSubscription = useCallback(async () => {
        try {
            await createSubscription({
                body: {
                    agentId,
                    priceLookupKey,
                },
            });
            setIsOpen(true);
        } catch (error) {
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
    }, [agentId, createSubscription, priceLookupKey]);

    return (
        <div>
            <Button
                label="Create Agent Billing Link"
                variant="brand"
                borderRadius="semi-rounded"
                isLoading={isLoading}
                onClick={handleCreateSubscription}
                size="small"
            />
            <BillonkLinkModal
                url={data?.url}
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
            />
        </div>
    );
};

export default CreateBillingLink;
