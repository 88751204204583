import { useOpenModal } from '@rainbow-modules/hooks';
import {
    StyledBadge,
    ChunkContent,
    ChunkItemContainer,
    ChunkHeader,
    MaximizeIcon,
} from './styled';

interface ChunItemProps {
    chunkName?: string | React.ReactNode;
    chunkContent?: string | React.ReactNode;
    chunkTokens?: string | React.ReactNode;
    documentName?: string | React.ReactNode;
}

const ChunkItem = (props: ChunItemProps) => {
    const {
        chunkContent, chunkName, chunkTokens, documentName,
    } = props;
    const [openModal] = useOpenModal('show-chunk-details');

    return (
        <ChunkItemContainer
            onClick={() => openModal({
                chunkName,
                chunkTokens,
                documentName,
                chunkContent,
            })}
        >
            <ChunkHeader>
                <StyledBadge label={chunkName} borderRadius="semi-square" size="small" />
                <MaximizeIcon />
            </ChunkHeader>
            <ChunkContent>
                {chunkContent}
            </ChunkContent>
        </ChunkItemContainer>
    );
};

export default ChunkItem;
