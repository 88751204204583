import React from 'react';
import { Campaign } from 'data/firestore/agent/campaign/types';
import { Call } from 'data/firestore/agent/campaign/call/types';
import { EntityGet } from 'data/firestore/types';

export interface CampaignContext {
    campaignData?: EntityGet<Campaign> | null;
    isLoading?: boolean;
    calls?: EntityGet<Call>[];
}

const context = React.createContext<CampaignContext>({
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
