import { RecordField } from '@rainbow-modules/record';
import { Agent } from 'data/firestore/agent/types';
import { InfoContainer } from '../../Details/styled';
import {
    CheckIcon,
    CancelIcon,
    StyledRecordField,
} from '../styled';

const getIcon = (status: string) => {
    if (status === 'active') return <CheckIcon />;
    if (
        ['canceled', 'unpaid', 'past_due', 'incomplete', 'incomplete_expired'].includes(status)
    ) return <CancelIcon />;
    return undefined;
};

const SubscriptionDetails = ({
    agentData,
    isLoading,
}:{
    agentData?: Agent | null;
    isLoading?: boolean;
}) => (
    <InfoContainer>
        <div>
            <RecordField
                isLoading={isLoading}
                label="Subscription ID"
                value={agentData?.billing?.subscriptionId as string}
            />
            <StyledRecordField
                isLoading={isLoading}
                label="Subscription Status"
                value={agentData?.billing?.subscriptionStatus as string}
                icon={getIcon(agentData?.billing?.subscriptionStatus as string)}
            />
        </div>
        <div>
            <RecordField
                isLoading={isLoading}
                label="Customer ID"
                value={agentData?.billing?.customerId as string}
            />
        </div>
    </InfoContainer>
);
export default SubscriptionDetails;
