import {
    IsLoadingCardContainer,
    IsLoadingItemContainer,
    StyledLoadingShapeMediumLarge,
    StyledLoadingShapeSmall,
} from './styled';

const IsLoadingStateAndAreaCode = () => (
    <IsLoadingCardContainer>
        <IsLoadingItemContainer>
            <StyledLoadingShapeMediumLarge />
            <StyledLoadingShapeSmall />
        </IsLoadingItemContainer>
    </IsLoadingCardContainer>
);

export default IsLoadingStateAndAreaCode;
