import styled from 'styled-components';

export const Container = styled.span`
    display: inline;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
`;

export const Question = styled.p`
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #000000;
    margin: 10px 0;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
`;
