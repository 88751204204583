import { useCallback, useEffect, useState } from 'react';
import { app } from 'data/firestore/firebase';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import createDownloadLink from './createDownloadLink';

const storage = getStorage(app);

const useRecording = (conversationId: string, recordingId?: string) => {
    const [recordingUrl, setRecordingUrl] = useState<string | undefined>(undefined);

    const downloadRecording = useCallback(
        () => createDownloadLink(recordingUrl as string, `${conversationId}__${recordingId}.wav`),
        [conversationId, recordingId, recordingUrl],
    );

    useEffect(() => {
        if (recordingId) {
            (async () => {
                const recording = ref(storage, `/recordings/${recordingId}.wav`);
                try {
                    const url = await getDownloadURL(recording);
                    setRecordingUrl(url);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error('Recording not found', e);
                }
            })();
        }
    }, [recordingId]);
    return {
        recordingUrl,
        downloadRecording,
    };
};

export default useRecording;
