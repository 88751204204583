import { CheckCircleFilled, ErrorCircleFilled } from '@rainbow-modules/icons';
import { NavigationButtonColumn } from '@rainbow-modules/listview';
import { RecordField } from '@rainbow-modules/record';
import styled from 'styled-components';
import { color } from 'theme';

export const SectionHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const StyledRecordField = styled(RecordField)`
    > span:last-child {
        align-items: center;
        text-transform: capitalize;
    }
`;

export const CheckIcon = styled(CheckCircleFilled)`
    width: 14px;
    height: 14px;
    color: ${color('success.main')};
`;

export const CancelIcon = styled(ErrorCircleFilled)`
    width: 14px;
    height: 14px;
    color: ${color('error.main')};
`;
