import { Container } from './styled';
import AudioAnalyzer from './AudioAnalyzer';

const Tools = () => (
    <Container>
        <h1>Tools</h1>
        <AudioAnalyzer />
    </Container>
);

export default Tools;
