import {
    useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Breadcrumb,
    Breadcrumbs,
    Button,
    ButtonIcon,
    Modal,
    RenderIf,
    Spinner,
    Textarea,
} from 'react-rainbow-components';
import { ExportBorder } from '@rainbow-modules/icons';
import { isEmpty } from '@rainbow-modules/validation';
import { orderBy, query, where } from 'firebase/firestore';
import {
    Pencil, Trash, Copy, Clear, MagicPencil,
} from 'components/icons';
import ChatMessage from 'components/ChatMessage';
import useConversation from 'data/firestore/conversation/use';
import updateConversation from 'data/firestore/conversation/update';
import removeConversation from 'data/firestore/conversation/remove';
import useMessages from 'data/firestore/message/useCollection';
import addMessage from 'data/firestore/message/add';
import removeMessage from 'data/firestore/message/remove';
import updateMessage from 'data/firestore/message/update';
import getDataset from 'data/firestore/dataset/get';
import getGroup from 'data/firestore/group/get';
import { Group } from 'data/firestore/group/types';
import { Dataset } from 'data/firestore/dataset/types';
import useEntityActions from 'hooks/useEntityActions';
import { ConversationFormValues } from 'types';
import { Message } from 'components/ChatMessage/types';
import { confirmModal } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import useHttpMutation from 'data/firestore/useHttpMutation';
import PageHeader from 'components/PageHeader';
import { COMPLETION_SETTINGS_DRAWER, INSERT_BUILDING_BLOCK_MODAL } from '../../../../constants';
import {
    Container,
    Title,
    Content,
    PlaygroundContainer,
    StyledButton,
    RightColumn,
    LeftColumn,
    SectionHeader,
    ModalTitle,
    ImportButton,
    StyledTextarea,
    StyledModal,
    ConversationActionsContainer,
} from './styled';
import isValidConversation from './helpers/isValidConversation';
import HelpMeWriteModal from './helpMeWriteModal';
import InsertBuildingBlockModal from './insertBuildingBlockModal';
import ConversationCompletion from './conversationCompletion';
import CompletionSettingsDrawer from './settingsDrawer';
import CompletionSuggestion from './completionSuggestion';

const importMessagesPlaceholder = `
assistant: Hello
user: Hi
assistant: How can I help you?
user: I need help with my order
`.trim();

interface CompletionResponse {
    message: string;
    transforms: string[];
}

interface CompletionRequest {
    conversation: Pick<Message, 'role' | 'content'>[];
    assistantMode: string;
    instruction?: string;
    modelName?: string;
    systemMessage?: string;
    temperature?: number;
}

const fixFloat = (n: number, pow10: number = 100) => {
    const pow = 10 ** Math.abs(Math.round(Math.log10(pow10)));
    return Math.round(n * pow) / pow;
};

const getOrder = (num1: number, num2: number = 0) => {
    if (num2 === 0 || num2 > num1 + 1) {
        return fixFloat(Math.trunc(num1) + 1);
    }
    const pow10 = 10 ** Math.round(Math.log10(num2 - num1));

    if (num2 > num1 + pow10) {
        return fixFloat(num1 + pow10, pow10);
    }
    return fixFloat(num1 + pow10 / 10, pow10 / 10);
};

const Conversation = () => {
    const { conversationId } = useParams();
    const { data, isLoading } = useConversation(conversationId as string);
    const [isLoadingDatasetAndGroup, setIsLoadingDatasetAndGroup] = useState(true);
    const [dataset, setDataset] = useState<Dataset | null>(null);
    const [group, setGroup] = useState<Group | null>(null);
    const { datasetId, groupId } = useParams();
    const navigate = useNavigate();
    const [isOpenHelpWrite, setIsOpenHelpWrite] = useState(false);
    const openHelpWriteModal = () => setIsOpenHelpWrite(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data && !isLoading) {
            (async () => {
                setDataset(await getDataset(data.datasetId));
                setDataset(await getDataset(data.datasetId));
                setGroup(await getGroup(data.groupId));
                setIsLoadingDatasetAndGroup(false);
            })();
        }
    }, [data, isLoading]);

    const { edit, remove: removeRequest } = useEntityActions<ConversationFormValues>('conversation');

    const editConversation = () => edit({
        initialValues: {
            name: data?.name,
            story: data?.story,
        },
        onSubmit: async ({ name: newName, story: newStory = '' }) => {
            await updateConversation(
                conversationId as string,
                {
                    name: newName,
                    story: newStory,
                },
            );
        },
    });

    const openConversationDeleteConfirmation = () => removeRequest({
        onConfirm: async () => {
            await removeConversation(conversationId as string);
            navigate(`/dataset/${datasetId}/group/${groupId}`);
        },
    });

    const { data: conversation = [], isLoading: isLoadingMessages } = useMessages({
        listQueryFn: (ref) => query(
            ref,
            where('conversationId', '==', conversationId),
            orderBy('order'),
        ),
        track: [conversationId],
    });
    const messages = conversation.filter((msg) => msg.role !== 'system').map((message, index) => ({
        ...message,
        order: message.order || (index + 1),
    })) as Omit<Message, 'conversationId'>[];

    const [isOpenImport, setIsOpenImport] = useState(false);
    const [system, setSystem] = useState('');
    const [messagesToImport, setMessagesToImport] = useState('');
    const [messagesToImportError, setMessagesToImportError] = useState('');
    const [importing, setImporting] = useState(false);

    useEffect(() => {
        if (conversation.length > 0) {
            const s = conversation.find((msg) => msg.role === 'system');
            if (s) {
                setSystem(s.content);
            }
        }
    }, [conversation]);

    const getOrderFromIndex = useCallback(
        (index: number = messages.length) => {
            const prevMessageOrder = messages[index - 1]?.order || 0;
            const nextMessageOrder = messages[index]?.order || 0;
            return getOrder(prevMessageOrder, nextMessageOrder);
        },
        [messages],
    );

    const add = useCallback(async ({
        content = '',
        role,
        order = 0,
    } : {
        content?: string;
        role?: 'assistant' | 'user' | 'system' | 'event';
        order?: number,
    }) => {
        await addMessage({
            content,
            role: role || (messages.length > 0
                && messages[messages.length - 1].role === 'user'
                ? 'assistant'
                : 'user'),
            conversationId: conversationId as string,
            order,
        });
    }, [conversationId, messages]);

    const remove = async (msgId: string) => {
        if (
            await confirmModal({
                question: 'Are you sure you want to delete this message?',
            })
        ) {
            await removeMessage(msgId);
        }
    };

    const update = async (id: string, msg: Pick<Message, 'role' | 'content'>) => {
        await updateMessage(id, msg);
    };

    const updateSystem = async () => {
        const message = conversation.find((msg) => msg.role === 'system');
        if (message) {
            await update(message.id, { role: 'system', content: system });
        } else {
            await add({
                content: system,
                role: 'system',
            });
        }
    };
    const copyConversation = () => {
        const text = messages.map((msg) => `${msg.role}: ${msg.content}`).join('\n');
        navigator.clipboard.writeText(text);
    };
    const importMessages = async () => {
        if (isValidConversation(messagesToImport)) {
            setMessagesToImportError('');
            const msgs = messagesToImport.split('\n').map((msg) => {
                const [role, content] = msg.split(': ');
                return { role, content };
            });
            setImporting(true);
            let order = getOrderFromIndex();
            await msgs.reduce(async (acc, msg) => {
                await acc;
                await add({
                    content: msg.content,
                    role: msg.role as 'assistant' | 'user',
                    order,
                });
                order += 1;
            }, Promise.resolve());
            setIsOpenImport(false);
            setMessagesToImport('');
            setImporting(false);
        } else {
            setMessagesToImportError('Invalid format');
        }
    };

    const importMessagesAfter = useCallback(
        async (msgs: Message[], atIndex: number = messages.length) => {
            let order = getOrderFromIndex(atIndex);
            const beforeOrder = messages[atIndex]?.order || 0;
            await msgs.reduce(async (acc, msg) => {
                await acc;
                await add({
                    content: msg.content,
                    role: msg.role as 'assistant' | 'user',
                    order,
                });
                order = getOrder(order, beforeOrder);
            }, Promise.resolve());
        },
        [add, getOrderFromIndex, messages],
    );

    const openImportModal = () => setIsOpenImport(true);

    const clearConversation = () => Promise.all(
        messages.map((message) => removeMessage(message.id)),
    );

    const clearConversationRequest = async () => {
        if (
            await confirmModal({
                variant: 'destructive',
                header: 'Clear conversation',
                question:
                    'You are about to remove all messages permanently, this cannot be undone. Are you sure you want to continue?',
                okButtonLabel: 'Clear',
            })
        ) {
            await clearConversation();
        }
    };

    const inserBuildingBlocksModalProps = useConnectModal(INSERT_BUILDING_BLOCK_MODAL);
    const [openInsertBuildingBlocks] = useOpenModal(INSERT_BUILDING_BLOCK_MODAL);

    const addNewMessageAtIndex = useCallback((index: number = messages.length) => add({
        order: getOrderFromIndex(index),
    }), [add, getOrderFromIndex, messages.length]);

    const importMessagesAtIndex = useCallback(
        (msgs: Message[] = [], index: number = messages.length) => importMessagesAfter(msgs, index),
        [importMessagesAfter, messages.length],
    );

    const insertBuildingBlockAtIndex = useCallback(
        (index: number = messages.length) => openInsertBuildingBlocks({
            onInsert: (block: object) => add({
                ...block,
                order: getOrderFromIndex(index),
            }),
        }),
        [add, getOrderFromIndex, messages.length, openInsertBuildingBlocks],
    );

    const completionSettingsDrawerProps = useConnectModal(COMPLETION_SETTINGS_DRAWER);

    const [completionInstruction, setCompletionInstrunction] = useState<string>('');
    const [completionSuggestion, setCompletionSuggestion] = useState<Record<string, string>>({});
    const {
        mutate: requestCompletion,
        isLoading: isLoadingSuggestion,
    } = useHttpMutation<CompletionRequest, CompletionResponse>({
        method: 'POST',
        pathname: '/conversation/complete',
    });

    const submitCompletionRequest = (values: Record<string, unknown>) => {
        const {
            assistantMode = 'gpt', instruction, modelName, temperature,
        } = values;
        requestCompletion({
            body: {
                assistantMode: assistantMode as string,
                instruction: instruction as string,
                modelName: modelName as string,
                temperature: temperature ? Number(temperature) : undefined,
                systemMessage: assistantMode === 'custom' ? system : undefined,
                conversation: messages.map(({ role, content }) => ({ role, content })),
            },
        }, {
            onSuccess: (response) => {
                const { message } = response;
                setCompletionSuggestion({ role: 'assistant', content: message });
            },
        });
    };

    useLayoutEffect(() => {
        containerRef.current?.scrollBy(0, containerRef.current?.scrollHeight);
    }, [completionSuggestion, isLoadingSuggestion, conversation]);

    if (isLoading || isLoadingDatasetAndGroup || isLoadingMessages) {
        return <Spinner />;
    }

    return (
        <Container>
            <PageHeader
                title={(
                    <Breadcrumbs>
                        <Breadcrumb label={dataset?.name} onClick={() => navigate(`/dataset/${dataset?.id}`)} />
                        <Breadcrumb label={group?.name} onClick={() => navigate(`/dataset/${dataset?.id}/group/${group?.id}`)} />
                        <Breadcrumb label={<Title>{data?.name}</Title>} />
                    </Breadcrumbs>
                )}
                actions={(
                    <>
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<MagicPencil />}
                            onClick={openHelpWriteModal}
                            tooltip="Help Write"
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Clear />}
                            onClick={clearConversationRequest}
                            tooltip="Clear conversation"
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<ExportBorder />}
                            onClick={openImportModal}
                            tooltip="Import conversation"
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Copy />}
                            onClick={copyConversation}
                            tooltip="Copy conversation"
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Pencil />}
                            onClick={editConversation}
                            tooltip="Edit"
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Trash />}
                            onClick={openConversationDeleteConfirmation}
                            tooltip="Delete"
                        />
                    </>
                )}
            />
            <Content ref={containerRef}>
                <PlaygroundContainer>
                    <LeftColumn>
                        <Title>System Message</Title>
                        <StyledTextarea
                            value={system}
                            onChange={(e) => setSystem(e.target.value)}
                            onBlur={updateSystem}
                            rows={5}
                            placeholder="Enter a system message for the conversation"
                        />
                    </LeftColumn>
                    <RightColumn>
                        <SectionHeader>
                            <Title>Conversation</Title>
                        </SectionHeader>
                        {messages?.map((message, index) => (
                            <ChatMessage
                                key={`${message.id}-${message.content}`}
                                value={message}
                                onRemove={() => remove(message.id)}
                                actionsAlwaysVisible={index === messages.length - 1}
                                onChange={(updatedMessage) => update(message.id, updatedMessage)}
                                onAddMessageAfter={() => addNewMessageAtIndex(index + 1)}
                                onImportAfter={(msgs) => importMessagesAtIndex(
                                    msgs as Message[],
                                    index + 1,
                                )}
                                onAddBuildingBlockAfter={
                                    () => insertBuildingBlockAtIndex(index + 1)
                                }
                            />
                        ))}
                        <RenderIf isTrue={!messages.length}>
                            <ConversationActionsContainer>
                                <StyledButton
                                    label="Add Message"
                                    size="small"
                                    variant="outline-brand"
                                    borderRadius="semi-rounded"
                                    onClick={() => addNewMessageAtIndex()}
                                />
                                <StyledButton
                                    label="Add Building Block"
                                    size="small"
                                    variant="outline-brand"
                                    borderRadius="semi-rounded"
                                    onClick={() => insertBuildingBlockAtIndex()}
                                />
                                <StyledButton
                                    label="Import Conversation"
                                    size="small"
                                    variant="outline-brand"
                                    borderRadius="semi-rounded"
                                    onClick={openImportModal}
                                />
                            </ConversationActionsContainer>
                        </RenderIf>
                        <RenderIf isTrue={completionSuggestion.content || isLoadingSuggestion}>
                            <CompletionSuggestion
                                isLoading={isLoadingSuggestion}
                                {...completionSuggestion}
                                onInsertClick={() => {
                                    add({
                                        ...completionSuggestion,
                                        order: getOrderFromIndex(),
                                    });
                                    setCompletionInstrunction('');
                                    setCompletionSuggestion({});
                                }}
                                onDeleteClick={() => setCompletionSuggestion({})}
                            />
                        </RenderIf>
                    </RightColumn>
                    <ConversationCompletion
                        instruction={completionInstruction}
                        onInstructionChange={setCompletionInstrunction}
                        dataset={dataset}
                        disabled={isLoadingSuggestion || !isEmpty(completionSuggestion)}
                        onSubmit={submitCompletionRequest}
                    />
                </PlaygroundContainer>
            </Content>
            <CompletionSettingsDrawer
                {...completionSettingsDrawerProps}
            />
            <Modal
                isOpen={isOpenImport}
                onRequestClose={() => setIsOpenImport(false)}
                title={<ModalTitle>Import Message</ModalTitle>}
                size="medium"
                borderRadius="semi-rounded"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_medium"
                            label="Close"
                            variant="neutral"
                            borderRadius="semi-rounded"
                            onClick={() => setIsOpenImport(false)}
                        />
                        <ImportButton
                            label="Import"
                            borderRadius="semi-rounded"
                            onClick={importMessages}
                            variant="brand"
                            isLoading={importing}
                        />
                    </div>
                )}
            >
                <Textarea
                    value={messagesToImport}
                    onChange={(e) => setMessagesToImport(e.target.value)}
                    placeholder={importMessagesPlaceholder}
                    rows={10}
                    error={messagesToImportError}
                    disabled={importing}
                />
            </Modal>
            <InsertBuildingBlockModal
                datasetId={datasetId}
                {...inserBuildingBlocksModalProps}
            />
            <StyledModal
                isOpen={isOpenHelpWrite}
                onRequestClose={() => setIsOpenHelpWrite(false)}
                title={<ModalTitle>Help me write the Conversation</ModalTitle>}
                size="medium"
                borderRadius="semi-rounded"
            >
                <HelpMeWriteModal
                    messages={messages}
                />
            </StyledModal>
        </Container>
    );
};

export default Conversation;
