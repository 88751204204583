import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { AddService, EntityGet } from "../../types";
import { Buildingblocks } from "./types";
import get from "./get";

export type BuildingblocksAddService<T> = (
  datasetId: string,
  doc: Omit<T, "id">
) => Promise<EntityGet<T>>;

const add: BuildingblocksAddService<Buildingblocks> = async (
  datasetId,
  data
) => {
  const collectionRef = collection(db, `/dataset/${datasetId}/buildingBlocks`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(datasetId, docRef.id) as Promise<EntityGet<Buildingblocks>>;
};

export default add;
