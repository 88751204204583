import { IconProps } from './types';

const HighCognitiveLoad = ({ title = 'HighCognitiveLoad', className }: IconProps) => (
    <svg className={className} width={15} height={15} viewBox="0 0 15 15" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.71418 0.994847C4.80804 0.361948 6.07826 0 7.4302 0C8.78214 0 10.0524 0.361948 11.1462 0.994847C11.5902 1.25171 11.7418 1.81983 11.485 2.26378C11.2281 2.70772 10.66 2.85938 10.216 2.60252C9.39729 2.1288 8.44685 1.85738 7.4302 1.85738C6.41354 1.85738 5.4631 2.1288 4.64436 2.60252C4.20042 2.85938 3.6323 2.70772 3.37543 2.26378C3.11857 1.81983 3.27023 1.25171 3.71418 0.994847Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.26378 3.3755C2.70772 3.63236 2.85938 4.20048 2.60252 4.64442C2.1288 5.46316 1.85738 6.41361 1.85738 7.43026C1.85738 8.44691 2.1288 9.39735 2.60252 10.2161C2.85938 10.66 2.70772 11.2282 2.26378 11.485C1.81983 11.7419 1.25171 11.5902 0.994847 11.1463C0.361948 10.0524 0 8.7822 0 7.43026C0 6.07832 0.361948 4.8081 0.994847 3.71424C1.25171 3.27029 1.81983 3.11863 2.26378 3.3755Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5367 3.3755C12.9806 3.11863 13.5488 3.27029 13.8056 3.71424C14.4385 4.8081 14.8005 6.07832 14.8005 7.43026C14.8005 8.7822 14.4385 10.0524 13.8056 11.1463C13.5488 11.5902 12.9806 11.7419 12.5367 11.485C12.0927 11.2282 11.9411 10.66 12.1979 10.2161C12.6717 9.39735 12.9431 8.44691 12.9431 7.43026C12.9431 6.41361 12.6717 5.46317 12.1979 4.64442C11.9411 4.20048 12.0927 3.63236 12.5367 3.3755Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.37543 12.7362C3.6323 12.2923 4.20042 12.1406 4.64436 12.3975C5.4631 12.8712 6.41354 13.1426 7.4302 13.1426C8.44685 13.1426 9.39729 12.8712 10.216 12.3975C10.66 12.1406 11.2281 12.2923 11.485 12.7362C11.7418 13.1802 11.5902 13.7483 11.1462 14.0051C10.0524 14.638 8.78214 15 7.4302 15C6.07826 15 4.80804 14.638 3.71418 14.0051C3.27023 13.7483 3.11857 13.1802 3.37543 12.7362Z" fill="currentColor" />
    </svg>
);

export default HighCognitiveLoad;
