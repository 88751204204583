import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { ButtonIcon, RenderIf } from 'react-rainbow-components';
import { MinusCircle } from 'components/icons';
import { Message } from 'components/ChatMessage/types';
import {
    BadgeContainer,
    Container,
    RoleContainer,
    StyledBadge,
    MessageContent,
    StyledTextarea,
    ActionsContainer,
} from './styled';

interface ChatMessageInputProps {
    id: string;
    role?: 'user' | 'assistant' | 'system' | 'event';
    content?: string;
    editMode?: boolean;
    onRemove?: () => void;
    onChange?: (value: Message) => void;
}

const ChatMessageInput = ({
    id,
    role = 'user',
    content: contentProp = '',
    editMode: editModeProp = true,
    onRemove = () => {},
    onChange = () => {},
}: ChatMessageInputProps) => {
    const inputRef = useRef<any>({});
    const [editMode, setEditMode] = useState<boolean>(editModeProp);
    const [content, setContent] = useState(contentProp);

    const handleRoleChange = () => onChange({ content, role: role === 'user' ? 'assistant' : 'user', id });

    const handleContentChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => setContent(event.target.value),
        [],
    );

    const triggerChange = useCallback(
        () => {
            setEditMode(false);
            onChange({ content, role, id });
        },
        [content, id, onChange, role],
    );

    const onKeydown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                triggerChange();
            }
        },
        [triggerChange],
    );

    const onContentClick = useCallback(() => {
        if (!editMode) setEditMode(true);
    }, [editMode]);

    useEffect(() => {
        if (editMode) {
            inputRef.current?.focus();
        }
    }, [editMode]);

    useEffect(() => setContent(contentProp), [contentProp]);

    return (
        <Container role={role} id={id}>
            <RoleContainer onClick={handleRoleChange}>
                <BadgeContainer>
                    <RenderIf isTrue={role === 'user'}>
                        <StyledBadge label="User" borderRadius="semi-square" />
                    </RenderIf>
                    <RenderIf isTrue={role === 'assistant'}>
                        <StyledBadge label="Assistant" borderRadius="semi-square" />
                    </RenderIf>
                </BadgeContainer>
            </RoleContainer>
            <MessageContent onClick={onContentClick}>
                <RenderIf isTrue={!editMode}>
                    {content}
                </RenderIf>
                <RenderIf isTrue={editMode}>
                    <StyledTextarea
                        ref={inputRef}
                        label="Content"
                        value={content}
                        onChange={handleContentChange}
                        onBlur={triggerChange}
                        onKeyDown={onKeydown}
                        hideLabel
                        size="small"
                        rows={1}
                        borderRadius="semi-rounded"
                        grow
                    />
                </RenderIf>
            </MessageContent>
            <ActionsContainer>
                <ButtonIcon
                    assistiveText="delete message"
                    borderRadius="semi-rounded"
                    icon={<MinusCircle />}
                    onClick={onRemove}
                />
            </ActionsContainer>
        </Container>
    );
};

export default ChatMessageInput;
