import { MouseEvent, useMemo } from 'react';
import { Picklist, Option } from 'react-rainbow-components';
import { useReduxForm } from '@rainbow-modules/hooks';

const elevenlabsModels = [
    'eleven_multilingual_v2',
    'eleven_multilingual_v1',
    'eleven_turbo_v2',
    'eleven_turbo_v2_5',
    'eleven_monolingual_v1',
];

interface Props {
    value?: string;
    onChange?: (value?: string) => void;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onFocus?: (value?: string) => void;
    onBlur?: (value?: string) => void;
}

const ElevenLabsModelPicker = (props: Props) => {
    const {
        value,
        onChange = () => {},
        onFocus = () => {},
        onBlur = () => {},
        ...rest
    } = useReduxForm(props);
    const model = elevenlabsModels.find((item) => item === value);
    const picklistValue = {
        label: model,
        name: model,
        value: model,
    };

    const picklistOptions = useMemo(
        () => elevenlabsModels.map(
            (modelId) => <Option key={modelId} name={modelId} label={modelId} value={modelId} />,
        ),
        [],
    );

    return (
        <Picklist
            {...rest}
            onBlur={(newValue) => onBlur(String(newValue?.name))}
            onFocus={(newValue) => onFocus(String(newValue?.name))}
            onChange={(newValue) => onChange(String(newValue?.name))}
            value={picklistValue}
            enableSearch
        >
            {picklistOptions}
        </Picklist>
    );
};

export default ElevenLabsModelPicker;
