import { useState } from 'react';
import {
    ButtonIcon, Button,
} from 'react-rainbow-components';
import { Trash, Pencil } from 'components/icons';
import useChunks from 'data/firestore/agent/knowledgebase/useCollectionWithPagination';
import { useParams } from 'react-router-dom';
import { orderBy, query, where } from 'firebase/firestore';
import { useOpenModal } from '@rainbow-modules/hooks';
import updateDocument from 'data/firestore/agent/document/update';
import removeDocument from 'data/firestore/agent/document/remove';
import { Document } from 'data/firestore/agent/document/types';
import { confirmModal } from '@rainbow-modules/app';
import { ADD_DOCUMENT_MODAL } from '../../../../../constants';
import ChunkItem from './chunckItem';
import {
    DocumentItemContainer,
    DocumentHeader,
    DocumentTitle,
    DocumentDate,
    Actions,
    DocumentContent,
    DocumentFooter,
    StyledFileText,
    DocumentHeaderLeft,
    Row,
    ChunkList,
    StyledPagination,
    StyledBadge,
    AnimatedChevronDown,
    ModalTitle,
} from './styled';

interface DocumentItemProps {
    documentId: string;
    documentTitle?: string | React.ReactNode;
    documentDate?: string | React.ReactNode;
    documentContent?: string | React.ReactNode;
    documentTokens?: string | React.ReactNode;
}

const DocumentItem = (props: DocumentItemProps) => {
    const {
        documentId,
        documentDate,
        documentTitle,
        documentContent,
        documentTokens,
    } = props;

    const { agentId = '' } = useParams();
    const [activeChunkPage, setActiveChunkPage] = useState(1);
    const [isChunksOpen, setIsChunksOpen] = useState(false);
    const [openEditModal, closeEditModal] = useOpenModal(ADD_DOCUMENT_MODAL);

    const {
        data: chunks, nextPage, prevPage, totalRecords,
    } = useChunks(agentId, {
        listQueryFn: (ref) => query(ref, where('metadata.sourceDocumentId', '==', documentId), orderBy('metadata.name', 'asc')),
        limit: 3,
    });

    const handlePageChange = (event: React.MouseEvent<HTMLElement>, page: number) => {
        if (page > activeChunkPage) {
            nextPage();
        } else {
            prevPage();
        }
        setActiveChunkPage(page);
    };

    const edit = () => {
        openEditModal({
            title: <ModalTitle>Edit Document</ModalTitle>,
            submitButtonLabel: 'Save',
            initialValues: {
                name: documentTitle,
                content: documentContent,
            },
            onSubmit: async ({ name, content }: Document) => {
                await updateDocument(agentId, documentId, {
                    name,
                    content,
                });
                closeEditModal();
            },
        });
    };
    const remove = async () => {
        if (await confirmModal({
            variant: 'destructive',
            header: 'Delete Document',
            question:
                'Removing this document will delete it permanently including all it\'s chunks. Are you sure you want to continue?',
            okButtonLabel: 'Delete',
            borderRadius: 'semi-rounded',
        })) {
            await removeDocument(agentId, documentId);
        }
    };

    const chunkBtnText = isChunksOpen ? 'Hide Chunks' : 'View Chunks';
    return (
        <DocumentItemContainer>
            <DocumentHeader>
                <DocumentHeaderLeft>
                    <StyledFileText />
                    <div>
                        <DocumentTitle>
                            {documentTitle}
                        </DocumentTitle>
                        <DocumentDate>
                            {documentDate}
                        </DocumentDate>
                    </div>
                </DocumentHeaderLeft>
                <Actions>
                    <ButtonIcon icon={<Pencil />} borderRadius="semi-rounded" onClick={edit} />
                    <ButtonIcon icon={<Trash />} borderRadius="semi-rounded" onClick={remove} />
                </Actions>
            </DocumentHeader>
            <DocumentContent>
                {documentContent}
            </DocumentContent>
            <DocumentFooter>
                <Row>
                    <div>
                        <StyledBadge label={`${totalRecords} chunks`} borderRadius="semi-square" size="small" />
                        <StyledBadge label={documentTokens} borderRadius="semi-square" size="small" />
                    </div>
                    <Button variant="base" borderRadius="semi-rounded" size="small" onClick={() => setIsChunksOpen(!isChunksOpen)}>
                        {chunkBtnText}
                        <AnimatedChevronDown className="rainbow-m-left_x-small" isOpen={isChunksOpen} />
                    </Button>
                </Row>
                {isChunksOpen && (
                    <>
                        <ChunkList>
                            {chunks.map((chunk) => (
                                <ChunkItem
                                    key={chunk.id}
                                    documentName={documentTitle}
                                    chunkName={chunk.metadata?.name}
                                    chunkTokens="345 Tokens"
                                    chunkContent={chunk.content}
                                />
                            ))}
                        </ChunkList>
                        <StyledPagination
                            activePage={activeChunkPage}
                            onChange={handlePageChange}
                            pages={Math.ceil(totalRecords / 3)}
                        />
                    </>
                )}
            </DocumentFooter>
        </DocumentItemContainer>
    );
};

export default DocumentItem;
