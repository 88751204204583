import { useQueryClient } from 'react-query';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { showAppMessage } from '@rainbow-modules/app';

export interface BuyPhoneRequest {
    number: string;
}

const useBuyPhone = () => {
    const queryClient = useQueryClient();

    return useHttpMutation<BuyPhoneRequest, void>({
        pathname: '/phone-numbers/available',
        method: 'POST',
        onSuccess: () => queryClient.invalidateQueries(
            'twilio-available-phone-numbers',
            {
                refetchActive: true,
                refetchInactive: true,
            },
        ),
        onError: (error) => showAppMessage({
            message: error.message,
            variant: 'error',
            timeout: 5000,
        }),
    });
};

export default useBuyPhone;
