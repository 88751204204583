import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { Document } from "./types";

export type DocumentUpdateService<T> = (
  agentId: string,
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: DocumentUpdateService<Document> = async (agentId, id, data) => {
  const collectionRef = doc(db, `/agent/${agentId}/document/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
