import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { Session } from "./types";

export type SessionUpdateService<T> = (
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: SessionUpdateService<Session> = async (id, data) => {
  const collectionRef = doc(db, `/session/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
