import { ButtonIcon } from 'react-rainbow-components';
import styled, { createGlobalStyle } from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    background-color: #F9F9F9;
    border-radius: 4px;
`;

export const WaveformGlobalStyle = createGlobalStyle`
    
    #wave ::part(cursor) {
        border-radius: 4px;
    }

    #wave ::part(region) {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;     
        overflow: hidden;         
        text-overflow: ellipsis;
        padding: 0 12px;   
    }

    #wave ::part(no-silent) {
        background-color: rgba(247, 192, 43, 0.3) !important;
    }

    #wave ::part(silent) {
        opacity: 0.3 !important;
    }

    #wave ::part(region-content) {
        padding: 0 !important;
        margin: 0 !important;
        white-space: nowrap;     
        overflow: hidden;         
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    #wave ::part(timeline-notch) {
        color: #979797;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

export const Header = styled.div`
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 0 0 12px;
`;

export const PlayButton = styled(ButtonIcon)`

`;

export const Timer = styled.div`
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const Zoom = styled.label`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: ${color('text.label')};
`;

export const Wave = styled.div`
    min-height: 128px;
`;
