import styled from 'styled-components';
import { color } from 'theme';

export const InfoContainer = styled.div`
    display: flex;
    border-radius: 8px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background: rgba(227, 229, 237, 0.05);
    padding: 20px 16px;
    margin-bottom: 2rem;

    & > div {
        min-width: 30%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 12px;
`;

export const PlaceholderText = styled.p`
    font-size: 14px;
    color: ${color('text.header')};
    font-style: italic;
`;
