import { RecordField } from '@rainbow-modules/record';
import styled from 'styled-components';
import { color } from 'theme';

const getBgColor = (selected: boolean) => {
    if (selected) return '#EFEFF6';
    return 'transparent';
};

const getIconBgColor = (role: string) => {
    if (role === 'user') return color('brand.light');
    return color('brand.main');
};

const getIconColor = (role: string) => {
    if (role === 'user') return color('brand.main');
    return 'white';
};

export const MessageContainer = styled.button.withConfig({
    shouldForwardProp: (prop) => !['$selected', '$isLoading'].includes(prop),
})<{ $selected: boolean; $isLoading: boolean }>`
    border-radius: 8px;
    display: flex;
    background: ${(props) => getBgColor(props.$selected)};
    align-items: top;
    padding: 12px;
    gap: 12px;
    border: none;
    outline: none;
    text-align: left;

    pointer-events: ${(props) => (props.$isLoading ? 'none' : 'unset')};

    :hover,
    :focus,
    :active {
        background: ${(props) => getBgColor(!props.$isLoading)};
    }
`;

export const MessageIconContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['$role'].includes(prop),
})<{ $role: string }>`
    border-radius: 8px;
    display: inline-flex;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    flex: 0;
    color: ${(props) => getIconColor(props.$role)};;
    background: ${(props) => getIconBgColor(props.$role)};
`;

export const MessageInfo = styled(RecordField)`
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 0;

    span:first-child {
        font-family: Lato;
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: capitalize;
        color: ${color('text.main')};
    }

    span:last-child {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        color: ${color('text.label')};
    }
`;

export const MessageText = styled.p`
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
`;
