import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

export type ConversationRemoveService = (id: string) => Promise<void>;

const remove: ConversationRemoveService = async (id) => {
  const docRef = doc(db, `/conversation/${id}`);
  return await deleteDoc(docRef);
};

export default remove;
