import { IconProps } from './types';

const Pause = ({ title = 'Pause', className }: IconProps) => (
    <svg className={className} width={8} height={10} viewBox="0 0 8 10" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 0.5C2.05228 0.5 2.5 0.947715 2.5 1.5V8.5C2.5 9.05228 2.05228 9.5 1.5 9.5C0.947715 9.5 0.5 9.05228 0.5 8.5V1.5C0.5 0.947715 0.947715 0.5 1.5 0.5Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 0.5C7.05228 0.5 7.5 0.947715 7.5 1.5V8.5C7.5 9.05228 7.05228 9.5 6.5 9.5C5.94772 9.5 5.5 9.05228 5.5 8.5V1.5C5.5 0.947715 5.94772 0.5 6.5 0.5Z" fill="currentColor" />

    </svg>
);

export default Pause;
