import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    background: ${color('background.main')};
    height: 100%;
    margin-bottom: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 8px 20px;
`;

export const PageTitle = styled.h1`
    font-size: 15px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-item: center;
`;

export const Content = styled.div`
    height: 100%;
    display: flex;
    padding: 8px 16px;
    gap: 24px;
    margin-top: 10px;
    overflow: auto;
    box-sizing: border-box;
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    line-height: 18px;
    color: ${color('text.label')};
`;
