import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../../types";
import { Document } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type DocumentGetService<T> = (
  agentId: string,
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: DocumentGetService<EntityGet<Document>> = async (
  agentId,
  id,
  opts = {}
) => {
  const docRef = doc(db, `/agent/${agentId}/document/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Document>)
    );
  }
  return null;
};

export default get;
