import { useCallback } from 'react';
import { MagnifyingGlass } from '@rainbow-modules/icons';
import DatePickerFilter from 'components/DatePickerFilter';
import AgentPicker from 'components/AgentPicker';
import {
    FormContainer,
    StyledInput,
    SelectorsContainer,
} from './styled';
import { SearchFormProps } from './types';

const SearchForm = ({
    selectedDate,
    selectedAgents,
    searchTerm,
    onChange = () => {},
}: SearchFormProps) => {
    const selectAgent = useCallback(
        (value: string[] | null) => onChange({
            text: searchTerm,
            date: selectedDate,
            agents: value || undefined,
        }),
        [onChange, searchTerm, selectedDate],
    );

    const selectDate = useCallback(
        (value: Date[]) => onChange({
            text: searchTerm,
            date: value,
            agents: selectedAgents,
        }),
        [onChange, searchTerm, selectedAgents],
    );

    const searchValue = useCallback(
        (value: string) => onChange({
            date: selectedDate,
            agents: selectedAgents,
            text: value,
        }),
        [onChange, selectedDate, selectedAgents],
    );

    return (
        <FormContainer>
            <StyledInput
                placeholder="Find conversation..."
                borderRadius="semi-rounded"
                icon={<MagnifyingGlass />}
                onChange={(e) => searchValue(e.target.value)}
                value={searchTerm as string}
                size="small"
            />
            <SelectorsContainer>
                <AgentPicker
                    borderRadius="semi-rounded"
                    size="small"
                    hideLabel
                    value={selectedAgents}
                    selectionType="multiple"
                    onChange={selectAgent}
                />
                <DatePickerFilter
                    placeholder="Select Date"
                    borderRadius="semi-rounded"
                    size="small"
                    value={selectedDate}
                    onChange={selectDate}
                />
            </SelectorsContainer>
        </FormContainer>
    );
};

export default SearchForm;
