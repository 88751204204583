import { useContext, useMemo } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { RenderIf, Badge, Chip } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import { isEmpty } from '@rainbow-modules/validation';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import ModelLink from 'components/ModelLink';
import SentimentTile from 'components/SentimentTile';
import useAgent from 'data/firestore/agent/use';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import formatter from 'data/services/date/formatter';
import useSessionMetadata from 'hooks/useSessionMetadata';
import CallStatusBadge from 'components/CallStatusBadge';
import { PageHeaderTitle } from 'components/styled';
import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import HumanEvaluator from '../humanEvaludator';
import context, { Context } from '../context';
import {
    StyledSpinner,
    InsightsContainer,
    StyledLoadingShape,
} from '../styled';
import {
    InfoContainer, StyledRecordPrimaryDetails, StyledRecordDetails, Content,
} from './styled';

const CampaignLink = ({
    agentId,
    value,
}: {
    agentId: string;
    value: string;
}) => <RouterLink to={`/agents/${agentId}/campaigns/${value}/calls`}>{value}</RouterLink>;

const Link = ({ value }: { value: string }) => <ModelLink versionName={value} />;

const ConversationDetails = () => {
    const { conversationId = '' } = useParams();
    const {
        sessionData: data, isLoading,
    } = useContext<Context>(context);

    const {
        data: agentInfo,
        isLoading: isLoadingAgentInfo,
    } = useAgent(data?.agentId || '', { disabled: !data });

    const [conversationSummary, isLoadingConversationSummary] = useDocOnce({
        path: `/session/${conversationId}/metadata/summary`,
        flat: true,
    });

    const { metadata = [], isLoading: isLoadingMetadata } = useSessionMetadata(data?.id, ['modelConfig', 'postcall']);

    const { model, temperature, systemMessage } = useMemo(
        () => metadata.find((item) => item.id === 'modelConfig') || {},
        [metadata],
    );

    const tags = useMemo(
        // eslint-disable-next-line no-underscore-dangle
        () => (data?._tags || []).map(
            (tag) => (
                <Badge
                    key={`tag__${tag}`}
                    className="rainbow-m-left_small"
                    variant="default"
                    borderRadius="semi-square"
                    size="medium"
                >
                    {tag}
                </Badge>
            ),
        ),
        // eslint-disable-next-line no-underscore-dangle
        [data?._tags],
    );

    const topics = useMemo(
        () => {
            const {
                metadata: postCallMetadata,
            } = metadata.find((item) => item.id === 'postcall') || {} as Record<string, unknown>;
            const callTopics = (
                postCallMetadata as Record<string, unknown>
            )?.topics as Record<string, unknown>[];
            return (callTopics || []).map(
                (topic: Record<string, unknown>) => (
                    <Chip
                        borderRadius="semi-square"
                        label={(
                            <>
                                {`${topic.topic}`}
                                <Badge
                                    key={`topic__${topic.id}`}
                                    className="rainbow-m-left_small"
                                    variant="brand"
                                    borderRadius="rounded"
                                    size="small"
                                >
                                    {`score: ${topic.confidenceScore}`}
                                </Badge>
                            </>
                        )}
                    />
                ),
            );
        },
        [metadata],
    );

    return (
        <Content>
            <RenderIf isTrue={isLoading || isLoadingMetadata}>
                <StyledSpinner size="small" type="arc" variant="brand" />
            </RenderIf>
            <RenderIf isTrue={!isLoading && !isLoadingMetadata}>
                <InsightsContainer>
                    <PageHeaderTitle>Insights</PageHeaderTitle>
                    <StyledRecordPrimaryDetails>
                        <RecordField
                            label="SENTIMENT"
                            value={data?.sentiment}
                            isLoading={isLoading}
                            component={SentimentTile}
                        />
                        <RecordField
                            label="START TIME"
                            value={formatter.format((data as EntityGet<Session>)?.createdAt)}
                            isLoading={isLoading}
                        />
                        <RecordField label="DURATION" value={data?.duration} isLoading={isLoading} />
                        <RecordField label="STATUS" value={<CallStatusBadge status={data?.status} />} isLoading={isLoading} />
                        <RecordField label="Company" value={agentInfo?.companyName} isLoading={isLoadingAgentInfo} />
                        <RecordField label="FROM" value={formatIntlPhoneNumber(data?.from)} isLoading={isLoading} />
                        <RecordField label="TO" value={formatIntlPhoneNumber(data?.to)} isLoading={isLoading} />
                        <RenderIf isTrue={data?.campaignId}>
                            <RecordField
                                label="CAMPAIGN"
                                value={data?.campaignId}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                agentId={data?.agentId}
                                component={CampaignLink}
                            />
                        </RenderIf>
                        <RecordField label="Type" value={<Badge borderRadius="semi-square" label={data?.direction} />} isLoading={isLoading} />
                    </StyledRecordPrimaryDetails>
                </InsightsContainer>
                <RenderIf isTrue={!!conversationSummary?.summary}>
                    <PageHeaderTitle className="rainbow-m-top_medium">Summary</PageHeaderTitle>
                    <InfoContainer>
                        <RenderIf isTrue={isLoadingConversationSummary}>
                            <StyledLoadingShape $width="80%" />
                            <StyledLoadingShape $width="90%" />
                            <StyledLoadingShape $width="70%" />
                        </RenderIf>
                        <RenderIf isTrue={!isLoadingConversationSummary}>
                            {conversationSummary?.summary}
                        </RenderIf>
                    </InfoContainer>
                </RenderIf>
                <RenderIf isTrue={!isEmpty(metadata)}>
                    <PageHeaderTitle className="rainbow-m-top_medium">Details</PageHeaderTitle>
                    <InfoContainer>
                        <StyledRecordDetails>
                            <RecordField
                                label="Language"
                                value={data?.language?.join(',').toUpperCase()}
                                isLoading={isLoading}
                            />
                            <RecordField
                                label="Human Evaluation"
                                value={(
                                    <HumanEvaluator
                                        conversationId={conversationId}
                                        value={data?.humanEvaluation}
                                    />
                                )}
                                isLoading={isLoading}
                            />
                        </StyledRecordDetails>
                        <StyledRecordDetails>
                            <RecordField
                                isLoading={isLoading}
                                label="Model:"
                                component={Link}
                                value={model as string}
                            />
                            <RecordField
                                isLoading={isLoading}
                                label="Temperature:"
                                type="number"
                                value={temperature as number}
                            />
                        </StyledRecordDetails>
                    </InfoContainer>
                    <PageHeaderTitle className="rainbow-m-top_medium">Tags</PageHeaderTitle>
                    <InfoContainer>
                        <div>{tags}</div>
                    </InfoContainer>
                    <PageHeaderTitle className="rainbow-m-top_medium">Topics</PageHeaderTitle>
                    <InfoContainer>
                        <div>{topics}</div>
                    </InfoContainer>
                    <PageHeaderTitle className="rainbow-m-top_medium">System Message</PageHeaderTitle>
                    <InfoContainer className="rainbow-m-bottom_large">{systemMessage as string}</InfoContainer>
                </RenderIf>
            </RenderIf>
        </Content>
    );
};

export default ConversationDetails;
