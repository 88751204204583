import { IconProps } from './types';

const Maximize = ({ title = 'Maximize', className }: IconProps) => (
    <svg className={className} width={16} height={16} viewBox="0 0 16 16">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.5 2C9.5 1.72386 9.72386 1.5 10 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V6C14.5 6.27614 14.2761 6.5 14 6.5C13.7239 6.5 13.5 6.27614 13.5 6V2.5H10C9.72386 2.5 9.5 2.27614 9.5 2Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 9.5C2.27614 9.5 2.5 9.72386 2.5 10V13.5H6C6.27614 13.5 6.5 13.7239 6.5 14C6.5 14.2761 6.27614 14.5 6 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V10C1.5 9.72386 1.72386 9.5 2 9.5Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3537 1.64645C14.549 1.84171 14.549 2.15829 14.3537 2.35355L9.68705 7.02022C9.49179 7.21548 9.1752 7.21548 8.97994 7.02022C8.78468 6.82496 8.78468 6.50838 8.97994 6.31311L13.6466 1.64645C13.8419 1.45118 14.1585 1.45118 14.3537 1.64645Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.02022 8.97848C7.21548 9.17374 7.21548 9.49032 7.02022 9.68558L2.35355 14.3523C2.15829 14.5475 1.84171 14.5475 1.64645 14.3523C1.45118 14.157 1.45118 13.8404 1.64645 13.6451L6.31311 8.97848C6.50838 8.78322 6.82496 8.78322 7.02022 8.97848Z" fill="currentColor" />
    </svg>
);

export default Maximize;
