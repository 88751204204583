import { DefaultTheme } from 'styled-components';
import { CONFIDENCE_HIGH, CONFIDENCE_LOW } from './constants';

declare module 'styled-components' {
    interface DefaultTheme {
        rainbow: {
            palette: {
                [color: string]: {
                    [type: string]: string;
                };
            };
        };
    }
}

interface StyledThemeProps {
    theme: DefaultTheme;
}

export const color = (name: string): ((props: StyledThemeProps) => string) => {
    const [colorName, type] = name.split('.');
    return (props) => props.theme.rainbow.palette[colorName][type];
};

export const getConfidenceColor = (props: StyledThemeProps & { value?: number }) => {
    const { value } = props;
    if (value) {
        if (value >= CONFIDENCE_HIGH) {
            return `
                color: ${props.theme.rainbow.palette.text.label};
            `;
        }
        if (value < CONFIDENCE_LOW) {
            return `
                color: ${props.theme.rainbow.palette.error.main};
            `;
        }
        return `
                color: ${props.theme.rainbow.palette.warning.main};
            `;
    }
    return `
        color: ${props.theme.rainbow.palette.text.label};
    `;
};

export const getConfidenceColors = (props: StyledThemeProps & { value: number }) => {
    const { value } = props;
    if (value >= CONFIDENCE_HIGH) {
        return `
            color: ${props.theme.rainbow.palette.success.main};
            background: ${props.theme.rainbow.palette.success.light};
        `;
    }
    if (value < CONFIDENCE_LOW) {
        return `
            color: ${props.theme.rainbow.palette.error.main};
            background: ${props.theme.rainbow.palette.error.light};
        `;
    }
    return `
            color: ${props.theme.rainbow.palette.warning.main};
            background: ${props.theme.rainbow.palette.warning.light};
        `;
};
