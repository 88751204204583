import { IconProps } from './types';

const Trash = ({ title = 'Trash', className }: IconProps) => (
    <svg className={className} width={20} height={22} viewBox="0 0 20 22" fill="currentColor">
        <title>{title}</title>
        <path
            d="M0.5 5C0.5 4.72386 0.723858 4.5 1 4.5H19C19.2761 4.5 19.5 4.72386 19.5 5C19.5 5.27614 19.2761 5.5 19 5.5H1C0.723858 5.5 0.5 5.27614 0.5 5Z"
        />
        <path
            d="M8 1.5C7.60218 1.5 7.22064 1.65804 6.93934 1.93934C6.65804 2.22064 6.5 2.60218 6.5 3V5C6.5 5.27614 6.27614 5.5 6 5.5C5.72386 5.5 5.5 5.27614 5.5 5V3C5.5 2.33696 5.76339 1.70107 6.23223 1.23223C6.70107 0.763392 7.33696 0.5 8 0.5H12C12.663 0.5 13.2989 0.763392 13.7678 1.23223C14.2366 1.70107 14.5 2.33696 14.5 3V5C14.5 5.27614 14.2761 5.5 14 5.5C13.7239 5.5 13.5 5.27614 13.5 5V3C13.5 2.60218 13.342 2.22064 13.0607 1.93934C12.7794 1.65804 12.3978 1.5 12 1.5H8ZM3 4.5C3.27614 4.5 3.5 4.72386 3.5 5V19C3.5 19.3978 3.65804 19.7794 3.93934 20.0607C4.22064 20.342 4.60218 20.5 5 20.5H15C15.3978 20.5 15.7794 20.342 16.0607 20.0607C16.342 19.7794 16.5 19.3978 16.5 19V5C16.5 4.72386 16.7239 4.5 17 4.5C17.2761 4.5 17.5 4.72386 17.5 5V19C17.5 19.663 17.2366 20.2989 16.7678 20.7678C16.2989 21.2366 15.663 21.5 15 21.5H5C4.33696 21.5 3.70107 21.2366 3.23223 20.7678C2.76339 20.2989 2.5 19.663 2.5 19V5C2.5 4.72386 2.72386 4.5 3 4.5Z"
        />
    </svg>
);

export default Trash;
