import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid rgb(215, 217, 226);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(215, 217, 226) 0px 2px 4px 0px;
    transition: all 0.1s linear 0s;
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-width: 600px;
`;

export const ProcessName = styled.h1`
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`;
