import { useCallback } from 'react';
import useAgents from 'data/firestore/agent/useCollection';
import { AgentPickerProps } from './types';
import AgentPickerVariantMultiple from './multiple';
import AgentPickerVariantSingle from './single';

const AgentPicker = (props: AgentPickerProps) => {
    const {
        selectionType = 'single',
        value = [],
        onChange = () => {},
        name,
        ...rest
    } = props;

    const { data: agents = [], isLoading } = useAgents();

    const handleSingleSelectOnChange = useCallback(
        (newValue: string | null) => {
            if (newValue) return onChange([newValue]);
            return onChange(null);
        },
        [onChange],
    );

    if (selectionType === 'multiple') {
        return (
            <AgentPickerVariantMultiple
                {...rest}
                agents={agents}
                isLoading={isLoading}
                onChange={onChange}
                value={value}
            />
        );
    }

    return (
        <AgentPickerVariantSingle
            {...rest}
            name={name}
            agents={agents}
            isLoading={isLoading}
            value={value?.at(0)}
            onChange={handleSingleSelectOnChange}
        />
    );
};

export default AgentPicker;
