import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';
import { color } from 'theme';

export const InfoContainer = styled.div`
    display: flex;
    border-radius: 8px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background: rgba(227, 229, 237, 0.05);
    padding: 20px 16px;
    margin: 10px 0 20px;

    & > div {
        min-width: 30%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

export const Content = styled.div`
    padding: 20px 20px 8px;
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: ${color('text.label')};
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    top: 24px;
    left: 50%;
    z-index: unset;
    transform-origin: 0px 50% 0px
`;
