import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    padding-top: 16px;
    gap: 32px;
`;

export const RightContent = styled.div`
    flex-grow: 1;
`;

export const SectionHeader = styled.header`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 220px;
    min-width: 220px;
    box-sizing: border-box;
`;

export const ChartContainer = styled.div`
    background: #F7F9FC;
    border-radius: 10px;
    padding: 24px 24px 8px 20px;
`;
