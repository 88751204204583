import { db } from 'data/firebase';
import { onSnapshot, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface IntentMetadata {
    name: string;
}

const useIntent = (
    messageId: string,
    collectionPath: string = '/message',
) => {
    const [metadata, setMetadata] = useState<IntentMetadata | null>(null);
    useEffect(() => {
        const ref = doc(db, `${collectionPath}/${messageId}/metadata/intent`);
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (snapshot.exists()) {
                setMetadata(snapshot.data() as IntentMetadata);
            }
        });
        return () => unsubscribe();
    }, [messageId, collectionPath]);
    return metadata;
};

export default useIntent;
