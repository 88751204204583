import { UniversalForm } from '@rainbow-modules/forms';
import { Slider } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen?: boolean }>`
    background: ${color('background.main')};
    display: flex;
    overflow: hidden;
    gap: 8px;
    transition: all 500ms ease;

    width: ${(props) => (props.isOpen ? '200px' : '0px')};
`;

export const StyledSlider = styled(Slider)`
    input::-moz-range-progress {
        background: ${color('brand.main')};
    }
`;

export const StyledUniversalForm = styled(UniversalForm)`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const SubmitButton = styled.button`
    display: none;
`;
