// colors
export const BRAND = '#A182E0';
export const SUCCESS = '#75d093';
export const WARNING = '#eb8f00';
export const ERROR = '#fd5556';
export const BACKGROUND = '#FBF9FF';
export const TEXT_MAIN = '#7F67BE';
export const ACTION_HIGHLIGHT = '#EAE0FF';

export const CONFIDENCE_HIGH = 0.8;
export const CONFIDENCE_LOW = 0.45;

// responsive
export const screenSize = {
    mobile: 520,
    tablet: 820,
    laptop: 1024,
};
export const mediaSize = {
    mobile: `(max-width: ${screenSize.mobile}px)`,
    tablet: `(max-width: ${screenSize.tablet}px)`,
    laptop: `(max-width: ${screenSize.laptop}px)`,
};
export const mediaSizeMinWidth = {
    mobile: `(min-width: ${screenSize.mobile}px)`,
    tablet: `(min-width: ${screenSize.tablet}px)`,
    laptop: `(min-width: ${screenSize.laptop}px)`,
};

// sizes
export const TOP_BAR_HEIGHT = 76;

export const ITEMS_PER_PAGE = 20;

export const ADD_MODIFY_AGENT_DRAWER = 'add-modify-agent__drawer';
export const ADD_CAMPAIGN_DRAWER = 'add-campaign__drawer';
export const ADD_DOCUMENT_MODAL = 'add-document';
export const SHOW_CHUNK_DETAILS_MODAL = 'show-chunk-details';
export const SHOW_USAGE_RECORD_DETAILS_MODAL = 'show-usage-record-details';
export const CREATE_MODEL_MODAL = 'create-model-modal';
export const BUILDING_BLOCK_MODAL = 'building-block-modal';
export const INSERT_BUILDING_BLOCK_MODAL = 'insert-building-block-modal';
export const COMPLETION_SETTINGS_DRAWER = 'completion-settings-drawer';

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

// supported agent languages
export const availableLanguages = [
    { name: 'en', label: 'English (US)' },
    { name: 'es', label: 'Español (US)' },
];

// states of USA
export const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

export const BASIC_MODELS = [
    'gpt-3.5-turbo-16k',
    'gpt-3.5-turbo-1106',
    'gpt-3.5-turbo-0125',
    'gpt-3.5-turbo',
    'gpt-4',
    'gpt-4-32k',
    'gpt-4-1106-preview',
    'gpt-4-0125-preview',
    'gpt-4-turbo-2024-04-09',
    'gpt-4-turbo',
    'gpt-4o-2024-05-13',
    'gpt-4o-2024-08-06',
];
