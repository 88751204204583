import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { AddService, EntityGet } from "../../types";
import { Subscriptions } from "./types";
import get from "./get";

export type SubscriptionsAddService<T> = (
  agentId: string,
  doc: Omit<T, "id">
) => Promise<EntityGet<T>>;

const add: SubscriptionsAddService<Subscriptions> = async (agentId, data) => {
  const collectionRef = collection(db, `/agent/${agentId}/subscriptions`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(agentId, docRef.id) as Promise<EntityGet<Subscriptions>>;
};

export default add;
