import SentimentBadge from 'components/SentimentBadge';
import { SentimentTileWrapper } from './styled';

interface SentimentTileProps {
    value?: {
        score: number | null;
        magnitude: number | null;
        label: string;
    };
}

const SentimentTile = (props: SentimentTileProps) => {
    const { value } = props;
    if (!value) return null;
    return (
        <SentimentTileWrapper>
            <SentimentBadge sentiment={value} />
        </SentimentTileWrapper>
    );
};

export default SentimentTile;
