import { IconProps } from './types';

const CsvFile = ({ title = 'Csv File', className }: IconProps) => (
    <svg className={className} width="20px" height="23px" viewBox="0 0 20 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="contacts_groups-copy" transform="translate(-1001.000000, -238.000000)">
                <g id="csv" transform="translate(1001.000000, 238.000000)">
                    <path d="M1.785,-1.42108547e-15 C0.805,-1.42108547e-15 0,0.805 0,1.785 L0,20.615 C0,21.595 0.805,22.4 1.785,22.4 L17.815,22.4 C18.795,22.4 19.6,21.595 19.6,20.615 L19.6,7.105 L12.95,-1.42108547e-15 L1.785,-1.42108547e-15 Z" id="Path" fill="#2EC4B6" />
                    <path d="M7.105,15.12 C7.14,15.155 7.175,15.225 7.175,15.295 C7.175,15.435 7.07,15.54 6.93,15.54 C6.86,15.54 6.79,15.54 6.755,15.47 C6.51,15.19 6.09,14.98 5.705,14.98 C4.795,14.98 4.095,15.68 4.095,16.695 C4.095,17.675 4.795,18.41 5.705,18.41 C6.09,18.41 6.475,18.235 6.755,17.92 C6.79,17.885 6.86,17.85 6.93,17.85 C7.07,17.85 7.175,17.955 7.175,18.095 C7.175,18.165 7.14,18.235 7.105,18.27 C6.79,18.62 6.335,18.865 5.705,18.865 C4.48,18.865 3.535,17.99 3.535,16.695 C3.535,15.4 4.48,14.525 5.705,14.525 C6.335,14.525 6.79,14.77 7.105,15.12 Z M9.485,18.865 C8.855,18.865 8.365,18.655 8.015,18.34 C7.945,18.305 7.945,18.235 7.945,18.165 C7.945,18.025 8.015,17.885 8.19,17.885 C8.225,17.885 8.295,17.92 8.33,17.955 C8.61,18.2 9.03,18.41 9.52,18.41 C10.255,18.41 10.5,17.99 10.5,17.675 C10.5,16.59 8.015,17.185 8.015,15.68 C8.015,15.015 8.61,14.525 9.45,14.525 C9.975,14.525 10.465,14.7 10.815,14.98 C10.85,15.015 10.885,15.085 10.885,15.155 C10.885,15.295 10.78,15.4 10.64,15.4 C10.605,15.4 10.535,15.4 10.5,15.365 C10.185,15.12 9.8,14.98 9.415,14.98 C8.89,14.98 8.54,15.26 8.54,15.645 C8.54,16.59 11.025,16.065 11.025,17.64 C11.025,18.235 10.605,18.865 9.485,18.865 Z M15.435,14.945 L13.965,18.55 C13.895,18.725 13.755,18.83 13.58,18.83 L13.545,18.83 C13.37,18.83 13.195,18.725 13.125,18.55 L11.69,14.945 C11.655,14.91 11.655,14.875 11.655,14.84 C11.655,14.7 11.76,14.56 11.935,14.56 C12.04,14.56 12.145,14.63 12.18,14.735 L13.545,18.235 L14.91,14.735 C14.945,14.63 15.05,14.56 15.155,14.56 C15.33,14.56 15.47,14.7 15.47,14.84 C15.47,14.875 15.435,14.91 15.435,14.945 L15.435,14.945 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero" />
                    <g id="Group" transform="translate(12.950000, 0.000000)" fillRule="nonzero">
                        <path d="M6.65,7.14 L6.65,7.49 L2.17,7.49 C2.17,7.49 -0.035,7.035 0.035,5.11 C0.035,5.11 0.105,7.14 2.135,7.14 L6.65,7.14 Z" id="Path" fillOpacity="0.110850087" fill="#000000" />
                        <path d="M0.035,0 L0.035,5.11 C0.035,5.67 0.42,7.14 2.17,7.14 L6.65,7.14 L0.035,0 Z" id="Path" fill="#FFFFFF" opacity="0.5" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default CsvFile;
