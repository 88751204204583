import { Conversation } from 'data/firestore/session/conversation/types';
import { Region } from 'components/ConversationPlayer/types';

const getUserRegions = (messages: Conversation[]) => {
    let userStartTimeMark: number | null = null;
    return messages.reduce((acc, msg) => {
        if (msg.role === 'event') {
            const content: { event: string } = JSON.parse(msg.content);
            if (content.event === 'user-start-speak') {
                userStartTimeMark = msg.timeMark as number / 1000;
            }
        } if (msg.role === 'user' && userStartTimeMark !== null) {
            acc.push({
                role: 'user',
                content: msg.content,
                start: userStartTimeMark,
                end: msg.timeMark as number / 1000,
            });
            userStartTimeMark = null;
        } else {
            console.error('we have a message from the user without a corresponding "user-start-speak" event');
        }
        return acc;
    }, [] as Region[]);
};

const getAssistantRegions = (messages: Conversation[]) => {
    let assistantStartTimeMark: number | null = null;
    return messages.reduce((acc, msg) => {
        if (msg.role === 'event') {
            const content: { event: string } = JSON.parse(msg.content);
            switch (content.event) {
                case 'assistant-start-speak':
                    assistantStartTimeMark = msg.timeMark as number / 1000;
                    break;
                default:
            }
        } else if (msg.role === 'assistant' && assistantStartTimeMark !== null) {
            acc.push({
                role: 'assistant',
                content: msg.content,
                start: assistantStartTimeMark,
                end: msg.timeMark as number / 1000,
            });
            assistantStartTimeMark = null;
        } else {
            console.error('we have a message from the assistant without a corresponding "assistant-start-speak" event');
        }
        return acc;
    }, [] as Region[]);
};

const getSyntetizeRegions = (
    messages: Conversation[],
) => messages.reduce((acc, msg) => {
    if (msg.role === 'event') {
        const content: {
            event: string,
            data: {
                text: string,
                metadata: {
                    id: string,
                    language: string,
                }
            },
        } = JSON.parse(msg.content);
        switch (content.event) {
            case 'synthesize-llm-response':
                acc.push({
                    role: 'synthesize',
                    content: content.data.text,
                    start: msg.timeMark as number / 1000,
                    end: 0,
                });
                break;
            case 'end-synthesize-llm-response':
                acc[acc.length - 1].end = msg.timeMark as number / 1000;
                break;
            default:
        }
    }
    return acc;
}, [] as Region[]);

interface RegionsObject {
    [key: string]: Region,
}
const getLlmRegions = (messages: Conversation[]) => {
    const regions = messages.reduce((acc, msg) => {
        if (msg.role === 'event') {
            const content = JSON.parse(msg.content) as {
                event: string,
                data: {
                    id: string,
                    output: string,
                },
            };
            switch (content.event) {
                case 'ask-agent':
                    acc[content.data.id] = {
                        role: 'agent-call',
                        content: '',
                        start: msg.timeMark as number / 1000,
                        end: 0,
                    };
                    break;
                case 'agent-response':
                    acc[content.data.id].content = content.data.output;
                    acc[content.data.id].end = msg.timeMark as number / 1000;
                    break;
                default:
            }
        }
        return acc;
    }, {} as RegionsObject);
    return Object.values(regions);
};

const getTranscriptions = (messages: Conversation[]) => messages.reduce((acc, msg) => {
    if (msg.role === 'event') {
        const content = JSON.parse(msg.content) as {
            event: string,
            data: {
                text: string,
                confidence: number,
            },
        };
        if (content.event === 'final-transcript') {
            const mark = msg.timeMark as number / 1000;
            acc.push({
                role: 'final-transcript',
                content: {
                    transcript: content.data.text,
                    confidence: content.data.confidence,
                },
                start: mark - 1,
                end: mark,
            });
        }
    }
    return acc;
}, [] as Region[]);

const getBargeInRegions = (messages: Conversation[]) => messages.reduce((acc, msg) => {
    if (msg.role === 'event') {
        const content = JSON.parse(msg.content) as {
            event: string,
        };
        if (content.event === 'barge-in') {
            const mark = msg.timeMark as number / 1000;
            acc.push({
                role: 'barge-in',
                content: '',
                start: mark - 1,
                end: mark,
            });
        }
    }
    return acc;
}, [] as Region[]);

const getRegions = (
    messages: Conversation[],
) => getAssistantRegions(messages)
    .concat(getUserRegions(messages))
    .concat(getSyntetizeRegions(messages))
    .concat(getLlmRegions(messages))
    .concat(getTranscriptions(messages))
    .concat(getBargeInRegions(messages));

export default getRegions;
