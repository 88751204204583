import { useCallback, useMemo } from 'react';
import { orderBy, query } from 'firebase/firestore';
import {
    confirmModal, hideAppSpinner, showAppMessage, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import { isEmpty } from '@rainbow-modules/validation';
import styled from 'styled-components';
import { Buildingblocks } from 'data/firestore/dataset/buildingBlocks/types';
import useCollection from 'data/firestore/dataset/buildingBlocks/useCollection';
import createBuildingBlock from 'data/firestore/dataset/buildingBlocks/add';
import updateBuildingBlock from 'data/firestore/dataset/buildingBlocks/update';
import removeBuildingBlock from 'data/firestore/dataset/buildingBlocks/remove';
import { BUILDING_BLOCK_MODAL } from '../../constants';

const ModalHeader = styled.h1`
    font-size: 22px;
    margin: 16px 24px;
`;

const useBuildingBlocks = ({
    datasetId,
    fetchData = true,
}: {
    datasetId: string;
    fetchData?: boolean;
}) => {
    const [openModal, closeModal] = useOpenModal(BUILDING_BLOCK_MODAL);

    const { data = [], isLoading } = useCollection(datasetId, {
        listQueryFn: (ref) => query(ref, orderBy('name', 'asc')),
        disabled: isEmpty(datasetId) || !fetchData,
        track: [datasetId],
    });

    const [openForm, closeForm] = useMemo(() => ([
        (props: Record<string, unknown> = { title: 'New Building Block' }) => openModal({
            cancelButtonLabel: 'Close',
            submitButtonLabel: 'Create Building Block',
            ...props,
            size: 'medium',
            title: <ModalHeader>{props.title as string}</ModalHeader>,
        }),
        closeModal,
    ]), [openModal, closeModal]);

    const add = useCallback(() => openForm({
        initialValues: { role: 'user' },
        onSubmit: async ({
            name, role, content,
        }: { name: string; role: 'user' | 'assistant'; content: string; }) => {
            try {
                showAppSpinner();
                await createBuildingBlock(datasetId, {
                    name, role, content,
                });
                closeForm();
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: (error as Error).message,
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
    }), [closeForm, datasetId, openForm]);

    const edit = useCallback((entityData: Buildingblocks) => openForm({
        title: 'Edit Building Block',
        submitButtonLabel: 'Update Building Block',
        initialValues: entityData,
        onSubmit: async ({
            name, role, content,
        }: { name: string; role: 'user' | 'assistant'; content: string; }) => {
            try {
                showAppSpinner();
                await updateBuildingBlock(datasetId, entityData.id, {
                    name, role, content,
                });
                closeForm();
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: (error as Error).message,
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
    }), [closeForm, datasetId, openForm]);

    const remove = useCallback(async (blockId: string) => {
        if (
            await confirmModal({
                variant: 'destructive',
                header: 'Delete Building Block',
                question:
                    'Removing this building block will delete it permanently. Are you sure you want to continue?',
                okButtonLabel: 'Delete',
                borderRadius: 'semi-rounded',
            })
        ) {
            try {
                showAppSpinner();
                await removeBuildingBlock(datasetId, blockId);
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: (error as Error).message,
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        }
    }, [datasetId]);

    return {
        data,
        isLoading,
        add,
        edit,
        remove,
        close: closeForm,
    };
};

export default useBuildingBlocks;
