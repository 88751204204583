import { useOpenModal } from '@rainbow-modules/hooks';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Label,
} from 'recharts';
import usePcaEmbeddings from 'data/firestore/agent/pcaembeddings/useCollection';
import { useParams } from 'react-router-dom';
import { limit, orderBy, query } from 'firebase/firestore';
import getChunk from 'data/firestore/agent/knowledgebase/get';
import { RenderIf } from 'react-rainbow-components';
import { PageHeaderTitle } from 'components/styled';
import { BRAND } from '../../../../../../constants';
import { Row, LeyendBadge } from './styled';
import {
    SectionHeader,
    SectionDescription,
    ChartContainer,
} from '../styled';

const KnowledgeBase = () => {
    const [openModal] = useOpenModal('show-chunk-details');
    const { agentId } = useParams();
    const { data, isLoading } = usePcaEmbeddings(agentId as string, {
        listQueryFn: (ref) => query(
            ref,
            orderBy('createdAt', 'desc'),
            limit(1),
        ),
    });
    const handleClick = async (_: unknown, index: number) => {
        const chunkId = data[0].ids[index];
        const chunk = await getChunk(agentId as string, chunkId);
        openModal({
            chunkName: chunk?.metadata?.name,
            chunkTokens: '???',
            documentName: chunk?.metadata?.sourceDocumentName,
            chunkContent: chunk?.content,
        });
    };
    const chartData = data.length > 0
        ? (JSON.parse(data[0].embeddings) as Array<Array<number>>).reduce((acc, curr) => {
            acc.push({
                x: curr[0],
                y: curr[1],
            });
            return acc;
        }, [] as Array<{ x: number; y: number }>)
        : [];
    const documents = data[0] && data[0].documents;
    const chunks = data[0] && data[0].ids.length;
    return (
        <>
            <SectionHeader>
                <PageHeaderTitle>Knowledge Base Insights</PageHeaderTitle>
                <SectionDescription>
                    Knowledge Base document proximity analysis.
                </SectionDescription>
            </SectionHeader>
            <ChartContainer>
                <Row>
                    <RenderIf isTrue={!isLoading}>
                        <LeyendBadge label={`${documents} ${documents > 1 ? 'Documents' : 'Document'}`} borderRadius="semi-square" />
                        <LeyendBadge label={`${chunks} ${chunks > 1 ? 'Chunks' : 'Chunk'}`} borderRadius="semi-square" size="small" />
                    </RenderIf>
                </Row>
                <ResponsiveContainer width="100%" height={350}>
                    <ScatterChart
                        margin={{
                            top: 8,
                            right: 8,
                            left: -24,
                            bottom: 8,
                        }}
                    >
                        <Label value="Knowledge Base document proximity" offset={0} position="top" />
                        <XAxis type="number" dataKey="x" name="first" />
                        <YAxis type="number" dataKey="y" name="second" />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Scatter name="PCA" data={chartData} fill={BRAND} onClick={handleClick} />
                    </ScatterChart>
                </ResponsiveContainer>
            </ChartContainer>
        </>
    );
};

export default KnowledgeBase;
