import { Spinner, TableWithBrowserPagination } from 'react-rainbow-components';
import { Clock } from 'components/icons';
import styled from 'styled-components';
import { color } from 'theme';
import { NavigationButtonColumn } from '@rainbow-modules/listview';

export const Container = styled.div`
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
`;

export const LeftColumn = styled.div`
    width: 250px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
`;

export const RightColumn = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isFullWidth'].includes(prop),
})<{ isFullWidth?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    
    ${(props) => !props.isFullWidth && `
        width: calc(100% - 250px);
    `};
`;

export const PendingPhoneNumberItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${color('text.main')};
    padding: 6px 2px;
    gap: 12px;
`;

export const ClockIcon = styled(Clock)`
    width: 16px;
    height: 16px;
    color: ${color('text.header')};
`;

export const StyledTable = styled(TableWithBrowserPagination)`
    width: 100%;
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    left: 50%;
    margin-bottom: -15px;
`;

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;
