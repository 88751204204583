import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Modal, RenderIf, Spinner, CheckboxGroup, Button,
} from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import useDatasets from 'data/firestore/dataset/useCollection';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { ModalTitle } from '../styled';

interface TrainModalProps {
    onRequestClose: () => void;
    isOpen: boolean;
}

interface CreateVersionRequest {
    modelId: string;
    datasetIds: Array<string>;
}

interface CreateVersionResponse {
    versionId: string;
}

const TrainModal = (props: TrainModalProps) => {
    const { isOpen, onRequestClose } = props;
    const { modelId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading } = useDatasets();
    const [datasetIds, setDatasetIds] = useState<Array<string>>([]);
    const {
        mutate: createVersion,
        isLoading: isCreatingVersion,
    } = useHttpMutation<CreateVersionRequest, CreateVersionResponse>({
        method: 'POST',
        onSuccess(response) {
            onRequestClose();
            navigate(`/models/${modelId}/version/${response.versionId}`);
        },
        onError: () => showAppMessage({
            variant: 'error',
            timeout: 5000,
            message: 'Combined datasets must have at least 10 samples.',
        }),
    });

    const requestClose = () => {
        setDatasetIds([]);
        onRequestClose();
    };

    const submit = () => createVersion({
        pathname: `/model/${modelId}/version`,
        body: {
            datasetIds,
            modelId: modelId as string,
        },
    });

    const options = data?.map((dataset) => ({ value: dataset.id, label: dataset.name }));

    return (
        <Modal
            title={<ModalTitle>Select Datasets to Train the Model</ModalTitle>}
            size="large"
            isOpen={isOpen}
            onRequestClose={requestClose}
            borderRadius="semi-rounded"
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        label="Close"
                        variant="neutral"
                        borderRadius="semi-rounded"
                        className="rainbow-m-right_medium"
                        onClick={requestClose}
                    />
                    <Button
                        label="Create"
                        variant="brand"
                        type="submit"
                        onClick={submit}
                        isLoading={isCreatingVersion}
                        disabled={isLoading || datasetIds.length === 0}
                        borderRadius="semi-rounded"
                    />
                </div>
            )}
        >
            <div>
                <RenderIf isTrue={isLoading}>
                    <Spinner />
                </RenderIf>
                <form>
                    <CheckboxGroup
                        options={options}
                        required
                        value={datasetIds}
                        onChange={(value) => setDatasetIds(value)}
                        label="Select Dataset"
                        className="rainbow-m-horizontal_large rainbow-m-bottom_medium"
                    />
                </form>
            </div>
        </Modal>

    );
};

export default TrainModal;
