import { StyledColumnWrapper } from './styled';

interface AgentTypeColumnProps {
    value?: string;
}

const AgentTypeColumn = (props: AgentTypeColumnProps) => {
    const { value = '' } = props;
    return (
        <StyledColumnWrapper>
            {value}
        </StyledColumnWrapper>
    );
};

export default AgentTypeColumn;
