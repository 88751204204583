import { IconProps } from './types';

const Copy = ({ title = 'Copy', className }: IconProps) => (
    <svg className={className} width={16} height={20} viewBox="0 0 16 20">
        <title>{title}</title>
        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M11 3H3C1.89543 3 1 3.89543 1 5V17C1 18.1046 1.89543 19 3 19H11C12.1046 19 13 18.1046 13 17V5C13 3.89543 12.1046 3 11 3ZM3 2C1.34315 2 0 3.34315 0 5V17C0 18.6569 1.34315 20 3 20H11C12.6569 20 14 18.6569 14 17V5C14 3.34315 12.6569 2 11 2H3Z" />
        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M4 0.5C4 0.223858 4.22386 0 4.5 0H12.5C14.433 0 16 1.567 16 3.5V15C16 15.2761 15.7761 15.5 15.5 15.5C15.2239 15.5 15 15.2761 15 15V3.5C15 2.11929 13.8807 1 12.5 1H4.5C4.22386 1 4 0.776142 4 0.5Z" />

    </svg>
);

export default Copy;
