import styled from 'styled-components';
import { NavigationButtonColumn } from '@rainbow-modules/listview';

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 4px;
    padding-left: 4px;
    height: 100%;
    gap: 4px;
`;

export const ModalTitle = styled.h1`
    font-size: 20px;
    text-align: left;
    padding: 16px 24px;
`;
