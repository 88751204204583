import SentimentBadge from 'components/SentimentBadge';
import { StyledColumnWrapper } from './styled';

interface SentimentColumnProps {
    value?: {
        score: number | null;
        magnitude: number | null;
        label: string;
    };
}

const SentimentColumn = (props: SentimentColumnProps) => {
    const { value } = props;
    if (!value) return null;
    return (
        <StyledColumnWrapper>
            <SentimentBadge sentiment={value} />
        </StyledColumnWrapper>
    );
};

export default SentimentColumn;
