import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import {
    Button, Column, RenderIf, ButtonIcon, Spinner,
} from 'react-rainbow-components';
import { ArrowRight, ArrowLeft } from '@rainbow-modules/icons';
import useAgentCampaign from 'data/firestore/agent/campaign/useCollectionWithPagination';
import listCampaignCalls from 'data/firestore/agent/campaign/call/list';
import { Campaign } from 'data/firestore/agent/campaign/types';
import DateTimeColumn from 'components/DateTimeColumn';
import CampaignStatus from 'components/CampaignStatus';
import { Refresh, Download } from 'components/icons';
import useSendCampaignMutation from 'hooks/useSendCampaignMutation';
import downloadCSV from '../helpers/downloadCSV';
import { TableFooter, StyledNavigationButtonColumn, StyledTable } from './styled';
import { ITEMS_PER_PAGE } from '../../../../../../constants';

const downloadReport = async (agentId: string, campaignId: string) => {
    const calls = await listCampaignCalls(agentId, campaignId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return downloadCSV(calls);
};

interface ActionsParams {
    agentId: string;
    value?: string;
    row?: Campaign
    resendCampaign: (data: { body: {
        agentId: string;
        contacts: Array<{
            name: string;
            phone: string;
        }>
        systemMessageTemplate: string;
        caller: string;
        model: string;
        temperature?: number;
    } }) => Promise<unknown>;
}

const Actions = (params: ActionsParams) => {
    const {
        agentId, value: campaignId = '', row, resendCampaign,
    } = params;
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <ButtonIcon
                variant="base"
                borderRadius="semi-rounded"
                onClick={async () => {
                    const {
                        id, caller, systemMessageTemplate, model, temperature,
                    } = row as Campaign;
                    setLoading(true);
                    try {
                        const calls = await listCampaignCalls(agentId, id);
                        const campaign = await resendCampaign({
                            body: {
                                agentId,
                                contacts: calls.map((call) => ({
                                    phone: call.to,
                                    name: call.customerName,
                                })),
                                systemMessageTemplate,
                                caller,
                                model,
                                temperature,
                            },
                        });
                        const { id: newCampaignId } = campaign as { id: string };
                        navigate(`/agents/${agentId}/campaigns/${newCampaignId}/calls`);
                        setLoading(false);
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                        setLoading(false);
                    }
                }}
                icon={isLoading ? <Spinner size="xx-small" type="arc" variant="brand" /> : <Refresh />}
                tooltip="Resend Campaign"
                className="rainbow-m-right_xx-small"
            />
            <ButtonIcon
                variant="base"
                borderRadius="semi-rounded"
                onClick={() => downloadReport(agentId, campaignId)}
                icon={<Download />}
                tooltip="Download Report"
            />
        </>
    );
};

const List = ({
    agentId,
}:{
    agentId: string;
}) => {
    const navigate = useNavigate();
    const {
        isLoading: isLoadingCampaigns,
        data: campaigns = [],
        totalRecords,
        nextPage,
        prevPage,
        firstPage,
        hasMore,
    } = useAgentCampaign(agentId, {
        disabled: !agentId,
        limit: ITEMS_PER_PAGE,
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
    });
    const { mutateAsync: resendCampaign } = useSendCampaignMutation();

    return (
        <>
            <StyledTable
                keyField="id"
                data={campaigns}
                emptyTitle="No campaigns were found"
                emptyDescription=""
                variant="listview"
                isLoading={isLoadingCampaigns}
            >
                <Column
                    header="Campaign ID"
                    field="id"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={StyledNavigationButtonColumn}
                    onClick={({ row }: { row: { id: string } }) => navigate(`${row.id}`)}
                />
                <Column
                    header="Date"
                    field="createdAt"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={DateTimeColumn}
                    defaultWidth={300}
                />
                <Column
                    header="Status"
                    field="status"
                    headerAlignment="left"
                    defaultWidth={120}
                    component={CampaignStatus}
                />
                <Column
                    field="id"
                    width={98}
                    component={Actions}
                    agentId={agentId}
                    resendCampaign={resendCampaign}
                />
            </StyledTable>
            <RenderIf isTrue={totalRecords > ITEMS_PER_PAGE}>
                <TableFooter>
                    <Button
                        variant="base"
                        size="small"
                        borderRadius="semi-rounded"
                        disabled={isLoadingCampaigns || firstPage}
                        onClick={prevPage}
                    >
                        <ArrowLeft className="rainbow-m-right_small" />
                        Prev
                    </Button>
                    <Button
                        variant="base"
                        size="small"
                        borderRadius="semi-rounded"
                        disabled={isLoadingCampaigns || !hasMore}
                        onClick={nextPage}
                    >
                        Next
                        <ArrowRight className="rainbow-m-left_small" />
                    </Button>
                </TableFooter>
            </RenderIf>
        </>
    );
};

export default List;
