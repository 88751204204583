import styled, { css } from 'styled-components';
import { color } from 'theme';

interface OptionProps {
    isSelected?: boolean;
}

export const OptionContainer = styled.li<OptionProps>`
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 8px 14px;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    flex: none;
    order: 1;
    color: ${color('text.label')};
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 2px;

    &:hover,
    :visited,
    :focus {
        background-color: ${color('brand.light')};
        text-decoration: none;
        color: ${color('text.label')};
    }

    ${(props) => props.isSelected
        && css`
            background-color: ${color('brand.light')};
            color: ${color('brand.main')};
            cursor: default;

            &:hover {
                background-color: ${color('brand.light')};
                color: ${color('brand.main')};
            }
        `}
`;

export const DatasetName = styled.span`
    max-width: 175px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
