/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { PicklistProps } from 'react-rainbow-components/components/Picklist';
import { Picklist, Option } from 'react-rainbow-components';
import { PickerVariantProps } from './types';

interface Props extends PickerVariantProps {
    name?: string;
    value?: string | null;
    onChange?: (value: string | null) => void;
}

const defaultValue = {
    label: 'All',
    name: 'all',
    value: 'all',
};

const AgentPickerVariantSingle = (props: Props) => {
    const {
        value: valueInProps,
        isLoading = false,
        agents = [],
        name,
        onChange = () => {},
        ...picklistProps
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = valueInProps;
                const found = agents.find((agent) => agent.id === valueId);
                if (found) {
                    const { id: agentId, companyName } = found;
                    return {
                        label: companyName,
                        name: agentId,
                        value: agentId,
                        searchableText: companyName,
                    };
                }
            }
        }
        return defaultValue;
    }, [agents, isLoading, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => {
            if (value.value === 'all') {
                return onChange(null);
            }
            return onChange(value.value);
        },
        [onChange],
    );

    const pickListOptions = useMemo(
        () => agents.map(
            (agent) => (
                <Option
                    key={`agent__${agent.id}`}
                    label={agent.companyName}
                    name={agent.id}
                    value={agent.id}
                    searchableText={agent.companyName}
                />
            ),
        ),
        [agents],
    );

    return (
        <Picklist
            {...picklistProps as PicklistProps}
            name={name}
            value={pickListvalue}
            onChange={handleOnChange}
            enableSearch
        >
            <Option label="All" name="all" value="all" />
            {pickListOptions}
        </Picklist>
    );
};

export default AgentPickerVariantSingle;
