import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { Dataset } from "./types";

export type DatasetUpdateService<T> = (
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: DatasetUpdateService<Dataset> = async (id, data) => {
  const collectionRef = doc(db, `/dataset/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
