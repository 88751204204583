import { Model } from 'data/firestore/model/types';
import useFineTunedModels from 'data/firestore/model/useCollection';
import { BASIC_MODELS } from '../../constants';

interface AgentCompatibleModel extends Model {
    root: boolean
}

export default () => {
    const { data, isLoading } = useFineTunedModels();
    return {
        data: [...BASIC_MODELS.map((basicModel) => ({
            id: basicModel,
            name: basicModel,
            root: true,
        })), ...data] as AgentCompatibleModel[],
        isLoading,
    };
};
