import styled from 'styled-components';
import { color } from 'theme';
import { BACKGROUND } from '../../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${BACKGROUND};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0px 0 16px;
`;

export const Content = styled.div`
    background: ${color('background.main')};
    height: 100%;
    margin-bottom: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
`;

export const PageTitle = styled.h1`
    font-size: 14px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;

export const SectionTitle = styled.h2`
    font-size: 14px;
    line-height: 18px;
    color: ${color('text.label')};
`;

export const SectionSubtitle = styled.p`
    font-size: 12px;
    line-height: 24px;
    color: ${color('text.header')};
`;
