import { Column, Button, Input } from 'react-rainbow-components';
import { useParams, useNavigate } from 'react-router-dom';
import { query, orderBy, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { Group } from 'data/firestore/group/types';
import getGroups from 'data/firestore/group/list';
import addGroup from 'data/firestore/group/add';
import useOpenTreeFormModal from 'hooks/useOpenTreeFormModal';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import DateTimeColumn from 'components/DateTimeColumn';
import { StyledNavigationButtonColumn } from 'components/styled';
import ActionsColumn from './columns/actions';
import ConversationsCountColumn from './columns/conversationsCount';
import {
    Container,
    StyledTable,
} from './styled';
import { Header } from '../styled';

const Groups = () => {
    const { datasetId = '' } = useParams();
    const [groups, setGroups] = useState<Group[]>([]);
    const navigate = useNavigate();
    const [openModal, closeModal] = useOpenTreeFormModal();

    const addNewGroup = useCallback(() => openModal({
        title: 'New Group',
        submitButtonLabel: 'Create Group',
        entity: 'Group',
        onSubmit: async ({ name }: { name: string; story?: string }) => {
            if (datasetId) {
                const group = await addGroup({
                    name,
                    datasetId,
                });
                navigate(`/dataset/${datasetId}/group/${group.id}`);
            }
            closeModal();
        },
    }), [closeModal, datasetId, navigate, openModal]);

    useEffect(() => {
        (async () => {
            const allGroups = await getGroups({
                listQueryFn: (ref) => query(ref, where('datasetId', '==', datasetId), orderBy('createdAt', 'asc')),
            });
            setGroups(allGroups);
        })();
    }, [datasetId]);

    return (
        <Container>
            <Header>
                <Input size="small" borderRadius="semi-rounded" placeholder="Find a group..." icon={<MagnifyingGlass />} type="search" />
                <Button
                    borderRadius="semi-rounded"
                    variant="brand"
                    onClick={addNewGroup}
                    size="small"
                >
                    <Plus className="rainbow-m-right_small" />
                    New Group
                </Button>
            </Header>
            <StyledTable data={groups} keyField="id" variant="listview">
                <Column
                    header="Group Name"
                    field="name"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={StyledNavigationButtonColumn}
                    onClick={({ row }: { row: { id: string } }) => navigate(`/dataset/${datasetId}/group/${row.id}`)}
                />
                <Column
                    header="Conversations"
                    field="conversations"
                    headerAlignment="left"
                    defaultWidth={130}
                    component={ConversationsCountColumn}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    headerAlignment="left"
                    defaultWidth={200}
                    component={DateTimeColumn}
                />
                <Column component={ActionsColumn} width={100} />
            </StyledTable>
        </Container>
    );
};

export default Groups;
