import {
    StyledSpinner,
    CheckmarkIcon,
    StyledBadge,
} from './styled';

interface IconMap {
    [key: string]: React.ComponentType;
}

const SpinerIcon = () => <StyledSpinner size="xx-small" type="arc" variant="brand" />;

const iconMap: IconMap = {
    ringing: SpinerIcon,
    completed: CheckmarkIcon,
};

interface CallStatusBadgeProps {
    status?: string;
}

const CallStatusBadge = (props: CallStatusBadgeProps) => {
    const { status } = props;
    if (status) {
        const Icon = iconMap[status.toLowerCase()];

        return (
            <StyledBadge status={status} borderRadius="semi-square">
                {Icon && <Icon />}
                <span className="rainbow-m-left_x-small">
                    {status}
                </span>
            </StyledBadge>
        );
    }
    return null;
};

export default CallStatusBadge;
