import { IconProps } from '../types';

const SlightlySmiling = ({ title = 'SlightlySmiling', className }: IconProps) => (
    <svg className={className} width={16} height={15} viewBox="0 0 16 15">
        <title>{title}</title>
        <path d="M7.78175 15C4.03846 15 0 12.6521 0 7.5C0 2.34794 4.03846 0 7.78175 0C9.86136 0 11.78 0.684257 13.2021 1.93202C14.7451 3.30054 15.5635 5.23256 15.5635 7.5C15.5635 9.76744 14.7451 11.686 13.2021 13.0546C11.78 14.3023 9.84794 15 7.78175 15Z" fill="url(#paint0_radial_100_11816)" />
        <path d="M5.15207 4.55371C4.5899 4.55371 4.07872 5.02867 4.07872 5.81758C4.07872 6.60648 4.5899 7.0801 5.15207 7.0801C5.71557 7.0801 6.22541 6.60514 6.22541 5.81758C6.22541 5.03001 5.72094 4.55371 5.15207 4.55371Z" fill="#422B0D" />
        <path d="M5.10514 5.08106C4.91462 4.98982 4.68519 5.07032 4.59261 5.26084C4.52151 5.40977 4.55371 5.58821 4.67312 5.7036C4.86363 5.79483 5.09306 5.71433 5.18564 5.52381C5.25675 5.37488 5.22455 5.19644 5.10514 5.08106Z" fill="#896024" />
        <path d="M10.3041 4.55371C9.74195 4.55371 9.23077 5.02867 9.23077 5.81758C9.23077 6.60648 9.74195 7.0801 10.3041 7.0801C10.8663 7.0801 11.3775 6.60514 11.3775 5.81758C11.3775 5.03001 10.8663 4.55371 10.3041 4.55371Z" fill="#422B0D" />
        <path d="M10.2505 5.08106C10.06 4.98982 9.83053 5.07032 9.73795 5.26084C9.66685 5.40977 9.69905 5.58821 9.81846 5.7036C10.009 5.79483 10.2384 5.71433 10.331 5.52381C10.4021 5.37488 10.3699 5.19644 10.2505 5.08106Z" fill="#896024" />
        <path d="M7.70128 11.352C6.48437 11.3614 5.32248 10.8503 4.50808 9.94596C4.44636 9.87485 4.4316 9.77423 4.47051 9.68836C4.50942 9.60115 4.59528 9.5448 4.69054 9.54346C4.73348 9.54346 4.77641 9.55553 4.81398 9.577C5.46067 9.94864 6.54206 10.4102 7.70128 10.4102H7.72006C8.87793 10.4102 9.96067 9.94864 10.606 9.577C10.6436 9.55553 10.6865 9.54346 10.7295 9.54346C10.8247 9.5448 10.9106 9.60115 10.9495 9.68836C10.9897 9.77423 10.975 9.87485 10.9119 9.94596C10.0975 10.8503 8.93294 11.3628 7.71604 11.352" fill="#422B0D" />
        <path d="M14.2071 3.0415C14.9222 4.19535 15.2952 5.56923 15.2952 7.09741C15.2952 9.36485 14.4768 11.2835 12.9338 12.652C11.5116 13.8997 9.57962 14.5974 7.51343 14.5974C5.09035 14.5974 2.54921 13.6113 1.04921 11.5196C2.49286 13.889 5.20842 14.9999 7.78177 14.9999C9.84796 14.9999 11.78 14.3022 13.2022 13.0545C14.7451 11.686 15.5635 9.76735 15.5635 7.49991C15.5635 5.79061 15.098 4.27183 14.2071 3.0415Z" fill="#EB8F00" />
        <defs>
            <radialGradient id="paint0_radial_100_11816" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78175 7.5) scale(7.64218)">
                <stop offset="0.5" stopColor="#FDE030" />
                <stop offset="0.92" stopColor="#F7C02B" />
                <stop offset="1" stopColor="#F4A223" />
            </radialGradient>
        </defs>
    </svg>
);

export default SlightlySmiling;
