import { Button, Slider, Textarea } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const PlaygroundContainer = styled.div`
    display: flex;
    gap: 24px;
    overflow: hidden;
    box-sizing: border-box;
    margin: 20px;
    flex: 1;
    padding: 0;
`;

export const LeftContent = styled.div`
    width: 20%;
    min-width: 300px;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    justify-content: stretch;
    overflow: hidden;
    box-sizing: border-box;
    max-height: 500px;
`;

export const MainContent = styled.div`
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    justify-items: start;
    width: 100%;
    min-height: 450px;
`;

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${color('border.divider')}
`;

export const Title = styled.span`
    font-size: 15px;
    font-family: "Lato Bold";
`;

export const StyledTextarea = styled(Textarea)`
    flex: 1;
    
    & > div {
        height: 100%;
    }

    textarea {
        height: 100%;
    }
`;

export const StyledSlider = styled(Slider)`
    margin-right: 10px;
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const ModalTitle = styled.span`
    font-size: 22px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    padding: 16px 20px;
`;

export const ImportButton = styled(Button)`
    align-self: flex-end;
`;

export const MessagesList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MessagesListActions = styled.div`
    display: flex;
    gap: 10px;
    padding: 20px 0;
`;
