import {
  collection,
  query,
  getDocs,
  getDocsFromServer,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../../../types";
import { Call } from "./types";
import { transformMetadata, processTimestampFields } from "../../../utils";

export type CallListService<T> = (
  agentId: string,
  campaignId: string,
  opts?: ListServiceOpts
) => Promise<Array<T>>;

const list: CallListService<EntityGet<Call>> = async (
  agentId,
  campaignId,
  opts = {}
) => {
  const collectionRef = collection(
    db,
    `/agent/${agentId}/campaign/${campaignId}/call`
  );
  const { disableCache } = opts;
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const getFn = disableCache ? getDocsFromServer : getDocs;
  const querySnapshot = await getFn(q);
  return querySnapshot.docs.map((doc) => {
    return processTimestampFields(
      transformMetadata({
        ...doc.data(),
        id: doc.id,
      } as EntityServerGet<Call>)
    );
  });
};

export default list;
