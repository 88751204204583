import styled from 'styled-components';
import { ButtonIcon, Spinner } from 'react-rainbow-components';
import { color } from 'theme';
import { Check, Clock } from 'components/icons';
import Tile from 'components/Tile';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-item: center;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 20px;
`;

export const PageTitle = styled.h1`
    font-size: 15px;
    color: ${color('text.main')};
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    color: ${color('text.main')};
`;

export const SectionSubTitle = styled.p`
    font-size: 14px;
    line-height: 18px;
    color: ${color('text.header')};
`;

export const TrashButton = styled(ButtonIcon)`
    width: 30px;
    height: 30px;

    > svg {
        height: 16px !important;
        width: 16px !important;
    }

`;

export const Insights = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
`;

export const TilesContainer = styled.div`
    display: flex;
    gap: 8px;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledSpinner = styled(Spinner)`
    position: initial;
    margin-top: 12px;
    margin-left: 8px;
`;

export const ClockIcon = styled(Clock)`
    color: ${color('text.header')};
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;

export const CheckmarkIcon = styled(Check)`
    color: #76b460;
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;

export const StyledTile = styled(Tile)`
    flex: 2;
`;
