import { Button, Textarea } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    gap: 20px;
    height: 100%;
    padding-bottom: 12px;
`;

export const InfoPanel = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 220px;
`;

export const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    
    & > form {
        flex: 1;
        display: flex;
        gap: 8px;
        flex-direction: column;
    }
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 1rem;
`;

export const ChipsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: start;
    flex-wrap: wrap;
    gap: 10px;
`;

export const StyledChip = styled(Button)`
    width: min-content;
    color: ${color('text.main')};;
    background: ${color('border.divider')};;
    border: none;

    &:hover,
    &:active,
    &:focus {
        color: ${color('text.main')};;
        background: ${color('border.divider')};;
        border: none;
        box-shadow: none;
    }
`;

export const StyledTextarea = styled(Textarea)`
    flex: 1;
    & > div {
        flex: 1;
    }
    
    div[id^="error-message-"] {
        flex: 0;
        position: absolute;
        bottom: 8px;
    }

    textarea {
        height: 100%;
    }
`;
