import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../../types";
import { Buildingblocks } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type BuildingblocksGetService<T> = (
  datasetId: string,
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: BuildingblocksGetService<EntityGet<Buildingblocks>> = async (
  datasetId,
  id,
  opts = {}
) => {
  const docRef = doc(db, `/dataset/${datasetId}/buildingBlocks/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Buildingblocks>)
    );
  }
  return null;
};

export default get;
