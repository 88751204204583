import { Badge } from 'react-rainbow-components';
import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    padding: 0 0 20px 0;
`;

export const LeyendBadge = styled(Badge)`
    background: #fff;
`;
