import { useContext } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import { PageHeaderTitle } from 'components/styled';
import updateTwilioConfig from 'data/firestore/twilioconfig/update';
import { InfoContainer } from '../styled';
import { StyledSubmitButton, StyledUniversalForm } from './styled';
import context, { Context } from '../../context';

interface TwilioConfigProps {
    isLoading?: boolean;
}

const TwilioConfig = ({
    isLoading,
}: TwilioConfigProps) => {
    const { twilioConfig: config } = useContext<Context>(context);

    return (
        <>
            <PageHeaderTitle>Twilio Config</PageHeaderTitle>
            <InfoContainer>
                <RenderIf isTrue={isLoading}>
                    Loading...
                </RenderIf>
                <RenderIf isTrue={!isLoading && !config}>
                    <div>There is no config for this agent</div>
                </RenderIf>
                <RenderIf isTrue={!isLoading && !!config}>
                    <StyledUniversalForm
                        id="twilio-config-form"
                        initialValues={{
                            authToken: config?.authToken,
                            conversationServiceSid: config?.conversationServiceSid,
                            apiKeySid: config?.apiKeySid,
                            apiKeySecret: config?.apiKeySecret,
                        } as unknown as Record<string, unknown>}
                        onSubmit={(value) => {
                            if (config?.id) {
                                updateTwilioConfig(config.id, value);
                            }
                        }}
                    >
                        <div>
                            <RecordField
                                isLoading={isLoading}
                                label="Sub Account SID:"
                                // eslint-disable-next-line max-len
                                value={config?.subAccountSid}
                            />
                            <RecordField
                                isLoading={isLoading}
                                name="authToken"
                                label="Auth Token:"
                                value={config?.authToken}
                                isEditable
                            />
                        </div>
                        <div>
                            <RecordField
                                isLoading={isLoading}
                                name="conversationServiceSid"
                                label="Conversation Service SID:"
                                value={config?.conversationServiceSid}
                                isEditable
                            />
                            <RecordField
                                isLoading={isLoading}
                                name="apiKeySid"
                                label="Api Key SID:"
                                value={config?.apiKeySid}
                                isEditable
                            />
                            <RecordField
                                isLoading={isLoading}
                                name="apiKeySecret"
                                label="Api Key Secret:"
                                value={config?.apiKeySecret}
                                isEditable
                            />
                        </div>
                        <StyledSubmitButton label="Save" size="small" type="submit" form="twilio-config-form" />
                    </StyledUniversalForm>
                </RenderIf>
            </InfoContainer>
        </>
    );
};

export default TwilioConfig;
