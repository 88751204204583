import { confirmModal } from '@rainbow-modules/app';
import useOpenTreeFormModal from 'hooks/useOpenTreeFormModal';
import { EntityName } from 'types';

interface EditParams<T> {
    initialValues: Record<string, unknown>;
    onSubmit: (values: T) => void;
}

interface RemoveParams {
    onConfirm: () => void;
}

const useEntityActions = <T = Record<string, unknown>>(entity: EntityName) => {
    const [openModal, closeModal] = useOpenTreeFormModal();

    const edit = ({ initialValues, onSubmit }: EditParams<T>) => openModal({
        title: `Edit ${entity}`,
        submitButtonLabel: `Edit ${entity}`,
        entity,
        initialValues,
        onSubmit: async (values: T) => {
            await onSubmit(values);
            closeModal();
        },
    });
    const remove = async ({ onConfirm }: RemoveParams) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: `Delete ${entity}`,
            question:
                `Removing this ${entity} will delete it permanently. Are you sure you want to remove this ${entity}?`,
            okButtonLabel: `Delete ${entity}`,
        });
        if (result) {
            await onConfirm();
        }
    };

    return { edit, remove };
};

export default useEntityActions;
