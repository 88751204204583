import { useMemo } from 'react';
import { ButtonIcon, RenderIf } from 'react-rainbow-components';
import { PhoneInputValue } from 'react-rainbow-components/components/PhoneInput';
import { Pencil, Trash } from 'components/icons';
import formatPhoneNumber from 'data/services/phones/format';
import { Subscriptions } from 'data/firestore/agent/subscriptions/types';
import {
    PhoneIcon, MailIcon, OptionContainer, StyledPhoneInput, StyledInput,
} from './styled';
import { Actions } from '../../styled';

interface NotificationOptionProps {
    type?: 'phone' | 'email';
    value: Subscriptions;
    onEdit?: (value: Subscriptions) => void;
    onDelete?: (value: Subscriptions) => void;
}

const NotificationOption = ({
    value,
    type = 'email',
    onEdit = () => {},
    onDelete = () => {},
}: NotificationOptionProps) => {
    const phoneValue = useMemo(() => {
        if (type === 'phone') {
            return {
                ...value,
                value: formatPhoneNumber(value?.to),
            };
        }
        return null;
    }, [type, value]);

    return (
        <OptionContainer>
            <div>
                <RenderIf isTrue={type === 'phone'}>
                    <RenderIf isTrue={typeof phoneValue !== 'string'}>
                        <StyledPhoneInput
                            value={phoneValue?.value as PhoneInputValue}
                            readOnly
                            hideLabel
                        />
                    </RenderIf>
                    <RenderIf isTrue={typeof phoneValue === 'string'}>
                        <StyledInput
                            value={phoneValue?.value as string}
                            icon={<PhoneIcon />}
                            readOnly
                            hideLabel
                        />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={type === 'email'}>
                    <StyledInput
                        value={value?.to}
                        icon={<MailIcon />}
                        readOnly
                        hideLabel
                    />
                </RenderIf>
            </div>
            <Actions>
                <ButtonIcon
                    borderRadius="semi-rounded"
                    icon={<Pencil />}
                    onClick={() => onEdit(phoneValue ?? value)}
                />
                <ButtonIcon
                    borderRadius="semi-rounded"
                    icon={<Trash />}
                    onClick={() => onDelete(value)}
                />
            </Actions>
        </OptionContainer>
    );
};

export default NotificationOption;
