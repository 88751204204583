import { PhoneInputValue } from 'react-rainbow-components/components/PhoneInput';
import { RecordField } from '@rainbow-modules/record';
import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import {
    OptionContainer, StyledPhoneInput, StyledLabel,
} from './styled';

interface PhoneProps {
    value?: Record<string, unknown>;
}

const Phone = ({ value }: PhoneProps) => (
    <OptionContainer>
        <div>
            <StyledPhoneInput
                value={value as PhoneInputValue}
                readOnly
                hideLabel
            />
            <StyledLabel
                name={formatIntlPhoneNumber(value?.number as string)}
                description={value?.location as string}
            />
        </div>
        <div>
            <RecordField
                label="Language"
                value={(value?.language as string) || 'AUTO'}
            />
        </div>
    </OptionContainer>
);

export default Phone;
