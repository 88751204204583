import styled from 'styled-components';
import { Input, Table } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import { NavigationButtonColumn } from '@rainbow-modules/listview';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;
export const StyledInput = styled(Input)`
    width: 40%;
 `;

export const Content = styled.div`
    padding: 8px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const DataField = styled(RecordField)`
    > span:first-child {
        font-weight: 700;
        font-size: 0.9rem;
        color: #000;
    }

    > span:last-child {
        font-weight: 400;
        font-size: 12px;
    }
`;

export const Divider = styled.div`
    flex: 1;
`;

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const StyledTable = styled(Table)`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    table {
        padding-bottom: 44px;
    }
`;

export const TableFooter = styled.div`
    height: fit-content;
    margin-bottom: 8px;
    padding: 32px 0 0;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 50%);
    margin-top: -32px;
    z-index: 1;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const SelectorsContainer = styled.div`
    display: flex;
    max-width: 40%;
    gap: 0.5rem;
    justify-content: flex-end;
    align-items: center;

    & > div:first-child {
        flex: 1;
        max-width: 300px;
        width: 300px;
    }

    & > div:last-child {
        flex: unset;
        max-width: unset;
        width: 200px;
    }
`;
