import styled from 'styled-components';
import { RecordPrimaryDetails } from '@rainbow-modules/record';
import { color } from 'theme';
import { BACKGROUND } from '../../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${BACKGROUND};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0px 0 16px;
    `;

export const Content = styled.div`
    background: ${color('background.main')};
    height: 100%;
    margin-bottom: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    flex: 1;
`;

export const Header = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 16px 20px;
`;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    gap: 32px;
`;

export const SideBar = styled.div`
    width: 250px;
    padding: 10px;
    box-sizing: border-box;
    gap: 1px;
    display: inline-flex;
    flex-direction: column;
`;

export const RightContent = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    margin-right: 16px;
`;

export const SectionHeader = styled.header`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;

export const ChartContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['$maxWidth'].includes(prop),
})<{ $maxWidth?: number }>`
    background: #F7F9FC;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0;
    margin-bottom: 40px;
    height: 450px;
    max-width: ${(props) => props.$maxWidth}px;
`;

export const SectionSummaryContainer = styled(RecordPrimaryDetails).withConfig({
    shouldForwardProp: (prop) => !['$maxWidth'].includes(prop),
})<{ $maxWidth?: number }>`
    max-width: ${(props) => props.$maxWidth}px;
    padding-bottom: 12px;
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px;

    > div {
        display: flex;
        flex-grow 1;
        flex-shrink: 1;
        flex-basis: 220px;
        border-right: none;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        padding: 12px 18px 12px 12px;
        box-sizing: border-box;
        margin-right: 0;

        :last-of-type {
            border: 1px solid #e6e6e6;
        }
    }
`;
