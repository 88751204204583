import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';
import { color } from 'theme';
import { Check, Clock, ErrorCircle } from 'components/icons';

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
`;

export const StyledSpinner = styled(Spinner)`
    position: initial;
    margin-top: 12px;
    margin-left: 8px;
`;

export const ClockIcon = styled(Clock)`
    color: ${color('text.header')};
    width: 18px;
    height: 18px;
    margin-right: 5px;
`;

export const CheckmarkIcon = styled(Check)`
    color: ${color('success.main')};
    width: 18px;
    height: 18px;
    margin-right: 5px;
`;

export const ErrorIcon = styled(ErrorCircle)`
    color: ${color('error.main')};
    width: 18px;
    height: 18px;
    margin-right: 5px;
`;
