export const getFormatter = (
    locale: string,
    options: Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat(locale, options);

export default getFormatter(
    'en-US',
    {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    },
);
