import styled from 'styled-components';
import { color } from 'theme';
import { LoadingShape, Spinner } from 'react-rainbow-components';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const SubHeaderContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
`;

export const SubHeaderTitle = styled.h1`
    font-size: 16px;
    line-height: 18px;
    color: ${color('text.label')};
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: ${color('text.main')};
`;

export const InsightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 4px;
    padding: 10px 0 0;
`;

export const SummaryContainer = styled.div`
    padding: 24px 0;
    gap: 8px;
    display: flex;
    flex-direction: column;
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    top: 24px;
    left: 50%;
    z-index: unset;
    transform-origin: 0px 50% 0px
`;

export const StyledLoadingShape = styled(LoadingShape).withConfig({
    shouldForwardProp: (prop) => !['$width'].includes(prop),
})<{ $width: number | string }>`
    width: ${(props) => props.$width};
`;

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 8px;
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const ScrollContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    padding-left: 12px;
`;

export const Row = styled.div`
    display: flex;
    padding: 16px 0 0;
    gap: 36px;
    align-items: center;
`;

export const EmptyMessageSpinner = styled(Spinner)`
    z-index: unset;
    left: 50%;
    top: 24px;
    position: unset;
    transform: unset;
`;
