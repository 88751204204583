import {
    useNavigate, Outlet, useMatch,
} from 'react-router-dom';
import { useEffect } from 'react';
import SidebarOption from './SidebarOption';
import {
    Sidebar,
    Container,
    RightContent,
} from './styled';

const Insights = () => {
    const navigate = useNavigate();
    const match = useMatch('agents/:agentId/insights/:insightId');
    const insightId = match?.params?.insightId;
    useEffect(() => {
        if (insightId === undefined) {
            navigate('knowledge-base');
        }
    }, [insightId, navigate]);
    return (
        <Container>
            <Sidebar>
                <SidebarOption
                    label="Knowledge Base"
                    isSelected={insightId === 'knowledge-base'}
                    name="knowledge-base"
                    onClick={() => navigate('knowledge-base')}
                />
                <SidebarOption
                    label="Calls Analysis"
                    name="call-analysis"
                    isSelected={insightId === 'call-analysis'}
                    onClick={() => navigate('call-analysis')}
                />
                <SidebarOption
                    label="Minutes Analysis"
                    name="minutes-analysis"
                    isSelected={insightId === 'minutes-analysis'}
                    onClick={() => navigate('minutes-analysis')}
                />
            </Sidebar>
            <RightContent>
                <Outlet />
            </RightContent>
        </Container>
    );
};

export default Insights;
