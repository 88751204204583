import { LoadingShape, RenderIf } from 'react-rainbow-components';
import timeFormatter from 'data/services/time/formatter';
import { Assistant, User } from 'components/icons';
import {
    MessageContainer,
    MessageIconContainer,
    MessageInfo,
    MessageText,
} from './styled';

interface IconMap {
    [key: string]: React.ComponentType;
}

const iconMap: IconMap = {
    user: User,
    assistant: Assistant,
};

interface ChatMessageProps {
    messageId?: string;
    role: 'user' | 'assistant' | 'system' | 'event';
    content?: string;
    createdAt?: Date;
    onClick?: (messageId?: string) => void;
    selected?: boolean;
    isLoading?: boolean;
}

const ChatMessage = ({
    messageId,
    role = 'assistant',
    content = '',
    createdAt,
    onClick = () => {},
    selected = false,
    isLoading = false,
}:ChatMessageProps) => {
    const Icon = iconMap[role.toLocaleLowerCase()] || iconMap.user;
    const handleClick = () => {
        if (isLoading) return;
        onClick(messageId);
    };

    return (
        <MessageContainer $selected={selected} $isLoading={isLoading} onClick={handleClick}>
            <MessageIconContainer $role={role}>
                <Icon />
            </MessageIconContainer>
            <div>
                <RenderIf isTrue={isLoading}>
                    <MessageInfo label={role} value="" />
                    <MessageText>
                        <LoadingShape shape="rounded-rect" />
                    </MessageText>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <MessageInfo label={role} value={timeFormatter.format(createdAt)} />
                    <MessageText>{content}</MessageText>
                </RenderIf>
            </div>
        </MessageContainer>
    );
};

export default ChatMessage;
