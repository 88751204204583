import { ButtonIcon } from 'react-rainbow-components';
import { Pencil, Trash } from 'components/icons';
import { MouseEvent } from 'react';
import updateConversation from 'data/firestore/conversation/update';
import removeConversation from 'data/firestore/conversation/remove';
import { Conversation } from 'data/firestore/conversation/types';
import useEntityActions from 'hooks/useEntityActions';
import { ConversationFormValues } from 'types';
import { StyledActionsColumn } from 'components/styled';

interface Props {
    row?: Conversation;
}

const ActionsColumn = ({ row }: Props) => {
    const { id, name, story } = row || {};
    const preventDefault = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const { edit, remove } = useEntityActions<ConversationFormValues>('conversation');

    const editConversation = () => edit({
        initialValues: {
            name,
            story,
        },
        onSubmit: async ({ name: newName, story: newStory = '' }) => {
            await updateConversation(
                id as string,
                {
                    name: newName,
                    story: newStory,
                },
            );
        },
    });

    const openConversationDeleteConfirmation = () => remove({
        onConfirm: () => removeConversation(id as string),
    });

    return (
        <StyledActionsColumn>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Pencil />}
                onClick={(event) => {
                    preventDefault(event);
                    editConversation();
                }}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                onClick={(event) => {
                    preventDefault(event);
                    openConversationDeleteConfirmation();
                }}
            />
        </StyledActionsColumn>
    );
};

export default ActionsColumn;
