import { StyledColumnWrapper } from './styled';

interface AgentLanguagesColumnProps {
    value?: { [key: string]: string };
}

const AgentLanguagesColumn = (props: AgentLanguagesColumnProps) => {
    const { value } = props;
    const languages = Object.keys(value || {});
    languages.sort();
    return (
        <StyledColumnWrapper>
            {languages.join(',').toUpperCase()}
        </StyledColumnWrapper>
    );
};

export default AgentLanguagesColumn;
