import { useMemo } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import styled from 'styled-components';

const ModalHeader = styled.h1`
    font-size: 22px;
    margin: 16px 24px;
`;

export const BUY_PHONE_FORM_MODAL = 'buy-phone-form-modal';

const useBuyPhoneModal = () => {
    const [openModal, closeModal] = useOpenModal(BUY_PHONE_FORM_MODAL);
    return useMemo(() => ([
        (props: Record<string, unknown> = { title: 'Title' }) => openModal({
            ...props,
            // title needs to be below ...props so that it can't be overridden
            title: <ModalHeader>{props.title as string}</ModalHeader>,
        }),
        closeModal,
    ]), [openModal, closeModal]);
};

export default useBuyPhoneModal;
