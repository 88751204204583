import styled from 'styled-components';
import { Badge, Spinner } from 'react-rainbow-components';
import { Check } from 'components/icons';
import { color } from 'theme';

interface VersionStatusdBadgeProps {
    status: string;
}

export const StyledBadge = styled(Badge)<VersionStatusdBadgeProps>`
    flex-shrink: 0;
    padding: 2px 8px 2px 6px;
    text-transform: uppercase;

    > span {
        display: flex;
        align-items: center;
    }

    ${(props) => props.status === 'ringing' && `
        color: ${color('brand.main')(props)};
        background: ${color('brand.light')(props)};
    `};

    ${(props) => props.status === 'completed' && `
        color: ${color('success.main')(props)};
        background: ${color('success.light')(props)};
    `};

`;

export const StyledSpinner = styled(Spinner)`
    position: initial;
    margin: 0;
    transform: unset;
    z-index: unset;
`;

export const CheckmarkIcon = styled(Check)`
    color: ${color('success.main')};
    width: 18px;
    height: 18px;
`;
