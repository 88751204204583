import { Link } from 'react-router-dom';
import useModelVersion from 'hooks/useModelVersion';

const ModelLink = ({ versionName }: { versionName: string }) => {
    const [version, isLoading] = useModelVersion(versionName);
    if (!version || isLoading) return (<div>{versionName}</div>);
    return (<Link to={`/models/${version.model}/version/${version.id}`}>{versionName}</Link>);
};

export default ModelLink;
