import { db } from 'data/firebase';
import { onSnapshot, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

// TODO: move this type to a shared location
interface CognitiveLoadMetadata {
    cognitiveLoad: string;
    why: string
}

const useCognitiveLoad = (
    messageId: string,
    collectionPath: string = '/message',
) => {
    const [metadata, setMetadata] = useState<CognitiveLoadMetadata | null>(null);
    useEffect(() => {
        const ref = doc(db, `${collectionPath}/${messageId}/metadata/cognitive-load`);
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (snapshot.exists()) {
                setMetadata(snapshot.data() as CognitiveLoadMetadata);
            }
        });
        return () => unsubscribe();
    }, [messageId, collectionPath]);
    return metadata;
};

export default useCognitiveLoad;
