import { Outlet } from 'react-router-dom';
import TopBar from 'components/TopBar';
import { Container, Content } from './styled';

const Models = () => (
    <Container>
        <TopBar />
        <Content>
            <Outlet />
        </Content>
    </Container>
);

export default Models;
