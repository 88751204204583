import styled from 'styled-components';

export const ModalContent = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const TableFooter = styled.div`
    height: fit-content;
    margin-bottom: 32px;
    padding: 0 16px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;
