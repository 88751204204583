import { LoadingShape, RenderIf } from 'react-rainbow-components';
import { TileWrapper, TileLabel, TileValue } from './styled';

interface TileProps {
    value: string | React.ReactNode;
    className?: string;
    label: string | React.ReactNode;
    isLoading?: boolean;
}

const Tile = (props: TileProps) => {
    const {
        value, label, className, isLoading,
    } = props;
    return (
        <TileWrapper className={className}>
            <TileLabel>
                {label}
            </TileLabel>
            <TileValue>
                {value}
                <RenderIf isTrue={isLoading}>
                    <LoadingShape />
                </RenderIf>
            </TileValue>
        </TileWrapper>
    );
};

export default Tile;
