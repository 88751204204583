import EditAgentForm from './editMode';
import AddAgentForm from './createMode';
import { AgentFormProps } from './types';

const AddUpdateAgentForm = ({ mode = 'create', ...rest }: AgentFormProps) => {
    if (mode === 'edit') {
        return <EditAgentForm {...rest} />;
    }
    return <AddAgentForm {...rest} />;
};

export default AddUpdateAgentForm;
