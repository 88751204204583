/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import { Badge } from 'react-rainbow-components';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import { StyledNavigationButtonColumn } from '../styled';

interface Props {
    value?: string;
    row?: {
        _tags?: string[];
    };
}

const SessionIdColumn = ({ row, value }: Props) => {
    const navigate = useNavigateWithQuery();
    const tags = useMemo(
        () => row?._tags?.map(
            (tag) => (
                <Badge
                    key={`tag__${tag}`}
                    className="rainbow-m-left_small"
                    variant="default"
                    borderRadius="semi-square"
                    size="medium"
                >
                    {tag}
                </Badge>
            ),
        ),
        [row?._tags],
    );
    return (
        <>
            <StyledNavigationButtonColumn
                value={value}
                row={row}
                onClick={() => navigate(`${value}/debug`)}
            />
            {tags}
        </>
    );
};

export default SessionIdColumn;
