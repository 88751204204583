const extractSectionName = (path: string) => {
    const matches = /^\/([^/]+)/.exec(path);
    if (matches) {
        const [, sectionName] = matches;
        return sectionName;
    }
    return undefined;
};

export default extractSectionName;
