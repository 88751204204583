import {
    ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Option, RenderIf } from 'react-rainbow-components';
import {
    StyledItemContainer,
    StyledInput,
    LabelContainer,
    StyledPicklist,
} from './styled';

interface PhoneListInputItemProps {
    name: string;
    label?: ReactNode;
    value?: Record<string, any>;
    currentValues?: Record<string, any>[];
    isSelected?: boolean;
    error?: string;
    languages?: Record<string, any>[];
    onChange?: (value: Record<string, any>[]) => void;
}

const getLanguage = (
    languages: Record<string, any>[] = [],
) => {
    if (languages?.length > 0) {
        if (languages?.length === 1) return languages[0];

        return { name: 'auto', label: 'Auto' };
    }
    return undefined;
};

const getInitialSelectedLanguage = (
    value?: Record<string, string>,
    values: Record<string, any>[] = [],
) => values?.find((val) => val.id === value?.id)?.language || value?.language;

const PhoneInputItem = (props: PhoneListInputItemProps) => {
    const {
        name,
        label,
        languages = [],
        value,
        error,
        currentValues = [],
        onChange = () => {},
    } = props;

    const isSelected = useMemo(
        () => currentValues?.some((val) => val.id === value?.id),
        [currentValues, value?.id],
    );

    // eslint-disable-next-line max-len
    const [
        selectedLanguage, setSelectedLanguage,
    ] = useState<Record<string, unknown>>();

    const languagesList = useMemo(
        () => [
            <Option key="header" label="Select language" variant="header" />,
            <Option key="auto" name="auto" label="Auto" value={{ name: 'auto', label: 'Auto' }} />,
            ...languages.sort((a, b) => Number((a.name as string) > (b.name as string))).map(
                (language) => <Option key={language.name} {...language} value={language} />,
            ),
        ],
        [languages],
    );

    const changeLanguage = useCallback((language?: Record<string, unknown>) => {
        if (isSelected) {
            onChange(currentValues.map(
                (val) => (val.id === name ? { ...val, language } : val),
            ));
        }
    }, [currentValues, isSelected, name, onChange]);

    const handleChangeLanguage = useCallback((newValue: Record<string, any>) => {
        setSelectedLanguage(newValue);
        changeLanguage(newValue);
    }, [changeLanguage]);

    const handleSelect = useCallback(() => {
        if (isSelected) {
            return onChange(currentValues.filter((val) => val.id !== name));
        }
        return onChange(currentValues.concat({ ...value, language: selectedLanguage }));
    }, [currentValues, isSelected, name, onChange, selectedLanguage, value]);

    useEffect(
        () => setSelectedLanguage(
            !selectedLanguage
                ? getInitialSelectedLanguage(value, currentValues)
                : getLanguage(languages) as Record<string, string>,
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [languages],
    );

    useEffect(
        () => changeLanguage(selectedLanguage),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedLanguage],
    );

    return (
        <StyledItemContainer>
            <StyledInput
                type="checkbox"
                checked={isSelected}
                tabIndex="-1"
                hideLabel
                error={error}
                onChange={handleSelect}
            />
            <LabelContainer>
                {label}
            </LabelContainer>
            <RenderIf isTrue={languages?.length}>
                <div>
                    <RenderIf isTrue={languages?.length > 1}>
                        <StyledPicklist
                            required={isSelected}
                            size="small"
                            value={selectedLanguage}
                            hideLabel
                            tabIndex="-1"
                            borderRadius="semi-rounded"
                            placeholder="Select Language"
                            onChange={handleChangeLanguage}
                        >
                            {languagesList}
                        </StyledPicklist>
                    </RenderIf>
                    <RenderIf isTrue={languages?.length === 1}>
                        {selectedLanguage?.label as string}
                    </RenderIf>
                </div>
            </RenderIf>
        </StyledItemContainer>
    );
};

export default PhoneInputItem;
