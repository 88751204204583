import {
    KeyboardEvent, useEffect, useRef, useState,
} from 'react';
import { UpDirection } from '@rainbow-modules/icons';
import {
    StyledContainer,
    StyledInput,
    StyledButtonIcon,
    ButtonContainer,
} from './styled';

interface MessageInputProps {
    className?: string;
    placeholder?: string;
    onSend?: (value: string) => void;
    disabled?: boolean;
}

interface InputRef {
    focus: () => void;
}

const MessageInput = ({
    className,
    placeholder = 'Talk to agent',
    onSend = () => {},
    disabled = false,
}: MessageInputProps) => {
    const inputRef = useRef<InputRef>();
    const [value, setValue] = useState<string>('');

    const sendMessage = () => {
        if (value !== '') {
            onSend(value);
            setValue('');
        }
    };

    const handleKeyDown = ({ key, shiftKey }: KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter' && !shiftKey) {
            sendMessage();
        }
    };

    useEffect(() => {
        if (!disabled) {
            inputRef.current?.focus();
        }
    }, [disabled]);

    return (
        <StyledContainer className={className} role="presentation" tabIndex={-1}>
            <StyledInput
                ref={inputRef}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                value={value}
                disabled={disabled}
                rows={1}
                grow
            />
            <ButtonContainer>
                <StyledButtonIcon
                    icon={<UpDirection />}
                    assistiveText="send"
                    title="send"
                    tabIndex={-1}
                    borderRadius="semi-rounded"
                    variant="brand"
                    onClick={sendMessage}
                    disabled={disabled || value?.length < 2}
                />
            </ButtonContainer>
        </StyledContainer>
    );
};

export default MessageInput;
