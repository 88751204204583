import { useContext, useMemo } from 'react';
import { PhoneInput, RenderIf } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import { PhoneInputValue } from 'react-rainbow-components/components/PhoneInput';
import { useParams } from 'react-router-dom';
import { EntityGet } from 'data/firestore/types';
import { Agent } from 'data/firestore/agent/types';
import dateFormatter from 'data/services/date/formatter';
import formatPhoneNumber from 'data/services/phones/format';
import ModelLink from 'components/ModelLink';
import { PageHeaderTitle } from 'components/styled';
import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import { availableLanguages } from '../../../../../constants';
import context, { Context } from '../context';
import { InfoContainer, PlaceholderText } from './styled';
import PhoneNumbers from './PhoneNumbers';
import TwilioConfig from './TwilioConfig';

const PhoneField = (
    { value }: { value?: string },
) => {
    const valueFormatted = useMemo(() => {
        let phoneInfo;
        if (value) {
            phoneInfo = formatPhoneNumber(value as string);
        }

        if (typeof phoneInfo === 'string') {
            return {
                countryCode: null,
                isoCode: null,
                phone: value,
            };
        }
        return phoneInfo;
    }, [value]);
    return <PhoneInput readOnly value={valueFormatted as PhoneInputValue} />;
};

const AgentDetails = () => {
    const { agentId = '' } = useParams();
    const {
        agentData: data, isLoading,
    } = useContext<Context>(context);

    const languages = useMemo(
        () => (data?.supportedLanguages || []).map(
            (supportedLanguage) => availableLanguages.find(
                (lang) => lang.name === supportedLanguage,
            )?.label as string,
        ).join(', '),
        [data?.supportedLanguages],
    );

    const greetings = useMemo(
        () => Object.keys(data?.greetings || {}).map(
            (language) => {
                const greeting = data?.greetings as Record<string, string>;
                const languageLabel = availableLanguages.find(
                    (lang) => lang.name === language,
                )?.label;
                return (
                    <RecordField
                        key={`agent-greeting-${language}`}
                        label={`Greeting ${languageLabel}:`}
                        value={greeting[language]}
                    />
                );
            },
        ),
        [data?.greetings],
    );

    const phoneNumbers = useMemo(
        () => (data?.phoneNumbers || []).map(
            (phone) => {
                const { number = '', language = '' } = phone;
                const languageLabel = availableLanguages.find(
                    (lang) => lang.name === language,
                )?.label;
                const phoneInfo = formatPhoneNumber(number);

                if (typeof phoneInfo === 'string') {
                    return {
                        number,
                        language: languageLabel,
                    };
                }

                return {
                    number,
                    isoCode: phoneInfo.isoCode,
                    language: languageLabel,
                };
            },
        ),
        [data?.phoneNumbers],
    );

    return (
        <div>
            <PageHeaderTitle>General Information</PageHeaderTitle>
            <InfoContainer>
                <div>
                    <RecordField
                        isLoading={isLoading}
                        label="Company Name:"
                        value={data?.companyName}
                    />
                    <RecordField
                        isLoading={isLoading}
                        label="Company Address:"
                        // eslint-disable-next-line max-len
                        value={data?.companyAddress.formattedAddress}
                    />
                    <RecordField
                        isLoading={isLoading}
                        type="number"
                        label="End-of-speech timeout:"
                        value={data?.speechTimeout}
                    />
                    <RecordField
                        isLoading={isLoading}
                        label="Speech Recognition Technology:"
                        value={data?.useTwilioStream ? 'Twilio Stream' : 'Response-Transcribe'}
                    />
                    <RecordField
                        isLoading={isLoading}
                        label="Call Recording:"
                        value={data?.callRecordingEnabled ? 'Enabled' : 'Disabled'}
                    />
                    <RecordField
                        isLoading={isLoading}
                        label="Call Recording:"
                        value={data?.callRecordingEnabled ? 'Enabled' : 'Disabled'}
                    />
                    <RecordField
                        label="Model"
                        isLoading={isLoading}
                        value={data?.model?.name}
                        component={
                            // eslint-disable-next-line max-len
                            // eslint-disable-next-line react/no-unstable-nested-components, react/no-unused-prop-types
                            ({ value }: { value: string; }) => <ModelLink versionName={value} />
                        }
                    />
                    <RecordField
                        isLoading={isLoading}
                        type="number"
                        label="Temperature:"
                        value={data?.temperature}
                    />
                </div>
                <div>
                    <RecordField
                        isLoading={isLoading}
                        label="Agent ID:"
                        value={agentId}
                    />
                    <RecordField
                        isLoading={isLoading}
                        type="dateTime"
                        label="Created At:"
                        value={dateFormatter.format((data as EntityGet<Agent>)?.createdAt)}
                    />
                    <RecordField
                        isLoading={isLoading}
                        label="Created By:"
                        value={data?.createdBy}
                    />
                    <RecordField
                        isLoading={isLoading}
                        type="dateTime"
                        label="Last Updated At:"
                        value={dateFormatter.format((data as EntityGet<Agent>)?.updatedAt)}
                    />
                </div>
            </InfoContainer>
            <TwilioConfig isLoading={isLoading} />
            <PageHeaderTitle>Hand-off Phone Number</PageHeaderTitle>
            <InfoContainer>
                <RenderIf isTrue={!data?.handoffNumber}>
                    <PlaceholderText>
                        There is no hand-off phone number set for this agent.
                    </PlaceholderText>
                </RenderIf>
                <RenderIf isTrue={Boolean(data?.handoffNumber)}>
                    <div>
                        <RecordField
                            isLoading={isLoading}
                            component={PhoneField}
                            label="Hand-off Phone Number:"
                            value={formatIntlPhoneNumber(data?.handoffNumber)}
                        />
                    </div>
                    <div>
                        <RecordField
                            isLoading={isLoading}
                            label="Call transfer percentage to hand-off:"
                            type="percent"
                            value={data?.handoffCallTransferPercentage || 0}
                        />
                    </div>
                </RenderIf>
            </InfoContainer>
            <PageHeaderTitle>Languages & Greetings</PageHeaderTitle>
            <InfoContainer>
                <div>
                    <RecordField
                        isLoading={isLoading}
                        label="Languages:"
                        value={languages}
                    />
                    {greetings}
                </div>
            </InfoContainer>
            <PageHeaderTitle>Phone Numbers</PageHeaderTitle>
            <PhoneNumbers
                isLoading={isLoading}
                phones={phoneNumbers}
            />
        </div>
    );
};

export default AgentDetails;
