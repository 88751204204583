import { useCallback, useMemo } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import DatasetOptions from 'components/DatasetOptions';
import TopBar from 'components/TopBar';
import Forms from './Forms';
import {
    Container,
    RightContent,
    Sidebar,
} from './styled';

const App = () => {
    const { datasetId, groupId } = useParams();
    const navigate = useNavigate();

    const datasetAdded = useCallback((newDatasetId: string) => {
        navigate(`/dataset/${newDatasetId}/groups`);
    }, [navigate]);

    const groupAdded = useCallback(
        (newGroupId: string) => {
            navigate(`/dataset/${datasetId}/group/${newGroupId}`);
        },
        [datasetId, navigate],
    );

    const conversationAdded = useCallback(
        (newConversationId: string) => {
            navigate(`/dataset/${datasetId}/group/${groupId}/conversation/${newConversationId}`);
        },
        [groupId, datasetId, navigate],
    );

    const outletContext = useMemo(
        () => ({
            onAddConversation: conversationAdded,
        }),
        [conversationAdded],
    );

    return (
        <Container>
            <Sidebar>
                <Forms
                    onAddDataset={datasetAdded}
                    onAddGroup={groupAdded}
                    onAddConversation={conversationAdded}
                />
                <DatasetOptions />
            </Sidebar>
            <RightContent>
                <TopBar />
                <Outlet context={outletContext} />
            </RightContent>
        </Container>
    );
};

export default App;
