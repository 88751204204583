import styled from 'styled-components';
import { HelpText } from 'react-rainbow-components';
import { color } from 'theme';

export const Name = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: ${color('text.main')};
    margin-bottom: 4px;
`;

export const Description = styled.div.withConfig({
    shouldForwardProp: (prop) => !['$required'].includes(prop),
})<{ $required?: boolean }>`
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    white-space: normal;
    margin-bottom: 4px;
    
    color: ${color('text.header')};

    ${(props) => props.$required && `
        margin-left: 12px;
    `}
`;

export const Container = styled.span`
    flex-direction: column;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 8px;
`;

export const StyledHelpText = styled(HelpText)`
    margin-left: 5px;
    width: 14px;
    height: 14px;

    > svg {
        margin-bottom: 3px;
        width: 14px;
        height: 14px;
    }
`;
