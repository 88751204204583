import { MouseEvent } from 'react';
import { Picklist, Option } from 'react-rainbow-components';
import { useReduxForm } from '@rainbow-modules/hooks';
import useVoices from 'data/firestore/speech/voices/useCollectionOnce';

interface Props {
    value?: string;
    onChange?: (value?: string) => void;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onFocus?: (value?: string) => void;
    onBlur?: (value?: string) => void;
    service?: string;
}

const VoicePicklist = (props: Props) => {
    const {
        value,
        onChange = () => {},
        service = '',
        onFocus = () => {},
        onBlur = () => {},
        ...rest
    } = useReduxForm(props);
    const { data, isLoading } = useVoices(service, {
        disabled: !service,
        track: [service],
    });
    const voice = data?.find((item) => item.id === value);
    const picklistValue = {
        label: voice?.name,
        name: voice?.id,
        value: voice?.id,
    };

    return (
        <Picklist
            {...rest}
            onBlur={(newValue) => onBlur(String(newValue?.name))}
            onFocus={(newValue) => onFocus(String(newValue?.name))}
            onChange={(newValue) => onChange(String(newValue?.name))}
            value={picklistValue}
            isLoading={isLoading}
            enableSearch
        >
            {data?.map(({ id, name }) => (
                <Option
                    key={id}
                    label={name}
                    name={id}
                    value={id}
                />
            ))}
        </Picklist>
    );
};

export default VoicePicklist;
