import { Button, LoadingShape } from 'react-rainbow-components';
import { Trash } from 'components/icons';
import {
    Card,
    CardTitle,
    CardContent,
    CardFooter,
    TrashButton,
} from './styled';

interface CompletionSuggestionProps {
    role?: string;
    content?: string;
    isLoading?: boolean;
    onInsertClick?: () => void;
    onDeleteClick?: () => void;
}

const CompletionSuggestion = ({
    role = 'assistant',
    content = '',
    isLoading = false,
    onInsertClick = () => {},
    onDeleteClick = () => {},
}: CompletionSuggestionProps) => {
    if (isLoading) {
        return (
            <Card className="rainbow-m-top_large rainbow-m-bottom_medium">
                <CardTitle>
                    Working on it...
                </CardTitle>
                <CardContent>
                    <LoadingShape />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className="rainbow-m-top_large rainbow-m-bottom_medium">
            <CardTitle>
                {role.toUpperCase()}
            </CardTitle>
            <CardContent>
                {content.split('\n').map((msg) => <p>{msg}</p>)}
            </CardContent>
            <CardFooter>
                <div>
                    <Button
                        borderRadius="semi-rounded"
                        size="small"
                        variant="brand"
                        className="rainbow-m-right_x-small"
                        label="Insert"
                        onClick={onInsertClick}
                    />
                </div>
                <TrashButton
                    borderRadius="semi-rounded"
                    variant="base"
                    icon={<Trash />}
                    onClick={onDeleteClick}
                />
            </CardFooter>
        </Card>
    );
};

export default CompletionSuggestion;
