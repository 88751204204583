/* eslint-disable react/jsx-no-undef */
import { useEffect, useMemo } from 'react';
import { useFormState, useForm, Field } from 'react-final-form';
import {
    Input, Picklist, Option, RenderIf,
    Textarea, Button, PhoneInput, CheckboxToggle,
} from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal } from '@rainbow-modules/hooks';
import { isEmpty } from '@rainbow-modules/validation';
import { Percent } from 'components/icons';
import PhoneListInput from 'components/PhoneListInput';
import Label from 'components/LabelWithDescription';
import SelectPhoneNumberForm from 'components/SelectPhoneNumber';
import useHttpQuery from 'data/firestore/useHttpQuery';
import validatePhoneNumber from 'data/services/phones/validate';
import useBuyPhone from 'hooks/useBuyPhone';
import useBuyPhoneModal, { BUY_PHONE_FORM_MODAL } from 'hooks/useBuyPhoneModal';
import usePrevious from 'hooks/usePrevious';
import ModelPicklist from 'components/ModelPicklist';
import { Agent } from 'data/firestore/agent/types';
import ModelVersionPicklist from 'components/ModelVersionPicklist';
import VoicePicklist from './voicePicklist';
import SpeechServicePicklist from './speechServicePicklist';
import { BASIC_MODELS, GOOGLE_MAPS_API_KEY, availableLanguages } from '../../../../constants';
import {
    FormHeader,
    FormTitle,
    FormFields,
    StyledMultiSelect,
    StyledGoogleAddressLookup,
    StyledInput,
} from './styled';
import ElevenLabsModelPicker from './elevenLabsModelPicker';
import ElevenLabsStreamingLatencyPicker from './elevenLabsStreamingLatencyPicker';

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const validateModel = (value: Agent['model']) => {
    if (isEmpty(value?.id)) return 'This field is required';
    return undefined;
};

const validateModelVersion = (value: Agent['model']) => {
    if (isEmpty(value?.version)) return 'This field is required';
    return undefined;
};

const validateTimeout = (value: number) => {
    if (isEmpty(value)) return 'This field is required';
    if (value < 0 || value > 6) return 'Value must be between 0 and 6';
    return undefined;
};

const validateTemperature = (value: number) => {
    if (isEmpty(value)) return 'This field is required';
    if (value < 0 || value > 1) return 'Value must be between 0 and 1';
    return undefined;
};

const validatePercentage = (value: number) => {
    if (isEmpty(value)) return 'This field is required';
    if (value < 0 || value > 100) return 'Value must be between 0 and 100';
    return undefined;
};

const validateSelectedPhone = (value: unknown) => {
    if (isEmpty(value)) return 'You must select at least one phone number';
    return undefined;
};

const validateHandoffPhoneNumber = (
    value?: { countryCode: string, isoCode: string, phone: string },
) => {
    if (!isEmpty(value?.phone) && !validatePhoneNumber(`${value?.countryCode}${value?.phone}`)) {
        return 'Invalid phone number';
    }
    return undefined;
};

const BuyPhoneFormInputFields = () => <SelectPhoneNumberForm />;

const isRootModel = (modelId?: string) => BASIC_MODELS.includes(modelId as string);

const Fields = () => {
    const { values, initialValues } = useFormState();
    const form = useForm();
    const connectedModalProps = useConnectModal(BUY_PHONE_FORM_MODAL);
    const [openModal, closeModal] = useBuyPhoneModal();

    const prevValues = usePrevious(values.speech);

    // Reset voice when changing speech service
    useEffect(() => {
        if (values?.supportedLanguages) {
            values.supportedLanguages.forEach((language: Record<string, string>) => {
                const currentService = values.speech?.[language.name]?.service;
                const prevService = prevValues?.[language.name]?.service;

                if (prevService !== undefined && currentService !== prevService) {
                    form.change(`speech.${language.name}.voice`, undefined);
                }
            });
        }
    }, [values.supportedLanguages, values.speech, form, prevValues]);

    const {
        data: phoneNumbers,
        isLoading: isLoadingPhoneNumbers,
    } = useHttpQuery({
        key: 'twilio-available-phone-numbers',
        pathname: '/phone-numbers/available',
    });

    const availablePhones = useMemo(() => {
        const usedPhoneNumbers = initialValues.usedPhones || [];
        const availablePhoneNumbers = phoneNumbers || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return [...usedPhoneNumbers, ...availablePhoneNumbers];
    }, [initialValues.usedPhones, phoneNumbers]);

    const {
        data: agentTypes = [],
        isLoading: isLoadingAgentTypes,
    } = useHttpQuery({
        key: 'agent-types',
        pathname: '/agent-types',
    });

    const agentTypesOptions = useMemo(
        () => (agentTypes as string[])?.map(
            (agentType) => (
                <Option
                    key={agentType}
                    name={agentType}
                    label={agentType}
                    value={agentType}
                />
            ),
        ),
        [agentTypes],
    );

    const { mutate: buyPhone } = useBuyPhone();

    const greetingsFields = useMemo(() => values?.supportedLanguages?.map(
        (language: Record<string, string>) => (
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Textarea}
                key={language.label}
                name={`greetings.${language?.name}`}
                required
                label={`${language?.label} Greeting`}
                labelAlignment="left"
                placeholder={`Enter a greeting on ${language?.label} for the agent`}
                borderRadius="semi-rounded"
                className="rainbow-m-top_medium"
                validate={validateNotEmpty}
            />
        ),
    ), [values?.supportedLanguages]);

    const voiceFields = useMemo(() => values?.supportedLanguages?.map(
        (language: Record<string, string>) => {
            const is11LabsSpeechService = values?.speech
                && values?.speech[language?.name]?.service.startsWith('elevenlabs-');
            return (
                <div key={`voice-${language.label}`} className="rainbow-m-top_medium">
                    <Label name={language?.label} />
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={SpeechServicePicklist}
                        name={`speech.${language?.name}.service`}
                        required
                        label="Service"
                        labelAlignment="left"
                        borderRadius="semi-rounded"
                        className="rainbow-m-top_medium"
                        validate={validateNotEmpty}
                    />
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={VoicePicklist}
                        name={`speech.${language?.name}.voice`}
                        required
                        label="Voice"
                        labelAlignment="left"
                        borderRadius="semi-rounded"
                        className="rainbow-m-top_medium"
                        validate={validateNotEmpty}
                        service={values?.speech?.[language?.name]?.service}
                    />
                    <RenderIf isTrue={is11LabsSpeechService}>
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={ElevenLabsModelPicker}
                            name={`speech.${language?.name}.config.model_id`}
                            required
                            label="Model ID"
                            labelAlignment="left"
                            borderRadius="semi-rounded"
                            className="rainbow-m-top_medium"
                            validate={validateNotEmpty}
                        />
                        <Field
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            component={ElevenLabsStreamingLatencyPicker}
                            name={`speech.${language?.name}.config.optimize_streaming_latency`}
                            required
                            label="Optimize Streaming Latency"
                            labelAlignment="left"
                            borderRadius="semi-rounded"
                            className="rainbow-m-top_medium"
                            validate={validateNotEmpty}
                        />
                    </RenderIf>
                </div>
            );
        },
    ), [values?.supportedLanguages, values?.speech]);

    const handleBuyNewPhone = () => openModal({
        title: 'Buy a new phone number',
        submitButtonLabel: 'Add',
        onSubmit: async (submittedValues: Record<string, string>) => {
            buyPhone({
                body: {
                    number: submittedValues.phoneNumber,
                },
            });
            closeModal();
        },
    });

    return (
        <>
            <FormHeader>
                <FormTitle>General Information</FormTitle>
            </FormHeader>
            <FormFields>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Input}
                    name="companyName"
                    required
                    label="Company Name"
                    labelAlignment="left"
                    placeholder="Enter the company name"
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateNotEmpty}
                />
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledGoogleAddressLookup}
                    apiKey={GOOGLE_MAPS_API_KEY}
                    name="companyAddress"
                    required
                    label="Company Address"
                    labelAlignment="left"
                    placeholder="Enter the company address"
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateNotEmpty}
                />
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Input}
                    type="number"
                    min={0}
                    max={6}
                    step={0.1}
                    name="speechTimeout"
                    required
                    label="End of speech timeout"
                    labelAlignment="left"
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateTimeout}
                />
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Picklist}
                    name="agentType"
                    required
                    label="Agent Type"
                    labelAlignment="left"
                    placeholder="Select an agent type"
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    isLoading={isLoadingAgentTypes}
                    disabled
                    validate={validateNotEmpty}
                >
                    {agentTypesOptions}
                </Field>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={ModelPicklist}
                    name="model"
                    required
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateModel}
                />
                <RenderIf isTrue={Boolean(values?.model?.id && !isRootModel(values?.model?.id))}>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={ModelVersionPicklist}
                        name="model"
                        required
                        borderRadius="semi-rounded"
                        className="rainbow-m-top_medium"
                        validate={validateModelVersion}
                    />
                </RenderIf>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Input}
                    type="number"
                    min={0}
                    max={1}
                    step={0.1}
                    name="temperature"
                    required
                    label="Temperature"
                    labelAlignment="left"
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateTemperature}
                />
                <div>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={CheckboxToggle}
                        name="callRecordingEnabled"
                        required
                        label="Call Recordings"
                        labelAlignment="top"
                        className="rainbow-m-top_medium"
                    />
                </div>
                <div>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={CheckboxToggle}
                        name="useTwilioStream"
                        label="Use Twilio stream"
                        labelAlignment="top"
                        className="rainbow-m-top_medium"
                        required
                    />
                </div>
                <div>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={CheckboxToggle}
                        name="multilingual"
                        label="Multilingual"
                        labelAlignment="top"
                        className="rainbow-m-top_medium"
                        required
                    />
                </div>
            </FormFields>
            <FormHeader>
                <FormTitle>Hand-off Phone Number</FormTitle>
            </FormHeader>
            <FormFields>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={PhoneInput}
                    name="handoffNumber"
                    label="Phone Number"
                    labelAlignment="left"
                    placeholder="Enter a hand-off phone number"
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateHandoffPhoneNumber}
                />
                <RenderIf isTrue={Boolean(values?.handoffNumber?.phone)}>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledInput}
                        type="number"
                        min={0}
                        max={100}
                        step={1}
                        name="handoffCallTransferPercentage"
                        icon={<Percent />}
                        iconPosition="right"
                        required
                        label="Call transfer percentage to hand-off number"
                        labelAlignment="left"
                        borderRadius="semi-rounded"
                        className="rainbow-m-top_medium"
                        validate={validatePercentage}
                        bottomHelpText="Enter the percentage of calls to be directly transferred to the hand-off number."
                    />
                </RenderIf>
            </FormFields>
            <FormHeader>
                <FormTitle>Languages</FormTitle>
            </FormHeader>
            <FormFields>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledMultiSelect}
                    name="supportedLanguages"
                    required
                    label="Language"
                    labelAlignment="left"
                    placeholder="Select languages"
                    showCheckbox
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateNotEmpty}
                >
                    {availableLanguages.map(
                        (language) => <Option key={language.name} {...language} />,
                    )}
                </Field>
            </FormFields>
            <RenderIf isTrue={greetingsFields?.length > 0}>
                <FormHeader>
                    <FormTitle>Greetings</FormTitle>
                </FormHeader>
                <FormFields>
                    {greetingsFields}
                </FormFields>
            </RenderIf>
            <RenderIf isTrue={voiceFields?.length > 0}>
                <FormHeader>
                    <FormTitle>Voices</FormTitle>
                </FormHeader>
                <FormFields>
                    {voiceFields}
                </FormFields>
            </RenderIf>
            <FormHeader>
                <FormTitle>Phone Numbers</FormTitle>
                <Button
                    label="Buy New Phone"
                    size="small"
                    variant="neutral"
                    borderRadius="semi-rounded"
                    onClick={handleBuyNewPhone}
                />
            </FormHeader>
            <FormFields>
                <Label
                    name="Phone numbers for the agent:"
                    description="Lorem ipsum is placeholder text commonly used in the graphic."
                />
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={PhoneListInput}
                    // eslint-disable-next-line max-len
                    isLoading={isLoadingPhoneNumbers}
                    name="phoneNumbers"
                    languages={values?.supportedLanguages}
                    phones={availablePhones}
                    validate={validateSelectedPhone}
                />
            </FormFields>
            <UniversalFormModal
                fields={BuyPhoneFormInputFields}
                borderRadius="semi-rounded"
                {...connectedModalProps}
            />
        </>
    );
};

export default Fields;
