import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RecordField } from '@rainbow-modules/record';
import ReactJson from 'react-json-view';
import { Button, RenderIf } from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import ConversationMessage from 'components/ChatMessage';
import EmptyMessage from 'components/EmptyMessage';
import useHttpMutation from 'data/firestore/useHttpMutation';
import context, { Context } from '../context';
import {
    Content,
    StyledSpinner,
    EmptyMessageSpinner,
} from '../styled';

const ConversationTranscript = () => {
    const { conversationId = '' } = useParams();
    const {
        messages = [],
        metadata = [],
        isLoading: isLoadingMessages,
    } = useContext<Context>(context);
    const filteredMessages = messages.filter((message) => message.role === 'assistant' || message.role === 'user');

    const speechToTextTranscript = useMemo(
        () => metadata.find((value) => value.id === 'transcript') as Record<string, unknown>,
        [metadata],
    );

    const {
        isLoading: isGeneratingTranscript,
        mutate: generateTranscript,
    } = useHttpMutation({
        pathname: `session/${conversationId}/transcript`,
        onError: ({ message }) => showAppMessage({
            message,
            variant: 'error',
            timeout: 5000,
        }),
    });

    const isLoading = isLoadingMessages || isGeneratingTranscript;

    return (
        <Content className="rainbow-p-right_medium">
            <RenderIf isTrue={isLoading}>
                <div className="rainbow-p-around_large">
                    <RenderIf isTrue={isLoadingMessages}>
                        <StyledSpinner size="small" type="arc" variant="brand" />
                    </RenderIf>
                    <RenderIf isTrue={isGeneratingTranscript}>
                        <EmptyMessage
                            icon={<EmptyMessageSpinner size="large" variant="brand" type="arc" />}
                            title="Generating"
                            description="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                        />
                    </RenderIf>
                </div>
            </RenderIf>
            <RenderIf isTrue={!isLoading && filteredMessages?.length === 0}>
                <div className="rainbow-p-around_large">
                    <RenderIf isTrue={!speechToTextTranscript}>
                        <EmptyMessage
                            title="There is no transcript in this conversation"
                            description="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                            action={(
                                <Button
                                    variant="brand"
                                    borderRadius="semi-rounded"
                                    label="Generate Transcript"
                                    onClick={() => generateTranscript({})}
                                />
                            )}
                        />
                    </RenderIf>
                    <RenderIf isTrue={speechToTextTranscript}>
                        <RecordField label="Deepgram transcript" value={<ReactJson src={speechToTextTranscript} />} />
                    </RenderIf>
                </div>
            </RenderIf>
            <RenderIf isTrue={!isLoading && filteredMessages?.length > 0}>
                {filteredMessages?.map((message) => (
                    <ConversationMessage
                        key={message.id}
                        value={message}
                        readOnly
                        collectionPath={`/session/${conversationId}/conversation`}
                    />
                ))}
            </RenderIf>
        </Content>
    );
};

export default ConversationTranscript;
