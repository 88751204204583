import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { Agent } from "./types";

export type AgentUpdateService<T> = (
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: AgentUpdateService<Agent> = async (id, data) => {
  const collectionRef = doc(db, `/agent/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
