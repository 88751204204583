/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Session } from 'data/firestore/session/types';
import { EntityGet } from 'data/firestore/types';
import { Conversation } from 'data/firestore/session/conversation/types';

export interface Context {
    sessionData?: Session | null;
    isLoading?: boolean;
    messages?: EntityGet<Conversation>[],
    metadata?: Record<string, unknown>[],
}

const context = React.createContext<Context>({
    isLoading: false,
    messages: [],
    metadata: [],
});

export const { Provider, Consumer } = context;

export default context;
