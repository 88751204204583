import { MouseEvent, useMemo } from 'react';
import { Picklist, Option } from 'react-rainbow-components';
import { useReduxForm } from '@rainbow-modules/hooks';

interface Props {
    value?: number;
    onChange?: (value?: number) => void;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    onFocus?: (value?: number) => void;
    onBlur?: (value?: number) => void;
}

const latencies = [
    { name: '0', label: '0 - No latency optimization', value: 0 },
    { name: '1', label: '1 - Normal latency optimization', value: 1 },
    { name: '2', label: '2 - Strong latency optimization', value: 2 },
    { name: '3', label: '3 - Max latency optimization', value: 3 },
    { name: '4', label: '4 - Max latency optimization with text normalizer', value: 4 },
];

const ElevenLabsStreamingLatencyPicker = (props: Props) => {
    const {
        value,
        onChange = () => {},
        onFocus = () => {},
        onBlur = () => {},
        ...rest
    } = useReduxForm(props);
    const picklistValue = useMemo(
        () => latencies.find((latency) => latency.value === value),
        [value],
    );

    const picklistOptions = useMemo(
        () => latencies.map(
            (latency) => (
                <Option
                    key={`11labs-optimized-streaming-latency__${latency.name}`}
                    name={latency.name}
                    label={latency.label}
                    value={latency.value}
                />
            ),
        ),
        [],
    );

    return (
        <Picklist
            {...rest}
            onBlur={(newValue) => onBlur(newValue?.value)}
            onFocus={(newValue) => onFocus(newValue?.value)}
            onChange={(newValue) => onChange(newValue?.value)}
            value={picklistValue}
            enableSearch
        >
            {picklistOptions}
        </Picklist>
    );
};

export default ElevenLabsStreamingLatencyPicker;
