import { useOpenModal } from '@rainbow-modules/hooks';
import { UniversalForm } from '@rainbow-modules/forms';
import useSendCampaignFlow from 'hooks/useSendCampaignFlow';
import { useNavigate } from 'react-router-dom';
import { Campaign } from 'data/firestore/agent/campaign/types';
import getVersion from 'data/firestore/model/version/get';
import { ADD_CAMPAIGN_DRAWER } from '../../../../../../constants';
import Footer from './footer';
import { Container, Content, FormContainer } from './styled';
import Fields from './fields';

interface Values {
    caller: {
        value: string;
    };
    contacts: Array<{
        name: string;
        phone: string;
    }>;
    systemMessageTemplate: string;
    model: {
        id: string;
        version: string;
    };
    temperature: number;
}

const AddCampaignForm = ({ agentId }:{ agentId: string }) => {
    const [, closeDrawer] = useOpenModal(ADD_CAMPAIGN_DRAWER);
    const navigate = useNavigate();
    const sendCampaignFlow = useSendCampaignFlow({
        onSuccess: ((campaign: Campaign) => {
            closeDrawer();
            navigate(`/agents/${agentId}/campaigns/${campaign.id}`);
        }) as () => void,
    });
    const onSubmit = async (values: Values) => {
        const {
            caller, contacts, systemMessageTemplate, temperature, model,
        } = values;

        const version = (
            model.version
                ? await getVersion(model.id, model.version)
                : { name: model.id }
        );

        return sendCampaignFlow({
            agentId,
            caller: caller.value,
            contacts,
            systemMessageTemplate,
            model: version?.name as string,
            temperature: Number(temperature),
        });
    };
    return (
        <Container>
            <Content>
                <FormContainer>
                    <UniversalForm
                        id="campaign-creation-form"
                        initialValues={{
                            temperature: 0.6,
                        }}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onSubmit={onSubmit}
                    >
                        <Fields />
                    </UniversalForm>
                </FormContainer>
            </Content>
            <Footer
                formId="campaign-creation-form"
                onCancelClick={closeDrawer}
            />
        </Container>
    );
};

export default AddCampaignForm;
