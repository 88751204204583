import { query, where } from 'firebase/firestore';
import {
    useParams, useNavigate, Outlet, useMatch,
} from 'react-router-dom';
import {
    Breadcrumb, Breadcrumbs, ButtonIcon, Tab, Tabset,
} from 'react-rainbow-components';
import { useOpenModal, useConnectModal } from '@rainbow-modules/hooks';
import { Pencil, Trash } from 'components/icons';
import useAgent from 'data/firestore/agent/use';
import useTwilioConfig from 'data/firestore/twilioconfig/useCollection';
import useDeleteAgent from 'hooks/useDeleteAgent';
import PageHeader from 'components/PageHeader';
import { ADD_MODIFY_AGENT_DRAWER } from '../../../../constants';
import { PageTitle, StyledDrawerTitle } from '../styled';
import { Container, Content } from './styled';
import { Provider } from './context';
import ChunkDetailsModal from './KnowledgeBase/chunkDetailsModal';

const AgentDetailsLayout = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigate();
    const [openDrawer] = useOpenModal(ADD_MODIFY_AGENT_DRAWER);
    const match = useMatch('agents/:agentId/:tabName');
    const { data, isLoading: isLoadingAgent } = useAgent(agentId as string);
    const { data: twilioConfig = [], isLoading: isLoadingConfig } = useTwilioConfig({
        listQueryFn: (ref) => query(ref, where('agentId', '==', agentId)),
    });
    const chunkDetailsModalProps = useConnectModal('show-chunk-details');
    const deteleAgent = useDeleteAgent({
        agentId,
        onSuccess: () => navigate('/agents'),
    });

    const isLoading = isLoadingAgent || isLoadingConfig;

    return (
        <Container>
            <PageHeader
                title={(
                    <Breadcrumbs>
                        <Breadcrumb
                            label="All Agents"
                            onClick={() => navigate('/agents/')}
                        />
                        <Breadcrumb label={<PageTitle>{agentId}</PageTitle>} />
                    </Breadcrumbs>
                )}
                actions={(
                    <>
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Pencil />}
                            onClick={() => openDrawer({
                                mode: 'edit',
                                header: <StyledDrawerTitle>Modify Agent</StyledDrawerTitle>,
                                agentData: data,
                            })}
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Trash />}
                            onClick={deteleAgent}
                        />
                    </>
                )}
            />
            <div className="rainbow-m-horizontal_large">
                <Tabset
                    variant="line"
                    onSelect={(event, eventName) => {
                        navigate(eventName);
                        event.preventDefault();
                    }}
                    activeTabName={match?.params?.tabName}
                >
                    <Tab name="details" label="Agent Details" />
                    <Tab name="system-message" label="System Message" />
                    <Tab name="knowledge-base" label="Knowledge Base" />
                    <Tab name="insights" label="Insights" />
                    <Tab name="keywords" label="Keywords" />
                    <Tab name="notifications" label="Notifications" />
                    <Tab name="versions" label="Versions" />
                    <Tab name="billing" label="Billing" />
                    <Tab name="campaigns" label="Campaigns" />
                    <Tab name="features" label="Features" />
                </Tabset>
            </div>
            <Content>
                <Provider
                    value={{
                        isLoading,
                        agentData: data,
                        twilioConfig: twilioConfig.at(0),
                    }}
                >
                    <Outlet />
                </Provider>
            </Content>
            <ChunkDetailsModal
                {...chunkDetailsModalProps}
            />
        </Container>
    );
};

export default AgentDetailsLayout;
