/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/no-unstable-nested-components */
import { CSSProperties } from 'styled-components';
import { useReduxForm } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import { BadgeContainer, RoleContainer, StyledBadge } from './styled';

interface RoleSelectProps {
    id?: string;
    className?: string;
    style?: CSSProperties;
    value?: 'assistant' | 'user';
    onChange?: (value: 'assistant' | 'user') => void;
}

const RoleSelect = (props: RoleSelectProps) => {
    const {
        id,
        className,
        style,
        value = 'user',
        onChange = () => {},
    } = useReduxForm(props);

    const handleRoleChange = () => onChange(value === 'user' ? 'assistant' : 'user');

    return (
        <RoleContainer onClick={handleRoleChange} id={id} className={className} style={style}>
            <BadgeContainer>
                <RenderIf isTrue={value === 'user'}>
                    <StyledBadge label="User" borderRadius="semi-square" />
                </RenderIf>
                <RenderIf isTrue={value === 'assistant'}>
                    <StyledBadge label="Assistant" borderRadius="semi-square" />
                </RenderIf>
            </BadgeContainer>
        </RoleContainer>
    );
};

export default RoleSelect;
