import { useState } from 'react';
import {
    Button, LoadingShape, RenderIf,
} from 'react-rainbow-components';
import {
    Shorten, Trash, Elaborate, Letters,
} from 'components/icons';
import { ArrowRight } from '@rainbow-modules/icons';
import useHttpMutation from 'data/firestore/useHttpMutation';
import updateMessage from 'data/firestore/message/update';
import {
    ModalContainer,
    ActionsContainer,
    Card,
    CardTitle,
    CardContent,
    CardFooter,
    TrashButton,
    ModalFooter,
    ArrowButton,
    StyledCustomTextarea,
    Icon,
} from './styled';
import { Message } from './types';

interface HelpMeWriteModalProps {
    message: Message;
    onRequestClose: () => void;
}

interface ReWrite {
    content: string;
    format: string;
}

interface RewriteResponse {
    message: string;
    transforms: string[];
}

interface RewriteRequest {
    message: string;
    transform: string;
    instruction?: string;
}

const HelpMeWriteModal = ({ message, onRequestClose }: HelpMeWriteModalProps) => {
    const [rewrites, setRewrites] = useState<ReWrite[]>([]);
    const [instruction, setInstruction] = useState('');
    const { mutate, isLoading } = useHttpMutation<RewriteRequest, RewriteResponse>({
        method: 'POST',
        pathname: '/message/transform',
    });

    const createRewrite = (transform: string) => {
        mutate({
            body: {
                message: message.content,
                transform,
                instruction,
            },
        }, {
            onSuccess: (response) => {
                setRewrites((prev) => [...prev, {
                    content: response.message,
                    format: transform,
                }]);
                if (instruction) {
                    setInstruction('');
                }
            },
        });
    };
    const updateRewrite = (index: number, content: string) => {
        mutate({
            body: {
                message: content,
                transform: 'rephrase',
            },
        }, {
            onSuccess: (response) => {
                setRewrites((prev) => {
                    // eslint-disable-next-line no-param-reassign
                    prev[index].content = response.message;
                    return prev;
                });
            },
        });
    };
    const removeRewrite = (index: number) => {
        setRewrites((prev) => prev.filter((_, i) => i !== index));
    };
    const insertRewrite = async (index: number) => {
        await updateMessage(message.id, {
            content: rewrites[index].content,
        });
        onRequestClose();
    };
    return (
        <ModalContainer>
            <Card>
                <CardTitle>
                    YOUR TEXT
                </CardTitle>
                <CardContent>
                    {message.content}
                </CardContent>
            </Card>
            {
                rewrites.map((rewrite: ReWrite, index: number) => (
                    <Card key={`${rewrite.format}-${rewrite.content}`}>
                        <CardTitle>
                            {rewrite.format.toUpperCase()}
                        </CardTitle>
                        <CardContent>
                            {rewrite.content}
                        </CardContent>
                        <CardFooter>
                            <div>
                                <Button
                                    borderRadius="semi-rounded"
                                    size="small"
                                    variant="brand"
                                    className="rainbow-m-right_x-small"
                                    label="Insert"
                                    onClick={() => insertRewrite(index)}
                                    isLoading={isLoading}
                                />
                                <Button
                                    borderRadius="semi-rounded"
                                    size="small"
                                    variant="base"
                                    label="Rephrase"
                                    onClick={() => updateRewrite(index, rewrite.content)}
                                    disabled={isLoading}
                                />
                            </div>
                            <TrashButton
                                borderRadius="semi-rounded"
                                variant="base"
                                icon={<Trash />}
                                onClick={() => removeRewrite(index)}
                                disabled={isLoading}
                            />
                        </CardFooter>
                    </Card>
                ))
            }
            <RenderIf isTrue={isLoading}>
                <Card>
                    <CardTitle>
                        Working on it...
                    </CardTitle>
                    <CardContent>
                        <LoadingShape />
                    </CardContent>
                </Card>
            </RenderIf>
            <ActionsContainer>
                <Button
                    borderRadius="semi-rounded"
                    size="small"
                    variant="border"
                    onClick={() => createRewrite('rephrase')}
                    disabled={isLoading}
                >
                    <Icon />
                    Rephrase
                </Button>
                <Button
                    borderRadius="semi-rounded"
                    size="small"
                    variant="border"
                    onClick={() => createRewrite('shorten')}
                    disabled={isLoading}
                >
                    <Icon as={Shorten} />
                    Shorten
                </Button>
                <Button
                    borderRadius="semi-rounded"
                    size="small"
                    variant="border"
                    onClick={() => createRewrite('elaborate')}
                    disabled={isLoading}
                >
                    <Icon as={Elaborate} />
                    Elaborate
                </Button>
                <Button
                    borderRadius="semi-rounded"
                    size="small"
                    variant="border"
                    onClick={() => createRewrite('fix')}
                    disabled={isLoading}
                >
                    <Icon as={Letters} />
                    Correct Spelling
                </Button>
            </ActionsContainer>
            <ModalFooter>
                <StyledCustomTextarea
                    label="Custom"
                    hideLabel
                    placeholder="Custom..."
                    borderRadius="semi-rounded"
                    grow
                    rows={1}
                    value={instruction}
                    onChange={(e) => setInstruction(e.target.value)}
                />
                <ArrowButton
                    borderRadius="semi-rounded"
                    variant="brand"
                    icon={<ArrowRight />}
                    onClick={() => createRewrite('custom')}
                    disabled={isLoading}
                />
            </ModalFooter>
        </ModalContainer>
    );
};

export default HelpMeWriteModal;
