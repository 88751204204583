import styled from 'styled-components';
import { color } from 'theme';
import {
    Textarea, Button, Badge, Modal, ButtonIcon,
} from 'react-rainbow-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import { Pencil, EditNote } from 'components/icons';
import { BACKGROUND } from '../../../../constants';

export const Container = styled.div`
    background: ${color('background.main')};
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 12px;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 16px 16px 20px;
`;

export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

export const Title = styled.span`
    font-size: 15px;
    font-family: "Lato Bold";
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const Content = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    overflow: auto;
`;

export const PlaygroundContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    gap: 24px;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 8px;
    padding-bottom: 40px;
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 8px;
    box-sizing: border-box;
`;

export const StyledTextarea = styled(Textarea)`
    flex: 1;
    
    & > div {
        height: 100%;
    }

    textarea {
        height: 100%;
    }
`;

export const StyledButton = styled(Button)`
    width: fit-content;
`;

export const StyledSubmitButton = styled(Button)`
    width: fit-content;
    align-self: flex-end;
`;

export const CompletionContainer = styled.div`
    display: flex;
    background: ${BACKGROUND};
    border-radius: 10px;
    color: ${color('text.header')};
    padding: 12px;
    gap: 16px;
    align-items: flex-start;
`;

export const CompletionText = styled.p`
    font-size: 15px;
    color: ${color('text.main')};
    line-height: 1.5;
    margin: 2px 0 4px 0;
`;

export const CompletionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
`;

export const StyledBadge = styled(Badge)`
    flex-shrink: 0;
    color: ${color('brand.main')};
    background: ${color('brand.light')};
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    flex-shrink: 0;
`;

export const IdealMessageItemContainer = styled.div`
    background: ${BACKGROUND};
    border-radius: 10px;
    padding: 8px 8px 8px 16px;
    display: flex;
    gap: 8px;
`;

export const MenuIcon = styled(Pencil)`
    height: 16px;
    width: auto;
`;

export const ModalTitle = styled.span`
    font-size: 22px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    padding: 16px 20px;
`;

export const ImportButton = styled(Button)`
    align-self: flex-end;
`;

export const StyledModal = styled(Modal)`
    background-color: ${color('background.secondary')};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px 0px;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 8px 0;
`;

export const Card = styled.div`
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${color('border.disabled')};
    background: ${color('background.main')};
`;

export const CardTitle = styled.h2`
    color: ${color('text.header')};
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    line-height: 150%;
`;

export const CardRole = styled.p`
    color: ${color('text.main')};
    font-size: 12px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
`;

export const CardContent = styled.p`
    color: ${color('text.main')};
    font-size: 14px;
    line-height: 150%;
`;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 12px;
`;

export const TrashButton = styled(ButtonIcon)`
    height: 32px;
    width: 32px;
`;

export const ModalFooter = styled.footer`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
`;

export const ArrowButton = styled(ButtonIcon)`
    position: absolute;
    right: 4px;
    top: 3px;
`;

export const StyledCustomTextarea = styled(Textarea)`
    width: 100%;
`;

export const Icon = styled(EditNote)`
    margin-right: 8px;
`;

export const BlocksContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 300px;
    overflow: auto;
    margin-bottom: 12px;
`;

export const InsertButton = styled(Button)`
    float: right;
`;

export const MessagesListActions = styled.div`
    display: flex;
    gap: 10px;
    padding: 20px 0;
`;

export const ConversationActionsContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: center;
`;
