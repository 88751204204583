import { useMutationFlow } from '@rainbow-modules/hooks';
import { Campaign } from 'data/firestore/agent/campaign/types';

import useSendCampaignMutation from 'hooks/useSendCampaignMutation';

interface Params {
    onSuccess?: (campaign: Campaign) => void;
}

interface ExecuteFlowParams {
    agentId: string;
    caller: string;
    contacts: Array<{
        name: string;
        phone: string;
    }>;
    systemMessageTemplate: string;
    model: string;
    temperature?: number;
}

export default ({
    onSuccess = () => {},
}: Params): (params: ExecuteFlowParams) => void => {
    const { mutateAsync: sendCampaign } = useSendCampaignMutation();
    const { mutate: executeSendCampaignFlow } = useMutationFlow({
        mutation: async (
            {
                agentId, contacts, systemMessageTemplate, caller, model, temperature,
            }: ExecuteFlowParams,
        ) => sendCampaign({
            body: {
                agentId,
                contacts,
                systemMessageTemplate: systemMessageTemplate as string,
                caller,
                model,
                temperature,
            },
        }),
        successMessage: 'The campaign was sended successfully.',
        onSuccess: onSuccess as () => void,
    });
    return executeSendCampaignFlow;
};
