import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import { color } from 'theme';
import { BACKGROUND } from '../../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${BACKGROUND};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0px 0 16px;
`;

export const Content = styled.div`
    height: 100%;
    gap: 16px;
    display: flex;
    overflow: hidden;
    margin-bottom: 16px;
    box-sizing: border-box;
    flex: 1;
`;

export const LeftContent = styled.div`
    background: ${color('background.main')};
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

export const Sidebar = styled.div`
    background: ${color('background.main')};
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    padding: 16px;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const ChatSimulatorHeader = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 12px 12px 12px 20px;
    border-bottom: 1px solid ${color('border.disabled')};
`;

export const Divider = styled.div`
    flex: 1;
`;

export const PageTitle = styled.h1`
    font-size: 24px;
    font-weight: 700;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;

export const SectionTitle = styled.h4`
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
`;

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ChatSimulatorFooter = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 16px;
    border-top: 1px solid ${color('border.disabled')};
    height: 96px;
`;

export const MessagesList = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    overflow: auto;
`;

export const StyledButton = styled(Button)`
    &[disabled] {
        color: ${color('brand.light')};
        border-color: ${color('brand.light')};
    }
`;

export const SessionEndMessage = styled.div`
    border-radius: 8px;
    display: flex;
    padding: 12px;
    gap: 12px;
    border: none;
    outline: none;
    justify-content: center;
    color: ${color('text.main')};
`;
