/* eslint-disable class-methods-use-this */
import readChunksAsJSON from './helpers/readChunksAsJSON';

interface Opts extends RequestInit {}

interface Listeners {
    [key: string]: Array<(event: MessageEvent) => void>;
}

/**
 * A minimal implementation of EventSource that accepts the request configuration.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/EventSource
 */
class EventSource {
    url: string;

    opts: Opts;

    listeners: Listeners = {};

    constructor(url: string, opts?: Opts) {
        this.url = url;
        this.opts = opts || { method: 'GET' };
        setTimeout(() => this.call());
    }

    private async call() {
        const response = await fetch(this.url, this.opts);
        if (response.ok && response.headers.get('content-type') === 'text/event-stream') {
            const reader = response.body?.getReader();
            if (!reader) return;
            // eslint-disable-next-line no-restricted-syntax
            for await (const event of readChunksAsJSON(reader)) {
                if (event) {
                    const { name, data } = event;
                    if (name) {
                        if (this.listeners[name]) {
                            this.listeners[name].forEach((listener) => {
                                listener(new MessageEvent(name, {
                                    data,
                                }));
                            });
                        }
                    } else {
                        const ev = new MessageEvent('message', {
                            data,
                        });
                        this.onmessage(ev);
                    }
                }
            }
        }
    }

    addEventListener(name: string, listener: (event: MessageEvent) => void) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }
        this.listeners[name].push(listener);
    }

    onmessage: (event: MessageEvent) => void = () => {};
}

export default EventSource;
