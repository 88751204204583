import {
    HighCognitiveLoad,
    LowCognitiveLoad,
    ModerateCognitiveLoad,
} from 'components/icons';
import { StyledBadge } from './styled';

interface IconMap {
    [key: string]: React.ComponentType;
}

const iconMap: IconMap = {
    low: LowCognitiveLoad,
    moderate: ModerateCognitiveLoad,
    high: HighCognitiveLoad,
};

interface CognitiveLoadBadgeProps {
    cognitiveLoad: {
        cognitiveLoad: string;
        why: string
    } | null;
}

const CognitiveLoadBadge = (props: CognitiveLoadBadgeProps) => {
    const { cognitiveLoad } = props;
    if (cognitiveLoad != null) {
        const Icon = iconMap[cognitiveLoad.cognitiveLoad.toLowerCase()] || iconMap.moderate;

        return (
            <StyledBadge borderRadius="semi-square" cognitiveLoad={cognitiveLoad.cognitiveLoad}>
                <Icon />
                <span className="rainbow-m-left_x-small">
                    Cognitive Load:
                    {' '}
                    {cognitiveLoad.cognitiveLoad}
                </span>
            </StyledBadge>
        );
    }
    return null;
};

export default CognitiveLoadBadge;
