import { useEffect, useState } from 'react';
import { Button, Option, RenderIf } from 'react-rainbow-components';
import { ArrowRight } from '@rainbow-modules/icons';
import { Dataset } from 'data/firestore/dataset/types';
import getVersion from 'data/firestore/model/version/get';
import {
    Container,
    StyledPicklist,
    CustomAssistantInputContainer,
} from './styled';
import SettingsButton from './settingsButton';
import InstructionsInput from './instructionsInput';
import isRootModel from '../helpers/isRootModel';

interface ConversationCompletionProps {
    dataset: Dataset | null;
    instruction?: string;
    disabled?: boolean;
    onInstructionChange?: (value: string) => void;
    onSubmit?: (values: Record<string, unknown>) => void;
}

const labels: Record<string, string> = {
    gpt: 'GPT Assistant',
    custom: 'Custom Model Assistant',
};

const extractSettings = (dataset: Dataset | null) => {
    const { model, temperature } = dataset || {};
    return { model, temperature } as Pick<Dataset, 'model' | 'temperature'>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConversationCompletion = ({
    dataset,
    instruction,
    disabled = false,
    onInstructionChange = () => {},
    onSubmit = () => {},
}: ConversationCompletionProps) => {
    const [assistantMode, setAssistantMode] = useState('gpt');
    const [settings, setSettings] = useState<Pick<Dataset, 'model' | 'temperature'>>();
    const [modelName, setModelName] = useState<string>('');

    useEffect(() => setSettings(extractSettings(dataset)), [dataset]);

    useEffect(() => {
        const { model } = settings || {};
        if (isRootModel(model?.id as string)) {
            setModelName(model?.id || '');
        } else if (model?.version) {
            (async () => {
                const versionInfo = await getVersion(model?.id, model.version || '');
                setModelName(versionInfo?.name || '');
            })();
        }
    }, [settings]);

    return (
        <Container>
            <StyledPicklist
                borderRadius="semi-rounded"
                value={{
                    name: assistantMode,
                    label: labels[assistantMode],
                    value: assistantMode,
                }}
                onChange={({ value }) => setAssistantMode(value)}
                disabled={disabled || !dataset}
            >
                <Option label="Select Assistant Mode" variant="header" />
                <Option name="custom" label="Custom Model Assistant" value="custom" />
                <Option name="gpt" label="GPT Assistant" value="gpt" />
            </StyledPicklist>
            <RenderIf isTrue={assistantMode === 'gpt'}>
                <InstructionsInput
                    value={instruction}
                    disabled={disabled || !dataset}
                    onChange={onInstructionChange}
                    onSend={() => onSubmit({
                        assistantMode,
                        instruction,
                    })}
                />
            </RenderIf>
            <RenderIf isTrue={assistantMode === 'custom'}>
                <CustomAssistantInputContainer>
                    <Button
                        variant="brand"
                        borderRadius="semi-rounded"
                        disabled={disabled || !dataset}
                        onClick={() => onSubmit({
                            assistantMode,
                            ...settings,
                            modelName,
                        })}
                    >
                        <span className="rainbow-m-right_small">Submit Request</span>
                        <ArrowRight />
                    </Button>
                    <SettingsButton
                        settings={settings}
                        onSettingsChanged={setSettings}
                    />
                </CustomAssistantInputContainer>
            </RenderIf>
        </Container>
    );
};

export default ConversationCompletion;
