import { IconProps } from './types';

const Play = ({ title = 'Play', className }: IconProps) => (
    <svg className={className} width={10} height={12} viewBox="0 0 10 12" fill="none">
        <title>{title}</title>
        <path d="M0.5 0L9.5 6L0.5 12V0Z" fill="currentColor" />
    </svg>
);

export default Play;
