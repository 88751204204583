import {
    Table, Column, RenderIf, LoadingShape,
} from 'react-rainbow-components';
import { useContext } from 'react';
import useCollection from 'data/firestore/dataset/useCollection';
import {
    documentId, query, where,
} from 'firebase/firestore';
import DateTimeColumn from 'components/DateTimeColumn';
import useConversationCount from 'data/firestore/conversation/useCount';
import { useNavigate } from 'react-router-dom';
import { PageHeaderTitle } from 'components/styled';
import {
    Header,
    StyledLoadingContainer,
    StyledNavigationButtonColumn,
    ConversationCounterContainer,
} from './styled';
import context from '../context';

const DatasetConversationsCountColumn = ({ value }: { value?: string }) => {
    const { count, isLoading } = useConversationCount({
        listQueryFn: (ref) => query(ref, where('datasetId', '==', value)),
    });
    return (
        <>
            <RenderIf isTrue={isLoading}>
                <StyledLoadingContainer>
                    <LoadingShape />
                </StyledLoadingContainer>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <ConversationCounterContainer>{count}</ConversationCounterContainer>
            </RenderIf>
        </>
    );
};

const VersionConversationsCountColumn = ({ value }: { value?: string }) => {
    const { version } = useContext(context);
    // eslint-disable-next-line max-len
    const count = (version?.insights?.conversationsByDataset as Record<string, number>)?.[value as string] as number || 0;
    return (<ConversationCounterContainer>{count}</ConversationCounterContainer>);
};

const SamplesCountColumn = ({ value }: { value?: string }) => {
    const { version } = useContext(context);
    // eslint-disable-next-line max-len
    const count = (version?.insights?.samplesByDataset as Record<string, number>)?.[value as string] as number || 0;
    return (<ConversationCounterContainer>{count}</ConversationCounterContainer>);
};

const Dataset = () => {
    const { version } = useContext(context);
    const navigate = useNavigate();
    const { data: datasets, isLoading: isLoadingDatasets } = useCollection({
        listQueryFn: (ref) => query(ref, where(documentId(), 'in', version?.datasetIds || [])),
        disabled: !version,
    });
    return (
        <div>
            <Header className="rainbow-m-top_large rainbow-m-bottom_medium">
                <PageHeaderTitle>
                    Datasets
                </PageHeaderTitle>
            </Header>

            <Table
                data={datasets}
                keyField="id"
                variant="listview"
                className="rainbow-m-left_medium rainbow-m-right_medium"
                isLoading={isLoadingDatasets}
            >
                <Column
                    header="Dataset Name"
                    field="name"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={StyledNavigationButtonColumn}
                    onClick={({ row }: { row: any }) => navigate(`/dataset/${row?.id}/groups`)}
                />
                <Column header="Conversations In Dataset" field="id" defaultWidth={200} cellAlignment="left" headerAlignment="left" component={DatasetConversationsCountColumn} />
                <Column header="Conversations Analyzed" field="id" defaultWidth={200} cellAlignment="left" headerAlignment="left" component={VersionConversationsCountColumn} />
                <Column header="Samples Extracted" field="id" defaultWidth={200} cellAlignment="left" headerAlignment="left" component={SamplesCountColumn} />
                <Column header="Created At" field="createdAt" defaultWidth={200} cellAlignment="left" headerAlignment="left" component={DateTimeColumn} />
            </Table>
        </div>
    );
};

export default Dataset;
