import { useEffect, useState } from 'react';
import {
    collectionGroup, query, where, getDocs,
} from 'firebase/firestore';
import { db } from 'data/firebase';
import { Version } from 'data/firestore/model/version/types';
import { EntityGet } from 'data/firestore/types';

type ModelVersion = EntityGet<Version> & { model: string };

const getModelVersion = async (versionName: string): Promise<ModelVersion | undefined> => {
    const querySnapshot = await getDocs(query(collectionGroup(db, 'version'), where('name', '==', versionName)));
    const modelVersion = !querySnapshot.empty ? {
        id: querySnapshot.docs?.[0].id,
        ...querySnapshot.docs?.[0].data(),
        model: querySnapshot.docs?.[0].ref.parent.parent?.id,
    } as EntityGet<Version> & { model: string } : undefined;
    return modelVersion;
};

const useModelVersion = (versionName?: string): [ModelVersion | undefined, boolean] => {
    const [version, setModelVersion] = useState<EntityGet<ModelVersion>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        (async () => {
            if (!versionName) return;
            const foundVersion = await getModelVersion(versionName);
            setModelVersion(foundVersion);
            setIsLoading(false);
        })();
    }, [versionName]);
    if (!versionName) {
        return [undefined, false];
    }
    return [version, isLoading];
};

export default useModelVersion;
