const formatTimeMark = (ms: number) => {
    // Convert milliseconds to total seconds
    const totalSeconds = Math.floor(ms / 1000);

    // Compute minutes and remaining seconds
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const milliseconds = ms % 1000;
    const millisecondsToShow = milliseconds < 1 ? milliseconds.toFixed(2) : milliseconds.toFixed(0);

    // Format to ensure proper display
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(millisecondsToShow).padStart(3, '0');

    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
};

export default formatTimeMark;
