import {
    StatusContainer, ClockIcon, StyledSpinner, CheckmarkIcon, ErrorIcon,
} from './styled';

interface Props {
    value?: string;
    className?: string;
}

const VersionStatus = (props: Props) => {
    const { value, className } = props;
    const statusMapper: Record<string, any> = {
        'processing-data': (
            <StatusContainer>
                <StyledSpinner size="xx-small" type="arc" variant="brand" />
                Processing data
            </StatusContainer>
        ),
        'ready-to-train': (
            <StatusContainer>
                <ClockIcon />
                Ready to train
            </StatusContainer>
        ),
        training: (
            <StatusContainer>
                <StyledSpinner size="xx-small" type="arc" variant="brand" />
                Training
            </StatusContainer>
        ),
        error: (
            <StatusContainer>
                <ErrorIcon />
                Error
            </StatusContainer>
        ),
        ready: (
            <StatusContainer>
                <CheckmarkIcon />
                Ready
            </StatusContainer>
        ),
    };
    return (
        <div className={className}>
            {statusMapper[value as string]}
        </div>
    );
};

export default VersionStatus;
