import { IconProps } from '../types';

const Pouting = ({ title = 'Pouting', className }: IconProps) => (
    <svg className={className} width={16} height={15} viewBox="0 0 16 15">
        <title>{title}</title>
        <path d="M7.78175 15C4.03846 15 0 12.6521 0 7.5C0 2.34794 4.03846 0 7.78175 0C9.86136 0 11.78 0.684257 13.2021 1.93202C14.7451 3.30054 15.5635 5.23256 15.5635 7.5C15.5635 9.76744 14.7451 11.686 13.2021 13.0546C11.78 14.3023 9.84794 15 7.78175 15Z" fill="url(#paint0_radial_100_11755)" />
        <path d="M14.2071 3.0415C14.9222 4.19535 15.2952 5.56923 15.2952 7.09741C15.2952 9.36485 14.4768 11.2835 12.9338 12.652C11.5116 13.8997 9.57962 14.5974 7.51343 14.5974C5.09035 14.5974 2.54921 13.6113 1.04921 11.5196C2.49286 13.889 5.20842 14.9999 7.78177 14.9999C9.84796 14.9999 11.78 14.3022 13.2022 13.0545C14.7451 11.686 15.5635 9.76735 15.5635 7.49991C15.5635 5.79061 15.098 4.27183 14.2071 3.0415Z" fill="#D84213" />
        <path d="M10.5107 12.8989C10.4651 12.8814 10.4235 12.8533 10.3913 12.8157C9.08587 11.4592 6.9271 11.4177 5.57066 12.7231C5.5398 12.754 5.5076 12.7848 5.47809 12.8157C5.44589 12.8533 5.40429 12.8814 5.35868 12.8989C5.25805 12.9364 5.14401 12.915 5.06351 12.8439C4.98435 12.7741 4.95751 12.6627 4.99777 12.5648C5.39759 11.5384 6.58632 10.7334 7.93471 10.7334C9.2831 10.7334 10.4718 11.5384 10.8716 12.5635C10.9119 12.6614 10.8851 12.7728 10.8059 12.8425C10.7254 12.9136 10.6114 12.9351 10.5107 12.8989Z" fill="#422B0D" />
        <path d="M7.04916 8.29406C6.74862 7.9157 5.51025 7.18851 5.06347 6.97652C4.8971 6.90005 4.72402 6.83833 4.54692 6.79271C4.51472 6.78332 4.47984 6.7793 4.44629 6.7793C4.26785 6.78332 4.1149 6.91078 4.07867 7.08654C4.03708 7.26901 4.131 7.45685 4.30273 7.53198C4.51338 7.63529 4.881 7.83118 5.26204 8.04719C4.94406 8.23636 4.74951 8.57983 4.74951 8.95014V9.14602C4.74951 9.70148 5.20032 10.1523 5.75577 10.1523C6.31123 10.1523 6.76204 9.70148 6.76204 9.14602V8.95014C6.76204 8.94209 6.76204 8.93538 6.76204 8.92733C6.9056 8.91794 7.0344 8.83207 7.09746 8.70327C7.1632 8.5691 7.14441 8.40944 7.04916 8.29406Z" fill="#422B0D" />
        <path d="M11.7961 7.08679C11.7585 6.90969 11.6029 6.78357 11.4217 6.78223C11.3882 6.78223 11.3533 6.78625 11.3211 6.79564C11.144 6.83992 10.9709 6.90164 10.8046 6.97677C10.3578 7.18607 9.11942 7.91595 8.81888 8.29162C8.72362 8.40835 8.70752 8.56935 8.77461 8.70351C8.83901 8.83366 8.96647 8.91818 9.11137 8.92758V8.95038V9.14627C9.11137 9.70173 9.56217 10.1525 10.1176 10.1525C10.6731 10.1525 11.1239 9.70173 11.1239 9.14627V8.95038C11.1239 8.58142 10.9293 8.23929 10.6127 8.05012C10.9937 7.8341 11.3614 7.63822 11.572 7.53491C11.7451 7.45978 11.8403 7.2706 11.7961 7.08679Z" fill="#422B0D" />
        <path d="M5.70487 8.24463C5.51435 8.1534 5.28492 8.2339 5.19235 8.42442C5.12124 8.57334 5.15344 8.75179 5.27285 8.86717C5.46337 8.95841 5.69279 8.8779 5.78537 8.68739C5.85648 8.53846 5.82428 8.36002 5.70487 8.24463Z" fill="#896024" />
        <path d="M10.0666 8.24463C9.8761 8.1534 9.64668 8.2339 9.5541 8.42442C9.48299 8.57334 9.51519 8.75179 9.6346 8.86717C9.82512 8.95841 10.0545 8.8779 10.1471 8.68739C10.2182 8.53846 10.186 8.36002 10.0666 8.24463Z" fill="#896024" />
        <defs>
            <radialGradient id="paint0_radial_100_11755" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78175 7.49999) scale(7.64218)">
                <stop offset="0.12" stopColor="#FAC742" />
                <stop offset="0.32" stopColor="#FAC440" />
                <stop offset="0.48" stopColor="#FBBA3B" />
                <stop offset="0.62" stopColor="#FBA931" />
                <stop offset="0.76" stopColor="#FC9224" />
                <stop offset="0.88" stopColor="#FE7413" />
                <stop offset="1" stopColor="#FF5100" />
            </radialGradient>
        </defs>
    </svg>
);

export default Pouting;
