import {
    useState, useEffect, useCallback, useMemo,
} from 'react';
import { Query, orderBy, query } from 'firebase/firestore';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import useSession from 'data/firestore/session/use';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useHttpMutationStream from 'hooks/useHttpMutationStream';
import { Message } from 'components/ChatMessage/types';

interface InitChatRequest {
    agentId: string;
}

interface InitChatResponse {
    id: string;
    createdAt: Date;
    agentId: string;
    from: string;
    status: string;
}

interface SendMessageRequest {
    message: string;
}

interface SendMessageResponse {
    content?: string;
}

interface EndChatRequest {}

const useChatSession = (agentId?: string) => {
    const [agent, setAgent] = useState<string>();
    const [chatId, setChatId] = useState<string>();

    const {
        mutate: initChat,
        isLoading: isInitializing,
    } = useHttpMutation<InitChatRequest, InitChatResponse>({
        pathname: '/chat',
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
        onSuccess: (data: InitChatResponse) => setChatId(data.id),
    });
    const {
        mutate: endSession,
        isLoading: isClosingSession,
    } = useHttpMutation<EndChatRequest, void>({
        pathname: `/chat/${chatId}/end`,
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
    });
    const {
        messageStream,
        mutate: sendMessage,
        isLoading: isSendingMessage,
    } = useHttpMutationStream<SendMessageRequest, SendMessageResponse>({
        pathname: `/chat/${chatId}/respond`,
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
    });

    const [messages, isLoadingMessages] = useCollection<Message>({
        path: `/session/${chatId}/conversation`,
        flat: true,
        disabled: !chatId,
        query: (ref: unknown) => query(ref as Query, orderBy('createdAt')),
    });

    const { data: sessionInfo, isLoading: isLoadingSessionInfo } = useSession(chatId as string, {
        disabled: !chatId,
    });

    const resetChat = useCallback(async () => {
        if (!chatId || !agent) return;
        try {
            await endSession({});
            setChatId(undefined);
            await initChat({
                body: {
                    agentId: agent,
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }, [endSession, initChat, chatId, agent]);

    useEffect(() => {
        if (agentId && agentId !== agent) {
            initChat({
                body: {
                    agentId,
                },
            });
            setAgent(agentId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentId]);

    const isMessageSent = useMemo(
        () => {
            if (messages.length > 1 && messages.length % 2 === 1) {
                const latestMessage = messages[messages.length - 1];
                if (latestMessage.role === 'assistant' && latestMessage.content === messageStream && isSendingMessage) {
                    return true;
                }
            }
            return !isSendingMessage;
        },
        [messages, messageStream, isSendingMessage],
    );

    return {
        chatId,
        chatEnded: sessionInfo?.status === 'completed',
        endReason: sessionInfo?.reason,
        isLoading: isInitializing || isLoadingSessionInfo || isClosingSession || isLoadingMessages,
        isSendingMessage: !isMessageSent,
        messages,
        currentResponseStream: messageStream,
        reset: resetChat,
        sendMessage,
    };
};

export default useChatSession;
