import {
    query, where, orderBy,
} from 'firebase/firestore';
import useDayAggregateCollection from 'data/firestore/agent/dayaggregate/useCollection';
import { Dayaggregate } from 'data/firestore/agent/dayaggregate/types';

function getLast30Dates() {
    const dates = [];
    for (let i = 0; i < 30; i += 1) {
        const d = new Date();
        d.setDate(d.getDate() - i);
        const dateStr = `${d.getFullYear()}${(`0${d.getMonth() + 1}`).slice(-2)}${(`0${d.getDate()}`).slice(-2)}`;
        dates.push(
            dateStr,
        );
    }
    return dates;
}

function transformLabelDateFormat(dateStr: string) {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return `${year}-${month}-${day}`;
}

function fillMissingDates(dates: string[], data: Dayaggregate[]) {
    const filledData = dates.map((date) => {
        const entry = data.find((d) => d.id === date);
        const label = transformLabelDateFormat(date);
        return entry ? { ...entry, label } : {
            date,
            total: null,
            label,
        };
    });
    return filledData.sort((a, b) => a.date.localeCompare(b.date));
}

function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}
const useLast30DaysCallData = (agentId: string) => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));

    const todayStr = formatDate(today);
    const thirtyDaysAgoStr = formatDate(thirtyDaysAgo);

    const { data, ...rest } = useDayAggregateCollection(agentId, {
        listQueryFn: (ref) => query(
            ref,
            where('date', '>=', thirtyDaysAgoStr),
            where('date', '<=', todayStr),
            orderBy('date'),
        ),
    });
    return {
        data: fillMissingDates(getLast30Dates(), data),
        ...rest,
    };
};

export default useLast30DaysCallData;
