import { useOpenModal } from '@rainbow-modules/hooks';
import {
    SectionDescription,
    SectionTitle,
    Source,
    Sources,
    StyledMessageInput,
    StyledResponseContainer,
    QAHeader,
} from './styled';
import useAskQuestion from './hooks/useAskQuestion';

const QAForm = () => {
    const {
        answer, sourceDocuments, isLoading, ask,
    } = useAskQuestion();
    const [openModal] = useOpenModal('show-chunk-details');

    const openSourceModal = (metadata: Record<string, any>) => {
        openModal({
            chunkName: metadata.name,
            chunkTokens: metadata.tokens,
            documentName: metadata.sourceDocumentName,
            chunkContent: metadata.content,
        });
    };

    return (
        <>
            <QAHeader>
                <SectionTitle>Ask Questions</SectionTitle>
                <SectionDescription>
                    You can ask questions about the added content to find out what
                    information the agent answers.
                </SectionDescription>
            </QAHeader>
            <StyledMessageInput placeholder="Ask any question" onSend={ask} disabled={isLoading} />
            {answer && (
                <StyledResponseContainer>
                    <SectionDescription>RESPONSE:</SectionDescription>
                    <p>{answer}</p>
                    <Sources>
                        {sourceDocuments.map(({ pageContent, metadata }) => (
                            <Source
                                key={metadata.name}
                                onClick={
                                    () => openSourceModal({ ...metadata, content: pageContent })
                                }
                            >
                                {metadata.sourceDocumentName}
                                {' '}
                                &gt;
                                {' '}
                                {metadata.name}
                            </Source>
                        ))}
                    </Sources>
                </StyledResponseContainer>
            )}
        </>
    );
};

export default QAForm;
