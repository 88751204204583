import {
    StatusContainer, ClockIcon, StyledSpinner, CheckmarkIcon,
} from './styled';

interface Props {
    value?: string;
    className?: string;
}

const CampaignStatus = (props: Props) => {
    const { value, className } = props;
    const statusMapper: Record<string, any> = {
        pending: (
            <StatusContainer>
                <ClockIcon />
                Pending
            </StatusContainer>
        ),
        'in-progress': (
            <StatusContainer>
                <StyledSpinner size="xx-small" type="arc" variant="brand" />
                Running
            </StatusContainer>
        ),
        finished: (
            <StatusContainer>
                <CheckmarkIcon />
                Finished
            </StatusContainer>
        ),
    };
    return (
        <div className={className}>
            {statusMapper[value as string]}
        </div>
    );
};

export default CampaignStatus;
