import { IconProps } from './types';

const Elaborate = ({ title = 'Elaborate', className }: IconProps) => (
    <svg className={className} width={18} height={18} viewBox="0 0 18 18" fill="none">
        <title>{title}</title>
        <path d="M3.75 16.5C3.5375 16.5 3.35938 16.4281 3.21563 16.2844C3.07188 16.1406 3 15.9625 3 15.75C3 15.5375 3.07188 15.3594 3.21563 15.2156C3.35938 15.0719 3.5375 15 3.75 15H14.25C14.4625 15 14.6406 15.0719 14.7844 15.2156C14.9281 15.3594 15 15.5375 15 15.75C15 15.9625 14.9281 16.1406 14.7844 16.2844C14.6406 16.4281 14.4625 16.5 14.25 16.5H3.75ZM9 13.9313C8.9 13.9313 8.80625 13.9156 8.71875 13.8844C8.63125 13.8531 8.55 13.8 8.475 13.725L6.525 11.775C6.3875 11.6375 6.31563 11.4656 6.30938 11.2594C6.30313 11.0531 6.375 10.875 6.525 10.725C6.6625 10.5875 6.83438 10.5156 7.04063 10.5094C7.24688 10.5031 7.425 10.5688 7.575 10.7063L8.25 11.3625V6.6375L7.575 7.29375C7.4375 7.43125 7.26563 7.5 7.05938 7.5C6.85313 7.5 6.675 7.425 6.525 7.275C6.3875 7.1375 6.31875 6.9625 6.31875 6.75C6.31875 6.5375 6.3875 6.3625 6.525 6.225L8.475 4.275C8.55 4.2 8.63125 4.14688 8.71875 4.11563C8.80625 4.08438 8.9 4.06875 9 4.06875C9.1 4.06875 9.19375 4.08438 9.28125 4.11563C9.36875 4.14688 9.45 4.2 9.525 4.275L11.475 6.225C11.6125 6.3625 11.6844 6.53438 11.6906 6.74063C11.6969 6.94688 11.625 7.125 11.475 7.275C11.3375 7.4125 11.1656 7.48438 10.9594 7.49063C10.7531 7.49688 10.575 7.43125 10.425 7.29375L9.75 6.6375V11.3625L10.425 10.7063C10.5625 10.5688 10.7344 10.5 10.9406 10.5C11.1469 10.5 11.325 10.575 11.475 10.725C11.6125 10.8625 11.6813 11.0375 11.6813 11.25C11.6813 11.4625 11.6125 11.6375 11.475 11.775L9.525 13.725C9.45 13.8 9.36875 13.8531 9.28125 13.8844C9.19375 13.9156 9.1 13.9313 9 13.9313ZM3.75 3C3.5375 3 3.35938 2.92813 3.21563 2.78438C3.07188 2.64063 3 2.4625 3 2.25C3 2.0375 3.07188 1.85938 3.21563 1.71563C3.35938 1.57188 3.5375 1.5 3.75 1.5H14.25C14.4625 1.5 14.6406 1.57188 14.7844 1.71563C14.9281 1.85938 15 2.0375 15 2.25C15 2.4625 14.9281 2.64063 14.7844 2.78438C14.6406 2.92813 14.4625 3 14.25 3H3.75Z" fill="currentColor" />
    </svg>
);

export default Elaborate;
