/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/no-unstable-nested-components */
import { useMemo } from 'react';
import { CSSProperties } from 'styled-components';
import { RenderIf } from 'react-rainbow-components';
import { useReduxForm, useUniqueIdentifier } from '@rainbow-modules/hooks';
import { isEmpty } from '@rainbow-modules/validation';
import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import {
    InputContainer, Header, ErrorText, StyledLoadingShape, EmptyMessage,
} from './styled';
import PhoneListInputItem from './item';

interface PhoneListInputProps {
    id?: string;
    className?: string;
    style?: CSSProperties;
    languages?: Record<string, string>[];
    isLoading?: boolean,
    value?: Record<string, unknown>[];
    phones: Record<string, string>[];
    onChange?: (value: Record<string, unknown>[]) => void;
}

const getPhoneLanguage = (
    currentLanguage?: Record<string, string>,
    languages: Record<string, any>[] = [],
) => {
    if (languages?.length === 0) return undefined;
    if (languages?.length === 1) return languages[0];

    if (languages.some((language) => language.name === currentLanguage?.name)) {
        return currentLanguage;
    }

    return { name: 'auto', label: 'Auto' };
};

const normalizeValue = (value: unknown, languages: Record<string, any>[] = []) => {
    if (isEmpty(value)) return [];
    const normalizedValue = (Array.isArray(value) ? value : [value]);

    return normalizedValue.map((val) => ({
        ...val,
        language: getPhoneLanguage(val?.language, languages),
    }));
};

const PhoneListInput = (props: PhoneListInputProps) => {
    const {
        id,
        className,
        style,
        isLoading = false,
        value = [],
        onChange = () => {},
        phones = [],
        error,
        languages = [],
    } = useReduxForm(props);

    const errorMessageId = useUniqueIdentifier('error-message');

    const defaultLanguage = useMemo(
        () => getPhoneLanguage({ name: '' }, languages),
        [languages],
    );

    const phonesList = useMemo(
        () => phones.map(
            (phone: Record<string, string>) => (
                <PhoneListInputItem
                    key={phone.id}
                    name={phone.id}
                    currentValues={normalizeValue(value, languages)}
                    label={(
                        <>
                            <span>{formatIntlPhoneNumber(phone?.number)}</span>
                            <span>{phone?.location}</span>
                        </>
                    )}
                    languages={languages}
                    value={{ id: phone.id, name: phone.id, language: defaultLanguage }}
                    onChange={onChange}
                    error={error}
                />
            ),
        ),
        [defaultLanguage, error, languages, onChange, phones, value],
    );

    return (
        <div
            id={id}
            role="listbox"
            className={className}
            // eslint-disable-next-line react/forbid-dom-props
            style={style}
        >
            <InputContainer $error={Boolean(error)}>
                <Header>
                    <span>Select the Phone Numbers</span>
                </Header>
                <RenderIf isTrue={isLoading}>
                    <div>
                        <StyledLoadingShape variant="solid" shape="rounded-rect" $width="40%" />
                        <StyledLoadingShape variant="solid" shape="rounded-rect" $width="30%" />
                        <StyledLoadingShape variant="solid" shape="rounded-rect" $width="60%" />
                    </div>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    {phonesList}
                    <RenderIf isTrue={isEmpty(phones)}>
                        <EmptyMessage>
                            There are no phones numbers available. Please buy a new one.
                        </EmptyMessage>
                    </RenderIf>
                </RenderIf>
            </InputContainer>
            <RenderIf isTrue={Boolean(error)}>
                <ErrorText id={errorMessageId}>
                    {error}
                </ErrorText>
            </RenderIf>
        </div>
    );
};

export default PhoneListInput;
