import {
    OptionContainer,
    DatasetName,
} from './styled';

interface OptionProps {
    label?: string | React.ReactNode;
    name: string;
    isSelected?: boolean;
    onClick: (name: string) => void;

}

const SidebarOption = (props: OptionProps) => {
    const {
        isSelected, label, onClick, name,
    } = props;

    return (
        <OptionContainer isSelected={isSelected} onClick={() => onClick(name)}>
            <DatasetName>{label}</DatasetName>
        </OptionContainer>
    );
};

export default SidebarOption;
