import { useState, useEffect } from 'react';
import useHttpQuery from 'data/firestore/useHttpQuery';

interface HookParams {
    reportType: string;
    agentId?: string;
}

interface Report {
    id: string;
    type: string;
    createdAt: Date;
    expiresAt: Date;
    data?: object;
}

const buildParams = (reportType: string, agentId?: string) => {
    const params = [`reportType=${reportType}`];
    if (agentId) {
        params.push(`agentId=${agentId}`);
    }
    return params.join('&');
};

const useReportData = ({ agentId = '', reportType }: HookParams) => {
    const [data, setData] = useState<{} | undefined>();

    const {
        refetch: refetchReport,
        isLoading,
    } = useHttpQuery<object>({
        key: [agentId, reportType],
        pathname: `/reports/latest?${buildParams(reportType, agentId)}`,
        queryOptions: {
            enabled: false,
            keepPreviousData: true,
        },
    });

    useEffect(() => {
        (async () => {
            const { data: latestReport } = await refetchReport({
                cancelRefetch: true,
            });
            setData((latestReport as Report).data);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentId, reportType]);

    return {
        isLoading,
        data,
    };
};

export default useReportData;
