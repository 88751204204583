import styled from 'styled-components';
import { Badge, Textarea } from 'react-rainbow-components';
import { color } from 'theme';

export const Container = styled.div`    
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background-color: ${(props) => (props.role === 'assistant' ? color('background.secondary') : color('background.main'))};
    border: 1px solid white;
    flex-grow: 1;
    gap: 8px;
    width: 100%;
    align-items: start;

    &:hover {
        cursor: pointer;
    }
`;

export const RoleContainer = styled.div`
    display: inline-flex;
    align-items: start;
    padding: 20px 0 20px 16px;
`;

export const ActionsContainer = styled.div`
    display: inline-flex;
    align-items: start;
    padding: 10px 16px 20px 0;
`;

export const MessageContent = styled.div`
    padding: 20px 16px;
    display: inline-flex;
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    min-height: 60px;
`;

export const BadgeContainer = styled.div`
    width: 100px;
`;

export const StyledBadge = styled(Badge)`
    flex-shrink: 0;
    color: ${color('brand.main')};
    background: ${color('brand.light')};
    cursor: pointer;
`;

export const StyledTextarea = styled(Textarea)`
    flex-grow: 1;
`;
