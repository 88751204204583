import { ButtonIcon, RenderIf } from 'react-rainbow-components';
import { Trash } from 'components/icons';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { confirmModal } from '@rainbow-modules/app';
import { useParams } from 'react-router-dom';
import useCollection from 'data/firestore/agent/useCollection';
import { limit, query, where } from 'firebase/firestore';
import { StyledColumnWrapper } from './styled';

const ActionsColumn = (props: any) => {
    const { modelId } = useParams();
    const { row } = props;
    const { data: agents, isLoading: isLoadingAgents } = useCollection({
        listQueryFn: (ref) => query(ref, where('model.version', '==', row.id), limit(1)),
    });
    const { mutate, isLoading } = useHttpMutation<{}, {}>({
        method: 'DELETE',
    });
    const isUsed = agents?.length > 0;

    const deleteVersion = async () => {
        if (await confirmModal({
            question: 'Are you sure you want to delete this version?',
        })) {
            mutate({
                pathname: `/model/${modelId}/version/${row.id}`,
            });
        }
    };
    return (
        <StyledColumnWrapper>
            <RenderIf isTrue={(row.status === 'ready-to-train' || row.status === 'processing-data') && !isUsed}>
                <ButtonIcon
                    borderRadius="semi-rounded"
                    icon={<Trash />}
                    onClick={deleteVersion}
                    disabled={isLoading || isLoadingAgents}
                />
            </RenderIf>
        </StyledColumnWrapper>
    );
};

export default ActionsColumn;
