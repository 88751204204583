import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import { color } from 'theme';

interface SentimentBadgeProps {
    sentimentLabel: string;
}

export const StyledBadge = styled(Badge)<SentimentBadgeProps>`
    flex-shrink: 0;
    gap: 12px;
    padding-right: 8px;
    padding-left: 8px;
    background: #EEF0F4;

    > span {
        display: flex;
        align-items: center;
    }

    ${(props) => (props.sentimentLabel === 'Helpful' || props.sentimentLabel === 'Very Helpful') && `
        color: ${color('success.main')(props)};
        background: ${color('success.light')(props)};
    `};

    ${(props) => (props.sentimentLabel === 'Unhelpful' || props.sentimentLabel === 'Very Unhelpful') && `
        color: ${color('error.main')(props)};
        background: ${color('error.light')(props)};
    `};
`;
