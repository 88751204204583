import { IconProps } from './types';

const Letters = ({ title = 'AB Letters', className }: IconProps) => (
    <svg className={className} width={18} height={18} viewBox="0 0 18 18" fill="none">
        <title>{title}</title>
        <path d="M3.67494 10.95L3.24369 12.2625C3.19369 12.4 3.10619 12.5156 2.98119 12.6094C2.85619 12.7031 2.71869 12.75 2.56869 12.75C2.31869 12.75 2.11557 12.6469 1.95932 12.4406C1.80307 12.2344 1.77494 12.0063 1.87494 11.7563L4.12494 5.7375C4.18744 5.5875 4.28119 5.46875 4.40619 5.38125C4.53119 5.29375 4.67494 5.25 4.83744 5.25H5.39994C5.56244 5.25 5.70619 5.29375 5.83119 5.38125C5.95619 5.46875 6.04994 5.5875 6.11244 5.7375L8.38119 11.7938C8.46869 12.0313 8.44057 12.25 8.29682 12.45C8.15307 12.65 7.95619 12.75 7.70619 12.75C7.55619 12.75 7.41869 12.7031 7.29369 12.6094C7.16869 12.5156 7.08119 12.4 7.03119 12.2625L6.56244 10.95H3.67494ZM4.12494 9.675H6.07494L5.17494 6.8625H5.06244L4.12494 9.675ZM11.9624 11.4H15.0749C15.2624 11.4 15.4218 11.4656 15.5531 11.5969C15.6843 11.7281 15.7499 11.8875 15.7499 12.075C15.7499 12.2625 15.6843 12.4219 15.5531 12.5531C15.4218 12.6844 15.2624 12.75 15.0749 12.75H10.7249C10.5999 12.75 10.4937 12.7062 10.4062 12.6187C10.3187 12.5312 10.2749 12.425 10.2749 12.3V11.5875C10.2749 11.5 10.2874 11.4156 10.3124 11.3344C10.3374 11.2531 10.3812 11.1812 10.4437 11.1187L14.0624 6.6H11.0999C10.9124 6.6 10.7531 6.53437 10.6218 6.40312C10.4906 6.27187 10.4249 6.1125 10.4249 5.925C10.4249 5.7375 10.4906 5.57812 10.6218 5.44687C10.7531 5.31562 10.9124 5.25 11.0999 5.25H15.2624C15.3874 5.25 15.4937 5.29375 15.5812 5.38125C15.6687 5.46875 15.7124 5.575 15.7124 5.7V6.4125C15.7124 6.5 15.6999 6.58437 15.6749 6.66562C15.6499 6.74687 15.6062 6.81875 15.5437 6.88125L11.9624 11.4Z" fill="currentColor" />
    </svg>
);

export default Letters;
