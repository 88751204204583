import { Email } from '@rainbow-modules/icons';
import { Phone } from 'components/icons';
import { Input, LoadingShape, PhoneInput } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;
    margin-bottom: 2rem;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 50%;
    font-size: 0.75rem;
    background: ${color('background.main')};
    border: 1px solid ${color('border.divider')};
    margin: 0;
`;

export const SectionHeader = styled.div`
    display: flex;
    border-bottom: 1px solid ${color('border.divider')};
    align-items: center;
    justify-content: space-between;    
    padding: 14px 16px;

    & > span {
        margin-bottom: 0;
    }
`;

export const SectionContent = styled.div``;

export const EmptyMessage = styled.div`
    font-family: 'Lato';
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    flex: 1;
    height: 60px;
    align-items: center;
    justify-content: center;
    color: ${color('text.disabled')};
`;

export const FieldContainer = styled.div`
    height: 150px;
`;

export const MailIconBrand = styled(Email)`
    color: ${color('brand.main')};
    width: 18px;
    height: 18px;
`;

export const MailIcon = styled(Email)`
    color: ${color('border.main')};
    width: 18px;
    height: 18px;
`;

export const PhoneIcon = styled(Phone)`
    color: ${color('border.main')};
    width: 18px;
    height: 18px;
`;

export const OptionContainer = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 12px 10px 12px 16px; 

    & > div:first-child {
        display: inline-flex;
        flex: 1;
    }
`;

export const StyledPhoneInput = styled(PhoneInput)`
    label + div > div {
        align-items: center;
    }
`;

export const StyledInput = styled(Input)`
    display: flex;
    flex: 1;
`;

export const StyledLoadingShape = styled(LoadingShape).withConfig({
    shouldForwardProp: (prop) => !['$width'].includes(prop),
})<{ $width?: string }>`
    margin: 14px 16px;
    display: flex;
    width: ${(props) => props.$width};
`;
