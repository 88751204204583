import styled from 'styled-components';
import {
    Textarea, Badge, ButtonIcon, Modal, Button,
} from 'react-rainbow-components';
import { color } from 'theme';
import { EditNote } from 'components/icons';

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    padding: 20px 16px;
    gap: 8px;
    width: 100%;
`;

export const MetadataContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin: 0 8px 16px 8px;
    align-self: flex-end;
    gap: 8px;
`;

export const Container = styled.div`    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: ${(props) => (props.role === 'assistant' ? color('background.secondary') : color('background.main'))};
    position: relative;
    border: 1px solid white;
    padding: 12px 0;
`;

export const RoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const BadgeContainer = styled.div`
    width: 100px;
`;

export const StyledTextarea = styled(Textarea)`
    flex-grow: 1;
`;

export const StyledBadge = styled(Badge)`
    flex-shrink: 0;
    color: ${color('brand.main')};
    background: ${color('brand.light')};
`;

export const MoreDetailsContainer = styled.div`
    display: flex;
    padding: 0 8px 12px 8px;
    flex-grow: 1;
    width: 100%;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    background: ${color('background.main')};
    border: 1px solid ${color('border.disabled')};
    border-radius: 8px;
    width: 100%;
    padding: 12px;
`;

export const DescriptionText = styled.p`
    font-size: 14px;
    color: ${color('text.label')};
`;

export const DescriptionTitle = styled.h2`
    font-size: 14px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
`;

export const NativeButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    &:hover {
        opacity: 0.8;
    }
`;

export const Actions = styled.div`
    display: flex;
    gap: 4px;
`;

export const MagicPencilButton = styled(ButtonIcon)`
    > svg {
        width: 20px !important;
        height: 20px !important;
    }
`;

export const StyledModal = styled(Modal)`
    background-color: ${color('background.secondary')};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px 0px;
`;

export const ModalTitle = styled.span`
    font-size: 22px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    padding: 16px 20px;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 8px 0;
`;

export const Card = styled.div`
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${color('border.disabled')};
    background: ${color('background.main')};
`;

export const CardTitle = styled.h2`
    color: ${color('text.header')};
    font-size: 12px;
    line-height: 150%;
`;

export const CardContent = styled.p`
    color: ${color('text.main')};
    font-size: 14px;
    line-height: 150%;
`;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 12px;
`;

export const TrashButton = styled(ButtonIcon)`
    height: 32px;
    width: 32px;
`;

export const ModalFooter = styled.footer`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
`;

export const ArrowButton = styled(ButtonIcon)`
    position: absolute;
    right: 4px;
    top: 3px;
`;

export const StyledCustomTextarea = styled(Textarea)`
    width: 100%;
`;

export const Icon = styled(EditNote)`
    margin-right: 8px;
`;

export const TimeBadgeContainer = styled.div`
    position: absolute;
    top: -10px;
    z-index: 100;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-gap: 1rem;
`;

export const TimeBadge = styled(Badge)`
    text-transform: lowercase;
`;

export const Time = styled.h3`
    color: ${color('text.header')};
    font-size: 14px;
    margin: 6px 0 0 4px;
`;

export const ConversationActionsContainer = styled.div`
    position: absolute;
    display: flex;
    bottom: -5px;
    z-index: 1000;
    gap: 8px;
    width: 100%;
    justify-content: center;
`;

export const StyledButton = styled(Button)`
    background: ${color('background.main')};
    height: 28px;
`;

export const ImportButton = styled(Button)`
    align-self: flex-end;
`;
