import { RecordField } from '@rainbow-modules/record';
import {
    AttrContainer,
    MessageIntent,
    MessageIntentConfidence,
    MetadataLabel,
} from '../styled';
import { MetadataRendererProps } from '../types';

interface IntentMatchMetadata {
    intent?: string;
    description?: string;
    confidence?: number;
}

const MessageIntentComponent = ({
    value,
}: { value?: string }) => <MessageIntent>{value}</MessageIntent>;

const MessageIntentConfidenceComponent = ({
    value = 0,
}: { value?: number }) => (
    <MessageIntentConfidence $confidence={value}>{value}</MessageIntentConfidence>
);

const IntentMatchMetadataRenderer = ({
    metadata,
}:MetadataRendererProps<IntentMatchMetadata>) => (
    <>
        <MetadataLabel>Intent Match:</MetadataLabel>
        <AttrContainer>
            <RecordField
                label="intent"
                value={metadata?.intent}
                component={MessageIntentComponent}
            />
            <RecordField label="description" value={metadata?.description} />
            <RecordField
                label="confidence"
                type="number"
                value={metadata?.confidence}
                component={MessageIntentConfidenceComponent}
            />
        </AttrContainer>
    </>
);

export default IntentMatchMetadataRenderer;
