import styled from 'styled-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import { Warning } from '@rainbow-modules/icons';
import { color } from 'theme';

export const ModalContent = styled.div`
    padding-bottom: 10px;
`;

export const ModalTitle = styled.h1`
    color: ${color('text.main')};
    font-size: 20px;
    margin-bottom: 12px;
    padding: 14px 12px 0;
`;

export const UrlContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 40px; 
    border-radius: 10px;
    margin-top: 10px;
    padding: 0;
    box-sizing: border-box;
    overflow-y: hidden;
    
    > span:first-child {
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
        padding: 10px;
        box-sizing: border-box;
        flex: 1;
        border: 1px solid ${color('brand.main')};
        border-right: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    display: inline-flex;
    box-sizing: border-box;
    color: ${color('brand.main')};
    background: ${color('brand.light')};
    height: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:active {
        transform: unset;
    }
`;

export const WarningMeessage = styled.div`
    display: flex;
    color: ${color('warning.main')};
    margin-top: 5px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    gap: 4px;
    align-items: center;
`;

export const WarningIcon = styled(Warning)`
    width: 14px;
    height: 14px;
`;
