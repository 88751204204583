import {
    Column, Table, Button, RenderIf,
} from 'react-rainbow-components';
import { ArrowRight, ArrowLeft } from '@rainbow-modules/icons';
import DateTimeColumn from 'components/DateTimeColumn';
import useUsageRecordCalls from 'data/firestore/usagereports/callsessions/useCollectionWithPagination';
import { ITEMS_PER_PAGE } from '../../../../../../constants';
import { ModalContent, TableFooter } from './styled';

interface UsageRecordDetailsModalProps {
    usageRecordId?: string;
}

const UsageRecordDetailsModal = ({
    usageRecordId = '',
}: UsageRecordDetailsModalProps) => {
    const {
        isLoading,
        data = [],
        totalRecords,
        nextPage,
        prevPage,
        firstPage,
        hasMore,
    } = useUsageRecordCalls(usageRecordId, {
        disabled: !usageRecordId,
    });

    return (
        <ModalContent>
            <Table
                keyField="id"
                data={(isLoading ? [] : data)}
                emptyDescription=""
                variant="listview"
                isLoading={isLoading}
            >
                <Column
                    header="ID"
                    field="id"
                    headerAlignment="left"
                    cellAlignment="left"
                />
                <Column
                    header="From"
                    field="from"
                    headerAlignment="left"
                    cellAlignment="left"
                />
                <Column
                    header="To"
                    field="to"
                    headerAlignment="left"
                    cellAlignment="left"
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={DateTimeColumn}
                    defaultWidth={300}
                />
                <Column
                    header="Duration"
                    field="duration"
                    headerAlignment="left"
                    cellAlignment="left"
                    defaultWidth={100}
                />
            </Table>
            <RenderIf isTrue={totalRecords > ITEMS_PER_PAGE}>
                <TableFooter>
                    <Button
                        variant="base"
                        size="small"
                        borderRadius="semi-rounded"
                        disabled={isLoading || firstPage}
                        onClick={prevPage}
                    >
                        <ArrowLeft className="rainbow-m-right_small" />
                        Prev
                    </Button>
                    <Button
                        variant="base"
                        size="small"
                        borderRadius="semi-rounded"
                        disabled={isLoading || !hasMore}
                        onClick={nextPage}
                    >
                        Next
                        <ArrowRight className="rainbow-m-left_small" />
                    </Button>
                </TableFooter>
            </RenderIf>
        </ModalContent>
    );
};

export default UsageRecordDetailsModal;
