import { useMemo } from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import { RenderIf } from 'react-rainbow-components';
import Phone from './phone';
import { Container, EmptyMessage, StyledLoadingShape } from './styled';

interface PhoneNumbersProps {
    isLoading?: boolean;
    phones?: Record<string, unknown>[],
}

const PhoneNumbers = ({
    isLoading = false,
    phones = [],
}:PhoneNumbersProps) => {
    const phonesList = useMemo(() => phones.map(
        (phone) => (
            <Phone
                key={`phone-${phone.isoCode}-${phone.phone}`}
                value={phone}
            />
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [phones]);

    return (
        <Container>
            <RenderIf isTrue={isLoading}>
                <StyledLoadingShape variant="solid" shape="rounded-rect" $width="40%" />
                <StyledLoadingShape variant="solid" shape="rounded-rect" $width="30%" />
                <StyledLoadingShape variant="solid" shape="rounded-rect" $width="60%" />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                {phonesList}
            </RenderIf>
            <RenderIf isTrue={!isLoading && isEmpty(phones)}>
                <EmptyMessage>
                    There are no phones added yet.
                </EmptyMessage>
            </RenderIf>
        </Container>
    );
};

export default PhoneNumbers;
