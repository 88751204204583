import {
    Button, Textarea,
} from 'react-rainbow-components';
import styled from 'styled-components';
import { CsvFile } from 'components/icons';
import { color } from 'theme';
import { Field } from 'react-final-form';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Content = styled.div`
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;
`;

export const FooterContainer = styled.footer`
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${color('border.divider')};
    padding: 20px 12px 0 12px;
`;
export const StyledTextarea = styled(Textarea)`
    display: flex;
    flex-grow: 1;
`;

export const FormContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const ImportContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const ImportLabel = styled.h1`
    font-size: 14px;
    margin-bottom: 4px;
    text-align: left;
    margin-left: 16px;
`;

export const ImportContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    border: 1px solid rgba(227, 229, 237, 0.25);
    width: 100%;
    border-radius: 10px;
    padding: 4px 2px;
`;

export const ImportPlaceholder = styled.h2`
    font-size: 16px;
    color: ${color('text.header')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ImportText = styled.h2`
    font-size: 16px;
    color: ${color('text.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ImportButton = styled(Button)`
    flex-shrink: 0;
`;

export const ImportIcon = styled(CsvFile)`
    width: 28px;
    height: 28px;
    margin: 0 8px;
`;

export const LeftContent = styled.div`
    display: flex;
    align-items: center;
`;

export const ImportIconLarge = styled(ImportIcon)`
    width: 42px;
    height: 42px;
`;

export const ImportedContent = styled(ImportContent)`
    padding: 8px 0;
    border-radius: 14px;
`;

export const ImportHelpText = styled.h4`
    font-size: 12px;
    color: #929ba5;
    margin: 8px 0 24px 12px;
`;

export const FieldsContainer = styled.div`
    width: calc(50% - 6px);

`;

export const FieldGrow = styled(Field)`
    flex: 1;

`;

export const Row = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
`;
