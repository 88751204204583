import { useCallback, useMemo, useState } from 'react';
import { MagnifyingGlass } from '@rainbow-modules/icons';
import {
    Input, Modal, RenderIf, Spinner,
} from 'react-rainbow-components';
import useBuildingBlocks from 'hooks/useBuildingBlocks';
import {
    ModalContainer,
    ModalTitle,
    BlocksContainer,
    Card,
    CardTitle,
    CardRole,
    CardContent,
    InsertButton,
} from './styled';

interface InsertBuildingBlockModalProps {
    datasetId?: string;
    isOpen?: boolean;
    onRequestClose?: () => void;
    onInsert?: (block: {
        role?: string;
        content?: string;
    }) => void;
}

interface BlockElementProps {
    name?: string;
    role?: string;
    content?: string;
    onInsert?: (block: {
        role?: string;
        content?: string;
    }) => void;
}

const BlockElement = ({
    name,
    role,
    content = '',
    onInsert = () => {},
}: BlockElementProps) => (
    <Card>
        <CardTitle>
            {name?.toUpperCase()}
            <InsertButton
                label="Insert"
                size="small"
                borderRadius="semi-rounded"
                variant="neutral"
                onClick={() => onInsert({ role, content })}
            />
        </CardTitle>
        <CardRole>{role}</CardRole>
        <CardContent>{content}</CardContent>
    </Card>
);

const InsertBuildingBlockModal = (props: InsertBuildingBlockModalProps) => {
    const {
        datasetId = '',
        isOpen,
        onRequestClose = () => {},
        onInsert = () => {},
    } = props;

    const [searchQuery, setSearchQuery] = useState<string>('');

    const {
        data = [],
        isLoading,
    } = useBuildingBlocks({ datasetId, fetchData: isOpen });

    const handleInsert = useCallback((block: {
        role?: string;
        content?: string;
    }) => {
        onInsert(block);
        onRequestClose();
    }, [onInsert, onRequestClose]);

    const blocks = useMemo(() => {
        let list = data;
        if (searchQuery) {
            list = list.filter(
                (block) => block.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
                || block.content.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0,
            );
        }
        return list.map((block, index) => {
            const key = `building-block-${index}`;
            return (
                <BlockElement
                    key={key}
                    {...block}
                    onInsert={handleInsert}
                />
            );
        });
    }, [data, handleInsert, searchQuery]);

    return (
        <Modal
            borderRadius="semi-rounded"
            size="medium"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <ModalTitle>Insert Building Blocks</ModalTitle>
            <ModalContainer>
                <Input
                    placeholder="Search building block..."
                    icon={<MagnifyingGlass />}
                    iconPosition="left"
                    borderRadius="semi-rounded"
                    className="rainbow-m-vertical_medium"
                    variant="shaded"
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <BlocksContainer>
                    <RenderIf isTrue={isLoading}>
                        <Spinner />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {blocks}
                    </RenderIf>
                </BlocksContainer>
            </ModalContainer>
        </Modal>
    );
};

export default InsertBuildingBlockModal;
