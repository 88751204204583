import styled from 'styled-components';
import { Badge, Spinner } from 'react-rainbow-components';
import { Check, Clock, ErrorCircle } from 'components/icons';
import { color } from 'theme';

interface VersionStatusdBadgeProps {
    status: string;
}

export const StyledBadge = styled(Badge)<VersionStatusdBadgeProps>`
    flex-shrink: 0;
    gap: 12px;
    padding-right: 8px;
    padding-left: 8px;
    text-transform: uppercase;

    > span {
        display: flex;
        align-items: center;
    }

    ${(props) => props.status === 'ready-to-train' && `
        color: ${color('text.main')(props)};
        background: ${color('text.highlight')(props)};
    `};

    ${(props) => props.status === 'processing-data' && `
        color: ${color('text.main')(props)};
        background: ${color('text.highlight')(props)};
    `};

    ${(props) => props.status === 'training' && `
        color: ${color('warning.main')(props)};
        background: ${color('warning.light')(props)};
    `};

    ${(props) => props.status === 'error' && `
        color: ${color('error.main')(props)};
        background: ${color('error.light')(props)};
    `};

    ${(props) => props.status === 'ready' && `
        color: ${color('success.main')(props)};
        background: ${color('success.light')(props)};
    `};

`;

export const StyledSpinner = styled(Spinner)`
    position: initial;
    margin: 0;
    transform: unset;
`;

export const ClockIcon = styled(Clock)`
    color: ${color('text.main')};
    width: 18px;
    height: 18px;
    margin-right: 5px;
`;

export const CheckmarkIcon = styled(Check)`
    color: ${color('success.main')};
    width: 18px;
    height: 18px;
    margin-right: 5px;
`;

export const ErrorIcon = styled(ErrorCircle)`
    color: ${color('error.main')};
    width: 18px;
    height: 18px;
    margin-right: 5px;
`;
