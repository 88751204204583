import styled from 'styled-components';
import { Sidebar } from 'react-rainbow-components';
import { SideBarOption } from '@rainbow-modules/app';
import {
    Folder, Matrass, Clock, Chat, Agent, Chart, Model,
} from 'components/icons';
import Logo from 'components/Logo';
import { color } from 'theme';
import { BACKGROUND, BRAND, ACTION_HIGHLIGHT } from '../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: ${BACKGROUND};
    display: flex;
    box-sizing: border-box;
`;

export const RightContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: calc(100vw - 100px);
`;

export const SideBarContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100px;
    min-width: 100px;
    box-sizing: border-box;
    background: #F7F3FF;
`;

export const Bar = styled(Sidebar)`
    width: 100%;
`;

export const SideBarItem = styled(SideBarOption).withConfig({
    shouldForwardProp: (prop) => !['$isSelected'].includes(prop),
})<{ $isSelected: boolean }>`
    > button {
        width: 60px;
        height: 60px;
        border-radius: 10px;

        span {
            color: inherit;
        }
        
        :hover,
        :focus,
        :active {
            background-color: ${ACTION_HIGHLIGHT};
            color: ${BRAND};
        }

        ${(props) => props.$isSelected && `
            background-color: ${ACTION_HIGHLIGHT};
            color: ${BRAND};
        `};
    }
`;

export const StyledLogo = styled(Logo)`
    margin-top: -8px;
`;

export const FolderIcon = styled(Folder)`
    width: 28px;
    height: 28px;
`;

export const MatrassIcon = styled(Matrass)`
    width: 28px;
    height: 28px;
`;

export const ClockIcon = styled(Clock)`
    width: 28px;
    height: 28px;
`;

export const ChatIcon = styled(Chat)`
    width: 28px;
    height: 28px;
`;

export const AgentIcon = styled(Agent)`
    width: 28px;
    height: 28px;
`;

export const ChartIcon = styled(Chart)`
    width: 28px;
    height: 28px;
`;

export const ModelIcon = styled(Model)`
    width: 28px;
    height: 28px;
`;

export const MessageContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: center;
    align-items: center;
    gap: 0.5rem;

    font-size: 2rem;

    & > svg {
        width: 64px;
        height: 64px;
        color: ${color('warning.main')};
    }
`;
