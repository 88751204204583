import { useParams } from 'react-router-dom';
import { Button, Column, Input } from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal } from '@rainbow-modules/hooks';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import ActionsColumn from 'components/ActionsColumn';
import { Buildingblocks } from 'data/firestore/dataset/buildingBlocks/types';
import useBuildingBlocks from 'hooks/useBuildingBlocks';
import { BUILDING_BLOCK_MODAL } from '../../../../constants';
import {
    Container, Header, StyledTable,
} from './styled';
import BuildingBlockForm from './form';

const BuildingBlocks = () => {
    const { datasetId = '' } = useParams();
    const connectedModalProps = useConnectModal(BUILDING_BLOCK_MODAL);
    const {
        data = [],
        isLoading,
        add: addNewBuildingBlock,
        edit: editBuildingBlock,
        remove: removeBuildingBlock,
    } = useBuildingBlocks({ datasetId });

    return (
        <Container>
            <Header>
                <Input size="small" borderRadius="semi-rounded" placeholder="Find a building block..." icon={<MagnifyingGlass />} type="search" />
                <Button size="small" borderRadius="semi-rounded" variant="brand" onClick={addNewBuildingBlock}>
                    <Plus className="rainbow-m-right_small" />
                    New Building Block
                </Button>
            </Header>
            <StyledTable data={data} isLoading={isLoading} keyField="id" variant="listview">
                <Column
                    header="Name"
                    field="name"
                    headerAlignment="left"
                    cellAlignment="left"
                    defaultWidth={200}
                />
                <Column
                    header="Role"
                    field="role"
                    headerAlignment="left"
                    cellAlignment="left"
                    defaultWidth={130}
                />
                <Column
                    header="Message"
                    field="content"
                    headerAlignment="left"
                    cellAlignment="left"
                />
                <Column
                    field="id"
                    component={ActionsColumn}
                    cellAlignment="right"
                    width={100}
                    onEdit={(row) => editBuildingBlock(row as unknown as Buildingblocks)}
                    onDelete={removeBuildingBlock}
                />
            </StyledTable>
            <UniversalFormModal
                borderRadius="semi-rounded"
                fields={BuildingBlockForm}
                {...connectedModalProps}
            />
        </Container>
    );
};

export default BuildingBlocks;
