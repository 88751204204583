import { IconProps } from './types';

const MagicPencil = ({ title = 'MagicPencil Role', className }: IconProps) => (
    <svg className={className} width={26} height={22} viewBox="0 0 26 22" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.825 2C22.5723 2 22.3219 2.04979 22.0884 2.14653C21.8548 2.24327 21.6426 2.38506 21.4639 2.56381L9.98349 14.0442L8.96262 17.7874L12.7058 16.7665L24.1862 5.28612C24.365 5.10737 24.5067 4.89517 24.6035 4.66162C24.7002 4.42807 24.75 4.17776 24.75 3.92497C24.75 3.67217 24.7002 3.42186 24.6035 3.18831C24.5067 2.95477 24.365 2.74256 24.1862 2.56381C24.0075 2.38506 23.7952 2.24327 23.5617 2.14653C23.3282 2.04979 23.0778 2 22.825 2ZM21.7057 1.22265C22.0606 1.07566 22.4409 1 22.825 1C23.2092 1 23.5895 1.07566 23.9444 1.22265C24.2993 1.36964 24.6217 1.58509 24.8933 1.8567C25.1649 2.12831 25.3804 2.45076 25.5274 2.80563C25.6744 3.1605 25.75 3.54086 25.75 3.92497C25.75 4.30908 25.6744 4.68943 25.5274 5.0443C25.3804 5.39918 25.1649 5.72162 24.8933 5.99323L13.319 17.5675C13.2575 17.629 13.181 17.6735 13.097 17.6964L8.38157 18.9824C8.20846 19.0296 8.02333 18.9804 7.89646 18.8536C7.76958 18.7267 7.72042 18.5415 7.76763 18.3684L9.05366 13.653C9.07656 13.569 9.12096 13.4925 9.18249 13.431L20.7568 1.8567C21.0284 1.58509 21.3508 1.36964 21.7057 1.22265Z" fill="currentColor" />
        <g clipPath="url(#clip0_1596_16725)">
            <path d="M13.25 4.99937C13.25 5.2327 13.1226 5.38482 12.8623 5.44719C11.9928 5.6554 11.1232 5.8663 10.2514 6.06418C10.0005 6.12117 9.86996 6.25579 9.81343 6.50438C9.616 7.36906 9.40691 8.23106 9.20095 9.09395C9.13499 9.37126 8.98558 9.50228 8.74552 9.49959C8.50771 9.49735 8.36592 9.36677 8.29862 9.08408C8.09267 8.22119 7.88357 7.35919 7.68525 6.49496C7.63006 6.25489 7.50263 6.12207 7.26213 6.06643C6.39793 5.86719 5.53598 5.65809 4.67313 5.45258C4.37789 5.38258 4.24507 5.23674 4.25001 4.9895C4.2545 4.75302 4.38507 4.61571 4.67134 4.54751C5.53419 4.34199 6.39614 4.13334 7.26033 3.93365C7.50038 3.87846 7.62961 3.74609 7.68435 3.50602C7.88268 2.64134 8.09177 1.77934 8.29772 0.916903C8.36592 0.632862 8.50592 0.502733 8.74373 0.500041C8.99006 0.497348 9.13275 0.627926 9.20409 0.926326C9.41005 1.78922 9.61824 2.65121 9.81836 3.51545C9.87176 3.74564 9.9965 3.87667 10.228 3.93007C11.1066 4.13289 11.9838 4.34289 12.861 4.55244C13.1226 4.61481 13.25 4.76603 13.2505 4.99892L13.25 4.99937Z" fill="currentColor" />
        </g>
        <g clipPath="url(#clip1_1596_16725)">
            <path d="M6.24997 12.4996C6.24997 12.6551 6.16501 12.7565 5.99152 12.7981C5.4118 12.9369 4.83208 13.0775 4.25086 13.2095C4.08365 13.2474 3.9966 13.3372 3.95891 13.5029C3.82729 14.0794 3.6879 14.654 3.55059 15.2293C3.50662 15.4142 3.40701 15.5015 3.24698 15.4997C3.08843 15.4982 2.99391 15.4112 2.94904 15.2227C2.81174 14.6475 2.67234 14.0728 2.54012 13.4966C2.50333 13.3366 2.41838 13.248 2.25804 13.211C1.68191 13.0781 1.10728 12.9387 0.532048 12.8017C0.335219 12.7551 0.246676 12.6578 0.249966 12.493C0.252958 12.3353 0.340005 12.2438 0.530852 12.1983C1.10608 12.0613 1.68072 11.9222 2.25685 11.7891C2.41688 11.7523 2.50303 11.6641 2.53953 11.504C2.67174 10.9276 2.81114 10.3529 2.94844 9.77794C2.99391 9.58857 3.08724 9.50182 3.24578 9.50003C3.41 9.49823 3.50513 9.58528 3.55269 9.78422C3.68999 10.3595 3.82879 10.9341 3.9622 11.5103C3.9978 11.6638 4.08096 11.7511 4.23531 11.7867C4.82101 11.9219 5.40581 12.0619 5.99062 12.2016C6.16501 12.2432 6.24997 12.344 6.25027 12.4993L6.24997 12.4996Z" fill="currentColor" />
        </g>
        <g clipPath="url(#clip2_1596_16725)">
            <path d="M24.25 17.9995C24.25 18.181 24.1509 18.2993 23.9485 18.3478C23.2721 18.5098 22.5958 18.6738 21.9177 18.8277C21.7226 18.872 21.6211 18.9767 21.5771 19.1701C21.4235 19.8426 21.2609 20.513 21.1007 21.1842C21.0494 21.3999 20.9332 21.5018 20.7465 21.4997C20.5615 21.4979 20.4512 21.3964 20.3989 21.1765C20.2387 20.5054 20.0761 19.8349 19.9218 19.1627C19.8789 18.976 19.7798 18.8727 19.5927 18.8294C18.9206 18.6745 18.2502 18.5118 17.5791 18.352C17.3494 18.2976 17.2461 18.1841 17.25 17.9918C17.2535 17.8079 17.355 17.7011 17.5777 17.6481C18.2488 17.4882 18.9192 17.3259 19.5913 17.1706C19.778 17.1277 19.8786 17.0247 19.9211 16.838C20.0754 16.1655 20.238 15.495 20.3982 14.8243C20.4512 14.6033 20.5601 14.5021 20.7451 14.5C20.9367 14.4979 21.0477 14.5995 21.1032 14.8316C21.2633 15.5027 21.4253 16.1732 21.5809 16.8453C21.6225 17.0244 21.7195 17.1263 21.8995 17.1678C22.5829 17.3256 23.2651 17.4889 23.9474 17.6519C24.1509 17.7004 24.25 17.818 24.2503 17.9992L24.25 17.9995Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_1596_16725">
                <rect width="9" height="9" fill="white" transform="translate(4.25 0.5)" />
            </clipPath>
            <clipPath id="clip1_1596_16725">
                <rect width="6" height="6" fill="white" transform="translate(0.25 9.5)" />
            </clipPath>
            <clipPath id="clip2_1596_16725">
                <rect width="7" height="7" fill="white" transform="translate(17.25 14.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default MagicPencil;
