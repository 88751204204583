/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Version } from 'data/firestore/model/version/types';
import { EntityGet } from 'data/firestore/types';

export interface Context {
    version?: EntityGet<Version> | null;
    isLoading?: boolean;
}

const context = React.createContext<Context>({
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
