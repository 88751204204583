import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";

export type SubscriptionsRemoveService = (
  agentId: string,
  id: string
) => Promise<void>;

const remove: SubscriptionsRemoveService = async (agentId, id) => {
  const docRef = doc(db, `/agent/${agentId}/subscriptions/${id}`);
  return await deleteDoc(docRef);
};

export default remove;
