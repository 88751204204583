import { ButtonIcon } from 'react-rainbow-components';
import { Pencil, Trash } from 'components/icons';
import { useOpenModal } from '@rainbow-modules/hooks';
import useDeleteAgent from 'hooks/useDeleteAgent';
import { ADD_MODIFY_AGENT_DRAWER } from '../../constants';
import { StyledColumnWrapper, StyledDrawerTitle } from './styled';

interface AgentActionsColumnProps {
    value?: string;
}

const AgentActionsColumn = (props: AgentActionsColumnProps) => {
    const { value = '' } = props;

    const [openDrawer] = useOpenModal(ADD_MODIFY_AGENT_DRAWER);
    const deteleAgent = useDeleteAgent({
        agentId: value,
    });

    return (
        <StyledColumnWrapper>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Pencil />}
                onClick={() => openDrawer({
                    mode: 'edit',
                    header: <StyledDrawerTitle>Modify Agent</StyledDrawerTitle>,
                    agentData: {
                        agentId: value,
                    },
                })}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                onClick={deteleAgent}
            />
        </StyledColumnWrapper>
    );
};

export default AgentActionsColumn;
