import { collection, query, getCountFromServer } from "firebase/firestore";
import { ListServiceOpts } from "../types";
import { db } from "../firebase";

export type UsagereportsCountService = (
  opts?: ListServiceOpts
) => Promise<number>;

const count: UsagereportsCountService = async (opts = {}) => {
  const collectionRef = collection(db, `/usagereports`);
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};

export default count;
