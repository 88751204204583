import { Button } from 'react-rainbow-components';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import { FormContainer, SearchInput } from './styled';
import { SearchFormProps } from './types';

const SearchForm = ({ onChange, onAddClick }: SearchFormProps) => (
    <FormContainer>
        <SearchInput
            placeholder="Find Agent"
            borderRadius="semi-rounded"
            icon={<MagnifyingGlass />}
            onChange={(e) => onChange(e.target.value)}
            size="small"
        />
        <Button
            variant="brand"
            type="button"
            borderRadius="semi-rounded"
            onClick={onAddClick}
            size="small"
        >
            <Plus className="rainbow-m-right_medium" />
            Add Agent
        </Button>
    </FormContainer>
);

export default SearchForm;
