import {
    Button, GoogleAddressLookup, Input, MultiSelect,
} from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Content = styled.div`
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${color('border.divider')};
    padding: 20px 12px 0 12px;
`;

export const FormContainer = styled.div`
    box-sizing: border-box;
`;

export const FormHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: stretch;
    padding: 0 0 12px;
    align-items: center;
    top: 0;
    background: ${color('background.main')};
    z-index: 10;
`;

export const FormTitle = styled.h2`
    flex: 1;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const FormFields = styled.fieldset`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    div[id^="error-message"] {
        align-self: start;
        margin-left: 1rem;
    }
`;

export const StyledSubmitButton = styled(Button)`
    &[disabled] {
        background-color: ${color('brand.light')};
        border-color: ${color('brand.light')};
        color: white;
    }
`;

export const StyledMultiSelect = styled(MultiSelect)`
    div[role="combobox"]+ div {
        align-self: start;
        margin-left: 1rem;
    }
`;

export const StyledInput = styled(Input)`
    label + div + div {
        align-self: start;
        margin-left: 5px;
    }
`;

export const StyledGoogleAddressLookup = styled(GoogleAddressLookup)`
    input + span {
        width: 0;
    }

    input + span > span {
        min-width: 0;
        flex-basis: 99%;
    }

    input + span > span > span{
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        white-space: nowrap;
    }
`;
