import { useFormState } from 'react-final-form';
import { RenderIf } from 'react-rainbow-components';
import { Field } from '@rainbow-modules/forms';
import { isEmpty } from '@rainbow-modules/validation';
import ModelPicklist from 'components/ModelPicklist';
import ModelVersionPicklist from 'components/ModelVersionPicklist';
import { Dataset } from 'data/firestore/dataset/types';
import { StyledSlider } from './styled';
import isRootModel from '../helpers/isRootModel';

const validateTemperature = (value: number) => {
    if (isEmpty(value)) return 'This field is required';
    if (value < 0 || value > 1) return 'Value must be between 0 and 1';
    return undefined;
};

const validateModel = (value: Dataset['model']) => {
    if (isEmpty(value?.id)) return 'This fieldd is required';
    return undefined;
};

const validateModelVersion = (value: Dataset['model']) => {
    if (isEmpty(value?.version)) return 'This field is required';
    return undefined;
};

const Form = () => {
    const { values } = useFormState();
    return (
        <>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={ModelPicklist}
                name="model"
                required
                borderRadius="semi-rounded"
                className="rainbow-m-top_medium"
                validate={validateModel}
            />
            <RenderIf isTrue={Boolean(values?.model?.id && !isRootModel(values?.model?.id))}>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={ModelVersionPicklist}
                    name="model"
                    required
                    borderRadius="semi-rounded"
                    className="rainbow-m-top_medium"
                    validate={validateModelVersion}
                />
            </RenderIf>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledSlider}
                type="number"
                min={0}
                max={1}
                step={0.1}
                name="temperature"
                required
                label="Temperature"
                labelAlignment="left"
                className="rainbow-m-top_medium"
                validate={validateTemperature}
            />
        </>
    );
};

export default Form;
