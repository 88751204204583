import { IconProps } from './types';

const EditNote = ({ title = 'Edit Note', className }: IconProps) => (
    <svg className={className} width={18} height={18} viewBox="0 0 18 18" fill="none">
        <title>{title}</title>
        <path d="M9.75 15.75C9.5375 15.75 9.35938 15.6781 9.21563 15.5344C9.07188 15.3906 9 15.2125 9 15V14.475C9 14.375 9.01875 14.2781 9.05625 14.1844C9.09375 14.0906 9.15 14.0063 9.225 13.9313L12.975 10.1813L14.5688 11.775L10.8188 15.525C10.7438 15.6 10.6594 15.6563 10.5656 15.6938C10.4719 15.7313 10.375 15.75 10.275 15.75H9.75ZM3 12C2.7875 12 2.60938 11.9281 2.46563 11.7844C2.32188 11.6406 2.25 11.4625 2.25 11.25C2.25 11.0375 2.32188 10.8594 2.46563 10.7156C2.60938 10.5719 2.7875 10.5 3 10.5H6.75C6.9625 10.5 7.14063 10.5719 7.28438 10.7156C7.42813 10.8594 7.5 11.0375 7.5 11.25C7.5 11.4625 7.42813 11.6406 7.28438 11.7844C7.14063 11.9281 6.9625 12 6.75 12H3ZM15.0938 11.25L13.5 9.65625L14.0438 9.1125C14.1813 8.975 14.3563 8.90625 14.5688 8.90625C14.7813 8.90625 14.9563 8.975 15.0938 9.1125L15.6375 9.65625C15.775 9.79375 15.8438 9.96875 15.8438 10.1813C15.8438 10.3938 15.775 10.5688 15.6375 10.7063L15.0938 11.25ZM3 9C2.7875 9 2.60938 8.92813 2.46563 8.78438C2.32188 8.64063 2.25 8.4625 2.25 8.25C2.25 8.0375 2.32188 7.85938 2.46563 7.71563C2.60938 7.57188 2.7875 7.5 3 7.5H9.75C9.9625 7.5 10.1406 7.57188 10.2844 7.71563C10.4281 7.85938 10.5 8.0375 10.5 8.25C10.5 8.4625 10.4281 8.64063 10.2844 8.78438C10.1406 8.92813 9.9625 9 9.75 9H3ZM3 6C2.7875 6 2.60938 5.92813 2.46563 5.78438C2.32188 5.64063 2.25 5.4625 2.25 5.25C2.25 5.0375 2.32188 4.85938 2.46563 4.71563C2.60938 4.57188 2.7875 4.5 3 4.5H9.75C9.9625 4.5 10.1406 4.57188 10.2844 4.71563C10.4281 4.85938 10.5 5.0375 10.5 5.25C10.5 5.4625 10.4281 5.64063 10.2844 5.78438C10.1406 5.92813 9.9625 6 9.75 6H3Z" fill="currentColor" />
    </svg>
);

export default EditNote;
