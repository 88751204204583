import {
    Breadcrumb,
    Breadcrumbs,
    ButtonIcon,
    Button,
    Spinner,
    Column,
    Input,
} from 'react-rainbow-components';
import { Plus, MagnifyingGlass } from '@rainbow-modules/icons';
import { Pencil, Trash } from 'components/icons';
import { confirmModal } from '@rainbow-modules/app';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { query, where, orderBy } from 'firebase/firestore';
import useDataset from 'data/firestore/dataset/use';
import useGroup from 'data/firestore/group/use';
import useConversations from 'data/firestore/conversation/useCollection';
import updateGroup from 'data/firestore/group/update';
import removeGroup from 'data/firestore/group/remove';
import addConversation from 'data/firestore/conversation/add';
import useOpenTreeFormModal from 'hooks/useOpenTreeFormModal';
import DateTimeColumn from 'components/DateTimeColumn';
import { StyledNavigationButtonColumn, StyledTable } from 'components/styled';
import PageHeader from 'components/PageHeader';
import {
    Container,
    Title,
    Content,
    ConversationsListHeader,
} from './styled';
import ActionsColumn from './columns/actions';

interface Values {
    name: string;
}

interface ConversationValues extends Values {
    story?: string;
}

interface OutletContext {
    onAddConversation: (conversationId: string) => void;
}

const Conversations = () => {
    const { datasetId = '', groupId = '' } = useParams();
    const navigate = useNavigate();
    const { data: dataset, isLoading: isLoadingDataset } = useDataset(datasetId);
    const { data: group, isLoading: isLoadingGroup } = useGroup(groupId);
    const { data: conversations, isLoading: isLoadingConversation } = useConversations({
        listQueryFn: (ref) => query(ref, where('groupId', '==', groupId), orderBy('createdAt', 'asc')),
        track: [groupId],
    });
    const [openModal, closeModal] = useOpenTreeFormModal();
    const { onAddConversation } = useOutletContext<OutletContext>();
    const isLoading = isLoadingDataset || isLoadingGroup || isLoadingConversation;

    if (isLoading) {
        return <Spinner />;
    }

    const openEditTitleForm = () => openModal({
        title: 'Edit Group',
        submitButtonLabel: 'Update Group',
        entity: 'group',
        initialValues: {
            name: group?.name,
        },
        onSubmit: (values: Values) => {
            updateGroup(groupId, { name: values.name });
            closeModal();
        },
    });

    const openDeleteConfirmation = async () => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Group',
            question:
                'Removing this group will delete it permanently. Are you sure you want to remove this group?',
            okButtonLabel: 'Delete',
        });
        if (result) {
            removeGroup(groupId);
            navigate(`/dataset/${datasetId}`);
        }
    };

    const openAddConversationForm = () => openModal({
        title: 'New Conversation',
        submitButtonLabel: 'Create Conversation',
        entity: 'conversation',
        onSubmit: async ({ name }: ConversationValues) => {
            const newConversation = await addConversation({
                name,
                groupId,
                datasetId,
                expectedCompletions: [],
            });
            onAddConversation(newConversation.id);
            closeModal();
        },
    });

    return (
        <Container>
            <PageHeader
                title={(
                    <Breadcrumbs>
                        <Breadcrumb label={dataset?.name} onClick={() => navigate(`/dataset/${datasetId}`)} />
                        <Breadcrumb label={<Title>{group?.name}</Title>} />
                    </Breadcrumbs>
                )}
                actions={(
                    <>
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Pencil />}
                            onClick={openEditTitleForm}
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Trash />}
                            onClick={openDeleteConfirmation}
                        />
                    </>
                )}
            />
            <Content>
                <ConversationsListHeader>
                    <Input size="small" borderRadius="semi-rounded" placeholder="Find a conversation..." type="search" icon={<MagnifyingGlass />} />
                    <Button
                        variant="brand"
                        borderRadius="semi-rounded"
                        onClick={openAddConversationForm}
                        size="small"
                    >
                        <Plus className="rainbow-m-right_small" />
                        New Conversation
                    </Button>
                </ConversationsListHeader>
                <StyledTable data={conversations} keyField="id" variant="listview">
                    <Column
                        header="Conversation Name"
                        field="name"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={StyledNavigationButtonColumn}
                        onClick={({ row }: { row: { id: string } }) => navigate(`/dataset/${datasetId}/group/${groupId}/conversation/${row.id}`)}
                    />
                    <Column
                        header="Created At"
                        field="createdAt"
                        headerAlignment="left"
                        defaultWidth={180}
                        component={DateTimeColumn}
                    />
                    <Column component={ActionsColumn} width={100} />
                </StyledTable>
            </Content>
        </Container>
    );
};

export default Conversations;
