import { useContext } from 'react';
import { Column, RenderIf } from 'react-rainbow-components';
import { EntityGet } from 'data/firestore/types';
import { Call } from 'data/firestore/agent/campaign/call/types';
import DateTimeColumn from 'components/DateTimeColumn';
import CampaignCallStatusColumn from 'components/CampaignCallStatusColumn';
import { useNavigate } from 'react-router-dom';
import { PageHeaderTitle } from 'components/styled';
import context, { CampaignContext } from '../context';
import {
    Container,
    LeftColumn,
    RightColumn,
    PendingPhoneNumberItem,
    ClockIcon,
    StyledTable,
    StyledSpinner,
    SpinnerContainer,
    StyledNavigationButtonColumn,
} from './styled';

const Calls = () => {
    const { calls } = useContext<CampaignContext>(context);
    const navigate = useNavigate();
    const pendingCalls: EntityGet<Call>[] | undefined = calls
        ?.filter((call) => call.status === 'pending' || call.status === 'in-progress')
        ?.slice(0, 10);
    const finishedCalls: EntityGet<Call>[] | undefined = calls
        ?.filter((call) => !(call.status === 'pending' || call.status === 'in-progress'))
        ?.map((call) => ({
            ...call,
            sessionStatus: call.session?.status || call.status,
        }));

    return (
        <Container>
            <RenderIf isTrue={pendingCalls && pendingCalls?.length > 0}>
                <LeftColumn>
                    <PageHeaderTitle className="rainbow-m-bottom_small">
                        Calls in Queue
                    </PageHeaderTitle>
                    {
                        pendingCalls?.map((call) => (
                            <PendingPhoneNumberItem key={call.id}>
                                {call.status === 'in-progress' ? <SpinnerContainer><StyledSpinner size="xx-small" type="arc" variant="brand" /></SpinnerContainer> : <ClockIcon />}
                                {call.to}
                            </PendingPhoneNumberItem>
                        ))
                    }
                </LeftColumn>
            </RenderIf>
            <RightColumn isFullWidth={!pendingCalls || pendingCalls?.length === 0}>
                <PageHeaderTitle className="rainbow-m-bottom_small">
                    Finished Calls
                </PageHeaderTitle>
                <StyledTable
                    keyField="id"
                    data={finishedCalls}
                    emptyTitle="No finished calls"
                    variant="listview"
                    pageSize={10}
                >
                    <Column
                        header="Phone Number"
                        cellAlignment="left"
                        headerAlignment="left"
                        field="to"
                        component={StyledNavigationButtonColumn}
                        onClick={({ row }: { row: { session: { id: string } } }) => row?.session?.id && navigate(`/history/conversations/${row?.session?.id}`)}
                    />
                    <Column header="Date and Time" defaultWidth={190} cellAlignment="left" headerAlignment="left" field="session.createdAt" component={DateTimeColumn} />
                    <Column header="Status" defaultWidth={140} cellAlignment="left" headerAlignment="left" field="sessionStatus" component={CampaignCallStatusColumn} />
                    <Column header="Duration" defaultWidth={120} cellAlignment="left" headerAlignment="left" field="session.duration" />
                </StyledTable>
            </RightColumn>
        </Container>
    );
};

export default Calls;
