import {
    ChangeEvent, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { showAppMessage } from '@rainbow-modules/app';
import { Button, Modal, Textarea } from 'react-rainbow-components';
import { Plus } from '@rainbow-modules/icons';
import { Message } from 'components/ChatMessage/types';
import useModelVersionChat from 'hooks/useModelVersionChat';
import isValidConversation from 'pages/App/Dataset/Conversation/helpers/isValidConversation';
import { PageHeaderTitle } from 'components/styled';
import context from '../context';
import {
    PlaygroundContainer,
    LeftContent,
    MainContent,
    StyledSlider,
    StyledTextarea,
    SectionHeader,
    Actions,
    ModalTitle,
    MessagesList,
    MessagesListActions,
    ImportButton,
} from './styled';
import ChatMessageInput from './ChatMessageInput';

const getRole = (messages: Message[]) => {
    if (messages.length > 0) {
        const { role } = messages[messages.length - 1];
        if (role === 'user') return 'assistant';
    }
    return 'user';
};

const importMessagesPlaceholder = `
assiatant: Hello
user: Hi
assiatant: How can I help you?
user: I need help with my order
`.trim();

const Chat = () => {
    const { version } = useContext(context);
    const { modelId } = useParams();
    const [temperature, setTemperature] = useState<number>(1.00);
    const [systemMessage, setSystemMessage] = useState<string>('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [isOpenImport, setIsOpenImport] = useState(false);
    const [messagesToImport, setMessagesToImport] = useState('');
    const [messagesToImportError, setMessagesToImportError] = useState('');
    const [importing, setImporting] = useState(false);

    const {
        currentResponseStream,
        isSendingMessage,
        sendMessages,
    } = useModelVersionChat({
        modelId,
        versionId: version?.id,
        onError: (error: Error) => showAppMessage({
            variant: 'error',
            message: error.message as string,
            timeout: 5000,
        }),
    });

    const onTemperatureChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setTemperature(Number(event.target.value)),
        [],
    );

    const onSystemMessageChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => setSystemMessage(event.target.value),
        [],
    );

    const copyConversation = useCallback(() => {
        const text = messages.map((msg: Message) => `${msg.role}: ${msg.content}`).join('\n');
        navigator.clipboard.writeText(text);
    }, [messages]);

    const openImportModal = useCallback(() => setIsOpenImport(true), []);

    const importMessages = useCallback(async () => {
        if (isValidConversation(messagesToImport)) {
            setMessagesToImportError('');
            const msgs = messagesToImport.split('\n').map((msg, index) => {
                const [role, content] = msg.split(': ');
                return { id: `message-${Date.now() + index}`, role, content };
            }) as Message[];
            setImporting(true);
            setMessages([...messages, ...msgs]);
            setIsOpenImport(false);
            setMessagesToImport('');
            setImporting(false);
        } else {
            setMessagesToImportError('Invalid format');
        }
    }, [messages, messagesToImport]);

    const addMessage = useCallback(
        () => {
            const newMessage = {
                id: `message-${Date.now()}`,
                role: getRole(messages),
                content: '',
            } as Message;
            setMessages([...messages, newMessage]);
        },
        [messages],
    );

    const removeMessage = useCallback((messageId: string) => {
        setMessages(messages.filter((message) => message.id !== messageId));
    }, [messages]);

    const updateMessage = useCallback((newData: Message) => {
        setMessages(messages.map((message) => (message.id !== newData.id ? message : newData)));
    }, [messages]);

    // eslint-disable-next-line no-alert
    const handleSubmit = useCallback(() => sendMessages({
        body: {
            systemMessage,
            temperature,
            messages: messages.map(({ role, content }) => ({ role, content })),
        },
    }), [messages, sendMessages, systemMessage, temperature]);

    const messagesList = useMemo(
        () => messages.map((message, index, list) => {
            const editModeEnabled = index === list.length - 1 && !isSendingMessage;
            return (
                <ChatMessageInput
                    {...message}
                    key={message.id}
                    editMode={editModeEnabled}
                    onRemove={() => removeMessage(message.id)}
                    onChange={updateMessage}
                />
            );
        }),
        [messages, isSendingMessage, updateMessage, removeMessage],
    );

    useEffect(() => {
        if (isSendingMessage) {
            if (currentResponseStream === '') {
                addMessage();
            } else {
                const latestMessage = messages[messages.length - 1];
                setMessages(
                    messages.map(
                        (message) => (
                            message.id !== latestMessage.id
                                ? message
                                : { ...latestMessage, content: currentResponseStream }
                        ),
                    ),
                );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSendingMessage, currentResponseStream]);

    return (
        <PlaygroundContainer>
            <LeftContent>
                <StyledSlider
                    min={0}
                    max={1.00}
                    step={0.01}
                    label="Temperature"
                    labelAlignment="left"
                    onChange={onTemperatureChange}
                    value={temperature}
                />
                <StyledTextarea
                    label="System Message"
                    borderRadius="semi-rounded"
                    value={systemMessage}
                    onChange={onSystemMessageChange}
                    labelAlignment="left"
                />
            </LeftContent>
            <MainContent>
                <SectionHeader>
                    <PageHeaderTitle>Conversation</PageHeaderTitle>
                    <Actions>
                        <Button label="Copy Conversation" borderRadius="semi-rounded" onClick={copyConversation} size="small" />
                        <Button label="Import Message" borderRadius="semi-rounded" onClick={openImportModal} size="small" />
                    </Actions>
                </SectionHeader>
                <MessagesList>
                    {messagesList}
                </MessagesList>
                <MessagesListActions>
                    <Button
                        borderRadius="semi-rounded"
                        onClick={addMessage}
                    >
                        <Plus className="rainbow-m-right_small" />
                        <span>Add Message</span>
                    </Button>
                    <Button
                        label="Submit"
                        variant="brand"
                        borderRadius="semi-rounded"
                        onClick={handleSubmit}
                    />
                </MessagesListActions>
            </MainContent>
            <Modal
                isOpen={isOpenImport}
                onRequestClose={() => setIsOpenImport(false)}
                title={<ModalTitle>Import Message</ModalTitle>}
                size="medium"
                borderRadius="semi-rounded"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_medium"
                            label="Close"
                            variant="neutral"
                            borderRadius="semi-rounded"
                            onClick={() => setIsOpenImport(false)}
                        />
                        <ImportButton
                            label="Import"
                            borderRadius="semi-rounded"
                            onClick={importMessages}
                            variant="brand"
                            isLoading={importing}
                        />
                    </div>
                )}
            >
                <Textarea
                    value={messagesToImport}
                    onChange={(e) => setMessagesToImport(e.target.value)}
                    placeholder={importMessagesPlaceholder}
                    rows={10}
                    error={messagesToImportError}
                    disabled={importing}
                />
            </Modal>
        </PlaygroundContainer>
    );
};

export default Chat;
