import styled from 'styled-components';
import { Check, Slash } from 'components/icons';
import { color } from 'theme';

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${color('text.main')};
    overflow: hidden;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
    gap: 8px;
`;

export const CheckmarkIcon = styled(Check)`
    color: ${color('success.main')};
    width: 14px;
    height: 14px;
`;

export const DisableIcon = styled(Slash)`
    color: ${color('text.header')};
    width: 14px;
    height: 14px;
`;
