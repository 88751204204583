import styled from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';
import { NavigationButtonColumn } from '@rainbow-modules/listview';
import { color } from 'theme';
import Tile from 'components/Tile';

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const TrashButton = styled(ButtonIcon)`
    width: 30px;
    height: 30px;

    > svg {
        height: 16px !important;
        width: 16px !important;
    }

`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 12px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 4px;
    padding-left: 4px;
    height: 100%;
    gap: 4px;
`;

export const SectionTitle = styled.h2`
    font-size: 14px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;

export const SectionSubTitle = styled.p`
    font-size: 12px;
    line-height: 18px;
    color: ${color('text.header')};
`;

export const TileContainer = styled.div`
    display: flex;
    gap: 8px;
    padding: 8px 20px;
`;

export const ModalTitle = styled.h1`
    font-size: 20px;
    text-align: left;
    padding: 20px 24px 16px 24px;
`;

export const StyledTile = styled(Tile)`
    flex: 1;
`;

export const PageTitle = styled.h1`
    font-size: 14px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;
