import styled from 'styled-components';
import { NavigationButtonColumn } from '@rainbow-modules/listview';
import { Table } from 'react-rainbow-components';
import { color } from 'theme';

export const ModalContent = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const TableFooter = styled.div`
    height: fit-content;
    padding: 12px 0;
    margin: 0 2px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    border-top: 1px solid ${color('border.divider')};
`;

export const StyledTable = styled(Table)`
    overflow: auto;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;
