import { IconProps } from './types';

const NewFileText = ({ title = 'new file text', className }: IconProps) => (
    <svg className={className} width={18} height={22} viewBox="0 0 18 22" fill="none">
        <title>{title}</title>
        <path
            fill="currentColor"
            d="M1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H11C11.1326 0.5 11.2598 0.552678 11.3536 0.646447L17.3536 6.64645C17.4473 6.74021 17.5 6.86739 17.5 7V19C17.5 19.663 17.2366 20.2989 16.7678 20.7678C16.2989 21.2366 15.663 21.5 15 21.5H3C2.33696 21.5 1.70107 21.2366 1.23223 20.7678C0.763392 20.2989 0.5 19.663 0.5 19V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V19C1.5 19.3978 1.65804 19.7794 1.93934 20.0607C2.22064 20.342 2.60218 20.5 3 20.5H15C15.3978 20.5 15.7794 20.342 16.0607 20.0607C16.342 19.7794 16.5 19.3978 16.5 19V7.20711L10.7929 1.5H3Z"
        />
        <path
            fill="currentColor"
            d="M11 0.5C11.2761 0.5 11.5 0.723858 11.5 1V6.5H17C17.2761 6.5 17.5 6.72386 17.5 7C17.5 7.27614 17.2761 7.5 17 7.5H11C10.7239 7.5 10.5 7.27614 10.5 7V1C10.5 0.723858 10.7239 0.5 11 0.5Z"
        />
        <path
            fill="currentColor"
            d="M9 9.58334C9.27614 9.58334 9.5 9.8072 9.5 10.0833V15.9167C9.5 16.1928 9.27614 16.4167 9 16.4167C8.72386 16.4167 8.5 16.1928 8.5 15.9167V10.0833C8.5 9.8072 8.72386 9.58334 9 9.58334Z"
        />
        <path
            fill="currentColor"
            d="M5.58334 13C5.58334 12.7239 5.8072 12.5 6.08334 12.5H11.9167C12.1928 12.5 12.4167 12.7239 12.4167 13C12.4167 13.2761 12.1928 13.5 11.9167 13.5H6.08334C5.8072 13.5 5.58334 13.2761 5.58334 13Z"
        />
    </svg>
);

export default NewFileText;
