import styled, { css } from 'styled-components';
import { color } from 'theme';
import { Folder } from 'components/icons';

export const Container = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface OptionProps {
    selected?: boolean;
}

export const Option = styled.li<OptionProps>`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    flex: none;
    order: 1;
    color: ${color('text.label')};
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 2px;

    &:hover,
    :visited,
    :focus {
        background-color: ${color('brand.light')};
        text-decoration: none;
        color: ${color('text.label')};
    }

    ${(props) => props.selected
        && css`
            background-color: ${color('brand.light')};
            color: ${color('brand.main')};
            cursor: default;

            &:hover {
                background-color: ${color('brand.light')};
                color: ${color('brand.main')};
            }
        `}
`;

export const FolderIcon = styled(Folder)`
    width: 18px;
    height: auto;
    margin-right: 8px;
    flex-shrink: 0;
`;

export const DatasetName = styled.span`
    max-width: 175px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
