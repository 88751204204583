import { collection, query, getCountFromServer } from "firebase/firestore";
import { ListServiceOpts } from "../../../types";
import { db } from "../../../firebase";

export type CallCountService = (
  agentId: string,
  campaignId: string,
  opts?: ListServiceOpts
) => Promise<number>;

const count: CallCountService = async (agentId, campaignId, opts = {}) => {
  const collectionRef = collection(
    db,
    `/agent/${agentId}/campaign/${campaignId}/call`
  );
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};

export default count;
