import MessageInput from 'components/MessageInput';
import {
    Textarea,
    Badge,
    Pagination,
    Modal,
    MarkdownOutput,
} from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { ChevronDown } from '@rainbow-modules/icons';
import { FileText, Maximize } from 'components/icons';
import styled from 'styled-components';
import { color } from 'theme';

export const ContentContainer = styled.div`
    display: flex;
    gap: 32px;
    height: 100%;
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
`;
export const SectionHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;

export const DocumentsList = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const DocumentItemContainer = styled.div`
    border-radius: 10px;
    border: 1px solid ${color('border.divider')};
`;

export const DocumentHeader = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid ${color('border.divider')};
`;

export const DocumentTitle = styled.h3`
    color: ${color('text.main')};
    font-size: 15px;
`;

export const DocumentDate = styled.p`
    color: ${color('text.header')};
    font-size: 12px;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const DocumentContent = styled.p`
    color: ${color('text.label')};
    font-size: 14px;
    padding: 24px 20px 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 110px;
    margin-bottom: 24px;
`;

export const DocumentFooter = styled.footer`
    display: flex;
    flex-direction: column;
    padding: 8px 8px 8px 12px;
    border-top: 1px solid ${color('border.divider')};
`;

export const StyledUniversalFormModal = styled(UniversalFormModal)`
    height: 90%;
    justify-content: space-between;

    > div {
        height: 100%;

        > form {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 12px;
    }
    }
`;

export const StyledTextarea = styled(Textarea)`
    display: flex;
    flex-grow: 1;
`;

export const ModalTitle = styled.h1`
    color: ${color('text.main')};
    font-size: 22px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    padding: 20px 52px 20px 28px;
`;

export const StyledFileText = styled(FileText)`
    color: ${color('brand.main')};
    height: 36px;
    width: auto;
`;

export const DocumentHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const ChunkList = styled.div`
    display: flex;
    gap: 12px;
    padding: 12px 4px 12px 0;
`;

export const ChunkItemContainer = styled.button`
    flex-basis: 33%;
    background: #EBE7F1;
    border: none;
    border-radius: 10px;
    text-align: left;

    :hover {
        box-shadow: rgba(42, 48, 57, 0.2) 0px 2px 8px 0px;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background: ${color('brand.light')};
    }
`;

export const StyledBadge = styled(Badge)`
    background: #F7F9FC;
`;

export const ChunkContent = styled.p`
    color: ${color('text.main')};
    font-size: 12px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 8px 8px 8px;
`;

export const ChunkHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
`;

export const MaximizeIcon = styled(Maximize)`
    width: 16px;
    height: 16px;
    color: ${color('brand.main')};
`;

export const StyledPagination = styled(Pagination)`
    align-self: flex-end;
`;

export const StyledModal = styled(Modal)`
    background-color: #EBE7F1;
    padding: 4px 8px;
`;

export const ChunkDetailsModalTitle = styled.h1`
    color: ${color('text.main')};
    font-size: 20px;
    margin-bottom: 12px;
`;

export const ChunkContentExpanded = styled(MarkdownOutput)`
    color: ${color('text.main')};
    font-size: 14px;
    margin: 16px 4px 0 4px;
    line-height: 1.6;
`;

export const AnimatedChevronDown = styled(ChevronDown)<{ isOpen?: boolean }>`
    transition: transform 0.15s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const StyledMessageInput = styled(MessageInput)`
    flex: 0;
`;

export const StyledResponseContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const Sources = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-top: 16px;
`;

export const Source = styled.a`
    padding: 0 4px;
    border-radius: 4px;
    color: ${color('brand.main')};
    background-color: #EBE7F1;
    align-self: flex-start;

    :hover {
        color: ${color('brand.main')};
        text-decoration: none;
    }
`;

export const QAHeader = styled.header`
    display: flex;
    flex-direction: column;
    margin: 68px 0 16px;
`;
