import { ButtonMenu, MenuItem, RenderIf } from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { Plus } from '@rainbow-modules/icons';
import { useParams } from 'react-router-dom';
import { useConnectModal } from '@rainbow-modules/hooks';
import addDataset from 'data/firestore/dataset/add';
import addGroup from 'data/firestore/group/add';
import addConversation from 'data/firestore/conversation/add';
import { NewFileText, NewFolder } from 'components/icons';
import useOpenTreeFormModal, { TREE_FORM_MODAL } from 'hooks/useOpenTreeFormModal';
import { EntityName } from 'types';
import Form from './form';
import capitalize from './helpers/capitalize';

type Callback = (id: string) => void;

interface Props {
    onAddDataset: Callback;
    onAddGroup: Callback;
    onAddConversation: Callback;
}

const Forms = ({ onAddDataset, onAddGroup, onAddConversation }: Props) => {
    const { datasetId, groupId } = useParams();
    const connectedModalProps = useConnectModal(TREE_FORM_MODAL);
    const [openModal, closeModal] = useOpenTreeFormModal();

    const openForm = (entity: EntityName) => {
        const capEntity = capitalize(entity);
        return openModal({
            title: `New ${capEntity}`,
            submitButtonLabel: `Create ${capEntity}`,
            entity,
            onSubmit: async ({ name, story }: { name: string; story?: string }) => {
                if (entity === 'dataset') {
                    const dataset = await addDataset({
                        name,
                        model: {
                            id: 'gpt-3.5-turbo',
                            name: 'gpt-3.5-turbo',
                        },
                        temperature: 0.7,
                    });
                    onAddDataset(dataset.id);
                }
                if (entity === 'group' && datasetId) {
                    const group = await addGroup({
                        name,
                        datasetId,
                    });
                    onAddGroup(group.id);
                }
                if (entity === 'conversation' && datasetId && groupId) {
                    const conversation = await addConversation({
                        name,
                        story,
                        groupId,
                        datasetId,
                        expectedCompletions: [],
                    });
                    onAddConversation(conversation.id);
                }
                closeModal();
            },
        });
    };

    return (
        <>
            <ButtonMenu
                // shaded TODO: add shaded prop to ButtonMenu
                label="New"
                menuAlignment="left"
                menuSize="x-small"
                buttonVariant="neutral"
                borderRadius="semi-rounded"
                icon={<Plus className="rainbow-m-right_small" />}
            >
                <MenuItem label="New Dataset" icon={<NewFolder />} onClick={() => openForm('dataset')} />
                <RenderIf isTrue={datasetId}>
                    <MenuItem label="New Group" icon={<NewFolder />} onClick={() => openForm('group')} />
                </RenderIf>
                <RenderIf isTrue={groupId}>
                    <MenuItem
                        label="New Conversation"
                        icon={(
                            <NewFileText
                                className="rainbow-m-left_xx-small"
                            />
                        )}
                        onClick={() => openForm('conversation')}
                    />
                </RenderIf>
            </ButtonMenu>
            <UniversalFormModal
                borderRadius="semi-rounded"
                fields={Form}
                {...connectedModalProps}
            />
        </>
    );
};

export default Forms;
