/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-debugger */
/* eslint-disable react/forbid-dom-props */
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import {
    Slider, InternalOverlay, FileSelector, RenderIf,
} from 'react-rainbow-components';
import {
    useEffect, useRef, useState, useCallback,
} from 'react';
import { Play, Pause } from 'components/icons';
import ReactJson from 'react-json-view';
import {
    Container, Header, Wave, WaveformGlobalStyle, PlayButton, Timer, Zoom, Controls,
} from './styled';
import RegionDetails from './RegionDetails';
import {
    AnalysisResults,
    RegionDetailsProps,
} from './types';

const useWavesurfer = (
    containerRef: React.RefObject<HTMLDivElement>,
) => {
    const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

    useEffect(() => {
        if (containerRef.current && !wavesurfer) {
            const topTimeline = TimelinePlugin.create({
                height: 20,
                insertPosition: 'beforebegin',
                timeInterval: 0.1,
                primaryLabelInterval: 5,
                secondaryLabelInterval: 1,
            });
            const ws = WaveSurfer.create({
                barWidth: 2,
                progressColor: 'rgb(161, 130, 224)',
                container: containerRef.current,
                plugins: [topTimeline],
            });

            setWavesurfer(ws);

            return () => ws.destroy();
        }
        return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return wavesurfer;
};

const useRegions = (wavesurfer: WaveSurfer | null) => {
    const [regions, setRegions] = useState<RegionsPlugin>();

    useEffect(() => {
        if (wavesurfer) {
            setRegions(wavesurfer.registerPlugin(RegionsPlugin.create()));
        }
        return undefined;
    }, [wavesurfer]);

    return regions;
};

const useDuration = (wavesurfer: WaveSurfer | null) => {
    const [duration, setDuration] = useState('0:00');

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer.on('decode', () => {
                setDuration(wavesurfer.getDuration().toFixed(2));
            });
        }
    }, [wavesurfer]);

    return duration;
};

const fixFloat = (n: number, pow10: number = 100) => {
    const pow = 10 ** Math.abs(Math.round(Math.log10(pow10)));
    return Math.round(n * pow) / pow;
};

const AudioAnalyzer = () => {
    const waveRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [zoom, setZoom] = useState(0);
    const [regionDetails, setRegionDetails] = useState<RegionDetailsProps>();
    const regionRef = useRef<HTMLElement | null>(null);
    const [results, setResults] = useState<AnalysisResults>();

    const wavesurfer = useWavesurfer(
        waveRef,
    );
    const duration = useDuration(wavesurfer);
    const wsRegions = useRegions(wavesurfer);

    const extractRegions = (audioData: Float32Array, duration: number) => {
        const minValue = 0.06;
        const minSilenceDuration = 0.25;
        const mergeDuration = 0.01;
        const scale = duration / audioData.length;

        let start = 0;
        let end = 0;
        let isSilent = false;
        const silentRegions = audioData.reduce((
            acc,
            curr: number,
            index: number,
        ) => {
            if (fixFloat(Math.abs(curr)) <= minValue && !isSilent) {
                start = index;
                isSilent = true;
            } else if (fixFloat(Math.abs(curr)) > minValue && isSilent) {
                end = index;
                isSilent = false;
                if (scale * (end - start) > minSilenceDuration) {
                    acc.push({
                        start: scale * start,
                        end: scale * end,
                    });
                }
            } else if (isSilent && index === audioData.length - 1) {
                end = index;
                isSilent = false;
                if (scale * (end - start) > minSilenceDuration) {
                    acc.push({
                        start: scale * start,
                        end: scale * end,
                    });
                }
            }
            return acc;
        }, [] as Array<{ start: number; end: number }>);

        // Merge silent regions that are close together
        const mergedRegions = silentRegions.reduce((acc, curr) => {
            if (acc.length - 1 >= 0) {
                const lastRegion = acc[acc.length - 1];
                if (curr.start - lastRegion.end < mergeDuration) {
                    lastRegion.end = curr.end;
                } else {
                    acc.push(curr);
                }
            } else {
                acc.push(curr);
            }
            return acc;
        }, [] as Array<{ start: number; end: number }>);

        const regions = mergedRegions
            .reduce((acc, curr, index) => {
                if (index - 1 >= 0) {
                    const prevRegion = mergedRegions[index - 1];
                    acc.push({
                        start: prevRegion.end,
                        end: curr.start,
                    });
                }
                return acc;
            }, [] as Array<{ start: number; end: number }>);

        return {
            noSilent: regions,
            silent: mergedRegions,
        };
    };

    useEffect(() => {
        if (wavesurfer) {
            wavesurfer?.on('decode', (duration) => {
                const decodedData = wavesurfer.getDecodedData();
                if (decodedData) {
                    const {
                        silent,
                        noSilent,
                    } = extractRegions(decodedData.getChannelData(0), duration);
                    setResults({
                        silent,
                        noSilent,
                        assertAgainst: {
                            startTimes: noSilent.filter((region, index) => {
                                if (index === 0) {
                                    return true;
                                }
                                const prevRegion = noSilent[index - 1];
                                return region.start - prevRegion.end >= 1.5;
                            }).map((region) => Math.round(region.start * 1000)),
                            resumeTimes: noSilent.filter((region, index) => {
                                if (index === 0) {
                                    return false;
                                }
                                const prevRegion = noSilent[index - 1];
                                return region.start - prevRegion.end < 1.5
                                    && region.start - prevRegion.end >= 0.5;
                            }).map((region) => Math.round(region.start * 1000)),
                            endTimes: noSilent.filter((region, index) => {
                                if (index + 1 >= noSilent.length) {
                                    return true;
                                }
                                const nextRegion = noSilent[index + 1];
                                return nextRegion.start - region.end >= 1.5;
                            }).map((region) => Math.round(region.end * 1000)),
                            pauseTimes: noSilent.filter((region, index) => {
                                if (index + 1 >= noSilent.length) {
                                    return true;
                                }
                                const nextRegion = noSilent[index + 1];
                                return nextRegion.start - region.end >= 0.5;
                            }).map((region) => Math.round(region.end * 1000)),
                        },
                    });
                    silent.forEach((region, index) => {
                        const silentRegion = wsRegions?.addRegion({
                            start: region.start,
                            end: region.end,
                            content: index.toString(),
                            drag: false,
                            resize: false,
                            id: 'silent',
                        });
                        silentRegion?.element.addEventListener('mouseenter', () => {
                            regionRef.current = silentRegion.element;
                            setRegionDetails({
                                role: 'silent',
                                start: region.start,
                                end: region.end,
                            });
                        });
                        silentRegion?.element.addEventListener('mouseleave', () => {
                            regionRef.current = null;
                            setRegionDetails(undefined);
                        });
                    });

                    noSilent.forEach((region, index) => {
                        const noSilentRegion = wsRegions?.addRegion({
                            start: region.start,
                            end: region.end,
                            content: index.toString(),
                            drag: false,
                            resize: false,
                            id: 'no-silent',
                        });
                        noSilentRegion?.element.addEventListener('mouseenter', () => {
                            regionRef.current = noSilentRegion.element;
                            setRegionDetails({
                                role: 'user',
                                start: region.start,
                                end: region.end,
                            });
                        });
                        noSilentRegion?.element.addEventListener('mouseleave', () => {
                            regionRef.current = null;
                            setRegionDetails(undefined);
                        });
                    });
                }
            });
        }
    }, [wavesurfer, wsRegions]);

    const onPlayClick = useCallback(() => {
        if (wavesurfer) {
            if (wavesurfer.isPlaying()) {
                wavesurfer.pause();
            } else {
                wavesurfer.play();
            }
        }
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer) {
            setCurrentTime(0);
            setIsPlaying(false);

            const subscriptions = [
                wavesurfer.on('play', () => setIsPlaying(true)),
                wavesurfer.on('pause', () => setIsPlaying(false)),
                wavesurfer.on('audioprocess', (time: number) => setCurrentTime(time)),
            ];

            return () => {
                subscriptions.forEach((unsub) => unsub());
            };
        }
        return undefined;
    }, [wavesurfer]);

    const updateZoom = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (wavesurfer) {
            const nextZoom = Number(event.target.value);
            setZoom(nextZoom);
            wavesurfer.zoom(nextZoom);
        }
    };

    const loadFile = (files: FileList) => {
        if (wsRegions) {
            wsRegions.clearRegions();
        }
        if (wavesurfer) {
            wavesurfer.empty();
            if (files) {
                const file = files[0];
                if (file) {
                    const objectURL = URL.createObjectURL(file);
                    wavesurfer?.load(objectURL);
                } else {
                    setResults(undefined);
                }
            }
        }
    };
    return (
        <>
            <FileSelector
                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                label="File selector"
                placeholder="Drag & Drop or Click to Browse"
                bottomHelpText="Select only one file"
                variant="multiline"
                accept=".wav"
                onChange={loadFile}
            />
            <Container>
                <Header>
                    <Controls>
                        <PlayButton onClick={onPlayClick} variant="brand" size="small" icon={isPlaying ? <Pause /> : <Play />} />
                        <Timer>
                            {currentTime.toFixed(2)}
                            {' '}
                            /
                            {' '}
                            {duration}
                        </Timer>
                    </Controls>
                    <Zoom htmlFor="zoom">
                        Zoom:
                        {' '}
                        <Slider label="Zoom" hideLabel min="0" max="100" value={zoom} onChange={updateZoom} />
                    </Zoom>
                </Header>
                <WaveformGlobalStyle />
                <Wave ref={waveRef} id="wave" />
                <InternalOverlay
                    isVisible={!!regionDetails}
                    render={() => (
                        <RegionDetails
                            {...regionDetails}
                        />
                    )}
                    triggerElementRef={() => regionRef}
                />
                <RenderIf isTrue={!!results}>
                    <ReactJson src={results || {}} collapsed />
                </RenderIf>
            </Container>
        </>
    );
};

export default AudioAnalyzer;
