import { useCallback, useContext, useRef } from 'react';
import { Button } from 'react-rainbow-components';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import { Field, UniversalForm } from '@rainbow-modules/forms';
import { isEmpty } from '@rainbow-modules/validation';
import Label from 'components/LabelWithDescription';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { PageHeaderTitle } from 'components/styled';
import context, { Context } from '../context';
import {
    Container,
    Content,
    InfoPanel,
    StyledTextarea,
    ChipsContainer,
    StyledChip,
} from './styled';

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const AgentSystemMessage = () => {
    const { agentData } = useContext<Context>(context);
    const inputRef = useRef();

    const {
        mutate: updateSystemMessage,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentData?.id}`,
        method: 'PATCH',
        server: process.env.REACT_APP_BASE_URL,
    });

    const insertTemplateVariable = useCallback((code: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const textareaRef = inputRef.current?.fieldRef.current?.textareaRef.current;
        const cursorPosition = textareaRef.selectionStart;
        const textBeforeCursorPosition = textareaRef.value.substring(0, cursorPosition);
        // eslint-disable-next-line max-len
        const textAfterCursorPosition = textareaRef.value.substring(cursorPosition, textareaRef.value.length);
        // textareaRef.value = `${textBeforeCursorPosition}${code}${textAfterCursorPosition}`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef.current?.fieldRef.current?.props.onChange(
            `${textBeforeCursorPosition}${code}${textAfterCursorPosition}`,
        );
        textareaRef.focus();
        // textareaRef.dispatchEvent(new Event('input', { bubbles: true }));
    }, []);

    const handleSubmit = useCallback(async (values: Record<string, unknown>) => {
        try {
            showAppSpinner();
            await updateSystemMessage({
                body: values,
            });
        } catch (error) {
            // no catch
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [updateSystemMessage]);

    return (
        <>
            <PageHeaderTitle>System Message</PageHeaderTitle>
            <Container>
                <Content>
                    <UniversalForm
                        id="agent-system-message-form"
                        initialValues={{
                            systemMessageTemplate: agentData?.systemMessageTemplate,
                        }}
                        onSubmit={handleSubmit}
                    >
                        <Field
                            ref={inputRef}
                            component={StyledTextarea}
                            name="systemMessageTemplate"
                            required
                            placeholder="System Message used when the agent was created."
                            borderRadius="semi-rounded"
                            validate={validateNotEmpty}
                        />
                        <div className="rainbow-flex rainbow-justify_end">
                            <Button
                                type="submit"
                                form="agent-system-message-form"
                                variant="brand"
                                borderRadius="semi-rounded"
                                label="Save"
                                size="small"
                            />
                        </div>
                    </UniversalForm>
                </Content>
                <InfoPanel>
                    <Label
                        name="Template Variables"
                        description="Click the variables in order to insert it on the system message."
                    />
                    <ChipsContainer>
                        <StyledChip
                            label="language"
                            borderRadius="semi-rounded"
                            variant="base"
                            size="small"
                            onClick={() => insertTemplateVariable('{language}')}
                        />
                        <StyledChip
                            label="company_name"
                            borderRadius="semi-rounded"
                            variant="base"
                            size="small"
                            onClick={() => insertTemplateVariable('{company_name}')}
                        />
                        <StyledChip
                            label="company_address"
                            borderRadius="semi-rounded"
                            variant="base"
                            size="small"
                            onClick={() => insertTemplateVariable('{company_address}')}
                        />
                        <StyledChip
                            label="customer_phone_number"
                            borderRadius="semi-rounded"
                            variant="base"
                            size="small"
                            onClick={() => insertTemplateVariable('{customer_phone_number}')}
                        />
                    </ChipsContainer>
                </InfoPanel>
            </Container>
        </>
    );
};

export default AgentSystemMessage;
