import { useParams } from 'react-router-dom';
import { Button, Input } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { MagnifyingGlass } from '@rainbow-modules/icons';
import { ADD_CAMPAIGN_DRAWER } from '../../../../../constants';
import {
    SectionHeader, StyledDrawer,
} from './styled';
import CampaignsList from './List';
import AddCampaignForm from './AddCampaignForm';

const Campaigns = () => {
    const { agentId = '' } = useParams();

    const addCampaignDrawerProps = useConnectModal(
        ADD_CAMPAIGN_DRAWER,
        { size: 'large', slideFrom: 'right' },
    );
    const [openCreationDrawer, closeCreationDrawer] = useOpenModal(ADD_CAMPAIGN_DRAWER);
    return (
        <>
            <SectionHeader>
                <Input
                    borderRadius="semi-rounded"
                    size="small"
                    placeholder="Find a campaign..."
                    icon={<MagnifyingGlass />}
                />
                <Button
                    label="Send New Campaign"
                    variant="brand"
                    borderRadius="semi-rounded"
                    size="small"
                    onClick={() => openCreationDrawer({
                        agentId,
                    })}
                />
            </SectionHeader>
            <CampaignsList agentId={agentId} />
            <StyledDrawer
                header="Send Campaign"
                {...addCampaignDrawerProps}
                onRequestClose={closeCreationDrawer}
            >
                <AddCampaignForm agentId={addCampaignDrawerProps.agentId as string} />
            </StyledDrawer>
        </>
    );
};

export default Campaigns;
