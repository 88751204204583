import { useLocation, Outlet } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';
import { Warning as WarningIcon } from '@rainbow-modules/icons';
import useCanAccessDashboard from 'hooks/useCanAccessDashboard';
import extractSectionName from './helpers/extractSectionName';
import {
    Container,
    RightContent,
    SideBarContainer,
    Bar,
    StyledLogo,
    SideBarItem,
    FolderIcon,
    ChatIcon,
    ClockIcon,
    AgentIcon,
    ChartIcon,
    ModelIcon,
    MessageContainer,
} from './styled';

const App = () => {
    const { pathname } = useLocation();
    const activeSection = extractSectionName(pathname);
    const { isLoading, canAccessDashboard } = useCanAccessDashboard();

    if (isLoading) {
        return (
            <Container>
                <Spinner variant="brand" type="arc" />
            </Container>
        );
    }

    if (!canAccessDashboard) {
        return (
            <Container>
                <MessageContainer>
                    <WarningIcon />
                    <h3>Access Restricted</h3>
                </MessageContainer>
            </Container>
        );
    }

    return (
        <Container>
            <SideBarContainer>
                <StyledLogo />
                <Bar selectedItem={activeSection} hideSelectedItemIndicator>
                    <SideBarItem
                        name="dataset"
                        path="/dataset/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<FolderIcon />}
                        label="Dataset"
                        $isSelected={activeSection === 'dataset'}
                    />
                    <SideBarItem
                        name="models"
                        path="/models/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<ModelIcon />}
                        label="Models"
                        $isSelected={activeSection === 'models'}
                    />
                    <SideBarItem
                        name="history"
                        path="/history/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<ClockIcon />}
                        label="History"
                        $isSelected={activeSection === 'history'}
                    />
                    {/* <SideBarItem
                        name="tests"
                        path="/tests/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<MatrassIcon />}
                        label="Tests"
                        $isSelected={activeSection === 'tests'}
                    /> */}
                    <SideBarItem
                        name="chat-simulator"
                        path="/chat-simulator/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<ChatIcon />}
                        label="Chat"
                        $isSelected={activeSection === 'chat-simulator'}
                    />
                    <SideBarItem
                        name="agents"
                        path="/agents/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<AgentIcon />}
                        label="Agents"
                        $isSelected={activeSection === 'agents'}
                    />
                    <SideBarItem
                        name="insights"
                        path="/insights/"
                        className="rainbow-m-bottom_medium rainbow-m-top_large"
                        icon={<ChartIcon />}
                        label="Insights"
                        $isSelected={activeSection === 'insights'}
                    />
                </Bar>
            </SideBarContainer>
            <RightContent>
                <Outlet />
            </RightContent>
        </Container>
    );
};

export default App;
