import { MultiSelect } from 'react-rainbow-components';
import styled from 'styled-components';

export const StyledMultiSelect = styled(MultiSelect)`
    height: 1.9rem;

    label + div {
        padding: 0;
    }

    button {
        margin: auto 0.25rem;
    }
`;
