import { IconProps } from '../types';

const Neutral = ({ title = 'Neutral', className }: IconProps) => (
    <svg className={className} width={16} height={15} viewBox="0 0 16 15">
        <title>{title}</title>
        <path d="M7.78175 15C4.03846 15 0 12.6521 0 7.5C0 2.34794 4.03846 0 7.78175 0C9.86136 0 11.78 0.684257 13.2021 1.93202C14.7451 3.30054 15.5635 5.23256 15.5635 7.5C15.5635 9.76744 14.7451 11.686 13.2021 13.0546C11.78 14.3023 9.84794 15 7.78175 15Z" fill="url(#paint0_radial_102_11904)" />
        <path d="M14.2071 3.04199C14.9222 4.19584 15.2952 5.56972 15.2952 7.0979C15.2952 9.36534 14.4767 11.2839 12.9338 12.6525C11.5116 13.9002 9.57961 14.5979 7.51342 14.5979C5.09034 14.5979 2.54919 13.6118 1.04919 11.5201C2.49284 13.8895 5.20841 15.0004 7.78175 15.0004C9.84794 15.0004 11.78 14.3027 13.2021 13.055C14.7451 11.6864 15.5635 9.76784 15.5635 7.5004C15.5635 5.7911 15.0979 4.27231 14.2071 3.04199Z" fill="#EB8F00" />
        <path d="M11.1896 10.8673H4.48119C4.18468 10.8673 3.94452 10.6271 3.94452 10.3306C3.94452 10.0341 4.18468 9.79395 4.48119 9.79395H11.1896C11.4861 9.79395 11.7263 10.0341 11.7263 10.3306C11.7263 10.6271 11.4861 10.8673 11.1896 10.8673Z" fill="#422B0D" />
        <path d="M5.24195 5.22461C4.67979 5.22461 4.16861 5.69956 4.16861 6.48847C4.16861 7.27738 4.67979 7.751 5.24195 7.751C5.80412 7.751 6.3153 7.27604 6.3153 6.48847C6.3153 5.70091 5.80412 5.22461 5.24195 5.22461Z" fill="#422B0D" />
        <path d="M5.18967 5.75147C4.99915 5.66023 4.76972 5.74073 4.67715 5.93125C4.60604 6.08018 4.63824 6.25862 4.75765 6.37401C4.94817 6.46524 5.1776 6.38474 5.27017 6.19422C5.34128 6.04529 5.30908 5.86685 5.18967 5.75147Z" fill="#896024" />
        <path d="M10.3847 5.22461C9.82252 5.22461 9.31134 5.69956 9.31134 6.48847C9.31134 7.27738 9.82252 7.751 10.3847 7.751C10.9469 7.751 11.458 7.27604 11.458 6.48847C11.458 5.70091 10.9495 5.22461 10.3847 5.22461Z" fill="#422B0D" />
        <path d="M10.3351 5.75147C10.1446 5.66023 9.91513 5.74073 9.82255 5.93125C9.75144 6.08018 9.78364 6.25862 9.90305 6.37401C10.0936 6.46524 10.323 6.38474 10.4156 6.19422C10.4867 6.04529 10.4545 5.86685 10.3351 5.75147Z" fill="#896024" />
        <defs>
            <radialGradient id="paint0_radial_102_11904" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78175 7.49999) scale(7.64218)">
                <stop offset="0.5" stopColor="#FDE030" />
                <stop offset="0.92" stopColor="#F7C02B" />
                <stop offset="1" stopColor="#F4A223" />
            </radialGradient>
        </defs>
    </svg>
);

export default Neutral;
