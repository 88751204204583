import { Field, isRequired } from '@rainbow-modules/forms';
import { Input } from 'react-rainbow-components';
import RoleSelect from './roleSelect';
import { Container, MessageContainer } from './styled';
import MessageContent from './message';

const BuildingBlockForm = () => (
    <Container>
        <Field
            name="name"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Input}
            label="Name"
            required
            placeholder="Enter a name for the building block"
            className="rainbow-m-bottom_large"
            borderRadius="semi-rounded"
            labelAlignment="left"
            validate={isRequired('Please enter a name.')}
        />
        <MessageContainer>
            <Field
                name="role"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={RoleSelect}
                required
            />
            <Field
                name="content"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={MessageContent}
                required
                validate={isRequired('Please enter a message.')}
            />
        </MessageContainer>
    </Container>
);

export default BuildingBlockForm;
