import { useContext, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import ConversationEvents from 'components/ConversationEvents';
import context, { Context } from '../context';
import {
    Content,
    StyledSpinner,
} from '../styled';

const DebugView = () => {
    const { messages = [], isLoading } = useContext<Context>(context);

    const sortedEvents = useMemo(
        () => [...messages].sort(
            (a, b) => (a?.timeMark || 0) - (b?.timeMark || 0),
        ),
        [messages],
    );

    return (
        <Content>
            <RenderIf isTrue={isLoading}>
                <StyledSpinner size="small" type="arc" variant="brand" />
            </RenderIf>
            <RenderIf isTrue={!isLoading && messages?.length === 0}>
                <p>There are no messages in this session</p>
            </RenderIf>
            <RenderIf isTrue={!isLoading && messages?.length > 0}>
                <ConversationEvents data={sortedEvents} />
            </RenderIf>
        </Content>
    );
};

export default DebugView;
