import { IconProps } from './types';

const Slash = ({ title = 'Slash', className }: IconProps) => (
    <svg className={className} width={16} height={16} viewBox="0 0 16 16" fill="currentColor">
        <title>{title}</title>
        <g clipPath="url(#clip0_1546_15973)">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 2.33594C4.87055 2.33594 2.3335 4.87299 2.3335 8.0026C2.3335 11.1322 4.87055 13.6693 8.00016 13.6693C11.1298 13.6693 13.6668 11.1322 13.6668 8.0026C13.6668 4.87299 11.1298 2.33594 8.00016 2.33594ZM0.333496 8.0026C0.333496 3.76842 3.76598 0.335938 8.00016 0.335938C12.2343 0.335938 15.6668 3.76842 15.6668 8.0026C15.6668 12.2368 12.2343 15.6693 8.00016 15.6693C3.76598 15.6693 0.333496 12.2368 0.333496 8.0026Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.57951 2.58196C2.97004 2.19143 3.6032 2.19143 3.99373 2.58196L13.4204 12.0086C13.8109 12.3991 13.8109 13.0323 13.4204 13.4228C13.0299 13.8134 12.3967 13.8134 12.0062 13.4228L2.57951 3.99617C2.18899 3.60565 2.18899 2.97248 2.57951 2.58196Z" fill="currentColor" />
        </g>
    </svg>
);

export default Slash;
