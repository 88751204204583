/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Agent } from 'data/firestore/agent/types';
import { Twilioconfig } from 'data/firestore/twilioconfig/types';

export interface Context {
    agentData?: Agent | null;
    isLoading?: boolean;
    twilioConfig?: Twilioconfig | null
}

const context = React.createContext<Context>({
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
