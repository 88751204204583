const eventRegExp = /^(?:event:\s*(.+)\n)?data: (\s*.+)$/gm;

export default function readChunksAsJSON(reader: ReadableStreamDefaultReader) {
    return {
        async* [Symbol.asyncIterator]() {
            let readResult = await reader.read();
            while (!readResult.done) {
                const text = new TextDecoder().decode(readResult.value);
                let match = eventRegExp.exec(text);
                while (match !== null) {
                    const name = match[1] ? match[1].trim() : null;
                    const data = match[2];
                    yield {
                        name,
                        data,
                    };

                    match = eventRegExp.exec(text);
                }
                // eslint-disable-next-line no-await-in-loop
                readResult = await reader.read();
            }
        },
    };
}
