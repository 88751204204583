import login from 'data/services/auth/login';
import {
    Container,
    StyledGoogleIcon,
    StyledButton,
    Footer,
} from './styled';

const Login = () => {
    const handleLogin = async () => {
        try {
            await login();
        } catch (error: any) {
            // eslint-disable-next-line no-console
            console.error('Unable to login.');
        }
    };

    return (
        <Container>
            <StyledButton borderRadius="semi-rounded" variant="neutral" shaded onClick={handleLogin}>
                <StyledGoogleIcon />
                Continue with Google
            </StyledButton>
            <Footer><span>Company Name 2023</span></Footer>
        </Container>
    );
};

export default Login;
