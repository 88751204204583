import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import { color, getConfidenceColors, getConfidenceColor } from 'theme';

export const Container = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const ItemContainer = styled.li<{ isSelected?: boolean; onClick?: () => void | undefined }>`
    display: flex;
    padding: 0 8px;
    border-radius: 4px;

    ${(props) => props.onClick && `
        cursor: pointer;

        :hover {
            background-color: rgb(251, 249, 255);
        }
    `}

    ${(props) => props.isSelected && `
        background-color: rgb(251, 249, 255);
    `}

    :last-child {
        padding-bottom: 20px;
    }

`;

export const TimeMark = styled.span`
    min-width: 90px;
    font-size: 14px;
    color: ${color('text.header')};
    padding-top: 2px;
`;

export const Line = styled.div<{ isLastItem?: boolean; color?: string }>`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin-right: 16px;

    ${(props) => !props.isLastItem && `
        ::before {
            content: '';
            background-color: ${props.color || '#D8D8D8'};
            height: 100%;
            width: 1px;
            position: absolute;
            right: 50%;
            left: 50%;
        }
    `}
`;

export const IconContainer = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    min-width: 2rem;
`;

export const PhoneIconContainer = styled.span`
    width: 30px;
    height: 30px;
    background-color: #B8B4C1;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0px 0px 2px 0px #B8B4C1;
`;

export const EventCircle = styled.div<{ color?: string }>`
    border-radius: 50%;
    background-color: ${(props) => props.color || '#B8B4C1'};
    padding: 2px;
    box-shadow: 0px 0px 3px 0px ${(props) => props.color || '#B8B4C1'};
`;

export const Circle = styled.div<{ color?: string }>`
    border-radius: 50px;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.color || '#B8B4C1'};
    border: 3px solid white;
`;

export const Content = styled.div`
    min-height: 72px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2px;
`;

export const Label = styled.h2`
    font-size: 16px;
    color: ${color('text.label')};
`;

export const Duration = styled.h3`
    font-size: 12px;
    color: ${color('text.header')};
    margin: 12px 0;
`;

export const MessageContainer = styled.div`
    border: 1px solid ${color('border.divider')};
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-top: 4px;
`;

export const Row = styled.div`
    display: flex;
`;

export const RoleContainer = styled.div`
    width: 105px;
    min-width: 105px;
`;

export const RoleBadge = styled(Badge)<{ label: string }>`
    padding: 4px 8px;

    ${(props) => (props.label === 'assistant') && `
        color: ${color('brand.main')(props)};
        background: ${color('brand.light')(props)};
    `};

    ${(props) => (props.label === 'user') && `
        color: #F7C02B;
        background: rgba(247, 192, 43, 0.28);
    `};
`;

export const MessageContent = styled.p`
    font-size: 14px;
    color: ${color('text.label')};
`;

export const ContentContainer = styled.ul`
    padding: 24px 20px 24px 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const PayloadContainer = styled.div`
    min-width: 350px;
    width: 350px;
    flex-grow: 0;
    flex-shrink: 0;
    border-left: 1px solid ${color('border.divider')};
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 18px;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
`;

export const PayloadHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 20px;
    padding-right: 12px;
`;

export const PayloadTitle = styled.h1`
    font-size: 18px;
    color: ${color('text.label')};
`;

export const PayloadContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
`;

export const MetadataContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-self: flex-end;
    gap: 8px;
    margin-top: 12px;
`;

export const StyledBadge = styled(Badge)<{ value: number }>`
    flex-shrink: 0;
    ${getConfidenceColors}
    border-radius: 5px;
`;

export const NativeButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    &:hover {
        opacity: 0.8;
    }
`;

export const MoreDetailsContainer = styled.div`
    display: flex;
    padding: 0 8px 12px 8px;
    flex-grow: 1;
    width: 100%;
    margin-top: 12px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    background: ${color('background.main')};
    border: 1px solid ${color('border.disabled')};
    border-radius: 8px;
    width: 100%;
    padding: 12px;
`;

export const DescriptionText = styled.p`
    font-size: 14px;
    color: ${color('text.label')};
`;

export const DescriptionTitle = styled.h2`
    font-size: 14px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
`;

export const Word = styled.span<{ value?: number, isHover: boolean }>`
    ${getConfidenceColor}

    ${(props) => props.isHover && `
        background-color: rgb(230, 230, 230);
        border-radius: 2px;
    `}
`;
