/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { MultiSelectOption, MultiSelectProps } from 'react-rainbow-components/components/MultiSelect';
import { Option } from 'react-rainbow-components';
import { MultiselectVariantProps } from './types';
import { StyledMultiSelect } from './styled';

interface Props extends MultiselectVariantProps {
    value?: string[] | null;
    onChange?: (value: string[] | null) => void;
}

const AgentPickerVariantMultiple = (props: Props) => {
    const {
        value: valueInProps,
        isLoading = false,
        agents = [],
        onChange = () => {},
        ...otherProps
    } = useReduxForm(props);

    const multiSelectValue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps && valueInProps?.length > 0) {
                return valueInProps.reduce(
                    (result: Record<string, string>[], agentId) => {
                        const found = agents.find((agent) => agent.id === agentId);
                        if (found) {
                            const { companyName } = found;
                            result.push({
                                label: companyName,
                                name: agentId,
                                value: agentId,
                            });
                        }
                        return result;
                    },
                    [],
                );
            }
        }
        return null;
    }, [agents, isLoading, valueInProps]);

    const options = useMemo(
        () => agents.map(
            (agent) => (
                <Option
                    key={`agent__${agent.id}`}
                    label={agent.companyName}
                    name={agent.id}
                    value={agent.id}
                    searchableText={agent.companyName}
                />
            ),
        ),
        [agents],
    );

    const handleOnChange = useCallback(
        (newValue: MultiSelectOption[]) => onChange(
            newValue.map((val: MultiSelectOption) => val.value as string),
        ),
        [onChange],
    );

    return (
        <StyledMultiSelect
            {...otherProps as MultiSelectProps}
            enableSearch
            isLoading={isLoading}
            value={multiSelectValue as MultiSelectOption[]}
            onChange={handleOnChange}
            showCheckbox
        >
            {options}
        </StyledMultiSelect>
    );
};

export default AgentPickerVariantMultiple;
