import {
    Outlet,
    useMatch,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { ButtonIcon, Tab, Tabset } from 'react-rainbow-components';
import { Pencil, Trash } from 'components/icons';
import useOpenTreeFormModal from 'hooks/useOpenTreeFormModal';
import updateDataset from 'data/firestore/dataset/update';
import removeDataset from 'data/firestore/dataset/remove';
import useDataset from 'data/firestore/dataset/use';
import { confirmModal } from '@rainbow-modules/app';
import PageHeader from '../../../components/PageHeader';
import { Container } from './styled';

const Dataset = () => {
    const { datasetId = '' } = useParams();
    const [openModal, closeModal] = useOpenTreeFormModal();
    const navigate = useNavigate();
    const match = useMatch('dataset/:datasetId/:tabName');
    const dataset = useDataset(datasetId);
    const { name } = dataset.data || {};

    const openEditForm = () => openModal({
        title: 'Edit Dataset',
        submitButtonLabel: 'Update Dataset',
        entity: 'dataset',
        initialValues: {
            name,
        },
        onSubmit: (values: { name: string }) => {
            updateDataset(datasetId, { name: values.name });
            closeModal();
        },
    });

    const openDeleteConfirmation = async () => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Dataset',
            question:
                'Removing this dataset will delete it permanently. Are you sure you want to remove this dataset?',
            okButtonLabel: 'Delete',
        });
        if (result) {
            removeDataset(datasetId);
            navigate('/');
        }
    };
    return (
        <Container>
            <PageHeader
                title={name}
                actions={(
                    <>
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Pencil />}
                            onClick={openEditForm}
                        />
                        <ButtonIcon
                            borderRadius="semi-rounded"
                            icon={<Trash />}
                            onClick={openDeleteConfirmation}
                        />
                    </>
                )}
            />
            <div className="rainbow-m-horizontal_medium">
                <Tabset
                    variant="line"
                    onSelect={(event, eventName) => {
                        navigate(eventName);
                        event.preventDefault();
                    }}
                    activeTabName={match?.params?.tabName}
                >
                    <Tab name="groups" label="Groups" />
                    <Tab name="building-blocks" label="Building Blocks" />
                    <Tab name="settings" label="Settings" />
                </Tabset>
            </div>
            <Outlet />
        </Container>
    );
};

export default Dataset;
