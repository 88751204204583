import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    CartesianGrid,
    LabelList,
} from 'recharts';
import { RenderIf } from 'react-rainbow-components';
// import { useParams } from 'react-router-dom';
import { PageHeaderTitle } from 'components/styled';
import { Row, LeyendBadge } from './styled';
import { SectionHeader, SectionDescription, ChartContainer } from '../styled';

const fakeData = [
    { id: 'abc34', duration: 3 },
    { id: 'abc34', duration: 8 },
    { id: 'abc34', duration: 5 },
    { id: 'abc34', duration: 13 },
    { id: 'abc34', duration: 28 },
    { id: 'abc34', duration: 25 },
];

const UserPerformance = () => {
    const isLoading = false;
    return (
        <>
            <SectionHeader>
                <PageHeaderTitle>Average user response time per call</PageHeaderTitle>
                <SectionDescription>
                    Lorem impsu dolor sit amet, consectetur adipiscing elit.
                </SectionDescription>
            </SectionHeader>
            <ChartContainer>
                <Row>
                    <RenderIf isTrue={!isLoading}>
                        <LeyendBadge label={`Total: ${1452}`} borderRadius="semi-square" />
                    </RenderIf>
                </Row>
                <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                        data={fakeData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 35,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="id" angle={-40} textAnchor="end" />
                        <YAxis
                            dataKey="duration"
                            label={{ value: 'Average answer time', angle: -90 }}
                        />
                        <Tooltip />
                        <Legend verticalAlign="top" layout="horizontal" name="Total calls" />
                        <Bar dataKey="duration" fill="#8884d8">
                            <LabelList dataKey="duration" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </ChartContainer>
        </>
    );
};

export default UserPerformance;
