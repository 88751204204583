import { useState } from 'react';
import { Field, isRequired } from '@rainbow-modules/forms';
import { useFormState } from 'react-final-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidationErrors } from 'final-form';
import { TwilioResponse } from 'types';
import { RenderIf } from 'react-rainbow-components';
import useHttpQuery from 'data/firestore/useHttpQuery';
import SelectStateAndAreaCode from './selectStateAndAreaCode';
import AvailablePhoneNumbersList from './availablePhoneNumbers';
import IsLoadingStateAndAreaCode from './loadingcard';
import NotAvailableNumbersMessage from './notAvailableNumbersMessage';

const gethasErrorStateAndAreaCode = (
    errors: ValidationErrors,
) => !!(errors?.states || errors?.areaCode);

const gethasErrorStateAndAreaCodeWhenTouched = (errors: ValidationErrors, touched: boolean) => {
    if (touched) {
        return !!(errors?.states || errors?.areaCode);
    }
    return false;
};

const gethasErrorAvailableNumbers = (
    errors: ValidationErrors,
    availableNumbers: TwilioResponse[],
    submitFailed: boolean,
) => {
    if (availableNumbers.length === 0 && errors) {
        return Object.entries(errors).length === 1 && errors?.phoneNumber && submitFailed;
    }

    return errors?.phoneNumber && submitFailed && !gethasErrorStateAndAreaCode(errors);
};

const getErrorMessage = (errors: ValidationErrors) => {
    if (errors?.states && errors?.areaCode) {
        return 'You must select a state and area code to see the available phone numbers you can select for your group';
    }
    if (errors?.states) {
        return 'You must select a state to see the available phone numbers you can select for your group';
    }
    if (errors?.areaCode) {
        return 'You must enter an area code to see the available phone numbers you can select for your group';
    }
    return '';
};

const SelectPhoneNumberForm = () => {
    const [availableNumbers, setAvailableNumbers] = useState<TwilioResponse[]>([]);
    const [selectedNumber, setSelectedNumber] = useState<TwilioResponse>({} as TwilioResponse);
    const [emptyAfterSearch, setEmptyAfterSearch] = useState(false);

    const formState = useFormState();
    const { values, errors, submitFailed } = formState;
    const { states, areaCode } = values;

    const hasError = gethasErrorStateAndAreaCode(errors);
    const hasErrorWithTouched = gethasErrorStateAndAreaCodeWhenTouched(
        errors,
        formState.touched?.areaCode as boolean,
    );
    const hasErrorStateAndAreaCode = (hasError && submitFailed) || hasErrorWithTouched;
    const hasErrorPickPhoneNumber = gethasErrorAvailableNumbers(
        errors,
        availableNumbers,
        submitFailed,
    );

    const {
        // data: phones = [],
        // isLoading: isLoadingPhones,
        refetch: fetAvailableNumbers,
    } = useHttpQuery({
        key: 'twilio-available-phones-country',
        pathname: `/phone-numbers/sale/US?pageSize=3&regionCode=${states?.value as string}&areaCode=${areaCode}`,
        // queryOptions: {
        //     retryOnMount: false,
        //     refetchOnMount: false,
        //     enabled: false,
        // },
    });

    const [isLoading, setIsLoading] = useState(false);

    const onSearch = async () => {
        if (areaCode === '' || Object.entries(states).length === 0) {
            return;
        }
        setSelectedNumber({} as TwilioResponse);
        setIsLoading(true);
        const response = await fetAvailableNumbers({
            cancelRefetch: true,
        });
        const data = (response.data as { [key:string]: unknown }[]).map(
            (item) => ({
                number: item.number,
                ...(item.address as { [key:string]: unknown }),
            }),
        ) as TwilioResponse[];
        if (data.length === 0) {
            setEmptyAfterSearch(true);
        } else {
            setEmptyAfterSearch(false);
        }
        setIsLoading(false);
        setAvailableNumbers(data);
    };

    const onChangeSelectedNumber = (number: TwilioResponse) => {
        const numberToSelect = availableNumbers.find(
            (availablenumber) => availablenumber.number === number.number,
        );
        setSelectedNumber(numberToSelect as TwilioResponse);
    };

    return (
        <>
            <SelectStateAndAreaCode
                onSearchAvailableNumbers={onSearch}
                areaCode={areaCode}
                selectedState={states}
                errorMessage={getErrorMessage(errors)}
                showError={hasErrorStateAndAreaCode}
                buttonDisabled={hasError}
            />
            <RenderIf isTrue={isLoading}>
                <IsLoadingStateAndAreaCode />
            </RenderIf>
            <RenderIf isTrue={!isLoading && availableNumbers.length > 0 && !emptyAfterSearch}>
                <AvailablePhoneNumbersList
                    availableNumbers={availableNumbers}
                    onChange={(number: TwilioResponse) => onChangeSelectedNumber(number)}
                    value={selectedNumber}
                    showError={hasErrorPickPhoneNumber}
                />
            </RenderIf>
            <RenderIf isTrue={!isLoading && availableNumbers.length === 0 && emptyAfterSearch}>
                <NotAvailableNumbersMessage />
            </RenderIf>
            <Field
                name="phoneNumber"
                initialValue={selectedNumber.number}
                required
                validate={isRequired()}
            >
                {({ input }) => <input type="hidden" {...input} />}
            </Field>
        </>
    );
};

export default SelectPhoneNumberForm;
