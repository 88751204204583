/* eslint-disable no-restricted-syntax, no-await-in-loop */
import { useMutation } from 'react-query';
import { auth } from 'data/firestore/firebase';
import { Method } from 'data/firestore/types';
import { useState } from 'react';

export interface UseMutationParams<Res> {
    pathname?: string;
    method?: Method;
    server?: string;
    onSuccess?: (data: Res) => void;
    onError?: (error: Error) => void;
}

export interface MutationParams<Req, Res>
    extends Pick<UseMutationParams<Res>, 'pathname'> {
    body?: Req;
}

const createRequestHeaders = async () => {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };
    if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }
    return headers;
};

function tryParseJSON(data: string) {
    try {
        return JSON.parse(data);
    } catch (e) {
        return undefined;
    }
}

function readChunksAsJSON(reader: ReadableStreamDefaultReader) {
    return {
        async* [Symbol.asyncIterator]() {
            let readResult = await reader.read();
            while (!readResult.done) {
                const subChunks = new TextDecoder().decode(readResult.value).split(/(?<=})\n\ndata: (?={)/);
                for (const subChunk of subChunks) {
                    yield tryParseJSON(subChunk.replace(/^data: /, ''));
                }
                readResult = await reader.read();
            }
        },
    };
}

const useHttpMutationStream = <Req, Res>(params: UseMutationParams<Res>) => {
    const {
        pathname: hookPathname,
        method = 'POST',
        server = 'http://localhost:8080',
        onSuccess = () => { },
        onError = () => { },
    } = params;
    const [messageStream, updateMessageStream] = useState<string>('');
    return {
        messageStream,
        ...useMutation<Res, Error, MutationParams<Req, string>, unknown>(
            async (mParams: MutationParams<Req, string>): Promise<Res> => {
                const { body, pathname: mutationPathname } = mParams;
                const pathname = mutationPathname || hookPathname;
                if (!pathname) {
                    throw new Error('No pathname provided');
                }
                const url = new URL(pathname, server).toString();
                const response = await fetch(url, {
                    method,
                    headers: await createRequestHeaders(),
                    body: body ? JSON.stringify(body) : null,
                });
                if (response.ok) {
                    const reader = response.body?.getReader();
                    const chunks = [];
                    for await (
                        const { chunk } of readChunksAsJSON(reader as ReadableStreamDefaultReader)
                    ) {
                        chunks.push(chunk);
                        updateMessageStream(chunks.join(''));
                    }
                    updateMessageStream('');
                    return Promise.resolve({
                        content: chunks.join(''),
                    }) as Promise<Res>;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            },
            {
                onSuccess,
                onError,
            },
        ),
    };
};

export default useHttpMutationStream;
