import {
    ClockIcon,
    StyledSpinner,
    CheckmarkIcon,
    ErrorIcon,
    StyledBadge,
} from './styled';

interface IconMap {
    [key: string]: React.ComponentType;
}

const SpinerIcon = () => <StyledSpinner size="xx-small" type="arc" variant="brand" />;

const iconMap: IconMap = {
    'ready-to-train': ClockIcon,
    'processing-data': SpinerIcon,
    training: SpinerIcon,
    error: ErrorIcon,
    ready: CheckmarkIcon,
};

interface VersionStatusBadgeProps {
    status?: string;
}

const VersionStatusBadge = (props: VersionStatusBadgeProps) => {
    const { status } = props;
    if (status != null) {
        const Icon = iconMap[status.toLowerCase()] || iconMap.moderate;

        return (
            <StyledBadge status={status} borderRadius="semi-square">
                <Icon />
                <span className="rainbow-m-left_x-small">
                    {status}
                </span>
            </StyledBadge>
        );
    }
    return null;
};

export default VersionStatusBadge;
