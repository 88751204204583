/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable no-multi-spaces */
import { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';

interface TreeProps {
    data: any;
}

interface NodeData {
    name: string;
}

const marginTop = 10;
const marginBottom = 10;
const marginLeft = 40;

const Tree = ({ data }: TreeProps) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const gRef = useRef<SVGGElement>(null);
    const nodes = d3.hierarchy<NodeData>(data);

    const linkMaker = d3.linkHorizontal().x((d) => d[1]).y((d) => d[0]);

    const update = useCallback((node: d3.HierarchyNode<NodeData>) => {
        const tree = d3.tree<NodeData>().nodeSize([25, 150])(node);
        const g = d3.select(gRef.current);

        // Calculate minimum and maximum x values across all nodes
        const xValues = tree.descendants().map((n) => n.x);
        const xMin = Math.min(...xValues);
        const xMax = Math.max(...xValues);

        // Calculate tree dimensions
        const treeHeight = xMax - xMin + marginTop + marginBottom;

        // Calculate vertical shift needed to center the tree
        const containerHeight = 600; // Or whatever your SVG container height is
        const verticalShift = (containerHeight - treeHeight) / 2;

        if (svgRef.current) {
            svgRef.current.setAttribute('height', containerHeight.toString());
        }

        // Transform the <g> element to vertically center the tree
        g.attr('transform', `translate(${marginLeft},${marginTop + verticalShift})`);

        g.selectAll('path').data(tree.links()).enter()
            .append('path')
            .attr('d', (d) => {
                const sourceX = d.source.x - 7.5;
                const sourceY = d.source.y + 70;
                const targetX = d.target.x - 7.5;
                const targetY = d.target.y - 30;
                return linkMaker({
                    source: [sourceX, sourceY],
                    target: [targetX, targetY],
                });
            })
            .attr('fill', 'none')
            .attr('stroke', '#555')
            .attr('stroke-opacity', '0.4')
            .attr('stroke-width', '0.3');

        g.selectAll('rect')
            .data(tree.descendants())
            .enter()
            .append('rect')
            .attr('x', (d) => d.y - 30) // half the rect width
            .attr('y', (d) => d.x - 15) // half the rect height
            .attr('width', 100)
            .attr('height', 15)
            .attr('rx', 4) // corner radius
            .attr('ry', 4) // corner radius
            .attr('fill', 'white')
            .attr('stroke', 'black');

        g.selectAll('text')
            .data(tree.descendants())
            .enter()
            .append('text')
            .attr('x', (d) => d.y - 25)
            .attr('y', (d) => d.x - 4)
            .attr('text-anchor', 'start')
            .attr('width', 50)
            .text((d) => d.data.name)
            .style('font-size', '10px')
            .style('overflow', 'hidden')
            .style('text-overflow', 'ellipsis')
            .style('white-space', 'nowrap')
            .text((d) => {
                const text = d.data.name;
                const maxLength = 15;
                return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
            });
    }, [linkMaker]);

    useEffect(() => {
        update(nodes);
    }, [update, nodes]);
    return (
        <div style={{ overflow: 'auto', width: '100%', height: '600px' }}>
            <svg
                width="100%"
                height="600"
                style={{
                    maxWidth: '100%',
                    height: 'auto',
                    font: '10px sans-serif',
                    userSelect: 'none',
                }}
                ref={svgRef}
            >
                <g cursor="pointer" pointerEvents="all" ref={gRef} />
            </svg>
        </div>
    );
};

export default Tree;
