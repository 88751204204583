import styled from 'styled-components';
import { color } from 'theme';

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 12px 0;
    gap: 32px;
`;

export const SideBar = styled.div`
    width: 250px;
    padding: 10px;
    box-sizing: border-box;
    gap: 1px;
    display: inline-flex;
    flex-direction: column;
`;

export const RightContent = styled.div`
    box-sizing: border-box;
    flex-grow: 1;
    margin-right: 16px;
`;

export const SectionHeader = styled.header`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    margin-top: 20px;
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;

export const ChartContainer = styled.div`
    background: #F7F9FC;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0;
    margin-bottom: 40px;
    height: 450px;
`;
