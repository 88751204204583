import { IconProps } from './types';

const NewFolder = ({ title = 'new folder', className }: IconProps) => (
    <svg className={className} width={22} height={20} viewBox="0 0 22 20" fill="none">
        <title>{title}</title>
        <path
            fill="currentColor"
            d="M3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V17C1.5 17.3978 1.65804 17.7794 1.93934 18.0607C2.22064 18.342 2.60218 18.5 3 18.5H19C19.3978 18.5 19.7794 18.342 20.0607 18.0607C20.342 17.7794 20.5 17.3978 20.5 17V6C20.5 5.60218 20.342 5.22064 20.0607 4.93934C19.7794 4.65804 19.3978 4.5 19 4.5H10C9.83282 4.5 9.67671 4.41645 9.58397 4.27735L7.73241 1.5H3ZM1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H8C8.16718 0.5 8.32329 0.583551 8.41603 0.72265L10.2676 3.5H19C19.663 3.5 20.2989 3.76339 20.7678 4.23223C21.2366 4.70107 21.5 5.33696 21.5 6V17C21.5 17.663 21.2366 18.2989 20.7678 18.7678C20.2989 19.2366 19.663 19.5 19 19.5H3C2.33696 19.5 1.70107 19.2366 1.23223 18.7678C0.763392 18.2989 0.5 17.663 0.5 17V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223Z"
        />
        <path
            fill="currentColor"
            d="M11 8.08334C11.2761 8.08334 11.5 8.3072 11.5 8.58334V14.4167C11.5 14.6928 11.2761 14.9167 11 14.9167C10.7239 14.9167 10.5 14.6928 10.5 14.4167V8.58334C10.5 8.3072 10.7239 8.08334 11 8.08334Z"
        />
        <path
            fill="currentColor"
            d="M7.58334 11.5C7.58334 11.2239 7.8072 11 8.08334 11H13.9167C14.1928 11 14.4167 11.2239 14.4167 11.5C14.4167 11.7761 14.1928 12 13.9167 12H8.08334C7.8072 12 7.58334 11.7761 7.58334 11.5Z"
        />
    </svg>
);

export default NewFolder;
