import { IconProps } from './types';

const Translate = ({ title = 'AB Letters', className }: IconProps) => (
    <svg className={className} width={18} height={18} viewBox="0 0 16 16">
        <title>{title}</title>
        <path
            d="M7.93332 14.6654L10.9667 6.66536H12.3667L15.4 14.6654H14L13.3 12.632H10.0667L9.33332 14.6654H7.93332ZM10.4667 11.4654H12.8667L11.7 8.16536H11.6333L10.4667 11.4654ZM2.66666 12.6654L1.73332 11.732L5.09999 8.36536C4.67777 7.8987 4.30832 7.41536 3.99166 6.91536C3.67499 6.41536 3.39999 5.88759 3.16666 5.33203H4.56666C4.76666 5.73203 4.98055 6.09314 5.20832 6.41536C5.4361 6.73759 5.7111 7.07648 6.03332 7.43203C6.52221 6.8987 6.92777 6.35148 7.24999 5.79036C7.57221 5.22925 7.84443 4.63203 8.06666 3.9987H0.666656V2.66536H5.33332V1.33203H6.66666V2.66536H11.3333V3.9987H9.39999C9.16666 4.78759 8.84999 5.55425 8.44999 6.2987C8.04999 7.04314 7.55555 7.74314 6.96666 8.3987L8.56666 10.032L8.06666 11.3987L5.99999 9.33203L2.66666 12.6654Z"
            fill="currentColor"
        />
    </svg>
);

export default Translate;
