import styled from 'styled-components';
import { Drawer } from 'react-rainbow-components';
import { color } from 'theme';
import { BACKGROUND } from '../../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${BACKGROUND};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0px 0 16px;
`;

export const Content = styled.div`
    background: ${color('background.main')};
    height: 100%;
    margin-bottom: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 20px 8px 20px;
`;

export const PageTitle = styled.h1`
    font-size: 15px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    line-height: 18px;
    color: ${color('text.label')};
`;

export const StyledDrawerTitle = styled.h1`
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    font-family: Lato;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: ${color('text.title')};
    margin: 0 1.25rem;
    padding: 1.375rem 0 1.325rem;
    display: block;
    box-sizing: border-box;
    line-height: normal;
`;

export const StyledDrawer = styled(Drawer)`
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    width: 500px;

    & > h1 + div {
        margin: 0;
    }

    div[id^="drawer-content"] {
        padding: 1rem 0;
    }
`;
