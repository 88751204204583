import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../types";
import { Conversation } from "./types";
import { transformMetadata, processTimestampFields } from "../utils";

export type ConversationGetService<T> = (
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: ConversationGetService<EntityGet<Conversation>> = async (
  id,
  opts = {}
) => {
  const docRef = doc(db, `/conversation/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Conversation>)
    );
  }
  return null;
};

export default get;
