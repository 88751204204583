import { HelpText } from 'react-rainbow-components';
import {
    StyledColumnWrapper, CheckmarkIcon, DisableIcon,
} from './styled';

interface UsageReportStatusColumnProps {
    value?: string;
    row?: Record<string, unknown>;
}

const CampaignCallStatusColumn = (props: UsageReportStatusColumnProps) => {
    const { value, row } = props;
    const statusMapper: Record<string, any> = {
        completed: (
            <>
                <CheckmarkIcon />
                Completed
            </>
        ),
        busy: (
            <>
                <DisableIcon />
                Busy
            </>
        ),
        failed: (
            <>
                <HelpText text={row?.error as string} iconSize="small" variant="error" title="Error" />
                Failed
            </>
        ),
        'no-answer': (
            <>
                <DisableIcon />
                No Answer
            </>
        ),
    };
    return (
        <StyledColumnWrapper>
            {statusMapper[value as string]}
        </StyledColumnWrapper>
    );
};

export default CampaignCallStatusColumn;
