import { PageHeaderTitle } from 'components/styled';
import Tree from '../Tree';
import {
    SectionHeader, SectionDescription, ChartContainer,
} from './styled';

const data = {
    name: 'Root',
    children: [
        {
            name: 'Child 1',
            children: [
                { name: 'Grandchild 1.1' },
                { name: 'Grandchild 1.2', children: [{ name: 'Great-Grandchild 1.2.1' }] },
            ],
        },
        {
            name: 'Child 2',
            children: [
                { name: 'Grandchild 2.1' },
                {
                    name: 'Grandchild 2.2',
                    children: [
                        { name: 'Great-Grandchild 2.2.1' },
                        { name: 'Great-Grandchild 2.2.2', children: [{ name: 'Great-Great-Grandchild 2.2.2.1' }] },
                    ],
                },
            ],
        },
        {
            name: 'Child 3',
            children: [
                { name: 'Grandchild 3.1' },
                { name: 'Grandchild 3.2' },
            ],
        },
        {
            name: 'Child 4',
            children: [
                { name: 'Grandchild 4.1' },
                { name: 'Grandchild 4.2', children: [{ name: 'Great-Grandchild 4.2.1' }] },
                { name: 'Grandchild 4.3' },
            ],
        },
        {
            name: 'Child 5',
        },
    ],
};

const VersionIntentAnalysis = () => (

    <div>
        <SectionHeader>
            <PageHeaderTitle>Intent Distribution</PageHeaderTitle>
            <SectionDescription>
                Lorem impsu dolor sit amet, consectetur adipiscing elit.
            </SectionDescription>
        </SectionHeader>
        <ChartContainer>
            <Tree data={data} />
        </ChartContainer>
    </div>

);

export default VersionIntentAnalysis;
