import { Drawer } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const SectionHeader = styled.header`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;

export const StyledDrawer = styled(Drawer)`
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    width: 500px;

    & > h1 + div {
        margin: 0;
    }

    div[id^="drawer-content"] {
        padding: 1rem 0;
    }
`;
