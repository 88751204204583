import styled from 'styled-components';
import { Google } from '@rainbow-modules/icons';
import { Button } from 'react-rainbow-components';
import { color } from 'theme';
import { BACKGROUND } from '../../constants';

export const Container = styled.div`
    background: ${BACKGROUND};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
`;

export const StyledGoogleIcon = styled(Google)`
    width: 24px;
    height: 24px;
    margin-right: 20px;
`;

export const StyledButton = styled(Button)`
    max-width: 290px;
    width: 290px;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
`;

export const Footer = styled.footer`
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: ${color('text.disabled')};
    bottom: 40px;
    left: 0;
    right: 0;
`;
