import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { AddService, EntityGet } from "../types";
import { Group } from "./types";
import get from "./get";

export type GroupAddService<T> = (doc: Omit<T, "id">) => Promise<EntityGet<T>>;

const add: GroupAddService<Group> = async (data) => {
  const collectionRef = collection(db, `/group`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(docRef.id) as Promise<EntityGet<Group>>;
};

export default add;
