import styled from 'styled-components';
import { LoadingShape, PhoneInput } from 'react-rainbow-components';
import { color } from 'theme';
import Label from 'components/LabelWithDescription';

export const Container = styled.div`
    display: flex;
    align-items: start;
    flex: 1;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    font-size: 0.75rem;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background: rgba(227, 229, 237, 0.05);
    margin-bottom: 32px;

    @media (max-width: 900px) {
        width: 100%;
    }
`;

export const EmptyMessage = styled.div`
    font-family: 'Lato';
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    color: ${color('text.disabled')};
`;

export const OptionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-items: stretch;
    align-items: center;
    padding: 12px 10px 12px 16px;
    border-top: 1px solid ${color('border.divider')};

    & > div {
        display: inline-flex;
        width: 100%;
        flex: 1;
        align-items: center;
    }

    &:first-child {
        border-top: none;
    }
`;

export const StyledPhoneInput = styled(PhoneInput)`
    label + div {
        padding: 0;
    }
    label + div > div {
        align-items: center;
    }
    label + div > div + div, input {
        display: none;
    }
`;

export const StyledLoadingShape = styled(LoadingShape).withConfig({
    shouldForwardProp: (prop) => !['$width'].includes(prop),
})<{ $width?: string }>`
    margin: 8px 16px;
    display: flex;
    width: ${(props) => props.$width};

    &:first-child {
        margin-top: 14px;
    }

    &:last-child {
        margin-bottom: 14px;
    }
`;

export const StyledLabel = styled(Label)`
    font-family: Lato;
    letter-spacing: 0em;
    text-align: left;
    margin: 6px 0 0;
    
    & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    }
    
    & > span + div {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
`;
