import { IconProps } from './types';

const Refresh = ({ title = 'Resend', className }: IconProps) => (
    <svg className={className} width={20} height={21} viewBox="0 0 20 21" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.1666 3.33594C19.4428 3.33594 19.6666 3.5598 19.6666 3.83594V8.83594C19.6666 9.11208 19.4428 9.33594 19.1666 9.33594H14.1666C13.8905 9.33594 13.6666 9.11208 13.6666 8.83594C13.6666 8.55979 13.8905 8.33594 14.1666 8.33594H18.6666V3.83594C18.6666 3.5598 18.8905 3.33594 19.1666 3.33594Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9864 3.56938C9.48367 3.35624 7.95241 3.63726 6.62333 4.3701C5.29425 5.10294 4.23936 6.2479 3.61761 7.63243C2.99587 9.01697 2.84096 10.5661 3.17623 12.0463C3.51149 13.5266 4.31877 14.8577 5.47642 15.8392C6.63406 16.8207 8.07935 17.3994 9.59449 17.4881C11.1096 17.5767 12.6125 17.1705 13.8767 16.3307C15.1409 15.4908 16.0979 14.2629 16.6035 12.8318C16.6955 12.5715 16.9811 12.435 17.2415 12.5269C17.5019 12.6189 17.6384 12.9046 17.5464 13.1649C16.9686 14.8004 15.8749 16.2038 14.4301 17.1636C12.9853 18.1234 11.2677 18.5877 9.53609 18.4864C7.8045 18.3851 6.15274 17.7237 4.82972 16.602C3.5067 15.4803 2.58409 13.9589 2.20093 12.2672C1.81777 10.5755 1.99481 8.80511 2.70537 7.22278C3.41594 5.64045 4.62153 4.33193 6.14048 3.4944C7.65942 2.65687 9.40943 2.3357 11.1268 2.57929C12.8416 2.82253 14.4311 3.61569 15.6565 4.83949L19.5094 8.46771C19.7104 8.65702 19.7199 8.97346 19.5306 9.1745C19.3413 9.37553 19.0249 9.38504 18.8238 9.19573L14.9655 5.56239L14.9546 5.55183C13.8818 4.47829 12.489 3.78253 10.9864 3.56938Z" fill="currentColor" />
    </svg>
);

export default Refresh;
