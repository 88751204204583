import { ButtonIcon } from 'react-rainbow-components';
import { Pencil, Trash } from 'components/icons';
import { StyledColumnWrapper } from './styled';

interface AgentActionsColumnProps {
    value?: string;
    row?: Record<string, unknown>;
    onEdit?: (data: Record<string, unknown>) => void;
    onDelete?: (id:string) => void;
}

const ActionsColumn = (props: AgentActionsColumnProps) => {
    const {
        value = '', row = {}, onEdit = () => {}, onDelete = () => {},
    } = props;

    return (
        <StyledColumnWrapper>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Pencil />}
                onClick={() => onEdit(row)}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                onClick={() => onDelete(value)}
            />
        </StyledColumnWrapper>
    );
};

export default ActionsColumn;
