import { useEffect, useState } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import { Settings } from '@rainbow-modules/icons';
import { Dataset } from 'data/firestore/dataset/types';
import getModel from 'data/firestore/model/get';
import { COMPLETION_SETTINGS_DRAWER } from '../../../../../constants';
import { StyledButtonIcon, StyledRecordField } from './styled';
import isRootModel from '../helpers/isRootModel';

interface SettingsButtonProps {
    settings?: Pick<Dataset, 'model' | 'temperature'>;
    onSettingsChanged?: (values: Pick<Dataset, 'model' | 'temperature'>) => void;
}

const SettingsButton = ({
    settings = { temperature: 0.7 },
    onSettingsChanged = () => {},
}: SettingsButtonProps) => {
    const { model } = settings;
    const [isLoading, setLoading] = useState(false);
    const [modelName, setModelName] = useState<string>(model?.id || '');
    const [openSettings] = useOpenModal(COMPLETION_SETTINGS_DRAWER);

    useEffect(() => {
        if (isRootModel(model?.id)) {
            setModelName(model?.id || '');
        } else if (model?.id) {
            (async () => {
                setLoading(true);
                const modelInfo = await getModel(model?.id);
                setModelName(modelInfo?.name || '');
                setLoading(false);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model]);

    return (
        <div className="rainbow-flex">
            <StyledRecordField
                label={`Model: ${modelName}`}
                value={`Version: ${model?.version ? model?.version : 'none'}`}
                isLoading={isLoading}
            />
            <StyledButtonIcon
                borderRadius="semi-rounded"
                icon={<Settings />}
                onClick={() => openSettings({
                    settings,
                    onChange: onSettingsChanged,
                })}
                disabled={isLoading}
            />
        </div>
    );
};

export default SettingsButton;
