import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import TopBar from 'components/TopBar';
import { DatasetName, Option } from 'components/DatasetOptions/styled';
import { PageHeaderTitle } from 'components/styled';
import {
    Container,
    Content,
    Header,
    Row,
    SideBar,
    RightContent,
} from './styled';

const Insights = () => {
    const navigate = useNavigate();
    const match = useMatch('insights/:tabName');
    return (
        <Container>
            <TopBar />
            <Content>
                <Header>
                    <PageHeaderTitle>Insights</PageHeaderTitle>
                </Header>
                <Row>
                    <SideBar>
                        <Option
                            selected={match?.params?.tabName === 'call-duration'}
                            onClick={() => navigate('call-duration')}
                        >
                            <DatasetName>Call Duration</DatasetName>
                        </Option>
                        <Option
                            selected={match?.params?.tabName === 'turns-by-call'}
                            onClick={() => navigate('turns-by-call')}
                        >
                            <DatasetName>Turns by Call</DatasetName>
                        </Option>
                        <Option
                            selected={match?.params?.tabName === 'agent-performance'}
                            onClick={() => navigate('agent-performance')}
                        >
                            <DatasetName>Agent Performance</DatasetName>
                        </Option>
                        <Option
                            selected={match?.params?.tabName === 'user-performance'}
                            onClick={() => navigate('user-performance')}
                        >
                            <DatasetName>User Performance</DatasetName>
                        </Option>
                        <Option
                            selected={match?.params?.tabName === 'growth'}
                            onClick={() => navigate('growth')}
                        >
                            <DatasetName>Growth</DatasetName>
                        </Option>
                    </SideBar>
                    <RightContent>
                        <Outlet />
                    </RightContent>
                </Row>
            </Content>
        </Container>
    );
};

export default Insights;
