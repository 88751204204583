import styled from 'styled-components';
import { RecordPrimaryDetails, RecordDetails } from '@rainbow-modules/record';

export const InfoContainer = styled.div`
    display: flex;
    border-radius: 8px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background: rgba(227, 229, 237, 0.05);
    padding: 20px 16px;
    margin: 4px 0 0;
    gap: 24px;
`;

export const StyledRecordPrimaryDetails = styled(RecordPrimaryDetails)`
    gap: 10px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background: rgba(227, 229, 237, 0.05);
    padding: 20px 16px;
`;

export const StyledRecordDetails = styled(RecordDetails)`
    flex-grow: 1;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 12px 20px 0 8px;
`;
