import { IconProps } from './types';

const Matrass = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width={18} height={20} viewBox="0 0 18 20" fill="none">
        <title>{title}</title>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6908 19.7359C17.6038 19.8985 17.4344 20 17.25 20L0.75 20C0.568621 20 0.401466 19.9018 0.3132 19.7433C0.224934 19.5849 0.229399 19.391 0.324867 19.2368L6.75 8.85776L6.75 0.499999C6.75 0.223857 6.97386 -9.4208e-07 7.25 -9.17939e-07L10.25 -6.55671e-07C10.5261 -6.3153e-07 10.75 0.223857 10.75 0.499999L10.75 8.84861L17.666 19.2226C17.7683 19.3761 17.7778 19.5734 17.6908 19.7359ZM16.3157 19L9.83397 9.27735C9.77922 9.19522 9.75 9.09871 9.75 9L9.75 0.999999L7.75 0.999999L7.75 9C7.75 9.09298 7.72407 9.18412 7.67513 9.26318L1.64758 19L16.3157 19Z"
        />
        <path
            fill="currentColor"
            d="M15.153 18.386L11.2636 12.5C10.4476 12.6561 9.26013 12.9141 8.51823 13.2983C7.69595 13.7246 6.69357 13.423 5.76356 13.5L2.76356 18.386C2.72948 18.4335 2.76356 18.5 2.82206 18.5H15.0941C15.1526 18.5 15.1871 18.4335 15.153 18.386ZM6.36629 17.3837C5.87814 17.3837 5.48257 16.9881 5.48257 16.5C5.48257 16.0119 5.87814 15.6163 6.36629 15.6163C6.85443 15.6163 7.25 16.0119 7.25 16.5C7.25 16.9881 6.85443 17.3837 6.36629 17.3837ZM10.6926 16.7326C10.2044 16.7326 9.80887 16.337 9.80887 15.8489C9.80887 15.3607 10.2044 14.9651 10.6926 14.9651C11.1807 14.9651 11.5763 15.3607 11.5763 15.8489C11.5763 16.337 11.1807 16.7326 10.6926 16.7326Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M5.25 0.5C5.25 0.223858 5.47386 0 5.75 0L11.75 0C12.0261 0 12.25 0.223858 12.25 0.5C12.25 0.776142 12.0261 1 11.75 1L5.75 1C5.47386 1 5.25 0.776142 5.25 0.5Z"
        />
    </svg>
);

export default Matrass;
