import styled from 'styled-components';
import { Input, AvatarMenu, MenuItem } from 'react-rainbow-components';
import { MagnifyingGlass, User } from '@rainbow-modules/icons';
import logout from 'data/services/auth/logout';

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
`;

const TopBar = () => (
    <Header>
        <Input
            placeholder="Search something"
            borderRadius="semi-rounded"
            variant="shaded"
            icon={<MagnifyingGlass />}
        />
        <AvatarMenu icon={<User />}>
            <MenuItem label="Logout" onClick={logout} />
        </AvatarMenu>
    </Header>
);

export default TopBar;
