/* eslint-disable react/no-unused-prop-types */
import styled, { CSSProperties } from 'styled-components';
import { Picklist, Option } from 'react-rainbow-components';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Agent } from 'data/firestore/agent/types';
import useModels from 'hooks/useModels';
import useModelVersions from 'data/firestore/model/version/useCollectionOnce';
import { orderBy, query, where } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { EntityGet } from 'data/firestore/types';
import { Version } from 'data/firestore/model/version/types';
import { RecordField } from '@rainbow-modules/record';
import { color } from 'theme';
import formatter from 'data/services/date/formatter';
import { isEmpty } from '@rainbow-modules/validation';

interface Props {
    id?: string;
    className?: string;
    style?: CSSProperties;
    value?: Agent['model'];
    onChange?: (value: Agent['model']) => void;
    required?: boolean;
    borderRadius?: 'semi-rounded' | 'square' | 'semi-square' | 'rounded' | undefined;
    error?: string;
}

const getVersionLabel = (version: EntityGet<Version>): string => `${version.id} - ${version.createdAt}`;

const StyledRecordField = styled(RecordField)`
    padding: 5px 0;

    > span:first-child {
        color: ${color('text.main')};
        font-size: 14px;
    }
    > span:last-child {
        color: ${color('text.header')};
        font-size: 12px;
    }
`;

const EmptyComponent = () => <div className="rainbow-p-around_medium">A version of this model is not available for use yet.</div>;

const ModelVersionPicklist = (props: Props) => {
    const {
        id,
        className,
        style,
        required,
        value,
        onChange = () => {},
        error,
        borderRadius,
    } = useReduxForm(props);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const { data: models, isLoading: isLoadingModels } = useModels();
    const selectedModel = models?.find((model) => model.id === value?.id);
    const {
        data: modelVersions = [],
        isLoading: isLoadingModelVersions,
    } = useModelVersions(value?.id as string, {
        disabled: selectedModel?.root,
        listQueryFn: (ref) => query(ref, where('status', '==', 'ready'), orderBy('createdAt', 'desc')),
        track: [value?.id],
    });
    const modelVersionOptions = useMemo(() => {
        if (modelVersions.length === 0) return <EmptyComponent />;
        return [
            <Option label="Select model Version" variant="header" />,
            ...(modelVersions).map(
                (modelVersion) => {
                    const versionLabel = getVersionLabel(modelVersion);
                    const shouldShowVersion = !searchQuery
                        || versionLabel.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
                    if (shouldShowVersion) {
                        return (
                            <Option
                                key={modelVersion.id}
                                name={modelVersion.id}
                                label={(
                                    <StyledRecordField
                                        label={modelVersion.id}
                                        value={formatter.format(modelVersion.createdAt)}
                                    />
                                )}
                                value={modelVersion.id}
                            />
                        );
                    }
                    return null;
                },
            ),
        ];
    }, [modelVersions, searchQuery]);
    const selectedVersion = modelVersions.find((version) => version.id === value?.version);

    return (
        <Picklist
            id={id}
            className={className}
            value={selectedVersion ? {
                name: selectedVersion?.id,
                label: selectedVersion.id,
                value: selectedVersion.id,
            } : undefined}
            onChange={(newValue) => onChange({
                ...value,
                version: newValue.value,
            } as Agent['model'])}
            // eslint-disable-next-line react/forbid-component-props
            style={style}
            label="Version"
            borderRadius={borderRadius}
            required={required}
            labelAlignment="left"
            placeholder="Select a model version"
            isLoading={isLoadingModels || isLoadingModelVersions}
            error={error}
            enableSearch={!isEmpty(modelVersions)}
            onSearch={setSearchQuery}
        >
            {modelVersionOptions}
        </Picklist>
    );
};

export default ModelVersionPicklist;
