import { Spinner } from 'react-rainbow-components';
import useDataset from 'data/firestore/dataset/use';
import updateDataset from 'data/firestore/dataset/update';
import { useParams } from 'react-router-dom';
import Form from './form';
import { StyledUniversalForm } from './styled';

const DatasetSettings = () => {
    const { datasetId = '' } = useParams();
    const { data, isLoading } = useDataset(datasetId);

    if (isLoading) {
        return <Spinner />;
    }
    if (!data) {
        return null;
    }

    const {
        model,
        temperature,
    } = data;

    const initialValues = {
        model,
        temperature,
    };

    return (
        <StyledUniversalForm
            initialValues={initialValues}
            onSubmit={(values) => updateDataset(datasetId, values)}
        >
            <Form />
        </StyledUniversalForm>
    );
};

export default DatasetSettings;
