import { useContext, useState } from 'react';
import { auth } from 'data/firebase';
import EventSource from 'helpers/eventSource';
import context, { Context } from '../../context';

async function createRequestHeaders() {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    };
    if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }
    return headers;
}

const useAskQuestion = () => {
    const { agentData } = useContext<Context>(context);
    const [answer, setAnswer] = useState<string>('');
    const [sourceDocuments, setSourceDocuments] = useState<Record<string, any>[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const ask = async (question: string) => {
        setIsLoading(true);
        setAnswer('');
        setSourceDocuments([]);
        const encodedQuestion = encodeURIComponent(question);
        const evtSource = new EventSource(
            `${process.env.REACT_APP_BASE_URL}/agents/${agentData?.id}/ask?question=${encodedQuestion}`,
            {
                method: 'GET',
                headers: await createRequestHeaders(),
            },
        );
        evtSource.onmessage = (event) => {
            setAnswer((prev) => prev + event.data);
        };
        evtSource.addEventListener('sourceDocuments', (event) => {
            const source = JSON.parse(event.data);
            setSourceDocuments(source);
            setIsLoading(false);
        });
    };

    return {
        answer,
        sourceDocuments,
        isLoading,
        ask,
    };
};

export default useAskQuestion;
