import { useMemo } from 'react';
import { useUserClaims } from '@rainbow-modules/firebase-hooks';

const useCanAccessDashboard = (): {
    isLoading: boolean;
    canAccessDashboard: boolean;
} => {
    const [claims, isLoadingClaims] = useUserClaims();

    return useMemo(() => ({
        isLoading: isLoadingClaims,
        canAccessDashboard: claims?.roles?.includes('admin'),
    }), [claims?.roles, isLoadingClaims]);
};

export default useCanAccessDashboard;
