import { Table } from 'react-rainbow-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
`;

export const ConversationItemContainer = styled(Link)`
    border: 1px solid ${color('border.disabled')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px 4px 16px;
    border-radius: 10px;
    color: ${color('text.main')};
    background: ${color('background.main')};
    font-size: 15px;

    :hover,
    :active,
    :focus {
        color: ${color('text.main')};
        outline: none;
        text-decoration: none;
        background: ${color('background.secondary')};
    }
`;

export const StyledTable = styled(Table)`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 0 20px;
`;
