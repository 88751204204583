import { Close } from '@rainbow-modules/icons';
import { ButtonIcon } from 'react-rainbow-components';
import ReactJson from 'react-json-view';
import { EntityGet } from 'data/firestore/types';
import { Conversation } from 'data/firestore/session/conversation/types';
import { RecordField } from '@rainbow-modules/record';
import getEventName from './helpers/getEventName';
import {
    PayloadContainer, PayloadHeader, PayloadTitle, PayloadContent,
} from './styled';

interface Props {
    onClose: () => void;
    data: EntityGet<Conversation>;
}

const Payload = ({ onClose, data }: Props) => {
    let content;
    try {
        content = JSON.parse(data.content);
    } catch (error) {
        content = data.content;
    }
    const parsedData = {
        ...data,
        content,
    };
    const eventName = getEventName({ role: data.role, content });

    return (
        <PayloadContainer>
            <PayloadHeader>
                <PayloadTitle>
                    Event Details
                </PayloadTitle>
                <ButtonIcon icon={<Close />} onClick={onClose} size="small" borderRadius="semi-rounded" />
            </PayloadHeader>
            <PayloadContent>
                <RecordField label="Event" value={eventName} className="rainbow-m-bottom_medium" />
                <RecordField label="Payload" value={<ReactJson src={parsedData} />} />
            </PayloadContent>
        </PayloadContainer>
    );
};

export default Payload;
