import { useMemo } from 'react';
import {
    Field, UniversalFormModal, composeValidators, isEmail, isRequired,
} from '@rainbow-modules/forms';
import { confirmModal, hideAppSpinner, showAppSpinner } from '@rainbow-modules/app';
import { isEmpty } from '@rainbow-modules/validation';
import {
    Button, Input, PhoneInput, RenderIf,
} from 'react-rainbow-components';
import { useConnectModal } from '@rainbow-modules/hooks';
import useAddNotificationOption, { ADD_NOTIFICATION_OPTION_FORM_MODAL } from 'hooks/useAddNotificationOption';
import Label from 'components/LabelWithDescription';
import validatePhoneNumber from 'data/services/phones/validate';
import add from 'data/firestore/agent/subscriptions/add';
import update from 'data/firestore/agent/subscriptions/update';
import remove from 'data/firestore/agent/subscriptions/remove';
import { Subscriptions } from 'data/firestore/agent/subscriptions/types';
import {
    Container, Section, SectionHeader, SectionContent,
    EmptyMessage, FieldContainer, MailIconBrand, StyledLoadingShape,
} from './styled';
import NotificationOption from './item';

interface NotificationProps {
    agentId: string;
    phones?: Subscriptions[],
    emails?: Subscriptions[],
    isLoading?: boolean;
}

const validatePhone = (value: Record<string, string> = {}) => {
    if (!validatePhoneNumber(`${value?.countryCode}${value?.phone}`)) return 'Please enter a valid phone number.';
    return undefined;
};

const PhoneField = () => (
    <FieldContainer>
        <Field
            name="phoneNumber"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={PhoneInput}
            label="Phone Number"
            required
            placeholder="Enter your phone number"
            className="rainbow-m-bottom_xx-large"
            borderRadius="semi-rounded"
            labelAlignment="left"
            validate={validatePhone}
        />
    </FieldContainer>
);

const EmailField = () => (
    <FieldContainer>
        <Field
            name="emailAddress"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Input}
            label="Email Address"
            icon={<MailIconBrand />}
            required
            placeholder="Enter an email address"
            className="rainbow-m-bottom_xx-large"
            borderRadius="semi-rounded"
            labelAlignment="left"
            validate={composeValidators(
                isEmail('Please enter a valid email address.'),
                isRequired('Please enter a valid email address.'),
            )}
        />
    </FieldContainer>
);

const Notification = ({
    agentId,
    isLoading = false,
    phones = [],
    emails = [],
}:NotificationProps) => {
    const connectedModalProps = useConnectModal(ADD_NOTIFICATION_OPTION_FORM_MODAL);
    const [openModal, closeModal] = useAddNotificationOption();

    const addNewPhone = () => openModal({
        title: 'Add Phone Number',
        submitButtonLabel: 'Add',
        fields: PhoneField,
        onSubmit: async (values: any) => {
            const to = `${values.phoneNumber?.countryCode as string}${values.phoneNumber?.phone}`;
            showAppSpinner();
            await add(agentId, {
                type: 'sms',
                to,
            });
            hideAppSpinner();
            closeModal();
        },
    });

    const addNewEmail = () => openModal({
        title: 'Add Email Address',
        submitButtonLabel: 'Add',
        fields: EmailField,
        onSubmit: async (values: any) => {
            showAppSpinner();
            await add(agentId, {
                type: 'email',
                to: values.emailAddress,
            });
            hideAppSpinner();
            closeModal();
        },
    });

    const editPhone = (value: Subscriptions) => openModal({
        title: 'Edit Phone Number',
        submitButtonLabel: 'Update',
        initialValues: {
            phoneNumber: value.to,
        },
        fields: PhoneField,
        onSubmit: async (values: any) => {
            const to = `${values.phoneNumber?.countryCode as string}${values.phoneNumber?.phone}`;
            showAppSpinner();
            await update(agentId, value.id, {
                to,
            });
            hideAppSpinner();
            closeModal();
        },
    });

    const editEmail = (value: Subscriptions) => openModal({
        title: 'Edit Email Address',
        submitButtonLabel: 'Update',
        initialValues: {
            emailAddress: value.to,
        },
        fields: EmailField,
        onSubmit: async (values: any) => {
            showAppSpinner();
            await update(agentId, value.id, {
                to: values.emailAddress,
            });
            hideAppSpinner();
            closeModal();
        },
    });

    const deleteOption = async (option: Subscriptions) => {
        const type = (option.type === 'sms' ? 'phone number' : 'email address');
        if (await confirmModal({
            header: 'Subscription Remove ',
            question: `You have requested to remove the ${type} "${option.to}". This cannot be undone. Are you sure you want to continue?`,
            okButtonLabel: 'Yes',
            cancelButtonLabel: 'No',
            borderRadius: 'semi-rounded',

        })) {
            showAppSpinner();
            await remove(agentId, option.id);
            hideAppSpinner();
        }
    };

    const phonesList = useMemo(() => phones.map(
        (phone) => (
            <NotificationOption
                key={`option-${phone.id}`}
                type="phone"
                value={phone}
                onEdit={editPhone}
                onDelete={deleteOption}
            />
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [phones]);

    const emailsList = useMemo(() => emails.map(
        (email) => (
            <NotificationOption
                key={`option-${email.id}`}
                type="email"
                value={email}
                onEdit={editEmail}
                onDelete={deleteOption}
            />
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [phones]);

    return (
        <>
            <Container>
                <Section>
                    <SectionHeader>
                        <Label
                            name="Phone Numbers"
                            description="Phone numbers of those who will receive the notification."
                        />
                        <Button
                            disabled={isLoading}
                            label="Add"
                            size="small"
                            variant="neutral"
                            borderRadius="semi-rounded"
                            onClick={addNewPhone}
                        />
                    </SectionHeader>
                    <SectionContent>
                        <RenderIf isTrue={isLoading}>
                            <StyledLoadingShape variant="solid" shape="rounded-rect" $width="40%" />
                            <StyledLoadingShape variant="solid" shape="rounded-rect" $width="30%" />
                            <StyledLoadingShape variant="solid" shape="rounded-rect" $width="60%" />
                        </RenderIf>
                        <RenderIf isTrue={!isLoading}>
                            {phonesList}
                        </RenderIf>
                        <RenderIf isTrue={!isLoading && isEmpty(phones)}>
                            <EmptyMessage>
                                There is no phone number to receive notification yet.
                            </EmptyMessage>
                        </RenderIf>
                    </SectionContent>
                </Section>
                <Section>
                    <SectionHeader>
                        <Label
                            name="Email Address"
                            description="Emails of those who will receive the notification"
                        />
                        <Button
                            disabled={isLoading}
                            label="Add"
                            size="small"
                            variant="neutral"
                            borderRadius="semi-rounded"
                            onClick={addNewEmail}
                        />
                    </SectionHeader>
                    <SectionContent>
                        <RenderIf isTrue={isLoading}>
                            <StyledLoadingShape variant="solid" shape="rounded-rect" $width="30%" />
                            <StyledLoadingShape variant="solid" shape="rounded-rect" $width="60%" />
                            <StyledLoadingShape variant="solid" shape="rounded-rect" $width="40%" />
                        </RenderIf>
                        <RenderIf isTrue={!isLoading}>
                            {emailsList}
                        </RenderIf>
                        <RenderIf isTrue={!isLoading && isEmpty(emails)}>
                            <EmptyMessage>
                                There is no email address to receive notification yet.
                            </EmptyMessage>
                        </RenderIf>
                    </SectionContent>
                </Section>
            </Container>
            <UniversalFormModal
                borderRadius="semi-rounded"
                {...connectedModalProps}
            />
        </>
    );
};

export default Notification;
