import {
    BarChart,
    Bar,
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    CartesianGrid,
    LabelList,
} from 'recharts';
import { RenderIf } from 'react-rainbow-components';
import { PageHeaderTitle } from 'components/styled';
import { Row, LeyendBadge } from './styled';
import {
    SectionHeader,
    SectionDescription,
    ChartContainer,
} from '../styled';

const fakeData = [
    { id: 'abc34', duration: 3 },
    { id: 'abc34', duration: 8 },
    { id: 'abc34', duration: 5 },
    { id: 'abc34', duration: 13 },
    { id: 'abc34', duration: 28 },
    { id: 'abc34', duration: 25 },
];

const fakeDataScatter = [
    { timestamp: '123456', duration: 3 },
    { timestamp: '123457', duration: 8 },
    { timestamp: '123458', duration: 5 },
    { timestamp: '123459', duration: 13 },
    { timestamp: '123460', duration: 28 },
];

const AgentPerformance = () => {
    const isLoading = false;
    return (
        <>
            <SectionHeader>
                <PageHeaderTitle>Average agent response time per call</PageHeaderTitle>
                <SectionDescription>
                    Lorem impsu dolor sit amet, consectetur adipiscing elit.
                </SectionDescription>
            </SectionHeader>
            <ChartContainer>
                <Row>
                    <RenderIf isTrue={!isLoading}>
                        <LeyendBadge label={`Total: ${1452}`} borderRadius="semi-square" />
                    </RenderIf>
                </Row>
                <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                        data={fakeData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 35,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="id" angle={-40} textAnchor="end" />
                        <YAxis
                            dataKey="duration"
                            label={{ value: 'Averaage Answer Time', angle: -90 }}
                        />
                        <Tooltip />
                        <Legend verticalAlign="top" layout="horizontal" name="Total calls" />
                        <Bar dataKey="duration" fill="#8884d8">
                            <LabelList dataKey="duration" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>

            </ChartContainer>
            <SectionHeader>
                <PageHeaderTitle>Agent response time on the last 1000 turns</PageHeaderTitle>
                <SectionDescription>
                    Lorem impsu dolor sit amet, consectetur adipiscing elit.
                </SectionDescription>
            </SectionHeader>
            <ChartContainer>
                <Row>
                    <RenderIf isTrue={!isLoading}>
                        <LeyendBadge label={`Total: ${1452}`} borderRadius="semi-square" />
                    </RenderIf>
                </Row>
                <ResponsiveContainer width="100%" height={350}>
                    <ScatterChart
                        data={fakeDataScatter}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 35,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timestamp" angle={-40} textAnchor="end" />
                        <YAxis
                            dataKey="duration"
                            label={{ value: 'Answer Time', angle: -90 }}
                        />
                        <Scatter name="Average Response Time" data={fakeDataScatter} fill="#8884d8" />
                    </ScatterChart>
                </ResponsiveContainer>

            </ChartContainer>
        </>
    );
};

export default AgentPerformance;
