import {
    DocumentData, Query, query, where, documentId,
} from 'firebase/firestore';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { isEmpty } from '@rainbow-modules/validation';

const buildQuery = (metadataFields: string[]) => {
    if (isEmpty(metadataFields)) {
        return (queryRef: Query<DocumentData>) => queryRef;
    }
    return (queryRef: Query<DocumentData>) => query(queryRef, where(documentId(), 'in', metadataFields));
};

const useSessionMetadata = (
    sessionId?: string,
    metadataFields: string[] = [],
) => {
    const [metadata, isLoading] = useCollection({
        path: `/session/${sessionId}/metadata`,
        flat: true,
        query: buildQuery(metadataFields),
        disabled: !sessionId,
    });

    return {
        metadata: metadata as Record<string, unknown>[],
        isLoading,
    };
};

export default useSessionMetadata;
