import { CodeBlock } from '@rainbow-modules/content';
import { MetadataRendererProps } from '../types';
import { MetadataLabel } from '../styled';

const DefaultMetadataRenderer = ({
    title,
    metadata = {},
}: MetadataRendererProps) => (
    <>
        <MetadataLabel>{`${title}:`}</MetadataLabel>
        <CodeBlock label="Json" language="javascript" value={JSON.stringify(metadata, null, 2)} />
    </>
);

export default DefaultMetadataRenderer;
