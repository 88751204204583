import { useContext, KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Input, RenderIf } from 'react-rainbow-components';
import { UniversalForm } from '@rainbow-modules/forms';
import { useFormState, useForm } from 'react-final-form';
import updateAgent from 'data/firestore/agent/update';
import { PageHeaderTitle } from 'components/styled';
import context, { Context } from '../context';
import {
    Description, StyledField, StyledChipContainer, StyledChip,
} from './styled';

const KEYWORDS_LIMIT = 300;

const validate = (keywordArray: string[]) => (value: string) => {
    if (keywordArray.length >= KEYWORDS_LIMIT) {
        return `You may provide up to ${KEYWORDS_LIMIT} keywords.`;
    }
    if (keywordArray.find((item) => item === value)) {
        return "You can't provide the same keyword twice.";
    }
    return undefined;
};

const Content = () => {
    const { agentData } = useContext<Context>(context);
    const { keywords = '' } = agentData || {};
    const { agentId = '' } = useParams();
    const { values, errors } = useFormState();
    const { reset } = useForm();
    const keywordArray = keywords.split(', ');

    const handleKeyDown = ({ code }: KeyboardEvent<HTMLInputElement>) => {
        if (code === 'Enter' && values.keyword && !errors?.keyword) {
            const { keyword } = values;
            if (keywords) {
                updateAgent(agentId, {
                    keywords: `${keywords}, ${keyword}`,
                });
            } else {
                updateAgent(agentId, {
                    keywords: keyword,
                });
            }
            reset();
        }
    };

    const handleDelete = (keyword: string) => updateAgent(agentId, {
        keywords: keywordArray.filter((item: string) => item !== keyword).join(', '),
    });

    return (
        <>
            <StyledField
                name="keyword"
                component={Input}
                label="Add Keywords"
                labelAlignment="left"
                placeholder="Enter the keyword you want to add and press enter"
                borderRadius="semi-rounded"
                onKeyDown={handleKeyDown}
                maxLength={100}
                validate={validate(keywordArray)}
            />
            <RenderIf isTrue={keywords}>
                <StyledChipContainer>
                    {keywords?.split(', ')
                        ?.map((keyword:string) => (
                            <StyledChip
                                key={keyword}
                                label={keyword}
                                borderRadius="semi-rounded"
                                onDelete={() => handleDelete(keyword)}
                            />
                        ))}
                </StyledChipContainer>
            </RenderIf>
        </>
    );
};

const Keywords = () => (
    <>
        <PageHeaderTitle>Keywords</PageHeaderTitle>
        <Description>
            {`You can improve the recognition of the words or phrases you expect from your callers by
            adding them as keywords here.
            You may provide up to ${KEYWORDS_LIMIT} keywords (words or phrases) in this list.
            Your keywords may be up to 100 characters each.`}
        </Description>
        <UniversalForm>
            <Content />
        </UniversalForm>
    </>
);

export default Keywords;
