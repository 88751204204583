import { useState, useEffect } from "react";
import { FirestoreError } from "firebase/firestore";
import list from "./list";
import { Version } from "./types";
import {
  EntityGet,
  HookCollectionOpts,
  HookReturnCollection,
} from "../../types";

export type VersionUseCollectionHook<T> = (
  modelId: string,
  opts?: HookCollectionOpts<T>
) => HookReturnCollection<T>;

const useCollectionOnce: VersionUseCollectionHook<EntityGet<Version>> = (
  modelId,
  opts = {}
) => {
  const [data, setData] = useState<Array<EntityGet<Version>>>([]);
  const [error, setError] = useState<FirestoreError | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { disabled = false, onSnap, track = [] } = opts;
  useEffect(() => {
    if (!disabled) {
      setLoading(true);
      (async () => {
        try {
          const docs = await list(modelId, opts);
          setData(docs);
          if (typeof onSnap === "function") {
            onSnap(docs);
          }
        } catch (err) {
          if (err instanceof FirestoreError) {
            setError(err);
          } else {
            setError({
              code: "unknown",
              message: "Unknown error",
              name: "Unknown error",
            });
          }
        }
        setLoading(false);
      })();
    }
  }, [disabled, ...track]);
  return { data, error, isLoading };
};

export default useCollectionOnce;
