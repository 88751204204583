import { IconProps } from './types';

const Download = ({ title = 'Download', className }: IconProps) => (
    <svg className={className} width={20} height={21} viewBox="0 0 20 21">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 12.5C2.77614 12.5 3 12.7239 3 13V16.3333C3 16.6428 3.12292 16.9395 3.34171 17.1583C3.5605 17.3771 3.85725 17.5 4.16667 17.5H15.8333C16.1428 17.5 16.4395 17.3771 16.6583 17.1583C16.8771 16.9395 17 16.6428 17 16.3333V13C17 12.7239 17.2239 12.5 17.5 12.5C17.7761 12.5 18 12.7239 18 13V16.3333C18 16.908 17.7717 17.4591 17.3654 17.8654C16.9591 18.2717 16.408 18.5 15.8333 18.5H4.16667C3.59203 18.5 3.04093 18.2717 2.6346 17.8654C2.22827 17.4591 2 16.908 2 16.3333V13C2 12.7239 2.22386 12.5 2.5 12.5Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.47982 8.48238C5.67508 8.28712 5.99167 8.28712 6.18693 8.48238L10 12.2955L13.8132 8.48238C14.0084 8.28712 14.325 8.28712 14.5203 8.48238C14.7155 8.67765 14.7155 8.99423 14.5203 9.18949L10.3536 13.3562C10.1583 13.5514 9.84175 13.5514 9.64649 13.3562L5.47982 9.18949C5.28456 8.99423 5.28456 8.67765 5.47982 8.48238Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 2.5C10.2761 2.5 10.5 2.72386 10.5 3V13C10.5 13.2761 10.2761 13.5 10 13.5C9.72386 13.5 9.5 13.2761 9.5 13V3C9.5 2.72386 9.72386 2.5 10 2.5Z" fill="currentColor" />
    </svg>
);

export default Download;
