import styled from 'styled-components';
import { color } from 'theme';

export const SectionHeader = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const SectionDescription = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    line-height: 1.5;
`;
