import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

export type DatasetRemoveService = (id: string) => Promise<void>;

const remove: DatasetRemoveService = async (id) => {
  const docRef = doc(db, `/dataset/${id}`);
  return await deleteDoc(docRef);
};

export default remove;
