import { useCallback } from 'react';
import { showAppMessage } from '@rainbow-modules/app';
import { app } from 'data/firestore/firebase';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import createDownloadLink from './createDownloadLink';

const storage = getStorage(app);

const useConversationRawEvents = (conversationId?: string) => {
    const downloadJSONL = useCallback(
        async () => {
            try {
                const jsonlRecording = ref(storage, `/recordings_raw/${conversationId}.jsonl`);
                const jsonlUrl = await getDownloadURL(jsonlRecording);
                createDownloadLink(jsonlUrl as string, `${conversationId}.jsonl`);
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: 'JSONL file doesn\'t exists',
                });
            }
        },
        [conversationId],
    );

    const downloadWAV = useCallback(
        async () => {
            try {
                const wavRecording = ref(storage, `/recordings_raw/${conversationId}.wav`);
                const wavUrl = await getDownloadURL(wavRecording);
                createDownloadLink(`${wavUrl}`, `${conversationId}.wav`);
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: 'WAV file doesn\'t exists',
                });
            }
        },
        [conversationId],
    );

    return {
        downloadJSONL,
        downloadWAV,
    };
};

export default useConversationRawEvents;
