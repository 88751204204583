import { RecordDetails, RecordField } from '@rainbow-modules/record';
import { Container, ProcessName } from './styled';
import { RegionDetailsProps } from '../types';

const RegionDetails = (props: Partial<RegionDetailsProps>) => {
    const {
        role, start, end,
    } = props;
    const duration = `${(end as number - (start as number)) * 1000} ms`;
    return (
        <Container>
            <RecordDetails>
                <ProcessName>{role}</ProcessName>
                <RecordField label="Start" value={start} />
                <RecordField label="End" value={end} />
                <RecordField label="Duration" value={duration} />
            </RecordDetails>
        </Container>
    );
};

export default RegionDetails;
