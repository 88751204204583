import styled from 'styled-components';
import { BACKGROUND } from '../../constants';

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: ${BACKGROUND};
    display: flex;
    box-sizing: border-box;
`;

export const RightContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
`;

export const Sidebar = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 20px;
    padding-top: 100px;
    width: 260px;
    min-width: 260px;
    box-sizing: border-box;
`;
