import useHttpMutation from 'data/firestore/useHttpMutation';

export interface SendCampaignRequest {
    agentId: string;
    model: string;
    temperature?: number;

    contacts: Array<{
        name: string;
        phone: string;
    }>;
    systemMessageTemplate: string;
    caller: string;
}

const useSendCampaignMutation = () => useHttpMutation<SendCampaignRequest, { id: string }>({
    pathname: '/campaigns',
    method: 'POST',
});

export default useSendCampaignMutation;
