import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { AddService, EntityGet } from "../../types";
import { Document } from "./types";
import get from "./get";

export type DocumentAddService<T> = (
  agentId: string,
  doc: Omit<T, "id">
) => Promise<EntityGet<T>>;

const add: DocumentAddService<Document> = async (agentId, data) => {
  const collectionRef = collection(db, `/agent/${agentId}/document`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(agentId, docRef.id) as Promise<EntityGet<Document>>;
};

export default add;
