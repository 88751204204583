import { useContext } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import VersionStatus from 'components/VersionStatus';
import formatter from 'data/services/date/formatter';
import { PageHeaderTitle } from 'components/styled';
import context, { Context } from '../context';
import {
    InfoContainer,
    Content,
    StyledSpinner,
} from './styled';

const VersionDetails = () => {
    const {
        version: data, isLoading,
    } = useContext<Context>(context);

    return (
        <Content>
            <RenderIf isTrue={isLoading}>
                <StyledSpinner size="small" type="arc" variant="brand" />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <PageHeaderTitle>Version Details</PageHeaderTitle>
                <InfoContainer>
                    <div>
                        <RecordField
                            isLoading={isLoading}
                            label="Version ID:"
                            value={data?.id}
                        />
                        <RecordField
                            isLoading={isLoading}
                            label="OpenAI Name:"
                            value={data?.name}
                        />
                        <RecordField
                            isLoading={isLoading}
                            component={VersionStatus}
                            label="Status:"
                            value={data?.status}
                        />
                        <RecordField
                            isLoading={isLoading}
                            type="dateTime"
                            label="Created At:"
                            value={formatter.format(data?.createdAt)}
                        />
                    </div>
                    <div>
                        <RecordField
                            isLoading={isLoading}
                            type="number"
                            label="Conversations:"
                            value={data?.insights?.samplesWithSystemMessage}
                        />
                        <RecordField
                            isLoading={isLoading}
                            type="number"
                            label="Datasets"
                            value={data?.datasetIds.length}
                        />
                    </div>
                </InfoContainer>
            </RenderIf>
        </Content>
    );
};

export default VersionDetails;
