import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    CartesianGrid,
    LabelList,
} from 'recharts';
import { RenderIf } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import { PageHeaderTitle } from 'components/styled';
import { Row, LeyendBadge } from './styled';
import {
    SectionHeader,
    SectionDescription,
    ChartContainer,
} from '../styled';
import useLast30DaysCalls from './getLast30Days';

const CallAnalysis = () => {
    const { agentId } = useParams();
    const { data, isLoading } = useLast30DaysCalls(agentId as string);
    const totalCalls = data.reduce((acc, curr) => {
        if (curr.total) {
            return acc + curr.total;
        }
        return acc;
    }, 0);

    return (
        <>
            <SectionHeader>
                <PageHeaderTitle>Last 30 Days Phone Calls</PageHeaderTitle>
                <SectionDescription>
                    This chart visualizes the total number of phone calls made to
                    our service in the last 30 days. Each bar represents a day and
                    the height of the bar signifies the number of calls made on that day.
                </SectionDescription>
            </SectionHeader>
            <ChartContainer>
                <Row>
                    <RenderIf isTrue={!isLoading}>
                        <LeyendBadge label={`Total: ${totalCalls}`} borderRadius="semi-square" />
                    </RenderIf>
                </Row>
                <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 35,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label" angle={-40} textAnchor="end" />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign="top" layout="horizontal" name="Total calls" />
                        <Bar dataKey="total" fill="#8884d8">
                            <LabelList dataKey="total" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </ChartContainer>
        </>
    );
};

export default CallAnalysis;
