import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { PageHeaderTitle } from 'components/styled';
import context, { Context } from '../context';
import {
    SectionHeader, SectionDescription,
} from './styled';
import CreateBillingLink from './CreateBillingLink';
import SubscriptionDetails from './SubscriptionDetails';
import UsageReports from './UsageReports';

const Billing = () => {
    const { agentData, isLoading } = useContext<Context>(context);
    const { agentId = '' } = useParams();

    return (
        <>
            <SectionHeader>
                <div>
                    <PageHeaderTitle>Billing Information</PageHeaderTitle>
                    <SectionDescription>
                        Manage the billing information for this agent and view usage reports.
                    </SectionDescription>
                </div>
                <RenderIf isTrue={!agentData?.billing}>
                    <CreateBillingLink agentId={agentId} priceLookupKey="pay_as_you_go_0.50" />
                </RenderIf>
            </SectionHeader>
            <RenderIf isTrue={Boolean(agentData?.billing)}>
                <SubscriptionDetails agentData={agentData} isLoading={isLoading} />
                <UsageReports agentId={agentId} />
            </RenderIf>
        </>
    );
};

export default Billing;
