/* eslint-disable react/forbid-dom-props */
import { useEffect, useState } from 'react';
import {
    useNavigate, useParams, Outlet, useMatch,
} from 'react-router-dom';
import {
    Tab, Tabset, Breadcrumbs, Breadcrumb, Button, RenderIf, LoadingShape,
} from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import { isEmpty } from '@rainbow-modules/validation';
import VersionStatusBadge from 'components/VersionStatusBadge';
import useVersion from 'data/firestore/model/version/use';
import getModel from 'data/firestore/model/get';
import useHttpMutation from 'data/firestore/useHttpMutation';
import PageHeader from 'components/PageHeader';
import { PageHeaderTitle } from 'components/styled';
import { Header, PageTitle } from '../../styled';
import { Actions } from '../styled';
import { Provider } from './context';

const Version = () => {
    const { modelId = '', versionId } = useParams();
    const match = useMatch('models/:modelId/version/:versionId/:tabName/*');
    const [isLoading, setIsLoading] = useState(false);
    const [modelName, setModelName] = useState('');
    const navigate = useNavigate();
    const {
        data: version,
        isLoading: isLoadingVersion,
    } = useVersion(modelId as string, versionId as string);
    const { mutate: train } = useHttpMutation<{}, {}>({
        method: 'POST',
        onSuccess() {
            setIsLoading(false);
        },
        onError(error) {
            showAppMessage({
                variant: 'error',
                message: error.message,
                timeout: 5000,
            });
            setIsLoading(false);
        },
    });
    const trainVersion = async () => {
        setIsLoading(true);
        train({
            pathname: `/model/${modelId}/version/${versionId}/train`,
        });
    };
    useEffect(() => {
        (async () => {
            try {
                const model = await getModel(modelId);
                setModelName(model?.name || modelId);
            } catch (error) {
                // No catch
            }
        })();
    }, [modelId]);
    return (
        <div>
            <PageHeader title={(
                <Breadcrumbs>
                    <Breadcrumb
                        label="All Models"
                        onClick={() => navigate('/models/list')}
                    />
                    <Breadcrumb
                        label={(
                            <>
                                <RenderIf isTrue={!isEmpty(modelName)}>
                                    <PageTitle>{modelName}</PageTitle>
                                </RenderIf>
                                <RenderIf isTrue={isEmpty(modelName)}>
                                    <LoadingShape />
                                </RenderIf>
                            </>
                        )}
                        onClick={() => navigate(`/models/${modelId}`)}
                    />
                    <Breadcrumb label={(
                        <PageTitle>
                            {versionId}
                        </PageTitle>
                    )}
                    />
                </Breadcrumbs>
            )}
            />
            <Header className="rainbow-m-bottom_small">
                <div>
                    <PageHeaderTitle className="rainbow-m-bottom_xx-small">
                        Version ID:
                        {' '}
                        {version?.id}
                    </PageHeaderTitle>
                    <RenderIf isTrue={version?.name}>
                        <VersionStatusBadge status={version?.status} />
                    </RenderIf>
                </div>
                <Actions>
                    <RenderIf isTrue={version?.status === 'ready-to-train'}>
                        <Button
                            label="Train"
                            variant="brand"
                            borderRadius="semi-rounded"
                            onClick={trainVersion}
                            isLoading={isLoading}
                            size="small"
                        />
                    </RenderIf>
                </Actions>
            </Header>
            <div className="rainbow-p-horizontal_medium">
                <Tabset
                    variant="line"
                    onSelect={(event, eventName) => {
                        navigate(eventName);
                        event.preventDefault();
                    }}
                    activeTabName={match?.params?.tabName}
                >
                    <Tab name="details" label="Details" />
                    <Tab name="insights" label="Insights" />
                    <Tab name="datasets" label="Datasets" />
                    <Tab name="chat" label="Chat" disabled={version?.status !== 'ready'} />
                </Tabset>
            </div>
            <Provider
                value={{
                    version,
                    isLoading: isLoadingVersion,
                }}
            >
                <Outlet />
            </Provider>
        </div>
    );
};

export default Version;
