/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Input, RenderIf } from 'react-rainbow-components';
import { StateErrorMessage } from 'components/SelectPhoneNumber/styled';
import { TwilioResponse } from 'types';
import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import {
    Container, ItemContainer, ItemDescription, ItemName, LeftContent,
} from './styled';

interface AvailablePhoneNumbersListProps {
    availableNumbers: TwilioResponse[];
    onChange: (number: TwilioResponse) => void;
    value: TwilioResponse;
    showError: boolean;
}

const AvailablePhoneNumbersList = (props: AvailablePhoneNumbersListProps) => {
    const {
        availableNumbers, onChange, value, showError,
    } = props;

    const isSelected = (number: TwilioResponse) => value?.number === number.number;
    const onClick = (number: TwilioResponse) => {
        onChange(number);
    };
    return (
        <>
            <Container>
                {availableNumbers.map((number) => (
                    <ItemContainer
                        key={number.number}
                        isSelected={isSelected(number)}
                        onClick={() => onClick(number)}
                    >
                        <LeftContent>
                            <ItemName isSelected={isSelected(number)}>
                                {formatIntlPhoneNumber(number?.number)}
                            </ItemName>
                            <ItemDescription isSelected={isSelected(number)}>
                                {number.locality}
                                {' '}
                                {number.region}
                            </ItemDescription>
                        </LeftContent>
                        <Input
                            type="radio"
                            name="numbers"
                            value={number.number}
                            onChange={() => onClick(number)}
                            checked={isSelected(number)}
                        />
                    </ItemContainer>
                ))}
            </Container>
            <RenderIf isTrue={showError}>
                <StateErrorMessage>
                    You must select one of the available phone numbers for your group
                </StateErrorMessage>
            </RenderIf>
        </>
    );
};

export default AvailablePhoneNumbersList;
