import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    padding: 20px 24px 0 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-item: center;
`;
