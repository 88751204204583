import {
    Grinning,
    Neutral,
    Pouting,
    SlightlyFrowning,
    SlightlySmiling,
} from 'components/icons';
import { StyledBadge } from './styled';

interface IconMap {
    [key: string]: React.ComponentType;
}

const iconMap: IconMap = {
    'Very Helpful': Grinning,
    Helpful: SlightlySmiling,
    Neutral,
    'Mixed Emotions': Neutral,
    Detrimental: SlightlyFrowning,
    'Very Detrimental': Pouting,
};

interface SentimentBadgeProps {
    sentiment: {
        score: number | null;
        magnitude: number | null;
        label: string;
    } | null;
}

const SentimentBadge = (props: SentimentBadgeProps) => {
    const { sentiment } = props;
    if (sentiment !== null) {
        const Icon = iconMap[sentiment.label] || iconMap.Neutral;
        return (
            <StyledBadge
                borderRadius="semi-square"
                sentimentLabel={sentiment.label || 'Unknown'}
                title={`score: ${sentiment?.score} magnitud: ${sentiment?.magnitude}`}
            >
                <Icon />
                <span className="rainbow-m-left_x-small">
                    {sentiment.label || 'Unknown'}
                </span>
            </StyledBadge>
        );
    }
    return null;
};

export default SentimentBadge;
