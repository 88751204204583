import { Button } from 'react-rainbow-components';
import { AgentFormFooterProps } from './types';
import { Footer, StyledSubmitButton } from './styled';

const AgentFormFooter = ({
    formId,
    submitButtonLabel = 'Create',
    submitDisabled = false,
    onCancelClick = () => {},
}: AgentFormFooterProps) => (
    <Footer>
        <Button
            label="Close"
            variant="neutral"
            borderRadius="semi-rounded"
            className="rainbow-m-right_medium"
            onClick={onCancelClick}
        />
        <StyledSubmitButton
            label={submitButtonLabel}
            variant="brand"
            form={formId}
            type="submit"
            borderRadius="semi-rounded"
            disabled={submitDisabled}
        />
    </Footer>
);

export default AgentFormFooter;
