import { NavigationButtonColumn } from '@rainbow-modules/listview';
import styled from 'styled-components';
import { color } from 'theme';

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 20px 8px 20px;
`;
export const SectionTitle = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: ${color('text.main')};
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-right: 4px;
    padding-left: 4px;
    height: 100%;
    gap: 4px;
`;
export const ConversationCounterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
`;

export const StyledLoadingContainer = styled.div`
    padding-right: 12px;
    padding-left: 12px;
    margin-top: 14px;
    height: 10%;

    width: ${() => Math.floor(Math.random() * (120 - 92) + 92)}px;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 12px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;
