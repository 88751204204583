import {
    useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import {
    sum, mean, quantile, median,
} from 'simple-statistics';
import { PlotData } from 'plotly.js';
import { RecordField } from '@rainbow-modules/record';
import useReportData from 'hooks/useReportData';
import { PageHeaderTitle } from 'components/styled';
import {
    SectionHeader,
    SectionSummaryContainer,
    SectionDescription,
    ChartContainer,
} from '../styled';
import { StyledPlot } from './styled';

interface TrendData {
    dates: string[];
    turnsPerDay: number[];
    turnsPerCallAverage: number[];
    numCalls: number[];
}

const formatValue = (value: string | number, suffix: string = '') => {
    if (typeof value === 'string') return value;

    if (Number.isInteger(value)) return `${value}${suffix}`;

    return `${value.toFixed(0)}${suffix}`;
};

const formatValueRange = (
    a: string | number | null,
    b: string | number | null,
    suffix: string = '',
) => {
    if (
        (a === null || typeof a === 'string') && (b === null || typeof b === 'string')
    ) return '-';

    if (typeof a === 'number' && b === null) return `> ${a.toFixed(0)}${suffix}`;

    if (a === null && typeof b === 'number') return `< ${b.toFixed(0)}${suffix}`;

    return `${(a as number).toFixed(0)}${suffix} - ${(b as number).toFixed(0)}${suffix}`;
};

const CallAnalysis = () => {
    const [activeAgentTypes, setActiveAgentTypes] = useState<string[]>([]);
    const [plotWidth, setPlotWidth] = useState<number>(100);
    const containerRef = useRef<HTMLDivElement>(null);
    const { data: dataFreqDist = {} } = useReportData({
        reportType: 'plot_call-turns-analysis__distribution',
    });

    const { data: dataTrend = {}, isLoading: isLoadingTrendReport } = useReportData({
        reportType: 'plot_call-turns-analysis__trend',
    });

    const histogramData = useMemo(
        () => {
            const plotData = dataFreqDist as Record<string, number[]>;
            return Object.keys(plotData).reduce(
                (result: Record<string, unknown>[], key: string) => {
                    if (plotData[key].length > 0) {
                        return [
                            ...result,
                            {
                                x: plotData[key],
                                type: 'histogram',
                                name: key,
                                opacity: 0.6,
                            },
                        ];
                    }
                    return result;
                },
                [],
            );
        },
        [dataFreqDist],
    );

    const distributionData = useMemo(
        () => {
            const plotData = dataFreqDist as Record<string, number[]>;
            return Object.keys(plotData).reduce(
                (result: Record<string, unknown>[], key: string) => {
                    if (plotData[key].length > 0) {
                        return [
                            ...result,
                            {
                                y: plotData[key],
                                type: 'box',
                                name: key,
                            },
                        ];
                    }
                    return result;
                },
                [],
            );
        },
        [dataFreqDist],
    );

    const trendData = useMemo(
        () => {
            const plotData = dataTrend as Record<string, TrendData>;
            return Object.keys(plotData).reduce(
                (result: Record<string, unknown>[], key: string) => {
                    const { dates, turnsPerCallAverage, numCalls } = plotData[key];
                    return [
                        ...result,
                        {
                            x: dates,
                            y: numCalls,
                            type: 'bar',
                            xaxis: 'x',
                            yaxis: 'y2',
                            name: 'Total calls',
                            legendgroup: key,
                            legendgrouptitle: {
                                text: key,
                            },
                            legend: false,
                            opacity: 0.5,
                        },
                        {
                            x: dates,
                            y: turnsPerCallAverage,
                            type: 'scatter',
                            mode: 'lines+markers',
                            name: 'Average turns per call',
                            legendgrouptitle: {
                                text: key,
                                size: 16,
                            },
                            legendgroup: key,
                            markers: {
                                size: 10,
                            },
                        },
                    ];
                },
                [],
            );
        },
        [dataTrend],
    );

    const callTurnStats = useMemo(() => {
        if (activeAgentTypes.length === 0) {
            return {
                totalCalls: '-',
                averageDailyTurnsPerCall: '-',
                meanCallTurnsPerCall: '-',
                median: '-',
                quantile25: '-',
                quantile50: '-',
                quantile75: '-',
            };
        }

        const plotData = dataTrend as Record<string, TrendData>;
        const distData = dataFreqDist as Record<string, number[]>;

        const totalCalls = activeAgentTypes.reduce(
            (result: number, key: string) => {
                const { numCalls } = plotData[key];
                return result + sum(numCalls);
            },
            0,
        );

        const dailyTurnsPerCall = Object.values(
            activeAgentTypes.reduce(
                (acc: Record<string, Record<string, number>>, key: string) => {
                    const result = acc;
                    const { numCalls, turnsPerDay, dates } = plotData[key];
                    dates.forEach((dateStr, index) => {
                        if (!result[dateStr]) {
                            result[dateStr] = {
                                numCalls: 0,
                                totalTurns: 0,
                            };
                        }
                        result[dateStr].numCalls += numCalls[index];
                        result[dateStr].totalTurns += turnsPerDay[index];
                    });
                    return result;
                },
                {},
            ),
        ).map(({ numCalls, totalTurns }: Record<string, number>) => totalTurns / numCalls);

        const callTurns = activeAgentTypes.reduce(
            (result: number[], key: string) => [...result, ...(distData[key] || [])],
            [],
        );

        const [quantile25, quantile50, quantile75] = callTurns.length > 0
            ? quantile(callTurns, [0.25, 0.5, 0.75])
            : [0, 0, 0];

        return {
            totalCalls,
            averageDailyTurnsPerCall: (dailyTurnsPerCall.length > 0 ? mean(dailyTurnsPerCall) : 0),
            meanCallTurnsPerCall: (callTurns.length > 0 ? mean(callTurns) : 0),
            median: (callTurns.length > 0 ? median(callTurns) : 0),
            quantile25,
            quantile50,
            quantile75,
        };
    }, [activeAgentTypes, dataTrend, dataFreqDist]);

    useEffect(() => {
        if (Object.keys(dataTrend).length > 0) {
            setActiveAgentTypes(Object.keys(dataTrend));
        }
    }, [dataTrend]);

    useLayoutEffect(() => setPlotWidth(containerRef.current?.clientWidth || 100), []);

    return (
        <div className="rainbow-flex__column" ref={containerRef}>
            <SectionHeader>
                <PageHeaderTitle>
                    Turns Per Call Trend
                </PageHeaderTitle>
                <SectionDescription>
                    Turns per call average trend in the last 30 days
                </SectionDescription>
            </SectionHeader>
            <SectionSummaryContainer $maxWidth={plotWidth}>
                <RecordField
                    label="Total Calls"
                    isLoading={isLoadingTrendReport}
                    value={(callTurnStats.totalCalls)}
                />
                <RecordField
                    label="Average daily turns per call"
                    isLoading={isLoadingTrendReport}
                    value={formatValue(callTurnStats.averageDailyTurnsPerCall, ' turns')}
                />
                <RecordField
                    label="Average turns per call"
                    isLoading={isLoadingTrendReport}
                    value={formatValue(callTurnStats.meanCallTurnsPerCall, ' turns')}
                />
                <RecordField
                    label="Turns amount 25% of calls"
                    isLoading={isLoadingTrendReport}
                    value={formatValueRange(null, callTurnStats.quantile25, ' turns')}
                />
                <RecordField
                    label="Turns amount 50% of calls"
                    isLoading={isLoadingTrendReport}
                    value={formatValueRange(null, callTurnStats.median, ' turns')}
                />
                <RecordField
                    label="Turns amount 50% of calls between"
                    isLoading={isLoadingTrendReport}
                    value={formatValueRange(
                        callTurnStats.quantile25,
                        callTurnStats.quantile75,
                        ' turns',
                    )}
                />
                <RecordField
                    label="Turns amount 25% of calls"
                    isLoading={isLoadingTrendReport}
                    value={formatValueRange(callTurnStats.quantile75, null, ' turns')}
                />
            </SectionSummaryContainer>
            <ChartContainer $maxWidth={plotWidth}>
                <StyledPlot
                    config={{ displayModeBar: false }}
                    data={trendData}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        xaxis: {
                            title: 'Last 30 days',
                        },
                        yaxis: {
                            title: 'Turns per call average',
                            rangemode: 'tozero',
                        },
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right',
                            title: 'Number of calls per day',
                            rangemode: 'tozero',
                        },
                        legend: {
                            x: 1.1,
                            xanchor: 'left',
                            groupclick: 'togglegroup',
                            itemclick: 'toggle',
                            itemdoubleclick: false,
                        },
                    }}
                    useResizeHandler
                    onLegendClick={(e) => {
                        const { expandedIndex, data: eventData, node: { textContent } } = e;
                        const agentType = (
                            Number.isInteger(expandedIndex)
                                ? (eventData[expandedIndex] as PlotData)?.legendgroup as string
                                : textContent as string
                        );

                        if (activeAgentTypes.includes(agentType)) {
                            setActiveAgentTypes(
                                activeAgentTypes.filter((agentClass) => agentClass !== agentType),
                            );
                        } else {
                            setActiveAgentTypes([
                                ...activeAgentTypes,
                                agentType,
                            ]);
                        }

                        return true;
                    }}
                />
            </ChartContainer>
            <SectionHeader>
                <PageHeaderTitle>
                    Turns Per Call Frequency
                </PageHeaderTitle>
                <SectionDescription>
                    Turns per call frequency in the last 30 days
                </SectionDescription>
            </SectionHeader>
            <ChartContainer $maxWidth={plotWidth}>
                <StyledPlot
                    config={{ displayModeBar: false }}
                    data={histogramData}
                    layout={{
                        autosize: true,
                        barmode: 'overlay',
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        xaxis: {
                            title: 'Turns per call',
                        },
                        yaxis: {
                            title: 'Amount of calls',
                        },
                    }}
                    useResizeHandler
                />
            </ChartContainer>
            <SectionHeader>
                <PageHeaderTitle>
                    Turns Per Call Distribution
                </PageHeaderTitle>
                <SectionDescription>
                    Turns per call distribution in the last 30 days (by Agent Type)
                </SectionDescription>
            </SectionHeader>
            <ChartContainer $maxWidth={plotWidth}>
                <StyledPlot
                    config={{ displayModeBar: false }}
                    data={distributionData}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        yaxis: {
                            title: 'Turns per call',
                        },
                    }}
                    useResizeHandler
                />
            </ChartContainer>
        </div>
    );
};

export default CallAnalysis;
