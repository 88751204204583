import {
    confirmModal, hideAppSpinner, showAppMessage, showAppSpinner,
} from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { useCallback } from 'react';

interface DeleteAgentHookProps {
    agentId: string;
    onSuccess?: () => void;
}

const useDeleteAgent = ({
    agentId,
    onSuccess = () => {},
}: DeleteAgentHookProps) => {
    const {
        mutateAsync: deleteAgent,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentId}`,
        method: 'DELETE',
        server: process.env.REACT_APP_BASE_URL,
    });

    const openDeleteConfirmation = useCallback(async () => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Agent',
            question:
                'Removing this agent will delete it permanently. Are you sure you want to continue?',
            okButtonLabel: 'Delete',
            borderRadius: 'semi-rounded',
        });
        if (result) {
            try {
                showAppSpinner();
                await deleteAgent({});
                onSuccess();
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: (error as Error).message,
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        }
    }, [deleteAgent, onSuccess]);

    return openDeleteConfirmation;
};

export default useDeleteAgent;
