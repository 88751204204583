import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { AddService, EntityGet } from "../types";
import { Dataset } from "./types";
import get from "./get";

export type DatasetAddService<T> = (
  doc: Omit<T, "id">
) => Promise<EntityGet<T>>;

const add: DatasetAddService<Dataset> = async (data) => {
  const collectionRef = collection(db, `/dataset`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(docRef.id) as Promise<EntityGet<Dataset>>;
};

export default add;
