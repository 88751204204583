import {
    useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { sum, mean } from 'simple-statistics';
import { RecordField } from '@rainbow-modules/record';
import useReportData from 'hooks/useReportData';
import { PageHeaderTitle } from 'components/styled';
import {
    SectionHeader,
    SectionSummaryContainer,
    SectionDescription,
    ChartContainer,
} from '../styled';
import { StyledPlot } from './styled';

interface TrendData {
    dates: string[];
    totalDurations: number[];
    numCalls: number[];
}

const formatDuration = (value: string | number) => {
    if (typeof value === 'string') return value;

    const minutes = Math.trunc(value / 60);
    const seconds = value - minutes * 60;

    const output = [];

    if (minutes > 0) output.push(`${minutes}m`);

    if (seconds > 0) output.push(`${seconds}s`);

    return output.length > 0 ? output.join(' ') : '0';
};

const GrowthAnalysis = () => {
    const [plotWidth, setPlotWidth] = useState<number>(100);
    const containerRef = useRef<HTMLDivElement>(null);

    const { data: dataTrend = {}, isLoading: isLoadingTrendReport } = useReportData({
        reportType: 'plot_weekly-growth-analysis__trend',
    });

    const trendData = useMemo(
        () => {
            const plotData = dataTrend as Record<string, TrendData>;

            const processedData = Object.keys(dataTrend).reduce(
                (acc: Record<string, {
                    totalCalls: number;
                    totalMinutes: number;
                }>, key: string) => {
                    const result = acc;
                    const { totalDurations, numCalls, dates } = plotData[key];
                    dates.forEach((dateStr, index) => {
                        if (!result[dateStr]) {
                            result[dateStr] = {
                                totalMinutes: 0,
                                totalCalls: 0,
                            };
                        }
                        result[dateStr].totalMinutes += totalDurations[index];
                        result[dateStr].totalCalls += numCalls[index];
                    });
                    return result;
                },
                {},
            );

            const dates = Object.keys(processedData);
            const {
                totalCalls: totalNumCalls,
                totalMinutes: totalSeconds,
            } = Object.values<Record<string, number>>(processedData).reduce(
                (acc: Record<string, number[]>, val: Record<string, number>) => {
                    const { totalMinutes, totalCalls } = val;
                    acc.totalCalls.push(totalCalls);
                    acc.totalMinutes.push(totalMinutes);
                    return acc;
                },
                {
                    totalCalls: [],
                    totalMinutes: [],
                },
            );

            return [
                {
                    x: dates,
                    y: totalNumCalls,
                    type: 'bar',
                    xaxis: 'x',
                    yaxis: 'y2',
                    name: 'Total calls',
                    legendgroup: 'Growth',
                    opacity: 0.5,
                },
                {
                    x: dates,
                    y: totalSeconds,
                    type: 'scatter',
                    mode: 'lines+markers',
                    name: 'Total Seconds',
                    legendgroup: 'growth',
                    markers: {
                        size: 10,
                    },
                },
            ];
        },
        [dataTrend],
    );

    const growStats = useMemo(() => {
        const activeAgentTypes = Object.keys(dataTrend);
        if (activeAgentTypes.length === 0) {
            return {
                totalCalls: '-',
                totalMinutes: '-',
                meanDailyCalls: '-',
                meanDailyTime: '-',
            };
        }

        const plotData = dataTrend as Record<string, TrendData>;

        const totalCalls = activeAgentTypes.reduce(
            (result: number, key: string) => {
                const { numCalls } = plotData[key];
                return result + sum(numCalls);
            },
            0,
        );

        const totalMinutes = activeAgentTypes.reduce(
            (result: number, key: string) => {
                const { totalDurations } = plotData[key];
                return result + sum(totalDurations);
            },
            0,
        );

        const dailyCalls = Object.values<number>(
            activeAgentTypes.reduce(
                (acc: Record<string, number>, key: string) => {
                    const result = acc;
                    const { numCalls, dates } = plotData[key];
                    dates.forEach((dateStr, index) => {
                        if (!result[dateStr]) {
                            result[dateStr] = 0;
                        }
                        result[dateStr] += numCalls[index];
                    });
                    return result;
                },
                {},
            ),
        );

        const dailyTimeConsumed = Object.values<number>(
            activeAgentTypes.reduce(
                (acc: Record<string, number>, key: string) => {
                    const result = acc;
                    const { totalDurations, dates } = plotData[key];
                    dates.forEach((dateStr, index) => {
                        if (!result[dateStr]) {
                            result[dateStr] = 0;
                        }
                        result[dateStr] += totalDurations[index];
                    });
                    return result;
                },
                {},
            ),
        );

        return {
            totalCalls,
            totalMinutes,
            meanDailyCalls: (dailyCalls.length > 0 ? mean(dailyCalls).toFixed(2) : 0),
            meanDailyTime: (dailyTimeConsumed.length > 0 ? Math.round(mean(dailyTimeConsumed)) : 0),
        };
    }, [dataTrend]);

    useLayoutEffect(() => setPlotWidth(containerRef.current?.clientWidth || 100), []);

    return (
        <div className="rainbow-flex__column" ref={containerRef}>
            <SectionHeader>
                <PageHeaderTitle>
                    Weekly stats
                </PageHeaderTitle>
                <SectionDescription>
                    Number of calls and their duration in the last 7 days
                </SectionDescription>
            </SectionHeader>
            <SectionSummaryContainer $maxWidth={plotWidth}>
                <RecordField
                    label="Total calls made"
                    isLoading={isLoadingTrendReport}
                    value={(growStats.totalCalls)}
                />
                <RecordField
                    label="Total time consumed"
                    isLoading={isLoadingTrendReport}
                    value={formatDuration(growStats.totalMinutes)}
                />
                <RecordField
                    label="Average daily calls"
                    isLoading={isLoadingTrendReport}
                    value={growStats.meanDailyCalls}
                />
                <RecordField
                    label="Average consumed time per day"
                    isLoading={isLoadingTrendReport}
                    value={formatDuration(growStats.meanDailyTime)}
                />
            </SectionSummaryContainer>
            <ChartContainer $maxWidth={plotWidth}>
                <StyledPlot
                    config={{ displayModeBar: false }}
                    data={trendData as any}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        xaxis: {
                            title: 'Last 7 days',
                        },
                        yaxis: {
                            title: 'Total minutes',
                            rangemode: 'tozero',
                        },
                        yaxis2: {
                            overlaying: 'y',
                            side: 'right',
                            title: 'Total Calls',
                            rangemode: 'tozero',
                        },
                        legend: {
                            x: 1.1,
                            xanchor: 'left',
                            groupclick: 'togglegroup',
                            itemclick: 'toggle',
                            itemdoubleclick: false,
                        },
                    }}
                    useResizeHandler
                />
            </ChartContainer>
        </div>
    );
};

export default GrowthAnalysis;
