import { ButtonIcon } from 'react-rainbow-components';
import { Pencil, Trash } from 'components/icons';
import { confirmModal } from '@rainbow-modules/app';
import { useNavigate } from 'react-router-dom';
import { Group } from 'data/firestore/group/types';
import useOpenTreeFormModal from 'hooks/useOpenTreeFormModal';
import updateGroup from 'data/firestore/group/update';
import removeGroup from 'data/firestore/group/remove';
import { StyledActionsColumn } from 'components/styled';

interface Props {
    row?: Group;
}

interface Values {
    name: string;
}

const ActionsColumn = ({ row }: Props) => {
    const { id, name, datasetId } = row as Group;
    const [openModal, closeModal] = useOpenTreeFormModal();
    const navigate = useNavigate();

    const openEditTitleForm = () => openModal({
        title: 'Edit Group',
        submitButtonLabel: 'Update Group',
        entity: 'group',
        initialValues: {
            name,
        },
        onSubmit: (values: Values) => {
            updateGroup(id, { name: values.name });
            closeModal();
        },
    });

    const openDeleteConfirmation = async () => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Group',
            question:
                'Removing this group will delete it permanently. Are you sure you want to remove this group?',
            okButtonLabel: 'Delete',
        });
        if (result) {
            removeGroup(id);
            navigate(`/dataset/${datasetId}`);
        }
    };
    return (
        <StyledActionsColumn>
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Pencil />}
                onClick={openEditTitleForm}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                icon={<Trash />}
                onClick={openDeleteConfirmation}
            />
        </StyledActionsColumn>
    );
};

export default ActionsColumn;
