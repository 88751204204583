import { IconProps } from './types';

const Chart = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M20 5H17V19H20V5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 10H10V19H13V10Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 12H3V19H6V12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Chart;
