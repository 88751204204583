/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import { CSSProperties } from 'styled-components';
import { useReduxForm } from '@rainbow-modules/hooks';
import { MagicPencil } from 'components/icons';
import {
    Actions, MagicPencilButton, ModalTitle, StyledModal, StyledTextarea,
} from './styled';
import HelpMeWriteModal from './helpMeWriteModal';

interface MessageContentProps {
    id?: string;
    className?: string;
    style?: CSSProperties;
    value?: string;
    onChange?: (value: string) => void;
}

const MessageContent = (props: MessageContentProps) => {
    const {
        id,
        className,
        style,
        value = 'user',
        error,
        onChange = () => {},
    } = useReduxForm(props);
    const [isOpenHelpWrite, setIsOpenHelpWrite] = useState(false);
    const openHelpWriteModal = () => setIsOpenHelpWrite(true);

    return (
        <>
            <StyledTextarea
                id={id}
                className={className}
                style={style}
                label="Content"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                hideLabel
                rows={5}
                required
                error={error}
                borderRadius="semi-rounded"
                placeholder="Enter a message here..."
                grow
            />
            <Actions>
                <MagicPencilButton
                    disabled={!value}
                    icon={<MagicPencil />}
                    borderRadius="semi-rounded"
                    onClick={openHelpWriteModal}
                />
            </Actions>
            <StyledModal
                isOpen={isOpenHelpWrite}
                onRequestClose={() => setIsOpenHelpWrite(false)}
                title={<ModalTitle>Help me Write</ModalTitle>}
                size="medium"
                borderRadius="semi-rounded"
            >
                <HelpMeWriteModal
                    message={value}
                    onInsert={onChange}
                    onRequestClose={() => setIsOpenHelpWrite(false)}
                />
            </StyledModal>
        </>
    );
};

export default MessageContent;
