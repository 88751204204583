import {
    ReactNode, useCallback, useMemo, useState,
} from 'react';
import { DatePickerModal, Option } from 'react-rainbow-components';
import { getFormatter } from 'data/services/date/formatter';
import { StyledPickList } from './styled';

interface PicklistValue {
    label?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

interface DatePickerFilterProps {
    value?: Date[];
    onChange?: (value: Date[]) => void;
    [key: string]: unknown;
}

const dateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

const DatePickerFilter = ({
    value: valueInProps = [],
    onChange = () => {},
    ...rest
}: DatePickerFilterProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const picklictValue = useMemo(
        () => {
            if (valueInProps.length === 0) return { name: 'all', label: 'All Time' };

            if (valueInProps.length === 1) {
                return {
                    name: 'custom',
                    label: 'Custom',
                };
            }

            const [from, to] = valueInProps;

            const isSingleDay = Math.abs(to.getTime() - from.getTime()) <= 1000 * 60 * 60 * 24;

            if (isSingleDay) {
                if (from.getTime() <= Date.now() && Date.now() <= to.getTime()) {
                    return {
                        name: 'today',
                        label: 'Today',
                    };
                }

                const yesterday = Date.now() - (1000 * 60 * 60 * 24);
                if (from.getTime() <= yesterday && yesterday <= to.getTime()) {
                    return {
                        name: 'yesterday',
                        label: 'Yesterday',
                    };
                }
            }

            return {
                name: 'custom',
                label: `${dateFormatter.format(from)} - ${dateFormatter.format(to)}`,
            };
        },
        [valueInProps],
    );

    const handlePicklistChange = useCallback(
        (newValue: PicklistValue) => {
            const { name } = newValue;

            if (name === 'all') {
                return onChange([]);
            }

            if (name === 'today') {
                const startOf = new Date();
                const endOf = new Date();
                startOf.setHours(0, 0, 0, 0);
                endOf.setHours(23, 59, 59, 0);
                return onChange([startOf, endOf]);
            }

            if (name === 'yesterday') {
                const yesterday = Date.now() - (1000 * 60 * 60 * 24);
                const startOf = new Date(yesterday);
                const endOf = new Date(yesterday);
                startOf.setHours(0, 0, 0, 0);
                endOf.setHours(23, 59, 59, 0);
                return onChange([startOf, endOf]);
            }
            return openModal();
        },
        [onChange, openModal],
    );

    const handleDatePickerChange = useCallback(
        (newValue: Date | Date[]) => {
            const newVal = Array.isArray(newValue) ? newValue : [newValue];
            if (newVal.length > 1) {
                closeModal();
            }
            onChange(newVal);
        },
        [closeModal, onChange],
    );

    return (
        <>
            <StyledPickList
                onChange={handlePicklistChange}
                value={picklictValue}
                {...rest}
            >
                <Option name="all" label="All Time" />
                <Option name="today" label="Today" />
                <Option name="yesterday" label="Yesterday" />
                <Option name="custom" label="Custom Date" />
            </StyledPickList>
            <DatePickerModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                onChange={handleDatePickerChange}
                value={valueInProps}
                maxDate={new Date()}
                selectionType="range"
                title="Select Date(s)"
                borderRadius="semi-square"
            />
        </>
    );
};

export default DatePickerFilter;
