import useHttpMutationStream from 'hooks/useHttpMutationStream';
import { Message } from 'components/ChatMessage/types';

interface SendMessageRequest {
    temperature: number;
    systemMessage: string;
    messages: Pick<Message, 'role' | 'content'>[];
}

interface SendMessageResponse {
    content?: string;
}

const useModelVersionChat = ({
    modelId,
    versionId,
    onError = () => {},
}: {
    modelId?: string;
    versionId?: string;
    onError?: (error: Error) => void;
}) => {
    const {
        messageStream,
        mutate: sendMessages,
        isLoading: isSendingMessage,
    } = useHttpMutationStream<SendMessageRequest, SendMessageResponse>({
        pathname: `/model/${modelId}/version/${versionId}/chat`,
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
        onError,
    });

    return {
        isSendingMessage,
        currentResponseStream: messageStream,
        sendMessages,
    };
};

export default useModelVersionChat;
