import {
  collection,
  onSnapshot,
  FirestoreError,
  query,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../firebase";
import { EntityGet, EntityServerGet, ListServiceOpts } from "../types";
import { Dataset } from "./types";
import { transformMetadata, processTimestampFields } from "../utils";

export type DatasetOnSnapshotCollectionService<T> = (
  opts: ListServiceOpts,
  callback: (docs: Array<T>) => void,
  onError?: (error: FirestoreError) => void
) => Unsubscribe;

const onSnapshotCollection: DatasetOnSnapshotCollectionService<
  EntityGet<Dataset>
> = (opts: ListServiceOpts, callback, onError = () => {}) => {
  const collectionRef = collection(db, `/dataset`);
  const { disableCache } = opts;
  const q = opts.listQueryFn
    ? opts.listQueryFn(collectionRef)
    : query(collectionRef);
  return onSnapshot(
    q,
    { includeMetadataChanges: disableCache },
    (querySnap) => {
      const { metadata } = querySnap;
      const { fromCache } = metadata;
      if (disableCache && fromCache) return;
      callback(
        querySnap.docs.map((doc) =>
          processTimestampFields(
            transformMetadata(
              {
                ...doc.data(),
                id: doc.id,
              } as EntityServerGet<Dataset>,
              doc.metadata
            )
          )
        )
      );
    },
    onError
  );
};

export default onSnapshotCollection;
