import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 12px 12px 20px;
`;

export const Title = styled.h1`
    font-size: 15px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    height: 40px;
`;
