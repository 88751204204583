import styled from 'styled-components';
import { Input, Table } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import { NavigationButtonColumn } from '@rainbow-modules/listview';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    margin-bottom: 8px;
`;

export const Content = styled.div`
    padding: 8px 20px;
`;

export const DataField = styled(RecordField)`
    > span:first-child {
        font-weight: 700;
        font-size: 0.9rem;
        color: #000;
    }

    > span:last-child {
        font-weight: 400;
        font-size: 12px;
    }
`;

export const Divider = styled.div`
    flex: 1;
`;

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const StyledTable = styled(Table)`
    height: fit-content;
    margin-bottom: 12px;
    padding: 0;
`;

export const TableFooter = styled.div`
    height: fit-content;
    margin-bottom: 32px;
    padding: 0 16px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const SearchInput = styled(Input)`
    width: 40%;
`;
