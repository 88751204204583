import { ComponentType } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import DefaultMetadataRenderer from './renderers/defaultRenderer';
import IntentMatchMetadataRenderer from './renderers/intentMatch';
import { MetadataRendererProps } from './types';
import { Divider, Container, StyledSpinner } from './styled';

interface MessageMetadataProps {
    chatId?: string;
    messageId?: string;
}

interface MetadataRenderMap {
    [key: string]: ComponentType<MetadataRendererProps>;
}

const metadataRendererMap:MetadataRenderMap = {
    'intent-match': IntentMatchMetadataRenderer,
    default: DefaultMetadataRenderer,
};

const MessageMetadata = ({ chatId, messageId }: MessageMetadataProps) => {
    const [metadata, isLoadingMetadata] = useCollection({
        path: `/session/${chatId}/conversation/${messageId}/metadata`,
        flat: true,
        disabled: !(chatId && messageId),
        track: [messageId, chatId],
    });
    const metadataList = metadata.map((item, index) => {
        const Component = metadataRendererMap[item.id] || metadataRendererMap.default;
        const { id, ...data } = item;
        return (
            <div key={id}>
                <RenderIf isTrue={index > 0}>
                    <Divider />
                </RenderIf>
                <Component metadata={data} title={item.id} />
            </div>
        );
    });
    return (
        <Container>
            <RenderIf isTrue={isLoadingMetadata}>
                <StyledSpinner type="arc" variant="brand" />
            </RenderIf>
            <RenderIf isTrue={!isLoadingMetadata}>
                {metadataList}
            </RenderIf>
        </Container>
    );
};

export default MessageMetadata;
