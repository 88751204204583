import { Field, useFormState } from 'react-final-form';
import { useContext, useMemo } from 'react';
import {
    Option, Picklist, Slider, RenderIf,
} from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import ModelPicklist from 'components/ModelPicklist';
import ModelVersionPicklist from 'components/ModelVersionPicklist';
import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import { BASIC_MODELS } from '../../../../../../constants';
import ImportDataInput from './importDataInput';
import {
    StyledTextarea, FieldsContainer, FormContainer, FieldGrow,
} from './styled';
import context, { Context } from '../../context';
import { SectionTitle } from '../styled';
import { Agent } from '../../../../../../data/firestore/agent/types';

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const validateModel = (value: Agent['model']) => {
    if (isEmpty(value?.id)) return 'This field is required';
    return undefined;
};

const validateModelVersion = (value: Agent['model']) => {
    if (isEmpty(value?.version)) return 'This field is required';
    return undefined;
};

const isRootModel = (modelId?: string) => BASIC_MODELS.includes(modelId as string);

const Fields = () => {
    const { agentData, isLoading: isLoadingAgentData } = useContext<Context>(context);
    const { values } = useFormState();
    const phoneNumberOptions = useMemo(
        () => (agentData?.phoneNumbers)?.map(
            (phoneNumber) => (
                <Option
                    key={phoneNumber.number}
                    name={phoneNumber.number}
                    label={formatIntlPhoneNumber(phoneNumber.number)}
                    value={phoneNumber.number}
                />
            ),
        ),
        [agentData?.phoneNumbers],
    );
    return (
        <FormContainer>
            <SectionTitle className="rainbow-m-bottom_medium rainbow-m-top_small">Campaign Configuration</SectionTitle>
            <FieldsContainer>
                <Field
                    name="caller"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Picklist}
                    label="Caller Phone Number"
                    labelAlignment="left"
                    borderRadius="semi-rounded"
                    required
                    placeholder="Select the caller phone number"
                    className="rainbow-m-bottom_x-large"
                    validate={validateNotEmpty}
                    isLoading={isLoadingAgentData}
                >
                    {phoneNumberOptions}
                </Field>
            </FieldsContainer>
            <FieldsContainer>
                <FieldGrow
                    name="model"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={ModelPicklist}
                    label="Select Model"
                    labelAlignment="left"
                    borderRadius="semi-rounded"
                    required
                    placeholder="Select Model"
                    className="rainbow-m-bottom_x-large"
                    validate={validateModel}
                    isLoading={isLoadingAgentData}
                />
            </FieldsContainer>
            <RenderIf isTrue={Boolean(values?.model?.id) && !isRootModel(values?.model?.id)}>
                <FieldsContainer>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={ModelVersionPicklist}
                        name="model"
                        required
                        borderRadius="semi-rounded"
                        className="rainbow-m-bottom_x-large"
                        validate={validateModelVersion}
                    />
                </FieldsContainer>
            </RenderIf>
            <FieldsContainer>
                <Field
                    name="temperature"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Slider}
                    label="Select Temperature"
                    labelAlignment="left"
                    borderRadius="semi-rounded"
                    required
                    className="rainbow-m-bottom_x-large"
                    validate={validateNotEmpty}
                    isLoading={isLoadingAgentData}
                    min={0}
                    max={2}
                    step={0.1}
                />
            </FieldsContainer>

            <SectionTitle className="rainbow-m-bottom_medium rainbow-p-top_large">Campaign Information</SectionTitle>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={ImportDataInput}
                name="contacts"
                required
                validate={validateNotEmpty}
            />
            <Field
                name="systemMessageTemplate"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledTextarea}
                grow
                label="System Message Template"
                labelAlignment="left"
                borderRadius="semi-rounded"
                required
                validate={(value) => {
                    if (!value) return 'System message is required';
                    return undefined;
                }}
                rows={17}
                placeholder="Enter the system message template for the campaign."
                className="rainbow-p-bottom_x-large"
            />
        </FormContainer>
    );
};

export default Fields;
