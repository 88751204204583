import { Timestamp } from 'firebase/firestore';

const headers = ['Phone Number', 'Duration (seconds)', 'Status', 'Date Time'];
const fields = ['to', 'session.duration', 'session.status', 'session.createdAt'];

const getFieldValue = (obj: Record<string, unknown>, field: string): unknown => {
    if (typeof field === 'string') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return field.split('.').reduce((acc, item) => {
            const value = acc[item];
            if (value !== undefined) {
                return value;
            }
            return '';
        }, obj);
    }
    return '';
};

const convertToCSV = (data: Record<string, unknown>[]) => {
    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach((row) => {
        const values = fields.map((field) => {
            let value = getFieldValue(row, field);
            // This is especific from this data structure then this can't be reused
            if (field === 'session.status' && !value) {
                value = row.status as string;
            }
            if (value) {
                let finalValue = value;
                if (value instanceof Timestamp) {
                    finalValue = value.toDate();
                }
                const escaped = (`${finalValue}`).replace(/"/g, '\\"');
                return `"${escaped}"`;
            }
            return '""';
        });
        csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
};

const downloadCSV = (data: Record<string, unknown>[]) => {
    const csvString = convertToCSV(data);

    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.csv';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
};

export default downloadCSV;
