import { Container, Title, Description } from './styled';

interface EmptyMessageProps {
    className?: string;
    title?: string | React.ReactNode;
    description?: string | React.ReactNode;
    action?: React.ReactNode;
    icon?: React.ReactNode;
}

const EmptyMessage = (props: EmptyMessageProps) => {
    const {
        icon, title, description, action, className,
    } = props;

    return (
        <Container className={className}>
            {icon}
            <Title>{title}</Title>
            <Description>{description}</Description>
            {action}
        </Container>
    );
};

export default EmptyMessage;
