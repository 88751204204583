import {
    Button, ButtonIcon, Drawer, RadioGroup,
} from 'react-rainbow-components';
import { useState, useEffect } from 'react';
import { Pencil } from 'components/icons';
import updateSession from 'data/firestore/session/update';
import { Container, Form, Question } from './styled';
import evaluateConversation from './evaluateConversation';
import { Answer, Evaluations } from './types';

interface Props {
    conversationId: string;
    value?: Evaluations;
}

const questions = [
    'The customer service representative was polite and friendly.',
    'The representative clearly understood my issue or concern.',
    'The representative provided a satisfactory solution to my problem.',
    'My call was answered in a timely manner.',
    'I felt that the representative genuinely cared about helping me.',
    'The information provided by the representative was accurate and useful.',
    'Overall, I am satisfied with the customer service I received.',
];

const HumanEvaluator = ({ conversationId, value: initialValue }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(Array.isArray(initialValue)
        ? initialValue
        : questions.map((question) => ({ question, answer: null })));

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        await updateSession(conversationId, {
            humanEvaluation: value,
        });
        setIsLoading(false);
        setIsOpen(false);
    };
    useEffect(() => {
        if (Array.isArray(initialValue)) {
            setValue(initialValue);
        }
    }, [initialValue]);
    return (
        <>
            <Container>
                {evaluateConversation(value)}
                <ButtonIcon
                    icon={<Pencil />}
                    size="small"
                    onClick={() => setIsOpen(!isOpen)}
                />
            </Container>
            <Drawer
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                header="Human Evaluation"
                size="medium"
                slideFrom="right"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_medium"
                            label="Close"
                            variant="base"
                            borderRadius="semi-rounded"
                            onClick={() => setIsOpen(false)}
                        />
                        <Button
                            label="Create"
                            variant="brand"
                            form="human-evaluation-form"
                            borderRadius="semi-rounded"
                            type="submit"
                            isLoading={isLoading}
                        />
                    </div>
                )}
            >
                <Form id="human-evaluation-form" onSubmit={onSubmit}>
                    {questions.map((question, index) => (
                        <div key={question}>
                            <Question>{question}</Question>
                            <div>
                                <RadioGroup
                                    options={[
                                        { value: 'Strongly disagree', label: 'Strongly disagree' },
                                        { value: 'Disagree', label: 'Disagree' },
                                        { value: 'Neutral', label: 'Neutral' },
                                        { value: 'Agree', label: 'Agree' },
                                        { value: 'Strongly agree', label: 'Strongly agree' },
                                    ]}
                                    name={`question-${index}`}
                                    value={
                                        value[index]?.answer || undefined
                                    }
                                    onChange={(event) => {
                                        const newValue = [...value];
                                        newValue[index] = {
                                            question,
                                            answer: event.target.value as Answer,
                                        };
                                        setValue(newValue);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </Form>
            </Drawer>
        </>
    );
};

export default HumanEvaluator;
