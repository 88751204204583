import { IconProps } from '../types';

const Grinning = ({ title = 'Grinning', className }: IconProps) => (
    <svg className={className} width={16} height={15} viewBox="0 0 16 15">
        <title>{title}</title>
        <path d="M7.78175 15C4.03846 15 0 12.6521 0 7.5C0 2.34794 4.03846 0 7.78175 0C9.86136 0 11.78 0.684257 13.2021 1.93202C14.7451 3.30054 15.5635 5.23256 15.5635 7.5C15.5635 9.76744 14.7451 11.686 13.2021 13.0546C11.7773 14.3023 9.84794 15 7.78175 15Z" fill="url(#paint0_radial_100_11824)" />
        <path d="M5.20304 4.55371C4.64088 4.55371 4.1297 5.02867 4.1297 5.81758C4.1297 6.60648 4.64088 7.0801 5.20304 7.0801C5.76655 7.0801 6.27639 6.60514 6.27639 5.81758C6.27639 5.03001 5.77192 4.55371 5.20304 4.55371Z" fill="#422B0D" />
        <path d="M5.15607 5.08106C4.96555 4.98982 4.73612 5.07032 4.64355 5.26084C4.57244 5.40977 4.60464 5.58821 4.72405 5.7036C4.91457 5.79483 5.144 5.71433 5.23657 5.52381C5.30768 5.37488 5.27548 5.19644 5.15607 5.08106Z" fill="#896024" />
        <path d="M10.3551 4.55371C9.79295 4.55371 9.28177 5.02867 9.28177 5.81758C9.28177 6.60648 9.79295 7.0801 10.3551 7.0801C10.9173 7.0801 11.4285 6.60514 11.4285 5.81758C11.4285 5.03001 10.9173 4.55371 10.3551 4.55371Z" fill="#422B0D" />
        <path d="M10.3015 5.08106C10.111 4.98982 9.88154 5.07032 9.78896 5.26084C9.71786 5.40977 9.75006 5.58821 9.86947 5.7036C10.06 5.79483 10.2894 5.71433 10.382 5.52381C10.4531 5.37488 10.4209 5.19644 10.3015 5.08106Z" fill="#896024" />
        <path d="M14.258 3.0415C14.9731 4.19535 15.3461 5.56923 15.3461 7.09741C15.3461 9.36485 14.5277 11.2835 12.9848 12.652C11.5626 13.8997 9.63056 14.5974 7.56437 14.5974C5.14129 14.5974 2.60014 13.6113 1.10014 11.5196C2.54379 13.889 5.25936 14.9999 7.8327 14.9999C9.89889 14.9999 11.8309 14.3022 13.2531 13.0545C14.796 11.686 15.6145 9.76735 15.6145 7.49991C15.6145 5.79061 15.1489 4.27183 14.258 3.0415Z" fill="#EB8F00" />
        <path d="M12.9941 8.66987C12.8063 8.33043 12.3984 8.1815 12.0362 8.31835C10.6596 8.71952 9.23207 8.91943 7.79781 8.91272C6.36355 8.91943 4.936 8.71952 3.55944 8.31835C3.19853 8.1815 2.79066 8.32909 2.60282 8.66719C2.42035 9.00395 2.55184 9.39438 2.70613 9.73114C3.56749 11.6229 5.46597 12.7553 7.78708 12.762H7.80854C10.1297 12.762 12.0281 11.6229 12.8908 9.73114C13.0438 9.3917 13.1766 9.00664 12.9941 8.66987Z" fill="#422B0D" />
        <path d="M9.94593 12.2283C9.89629 12.1827 9.84531 12.1411 9.79432 12.0941C9.24692 11.6125 8.53851 11.3535 7.80863 11.3683C7.05729 11.3562 6.32608 11.6058 5.73976 12.0753C5.68878 12.1169 5.63511 12.1572 5.58547 12.2095C5.53583 12.2618 5.50899 12.2994 5.47679 12.3437C6.21472 12.6321 7.00094 12.7797 7.79388 12.7757H7.81534C8.57473 12.7757 9.32742 12.6402 10.0385 12.3732C10.0117 12.3222 9.98082 12.2739 9.94593 12.2283Z" fill="#ED7770" />
        <path d="M12.0362 8.31843C10.6597 8.71959 9.23212 8.91951 7.79786 8.9128C6.3636 8.91951 4.93605 8.71959 3.55949 8.31843C3.19858 8.18158 2.79071 8.32917 2.60287 8.66727C2.57604 8.71825 2.55457 8.77192 2.54115 8.82693C2.58543 8.84974 2.63641 8.87255 2.69679 8.8967C4.31217 9.70573 6.09795 10.1163 7.90385 10.0948C9.63596 10.1149 11.348 9.73793 12.9124 8.99464C12.9754 8.96781 13.0277 8.94231 13.0747 8.91816C13.064 8.83095 13.0358 8.74509 12.9942 8.66727C12.8064 8.32917 12.3985 8.18024 12.0362 8.31843Z" fill="white" />
        <path d="M12.9995 8.67122C12.809 8.33177 12.3998 8.1815 12.0348 8.31835C10.6583 8.71952 9.23073 8.91943 7.79781 8.91272C6.36355 8.91943 4.936 8.71952 3.55944 8.31835C3.19853 8.1815 2.79066 8.32909 2.60282 8.66719C2.42035 9.00395 2.55184 9.39438 2.70613 9.73114C2.77456 9.88275 2.85103 10.0317 2.93556 10.1752C2.93556 10.1752 2.6538 9.13141 2.89799 8.82819C2.98117 8.69939 3.12071 8.61889 3.27366 8.61084C3.33672 8.61084 3.39844 8.62157 3.45881 8.64036C4.85953 9.05225 6.31257 9.26021 7.77366 9.25753H7.82062C9.28171 9.26021 10.7348 9.05225 12.1355 8.64036C12.1958 8.62157 12.2576 8.61084 12.3206 8.61084C12.4736 8.61889 12.6144 8.69939 12.6976 8.82819C12.9458 9.13141 12.6601 10.1793 12.6601 10.1793C12.7432 10.0357 12.8264 9.88812 12.8962 9.73517C13.0492 9.39841 13.182 9.00932 12.9995 8.67122Z" fill="#EB8F00" />
        <defs>
            <radialGradient id="paint0_radial_100_11824" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78175 7.5) scale(7.64218)">
                <stop offset="0.5" stopColor="#FDE030" />
                <stop offset="0.92" stopColor="#F7C02B" />
                <stop offset="1" stopColor="#F4A223" />
            </radialGradient>
        </defs>
    </svg>
);

export default Grinning;
