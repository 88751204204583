import { RecordField } from '@rainbow-modules/record';
import { ButtonIcon, Input, Picklist } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    background: ${color('background.main')};
    display: flex;
    overflow: hidden;
    flex: 0;
    padding: 20px 0;
    gap: 8px;
    min-height: 40px;
`;

export const StyledInput = styled(Input)`
    flex: 1;
`;

export const StyledPicklist = styled(Picklist)`
    width: 230px;
    input {
        color: ${color('brand.main')};
    }
`;

export const CustomAssistantInputContainer = styled.div`
    display: inline-flex;
    flex: 1;
    justify-content:space-between;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    color: ${color('brand.main')};
    
    svg {
        width: 24px !important; 
        height: 24px !important;
    }
`;

export const StyledRecordField = styled(RecordField)`
    align-items: end;
    
    > span:last-child {
        color: ${color('text.header')};
        font-size: 12px;
    }
`;

export const InstructionsInputContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
`;

export const SendButtonContainer = styled.span`
    height: 100%;
    right: 3px;
    position: absolute;
    line-height: 1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SendButton = styled(ButtonIcon)`
    width: 32px;
    height: 32px;
`;
