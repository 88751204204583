import { IconProps } from './types';

const Shorten = ({ title = 'Shorten', className }: IconProps) => (
    <svg className={className} width={18} height={18} viewBox="0 0 18 18" fill="none">
        <title>{title}</title>
        <path d="M9.0001 12.6746L7.2001 14.4746C7.0626 14.6121 6.8876 14.6809 6.6751 14.6809C6.4626 14.6809 6.2876 14.6121 6.1501 14.4746C6.0126 14.3371 5.94385 14.1621 5.94385 13.9496C5.94385 13.7371 6.0126 13.5621 6.1501 13.4246L8.4751 11.0996C8.5501 11.0246 8.63135 10.9715 8.71885 10.9402C8.80635 10.909 8.9001 10.8934 9.0001 10.8934C9.1001 10.8934 9.19385 10.909 9.28135 10.9402C9.36885 10.9715 9.4501 11.0246 9.5251 11.0996L11.8501 13.4246C11.9876 13.5621 12.0563 13.7371 12.0563 13.9496C12.0563 14.1621 11.9876 14.3371 11.8501 14.4746C11.7126 14.6121 11.5376 14.6809 11.3251 14.6809C11.1126 14.6809 10.9376 14.6121 10.8001 14.4746L9.0001 12.6746ZM9.0001 5.32461L10.8001 3.52461C10.9376 3.38711 11.1126 3.31836 11.3251 3.31836C11.5376 3.31836 11.7126 3.38711 11.8501 3.52461C11.9876 3.66211 12.0563 3.83711 12.0563 4.04961C12.0563 4.26211 11.9876 4.43711 11.8501 4.57461L9.5251 6.89961C9.4501 6.97461 9.36885 7.02773 9.28135 7.05898C9.19385 7.09023 9.1001 7.10586 9.0001 7.10586C8.9001 7.10586 8.80635 7.09023 8.71885 7.05898C8.63135 7.02773 8.5501 6.97461 8.4751 6.89961L6.1501 4.57461C6.0126 4.43711 5.94385 4.26211 5.94385 4.04961C5.94385 3.83711 6.0126 3.66211 6.1501 3.52461C6.2876 3.38711 6.4626 3.31836 6.6751 3.31836C6.8876 3.31836 7.0626 3.38711 7.2001 3.52461L9.0001 5.32461Z" fill="currentColor" />
    </svg>
);

export default Shorten;
