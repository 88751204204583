import { RenderIf } from 'react-rainbow-components';
import { Container, Title, Actions } from './styled';

interface PageHeaderProps {
    className?: string;
    title?: string | React.ReactNode;
    actions?: React.ReactNode;
}

const PageHeader = (props: PageHeaderProps) => {
    const {
        title, actions, className,
    } = props;

    return (
        <Container className={className}>
            <RenderIf isTrue={typeof title === 'string'}>
                <Title>
                    {title}
                </Title>
            </RenderIf>
            <RenderIf isTrue={typeof title !== 'string'}>
                {title}
            </RenderIf>
            <Actions>
                {actions}
            </Actions>
        </Container>
    );
};

export default PageHeader;
