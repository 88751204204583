import { useParams } from 'react-router-dom';
import { Button } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import useDocuments from 'data/firestore/agent/document/useCollection';
import { Document } from 'data/firestore/agent/document/types';
import addDocument from 'data/firestore/agent/document/add';
import { PageHeaderTitle } from 'components/styled';
import Fields from './newDocument';
import DocumentItem from './documentItem';
import ChunkDetailsModal from './chunkDetailsModal';
import QAForm from './qaForm';
import { ADD_DOCUMENT_MODAL, SHOW_CHUNK_DETAILS_MODAL } from '../../../../../constants';
import {
    ContentContainer,
    LeftContent,
    RightContent,
    DocumentsList,
    SectionHeader,
    SectionDescription,
    StyledUniversalFormModal,
    ModalTitle,
} from './styled';

const KnowledgeBase = () => {
    const addDocumentModalProps = useConnectModal(ADD_DOCUMENT_MODAL);
    const chunkDetailsModalProps = useConnectModal(SHOW_CHUNK_DETAILS_MODAL);
    const [openAddDocumentModal, closeAddDocumentModel] = useOpenModal(ADD_DOCUMENT_MODAL);

    const { agentId = '' } = useParams();
    const { data: documents } = useDocuments(agentId);

    const addNewDocument = () => openAddDocumentModal({
        title: <ModalTitle>New Document</ModalTitle>,
        submitButtonLabel: 'Create',
        onSubmit: async ({ name, content }: Document) => {
            await addDocument(agentId, {
                name,
                content,
                contentType: 'text/plain',
            });
            closeAddDocumentModel();
        },
    });

    return (
        <ContentContainer>
            <LeftContent>
                <SectionHeader>
                    <div>
                        <PageHeaderTitle>Knowledge Base</PageHeaderTitle>
                        <SectionDescription>
                            Add specific information of your business to train the agent.
                        </SectionDescription>
                    </div>
                    <Button
                        label="New Document"
                        variant="brand"
                        borderRadius="semi-rounded"
                        onClick={addNewDocument}
                        size="small"
                    />
                </SectionHeader>

                <DocumentsList>
                    {documents?.map(({
                        id, name, content, updatedAt,
                    }) => (
                        <DocumentItem
                            key={id}
                            documentId={id}
                            documentTitle={name}
                            documentDate={updatedAt.toLocaleString(undefined, {
                                dateStyle: 'long',
                                timeStyle: 'short',
                            })}
                            documentContent={content}
                        />
                    ))}
                </DocumentsList>
                <StyledUniversalFormModal
                    borderRadius="semi-rounded"
                    size="large"
                    fields={Fields}
                    {...addDocumentModalProps}
                />
            </LeftContent>
            <RightContent>
                <QAForm />
            </RightContent>
            <ChunkDetailsModal
                {...chunkDetailsModalProps}
            />
        </ContentContainer>
    );
};

export default KnowledgeBase;
