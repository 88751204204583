import useModels from 'data/firestore/model/useCollection';
import addModel from 'data/firestore/model/add';
import updateModel from 'data/firestore/model/update';
import { Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { useConnectModal, useMutationFlow, useOpenModal } from '@rainbow-modules/hooks';
import {
    Table, Column, Button, Input,
} from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import DateTimeColumn from 'components/DateTimeColumn';
import { orderBy, query } from 'firebase/firestore';
import PageHeader from 'components/PageHeader';
import { MagnifyingGlass, Plus } from '@rainbow-modules/icons';
import { CREATE_MODEL_MODAL } from '../../../../constants';
import { StyledNavigationButtonColumn, ModalTitle } from './styled';
import ActionsColumn from './actionsColumn';
import {
    Header,
} from '../styled';

const Fields = () => (
    <Field
        name="name"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component={Input}
        label="Model Name:"
        required
        placeholder="Enter the name for your model"
        className="rainbow-m-bottom_x-large rainbow-m-horizontal_small"
        borderRadius="semi-rounded"
        labelAlignment="left"
    />
);

const ModelsList = () => {
    const { data, isLoading } = useModels({
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
    });
    const navigate = useNavigate();
    const navigateToModel = (value: any) => {
        navigate(`/models/${value.row.id}`);
    };

    const connectedCreateModelProps = useConnectModal(CREATE_MODEL_MODAL);
    const [openModal, closeModal] = useOpenModal(CREATE_MODEL_MODAL);
    const { mutate: addModelFlow } = useMutationFlow({
        mutation: async ({ name }: { name: string }) => {
            closeModal();
            const { id } = await addModel({ name });
            navigate(`/models/${id}`);
        },
        successMessage: 'Model created successfully',
    });
    const { mutate: editModelFlow } = useMutationFlow({
        mutation: async ({ id, name }: { id: string, name: string }) => {
            closeModal();
            await updateModel(id, { name });
        },
        successMessage: 'Model updated successfully',
    });
    const openCreateModel = () => openModal({
        title: <ModalTitle>New Model</ModalTitle>,
        submitButtonLabel: 'Create',
        onSubmit: addModelFlow,
    });

    const openEditModel = ({ id, name: currentName }:{ id:string, name: string }) => openModal({
        title: <ModalTitle>Edit Model</ModalTitle>,
        submitButtonLabel: 'Edit',
        onSubmit: ({ name }:{ name: string }) => editModelFlow({ id, name }),
        initialValues: {
            name: currentName,
        },
    });

    const actions = ({ value, row }: { value?: string, row?: any }) => (
        <ActionsColumn
            onEdit={() => openEditModel({ id: value as string, name: row.name as string })}
        />
    );

    return (
        <>
            <PageHeader title="Training Models" />
            <Header>
                <Input type="search" size="small" borderRadius="semi-rounded" placeholder="Find a Model..." icon={<MagnifyingGlass />} />
                <Button
                    variant="brand"
                    borderRadius="semi-rounded"
                    onClick={openCreateModel}
                    size="small"
                >
                    <Plus className="rainbow-m-right_small" />
                    Create New Model
                </Button>
            </Header>

            <Table data={data} isLoading={isLoading} keyField="id" variant="listview" className="rainbow-m-left_large rainbow-m-right_large">
                <Column
                    header="Name"
                    field="name"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={StyledNavigationButtonColumn}
                    onClick={navigateToModel}
                />
                <Column header="Created" field="createdAt" defaultWidth={180} component={DateTimeColumn} headerAlignment="left" />
                <Column header="Updated" field="updatedAt" defaultWidth={180} component={DateTimeColumn} headerAlignment="left" />
                <Column field="id" defaultWidth={50} component={actions} />
            </Table>

            <UniversalFormModal
                fields={Fields}
                onSubmit={addModelFlow}
                {...connectedCreateModelProps}
                borderRadius="semi-rounded"
            />
        </>
    );
};

export default ModelsList;
