import formatIntlPhoneNumber from 'data/services/phones/formatIntl';
import { StyledColumnWrapper } from './styled';

interface PhoneNumberColumnProps {
    value?: string;
}

const PhoneNumberColumn = (props: PhoneNumberColumnProps) => {
    const { value } = props;
    if (!value) return null;
    return (
        <StyledColumnWrapper>
            {formatIntlPhoneNumber(value)}
        </StyledColumnWrapper>
    );
};

export default PhoneNumberColumn;
