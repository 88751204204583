import { Outlet } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import TopBar from 'components/TopBar';
import { ADD_MODIFY_AGENT_DRAWER } from '../../../constants';
import { FormOpenMode } from './AddUpdateAgentForm/types';
import { Container, Content, StyledDrawer } from './styled';
import AddUpdateAgentForm from './AddUpdateAgentForm';

const Agents = () => {
    const connectedAddModifyProps = useConnectModal(
        ADD_MODIFY_AGENT_DRAWER,
        { size: 'small', slideFrom: 'right' },
    );
    const [, connectedAddModifyModal] = useOpenModal(ADD_MODIFY_AGENT_DRAWER);
    const { mode, agentData = {}, ...drawerProps } = connectedAddModifyProps;

    return (
        <Container>
            <TopBar />
            <Content>
                <Outlet />
            </Content>
            <StyledDrawer
                {...drawerProps}
                onRequestClose={connectedAddModifyModal}
            >
                <AddUpdateAgentForm mode={mode as FormOpenMode} {...(agentData as object)} />
            </StyledDrawer>
        </Container>
    );
};

export default Agents;
