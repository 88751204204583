/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
import { useContext } from 'react';
import { RecordField } from '@rainbow-modules/record';
import formatter from 'data/services/date/formatter';
import ModelLink from 'components/ModelLink';
import { PageHeaderTitle } from 'components/styled';
import {
    Container,
    Content,
    Text,
} from './styled';
import context, { CampaignContext } from '../context';

const Details = () => {
    const { campaignData, isLoading } = useContext<CampaignContext>(context);
    return (
        <Container>
            <PageHeaderTitle>
                Campaign Details
            </PageHeaderTitle>
            <Content>
                <RecordField label="Model" value={campaignData?.model} isLoading={isLoading} component={({ value }: { value: string }) => <ModelLink versionName={value} />} />
                <RecordField label="Temperature" value={campaignData?.temperature} isLoading={isLoading} />
                <RecordField label="Start at" value={formatter.format(campaignData?.createdAt)} isLoading={isLoading} />
            </Content>
            <br />
            <PageHeaderTitle>
                System Message
            </PageHeaderTitle>
            <Content>
                <Text>
                    {campaignData?.systemMessageTemplate}
                </Text>
            </Content>
        </Container>
    );
};

export default Details;
