import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useAgentSubscriptions from 'data/firestore/agent/subscriptions/useCollection';
import { Subscriptions } from 'data/firestore/agent/subscriptions/types';
import { PageHeaderTitle } from 'components/styled';
import { SectionHeader, SectionDescription } from './styled';
import Notification from './Notification';

const Notifications = () => {
    const { agentId = '' } = useParams();

    const {
        isLoading: isLoadingSubscriptions,
        data: subscriptions = [],
    } = useAgentSubscriptions(agentId, {
        disabled: !agentId,
    });

    const [
        smsSubscriptions,
        emailSubscriptions,
    ] = useMemo(
        () => subscriptions.reduce(
            (result: Subscriptions[][], subscription) => {
                const index = (subscription.type === 'sms' ? 0 : 1);
                result[index].push({ ...subscription });
                return result;
            },
            [[], []],
        ),
        [subscriptions],
    );

    return (
        <>
            <SectionHeader>
                <PageHeaderTitle>Notifications</PageHeaderTitle>
                <SectionDescription>
                    Lorem ipsum is placeholder text commonly used in the graphic.
                </SectionDescription>
            </SectionHeader>
            <Notification
                agentId={agentId}
                isLoading={isLoadingSubscriptions}
                phones={smsSubscriptions}
                emails={emailSubscriptions}
            />
        </>
    );
};

export default Notifications;
