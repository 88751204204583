import { RecordDetails, RecordField } from '@rainbow-modules/record';
import { Container, ProcessName } from './styled';
import { Region } from '../types';

const RegionDetails = (props: Partial<Region>) => {
    const {
        role, start, end, content,
    } = props;
    const duration = `${(end as number - (start as number)) * 1000} ms`;
    const dynamicContent = role === 'final-transcript'
        ? [
            { label: 'Transcript', value: content?.transcript },
            { label: 'Confidence', value: content?.confidence },
        ] : [
            { label: 'Content', value: content },
        ];
    return (
        <Container>
            <RecordDetails>
                <ProcessName>{role}</ProcessName>
                <RecordField label="Start" value={start} />
                <RecordField label="End" value={end} />
                <RecordField label="Duration" value={duration} />
                {dynamicContent.map((item) => (
                    <RecordField label={item.label} value={item.value} key={item.label} />
                ))}
            </RecordDetails>
        </Container>
    );
};

export default RegionDetails;
