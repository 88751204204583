import { Button, RainbowThemeContainer } from 'react-rainbow-components';
import { ERROR } from '../../../../../constants';
import {
    StyledModal,
    StyledBadge,
    ChunkDetailsModalTitle,
    ChunkContentExpanded,
} from './styled';

interface ChunkDetailsModalProps {
    isOpen?: boolean;
    onRequestClose?: () => void;
    chunkName?: string | React.ReactNode;
    chunkTokens?: string | React.ReactNode;
    documentName?: string | React.ReactNode;
    chunkContent?: string;
}

const theme = {
    rainbow: {
        palette: {
            brand: ERROR,
        },
    },
};

const ChunkDetailsModal = (props: ChunkDetailsModalProps) => {
    const {
        onRequestClose,
        isOpen,
        chunkName,
        chunkTokens,
        documentName,
        chunkContent,
    } = props;

    return (
        <StyledModal
            borderRadius="semi-rounded"
            size="medium"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <ChunkDetailsModalTitle>
                {documentName}
            </ChunkDetailsModalTitle>
            <div>
                <StyledBadge borderRadius="semi-square" size="small" label={chunkName} />
                <StyledBadge borderRadius="semi-square" size="small" label={chunkTokens} />
            </div>
            <ChunkContentExpanded value={chunkContent} />

            <RainbowThemeContainer theme={theme}>
                <Button label="Remove Chunk" borderRadius="semi-rounded" variant="outline-brand" />
            </RainbowThemeContainer>

        </StyledModal>
    );
};

export default ChunkDetailsModal;
