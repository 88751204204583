import { useEffect, useState } from 'react';
import useDatasets from 'data/firestore/dataset/useCollection';
import { orderBy, query } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { Dataset } from 'data/firestore/dataset/types';
import {
    Container,
    FolderIcon,
    Option,
    DatasetName,
} from './styled';

const DatasetOptions = () => {
    const [selectedDataset, setSelectedDataset] = useState<any>(null);
    const navigate = useNavigate();
    const { datasetId } = useParams();
    const { data: datasets, isLoading: isLoadingDatasets } = useDatasets({
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
    });

    useEffect(() => {
        if (datasets.length > 0 && datasetId) {
            setSelectedDataset(datasets.find((dataset) => dataset.id === datasetId));
        }
    }, [datasets, datasetId]);

    const handleSelectDataset = (dataset: Dataset) => {
        navigate(`/dataset/${dataset.id}/groups`);
    };

    if (isLoadingDatasets) {
        return null;
    }

    return (
        <Container>
            {datasets.map((dataset: Dataset) => (
                <Option
                    key={dataset.id}
                    onClick={() => handleSelectDataset(dataset)}
                    selected={selectedDataset?.id === dataset.id}
                >
                    <FolderIcon />
                    <DatasetName>{dataset.name}</DatasetName>
                </Option>
            ))}
        </Container>
    );
};

export default DatasetOptions;
