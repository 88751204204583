import styled from 'styled-components';
import { color } from 'theme';

export const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    height: 100%;
    border: 1px solid ${color('border.divider')};
    border-radius: 8px;
    padding: 8px 16px;
    gap: 4px;
`;

export const TileLabel = styled.h2`
    font-size: 14px;
    color: ${color('text.header')};
`;

export const TileValue = styled.h3`
    font-size: 16px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    text-transform: capitalize;
`;
