import { NavigationButtonColumn } from '@rainbow-modules/listview';
import { Table } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const StyledTable = styled(Table)`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;

    table {
        padding-bottom: 20px;
    }
`;

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;

export const StyledActionsColumn = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    padding: 2px 2px 2px 0;
`;

export const PageSubTitle = styled.h2`
    font-size: 18px;
    line-height: 1.5;
    color: ${color('text.main')};
`;

export const PageHeaderTitle = styled.h1`
    font-size: 15px;
    font-family: "Lato Bold";
    color: ${color('text.main')};
`;
