import { IconProps } from './types';

const Phone = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width={16} height={16} viewBox="0 0 16 16" fill="none">
        <title>{title}</title>

        <path
            d="M15.5001 11.6901V13.9401C15.5009 14.1489 15.4581 14.3557 15.3745 14.5471C15.2908 14.7385 15.168 14.9103 15.0141 15.0515C14.8602 15.1927 14.6785 15.3002 14.4806 15.3671C14.2828 15.434 14.0731 15.4589 13.8651 15.4401C11.5572 15.1893 9.34032 14.4007 7.39257 13.1376C5.58044 11.9861 4.04407 10.4497 2.89257 8.63757C1.62506 6.68098 0.836254 4.45332 0.590072 2.13507C0.57133 1.92767 0.595978 1.71864 0.662447 1.52129C0.728916 1.32394 0.83575 1.14259 0.976146 0.988789C1.11654 0.834987 1.28743 0.712105 1.47792 0.627964C1.6684 0.543824 1.87433 0.500269 2.08257 0.500073H4.33257C4.69655 0.49649 5.04942 0.625382 5.32539 0.862722C5.60137 1.10006 5.78163 1.42966 5.83257 1.79007C5.92754 2.51012 6.10366 3.21712 6.35757 3.89757C6.45848 4.16602 6.48032 4.45776 6.4205 4.73823C6.36069 5.01871 6.22172 5.27616 6.02007 5.48007L5.06757 6.43257C6.13524 8.31023 7.68991 9.86491 9.56757 10.9326L10.5201 9.98007C10.724 9.77843 10.9814 9.63946 11.2619 9.57964C11.5424 9.51983 11.8341 9.54167 12.1026 9.64257C12.783 9.89649 13.49 10.0726 14.2101 10.1676C14.5744 10.219 14.9071 10.4025 15.145 10.6832C15.3828 10.9639 15.5092 11.3223 15.5001 11.6901Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Phone;
