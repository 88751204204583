import { useState, useEffect } from 'react';
import searchAlgolia, { IndexType } from '../../data/services/algolia/searchAlgolia';

interface Page<T> {
    data: Array<T>,
    totalRecords: number,
    totalPages: number,
    currentPage: number
}

const useAlgoliaSearch = (props: {
    searchQuery: string;
    collection?: IndexType;
    filters?: string;
    currentPage?: number;
}) => {
    const {
        searchQuery, filters, collection, currentPage = 0,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<Page<object>>({
        data: [],
        totalRecords: 0,
        totalPages: 0,
        currentPage,
    });

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const resp = await searchAlgolia(
                searchQuery,
                collection,
                { page: currentPage, filters },
            );
            setSearchResults({
                data: resp?.hits || [],
                totalRecords: resp?.nbHits || 0,
                totalPages: resp?.nbPages || 0,
                currentPage: resp?.page || 0,
            });
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, filters]);

    const goToPage = async (page: number) => {
        if (isLoading || page < 0 || page >= searchResults.totalPages) return;
        try {
            setIsLoading(true);
            const resp = await searchAlgolia(searchQuery, collection, { page, filters });
            setSearchResults({
                data: resp?.hits || [],
                totalRecords: resp?.nbHits || 0,
                totalPages: resp?.nbPages || 0,
                currentPage: resp?.page || 0,
            });
        } catch (err) {
            // NO CATCH
        }
        setIsLoading(false);
    };

    return {
        ...searchResults,
        firstPage: searchResults.currentPage === 0,
        hasMore: searchResults.currentPage < searchResults.totalPages - 1,
        isLoading,
        goToPage,
    };
};

export default useAlgoliaSearch;
