import { IconProps } from './types';

const Pencil = ({ title = 'Pencil', className }: IconProps) => (
    <svg className={className} width={22} height={22} viewBox="0 0 22 22" fill="none">
        <title>{title}</title>
        <path
            fill="currentColor"
            d="M18 1.67157C17.6942 1.67157 17.3914 1.7318 17.1089 1.84881C16.8265 1.96583 16.5698 2.13734 16.3536 2.35355L2.94744 15.7597L1.71261 20.2874L6.24034 19.0526L19.6464 5.64644C19.8627 5.43023 20.0342 5.17354 20.1512 4.89105C20.2682 4.60855 20.3284 4.30577 20.3284 4C20.3284 3.69422 20.2682 3.39144 20.1512 3.10895C20.0342 2.82645 19.8627 2.56977 19.6464 2.35355C19.4302 2.13734 19.1735 1.96583 18.8911 1.84881C18.6086 1.7318 18.3058 1.67157 18 1.67157ZM16.7263 0.924931C17.1301 0.757662 17.5629 0.67157 18 0.67157C18.4371 0.67157 18.8699 0.757662 19.2737 0.924931C19.6776 1.0922 20.0445 1.33737 20.3536 1.64644C20.6626 1.95552 20.9078 2.32244 21.0751 2.72626C21.2423 3.13009 21.3284 3.5629 21.3284 4C21.3284 4.43709 21.2423 4.86991 21.0751 5.27373C20.9078 5.67755 20.6626 6.04448 20.3536 6.35355L6.85355 19.8535C6.79203 19.9151 6.71551 19.9595 6.63156 19.9824L1.13156 21.4824C0.958453 21.5296 0.773322 21.4804 0.646447 21.3535C0.519572 21.2267 0.470408 21.0415 0.517618 20.8684L2.01762 15.3684C2.04051 15.2845 2.08492 15.208 2.14645 15.1464L15.6464 1.64644C15.9555 1.33737 16.3224 1.0922 16.7263 0.924931Z"
        />
    </svg>
);

export default Pencil;
