import algoliasearch from 'algoliasearch';
import { createNullCache } from '@algolia/cache-common';
import { ITEMS_PER_PAGE } from '../../../constants';

const {
    REACT_APP_ALGOLIA_APPLICATION_ID = '',
    REACT_APP_ALGOLIA_SEARCH_API_KEY = '',
} = process.env;

const config = {
    appId: REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: REACT_APP_ALGOLIA_SEARCH_API_KEY,
};

const client = algoliasearch(
    config.appId,
    config.searchApiKey,
    {
        responsesCache: createNullCache(),
        requestsCache: createNullCache(),
    },
);

const indexes = {
    history: client.initIndex('sessions'),
    agents: client.initIndex('agents'),
};

export type IndexType = 'agents' | 'history';

interface Result {
    createdAt?: Date;
}

const searchAlgolia = async <T extends Result = Result> (
    query: string,
    indexType: IndexType = 'history',
    options: {
        page?: number;
        hitsPerPage?: number,
        filters?: string,
    } = {},
// eslint-disable-next-line consistent-return
) => {
    try {
        const resp = await indexes[indexType].search<T>(query, {
            filters: options?.filters,
            page: options?.page,
            hitsPerPage: options?.hitsPerPage || ITEMS_PER_PAGE,
        });
        return resp;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export default searchAlgolia;
