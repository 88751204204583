import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { DatasetName, Option } from 'components/DatasetOptions/styled';
import { Row, SideBar, RightContent } from './styled';

const Insights = () => {
    const navigate = useNavigate();
    const match = useMatch('insights/:tabName');

    return (
        <Row>
            <SideBar>
                <Option
                    selected={match?.params?.tabName === 'intent'}
                    onClick={() => navigate('intent')}
                >
                    <DatasetName>Intent Distribution</DatasetName>
                </Option>
                <Option
                    selected={match?.params?.tabName === 'topic'}
                    onClick={() => navigate('topic')}
                >
                    <DatasetName>Topic Analysis</DatasetName>
                </Option>
            </SideBar>
            <RightContent>
                <Outlet />
            </RightContent>
        </Row>
    );
};

export default Insights;
