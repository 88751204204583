import { useCallback } from 'react';
import {
    Outlet,
    useParams,
    useNavigate,
    useMatch,
} from 'react-router-dom';
import {
    Breadcrumb, Breadcrumbs, Tab, Tabset, Button,
} from 'react-rainbow-components';
import { Download } from '@rainbow-modules/icons';
import { hideAppSpinner, showAppSpinner } from '@rainbow-modules/app';
import { Refresh } from 'components/icons';
import useSendCampaignMutation from 'hooks/useSendCampaignMutation';
import { Campaign } from 'data/firestore/agent/campaign/types';
import useCampaign from 'data/firestore/agent/campaign/use';
import useCampaignCallsCount from 'data/firestore/agent/campaign/call/useCount';
import useCalls from 'data/firestore/agent/campaign/call/useCollection';
import formatter from 'data/services/date/formatter';
import PageHeader from 'components/PageHeader';
import { PageHeaderTitle } from 'components/styled';
import downloadCSV from '../helpers/downloadCSV';
import {
    Container,
    Content,
    Insights,
    TilesContainer,
    StatusContainer,
    StyledSpinner,
    ClockIcon,
    CheckmarkIcon,
    StyledTile,
} from './styled';
import { Provider } from './context';

const statusMapper: Record<string, any> = {
    pending: (
        <StatusContainer>
            <ClockIcon />
            Pending
        </StatusContainer>
    ),
    'in-progress': (
        <StatusContainer>
            <StyledSpinner size="xx-small" type="arc" variant="brand" />
            Running
        </StatusContainer>
    ),
    finished: (
        <StatusContainer>
            <CheckmarkIcon />
            Finished
        </StatusContainer>
    ),
};

const CampaignDetails = () => {
    const { agentId = '', campaignId = '' } = useParams();
    const navigate = useNavigate();
    const match = useMatch('agents/:agentId/campaigns/:campaignId/:tabName');
    const { data: campaignData, isLoading: isLoadingCampaign } = useCampaign(agentId, campaignId);
    const {
        data: calls,
        isLoading: isLoadingCalls,
    } = useCalls(agentId, campaignId, { track: [campaignId] });
    const {
        count: calleeCount,
        isLoading: isLoadingCallsCount,
    } = useCampaignCallsCount(agentId, campaignId);
    const totalSeconds = campaignData?.duration || 0;
    const avgSecondsPerCall = calleeCount ? totalSeconds / calleeCount : 0;

    const { mutateAsync: resendCampaign } = useSendCampaignMutation();

    const handleResendCampaign = useCallback(
        async () => {
            const {
                caller, systemMessageTemplate, model, temperature,
            } = campaignData as Campaign;
            showAppSpinner();
            try {
                const campaign = await resendCampaign({
                    body: {
                        agentId,
                        contacts: calls.map((call) => ({
                            phone: call.to,
                            name: call.customerName,
                        })),
                        systemMessageTemplate,
                        caller,
                        model,
                        temperature,
                    },
                });
                const { id: newCampaignId } = campaign as { id: string };
                navigate(`/agents/${agentId}/campaigns/${newCampaignId}/calls`);
                hideAppSpinner();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                hideAppSpinner();
            }
        },
        [agentId, calls, campaignData, navigate, resendCampaign],
    );

    return (
        <Container>
            <PageHeader
                title={(
                    <Breadcrumbs>
                        <Breadcrumb
                            label="All Agents"
                            onClick={() => navigate('/agents/')}
                        />
                        <Breadcrumb
                            label={agentId}
                            onClick={() => navigate(`/agents/${agentId}`)}
                        />
                        <Breadcrumb
                            label="Agent Campaigns"
                            onClick={() => navigate(`/agents/${agentId}/campaigns`)}
                        />
                        <Breadcrumb label={<PageHeaderTitle>{campaignId}</PageHeaderTitle>} />
                    </Breadcrumbs>
                )}
                actions={(
                    <>
                        <Button
                            variant="outline-brand"
                            borderRadius="semi-rounded"
                            size="small"
                            onClick={handleResendCampaign}
                            disabled={isLoadingCampaign || isLoadingCalls || campaignData?.status !== 'finished'}
                        >
                            <Refresh className="rainbow-m-right_small" />
                            Resend campaign
                        </Button>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Button onClick={() => downloadCSV(calls)} variant="outline-brand" borderRadius="semi-rounded" size="small">
                            <Download className="rainbow-m-right_small" />
                            Download Report
                        </Button>
                    </>
                )}
            />
            <Insights>
                <TilesContainer>
                    <StyledTile label="Campaign Date" value={formatter.format(campaignData?.createdAt)} />
                    <StyledTile label="Campaign Status" value={statusMapper[campaignData?.status as string]} />
                    <StyledTile label="Total Callee" value={calleeCount || undefined} isLoading={isLoadingCallsCount} />
                    <StyledTile label="Total Seconds Spoken" value={totalSeconds} />
                    <StyledTile label="Caller Phone Number" value={campaignData?.caller} />
                    <StyledTile label="Avg Seconds Per Call" value={avgSecondsPerCall} />
                </TilesContainer>
            </Insights>
            <div className="rainbow-m-horizontal_large">
                <Tabset
                    variant="line"
                    onSelect={(event, eventName) => {
                        navigate(eventName);
                        event.preventDefault();
                    }}
                    activeTabName={match?.params?.tabName}
                >
                    <Tab name="calls" label="Calls" />
                    <Tab name="details" label="Details" />
                </Tabset>
            </div>
            <Content>
                <Provider value={{ campaignData, isLoading: isLoadingCampaign, calls }}>
                    <Outlet />
                </Provider>
            </Content>
        </Container>
    );
};

export default CampaignDetails;
