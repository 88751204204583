import { IconProps } from './types';

const Clock = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width={18} height={18} viewBox="0 0 18 18" fill="none">
        <title>{title}</title>
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.00002 17.3337C13.6024 17.3337 17.3334 13.6027 17.3334 9.00033C17.3334 4.39795 13.6024 0.666992 9.00002 0.666992C4.39765 0.666992 0.666687 4.39795 0.666687 9.00033C0.666687 13.6027 4.39765 17.3337 9.00002 17.3337Z"
        />
        <path d="M9 4V9L12.3333 10.6667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Clock;
