import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";

export type BuildingblocksRemoveService = (
  datasetId: string,
  id: string
) => Promise<void>;

const remove: BuildingblocksRemoveService = async (datasetId, id) => {
  const docRef = doc(db, `/dataset/${datasetId}/buildingBlocks/${id}`);
  return await deleteDoc(docRef);
};

export default remove;
