import { useCallback } from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import useAgent from 'data/firestore/agent/useOnce';
import useHttpMutation from 'data/firestore/useHttpMutation';
import formatPhoneNumber from 'data/services/phones/format';
import { ADD_MODIFY_AGENT_DRAWER, availableLanguages } from '../../../../constants';
import AgentFormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';

const mapAgentDataToFormValues = (
    agentData: Record<string, unknown>,
) => {
    const {
        companyName,
        companyAddress,
        speechTimeout,
        type,
        multilingual,
        useTwilioStream,
        handoffNumber,
        callRecordingEnabled = false,
        handoffCallTransferPercentage = 0,
        supportedLanguages = [],
        greetings,
        phoneNumbers = [],
        model,
        temperature,
        speech,
    } = agentData;

    return {
        greetings,
        companyName,
        speechTimeout,
        agentType: { name: type, value: type, label: type },
        useTwilioStream,
        multilingual,
        handoffNumber: handoffNumber && formatPhoneNumber(handoffNumber as string),
        supportedLanguages: (supportedLanguages as string[]).map(
            (supportedLanguage) => availableLanguages.find(
                (language) => language.name === supportedLanguage,
            ),
        ),
        companyAddress: (companyAddress as Record<string, unknown>)?.addressInfo,
        callRecordingEnabled,
        handoffCallTransferPercentage: (handoffCallTransferPercentage as number) * 100,
        phoneNumbers: (phoneNumbers as Record<string, unknown>[]).map((phoneNumber) => ({
            id: phoneNumber.id,
            language: availableLanguages.find(
                (language) => language.name === phoneNumber.language,
            ) || { name: 'auto', label: 'Auto' },
        })),
        usedPhones: phoneNumbers,
        model,
        temperature,
        speech,
    } as Record<string, unknown>;
};

const EditAgentForm = (agentData: Record<string, unknown>) => {
    const [, closeDrawer] = useOpenModal(ADD_MODIFY_AGENT_DRAWER);

    const { data, isLoading } = useAgent(
        agentData?.agentId as string,
        { disabled: !agentData?.agentId },
    );

    const agentInfo = (agentData?.agentId ? data : agentData);

    const {
        mutateAsync: updateAgent,
    } = useHttpMutation<Record<string, unknown>, void>({
        pathname: `/agents/${agentInfo?.id}`,
        method: 'PATCH',
        server: process.env.REACT_APP_BASE_URL,
        onSuccess: closeDrawer,
    });

    const handleSubmit = useCallback(async (values: Record<string, unknown>) => {
        try {
            showAppSpinner();
            await updateAgent({
                body: values,
            });
        } catch (error) {
            // no catch
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [updateAgent]);

    return (
        <Container>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <Form
                        id="agent-form"
                        initialValues={mapAgentDataToFormValues(
                            agentInfo as Record<string, unknown> || {},
                        )}
                        onSubmit={handleSubmit}
                    />
                </RenderIf>
            </Content>
            <AgentFormFooter
                formId="agent-form"
                submitDisabled={isLoading}
                submitButtonLabel="Update"
                onCancelClick={closeDrawer}
            />
        </Container>
    );
};

export default EditAgentForm;
