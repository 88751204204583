import styled from 'styled-components';
import { UniversalForm } from '@rainbow-modules/forms';
import { Button } from 'react-rainbow-components';

export const StyledUniversalForm = styled(UniversalForm)`
    position: relative;
    display: flex;
    flex: 1;
    align-self: stretch;

     & > div {
        min-width: 30%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

export const StyledSubmitButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0; 
`;
