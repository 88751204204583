import styled from 'styled-components';
import { Field } from '@rainbow-modules/forms';
import { Chip } from 'react-rainbow-components';
import { color } from 'theme';

export const Header = styled.h2`
    color: ${color('text.main')};
    font-size: 16px;
    font-weight: 600;
`;

export const Description = styled.p`
    color: ${color('text.header')};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    max-width: 700px;
`;

export const StyledField = styled(Field)`
    width: 700px;
`;

export const StyledChipContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
`;

export const StyledChip = styled(Chip)`
    margin: 8px 8px 0 0;
`;
