import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { Subscriptions } from "./types";

export type SubscriptionsUpdateService<T> = (
  agentId: string,
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: SubscriptionsUpdateService<Subscriptions> = async (
  agentId,
  id,
  data
) => {
  const collectionRef = doc(db, `/agent/${agentId}/subscriptions/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
