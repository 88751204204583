import { IconProps } from './types';

const Clear = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 19 19" fill="none">
        <title>{title}</title>
        <path d="M9.22217 17C13.3643 17 16.7222 13.6421 16.7222 9.5C16.7222 5.35786 13.3643 2 9.22217 2C5.08003 2 1.72217 5.35786 1.72217 9.5C1.72217 13.6421 5.08003 17 9.22217 17Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.91968 4.19922L14.5247 14.8042" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Clear;
