import { Spinner } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: block;
    height: 100%;
    overflow: auto;
`;

export const Divider = styled.div`
    flex: 1;
    height: 1px;
    background: ${color('border.disabled')};
    margin: 8px 0;
`;

export const MetadataLabel = styled.h4`
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 16px 0;
`;

export const MetadataKey = styled.div`
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
`;

export const MetadataValue = styled.div`
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
`;

export const AttrContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const MessageIntent = styled.div`
    background: #E3E5ED;
    padding: 1px 4px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
`;

export const MessageIntentConfidence = styled.div.withConfig({
    shouldForwardProp: (prop) => !['$confidence'].includes(prop),
})<{ $confidence: number }>`
    padding: 1px 4px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    color: ${(props) => (props.$confidence < 0.3 ? color('error.dark') : 'unset')};
    background: ${(props) => (props.$confidence < 0.3 ? color('error.light') : 'unset')};
    border-radius: 2px;
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    top: 100px;
    left: 50%;
    z-index: unset;
    transform-origin: 0px 50% 0px
`;
