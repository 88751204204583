import { IconProps } from '../types';

const SlightlyFrowning = ({ title = 'SlightlyFrowning', className }: IconProps) => (
    <svg className={className} width={16} height={15} viewBox="0 0 16 15">
        <title>{title}</title>
        <path d="M7.78175 15C4.03846 15 0 12.6521 0 7.5C0 2.34794 4.03846 0 7.78175 0C9.86136 0 11.78 0.684257 13.2021 1.93202C14.7451 3.30054 15.5635 5.23256 15.5635 7.5C15.5635 9.76744 14.7451 11.686 13.2021 13.0546C11.78 14.3023 9.84794 15 7.78175 15Z" fill="url(#paint0_radial_100_11798)" />
        <path d="M14.2071 3.0415C14.9222 4.19535 15.2952 5.56923 15.2952 7.09741C15.2952 9.36485 14.4768 11.2835 12.9338 12.652C11.5116 13.8997 9.57962 14.5974 7.51343 14.5974C5.09035 14.5974 2.54921 13.6113 1.04921 11.5196C2.49286 13.889 5.20842 14.9999 7.78177 14.9999C9.84796 14.9999 11.78 14.3022 13.2022 13.0545C14.7451 11.686 15.5635 9.76735 15.5635 7.49991C15.5635 5.79061 15.098 4.27183 14.2071 3.0415Z" fill="#D84213" />
        <path d="M5.07335 4.55371C4.51118 4.55371 4 5.02867 4 5.81758C4 6.60648 4.51118 7.0801 5.07335 7.0801C5.63685 7.0801 6.14669 6.60514 6.14669 5.81758C6.14669 5.03001 5.64222 4.55371 5.07335 4.55371Z" fill="#422B0D" />
        <path d="M5.02648 5.08106C4.83596 4.98982 4.60653 5.07032 4.51396 5.26084C4.44285 5.40977 4.47505 5.58821 4.59446 5.7036C4.78498 5.79483 5.0144 5.71433 5.10698 5.52381C5.17809 5.37488 5.14589 5.19644 5.02648 5.08106Z" fill="#896024" />
        <path d="M10.2254 4.55371C9.66328 4.55371 9.1521 5.02867 9.1521 5.81758C9.1521 6.60648 9.66328 7.0801 10.2254 7.0801C10.7876 7.0801 11.2988 6.60514 11.2988 5.81758C11.2988 5.03001 10.7876 4.55371 10.2254 4.55371Z" fill="#422B0D" />
        <path d="M10.172 5.08106C9.98147 4.98982 9.75204 5.07032 9.65946 5.26084C9.58835 5.40977 9.62055 5.58821 9.73996 5.7036C9.93048 5.79483 10.1599 5.71433 10.2525 5.52381C10.3236 5.37488 10.2914 5.19644 10.172 5.08106Z" fill="#896024" />
        <path d="M7.78102 9.55143C8.99792 9.5407 10.1625 10.0532 10.9769 10.9575C11.0386 11.0286 11.0534 11.1293 11.0145 11.2151C10.9756 11.3023 10.8897 11.3587 10.7944 11.36C10.7515 11.36 10.7086 11.3479 10.671 11.3265C10.0243 10.9548 8.94291 10.4933 7.7837 10.4933H7.76492C6.60705 10.4933 5.52431 10.9548 4.87896 11.3265C4.84139 11.3479 4.79846 11.36 4.75553 11.36C4.66027 11.3587 4.57574 11.3023 4.53683 11.2151C4.49658 11.1293 4.51134 11.0286 4.5744 10.9575C5.3888 10.0532 6.55204 9.5407 7.76894 9.55143" fill="#422B0D" />
        <defs>
            <radialGradient id="paint0_radial_100_11798" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.78175 7.49999) scale(7.64218)">
                <stop offset="0.12" stopColor="#FAC742" />
                <stop offset="0.32" stopColor="#FAC440" />
                <stop offset="0.48" stopColor="#FBBA3B" />
                <stop offset="0.62" stopColor="#FBA931" />
                <stop offset="0.76" stopColor="#FC9224" />
                <stop offset="0.88" stopColor="#FE7413" />
                <stop offset="1" stopColor="#FF5100" />
            </radialGradient>
        </defs>
    </svg>
);

export default SlightlyFrowning;
