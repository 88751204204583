import { IconProps } from './types';

const ErrorCircle = ({ title = 'ErrorCircle', className }: IconProps) => (
    <svg className={className} width="16px" height="16px" viewBox="0 0 16 16">
        <title>{title}</title>
        <g clipPath="url(#clip0_1546_15979)">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 2.33594C4.87055 2.33594 2.3335 4.87299 2.3335 8.0026C2.3335 11.1322 4.87055 13.6693 8.00016 13.6693C11.1298 13.6693 13.6668 11.1322 13.6668 8.0026C13.6668 4.87299 11.1298 2.33594 8.00016 2.33594ZM0.333496 8.0026C0.333496 3.76842 3.76598 0.335938 8.00016 0.335938C12.2343 0.335938 15.6668 3.76842 15.6668 8.0026C15.6668 12.2368 12.2343 15.6693 8.00016 15.6693C3.76598 15.6693 0.333496 12.2368 0.333496 8.0026Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L10.7071 9.29289C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="currentColor" />
        </g>
    </svg>
);

export default ErrorCircle;
