import { useCallback, useRef } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import { Drawer, RenderIf } from 'react-rainbow-components';
import { COMPLETION_SETTINGS_DRAWER } from '../../../../../constants';
import FormFields from './form';
import { StyledUniversalForm, SubmitButton } from './styled';

interface CompletionSettingsDrawerProps {
    isOpen?: boolean;
    settings?: Record<string, unknown>,
    onChange?: (values: Record<string, unknown>) => void;
}

const CompletionSettingsDrawer = ({
    isOpen = false,
    settings = {},
    onChange = () => {},
}: CompletionSettingsDrawerProps) => {
    const [, closeDrawer] = useOpenModal(COMPLETION_SETTINGS_DRAWER);
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const submitForm = useCallback(() => submitButtonRef.current?.click(), []);
    const handleSubmit = useCallback((values: Record<string, unknown>) => {
        onChange(values);
        closeDrawer();
    }, [closeDrawer, onChange]);
    return (
        <Drawer
            isOpen={isOpen}
            header="Custom Assistant Config"
            slideFrom="right"
            onRequestClose={submitForm}
        >
            <RenderIf isTrue={isOpen}>
                <StyledUniversalForm
                    initialValues={settings}
                    onSubmit={handleSubmit}
                >
                    <FormFields />
                    <SubmitButton
                        ref={submitButtonRef}
                        type="submit"
                    />
                </StyledUniversalForm>
            </RenderIf>
        </Drawer>
    );
};

export default CompletionSettingsDrawer;
