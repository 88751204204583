import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import { color } from 'theme';

interface CognitiveLoadBadgeProps {
    cognitiveLoad: string;
}

export const StyledBadge = styled(Badge)<CognitiveLoadBadgeProps>`
    flex-shrink: 0;
    gap: 12px;
    padding-right: 8px;
    padding-left: 8px;

    > span {
        display: flex;
        align-items: center;
    }

    ${(props) => props.cognitiveLoad === 'High' && `
        color: ${color('error.main')(props)};
        background: ${color('error.light')(props)};
    `};

    ${(props) => props.cognitiveLoad === 'Moderate' && `
        color: ${color('warning.main')(props)};
        background: ${color('warning.light')(props)};
    `};

    ${(props) => props.cognitiveLoad === 'Low' && `
        color: ${color('success.main')(props)};
        background: ${color('success.light')(props)};
    `};

`;
