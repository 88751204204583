import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Pagination, RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import AgentLanguagesColumn from 'components/AgentLanguagesColumn';
import AgentTypeColumn from 'components/AgentTypeColumn';
import AgentActionsColumn from 'components/AgentActionsColumn';
import useAlgoliaSearch from 'hooks/useAlgoliaSearch';
import PageHeader from 'components/PageHeader';
import { ITEMS_PER_PAGE, ADD_MODIFY_AGENT_DRAWER } from '../../../../constants';
import {
    Container,
    Content,
    Header,
    StyledTable,
    TableFooter,
    StyledNavigationButtonColumn,
} from './styled';
import SearchForm from './searchForm';

const AgentsList = () => {
    const navigate = useNavigate();
    const [openDrawer] = useOpenModal(ADD_MODIFY_AGENT_DRAWER);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const {
        data: agents,
        totalRecords,
        totalPages,
        currentPage,
        isLoading: isLoadingAgents,
        goToPage,
    } = useAlgoliaSearch({
        searchQuery: searchTerm,
        collection: 'agents',
    });

    const onFilterChange = useCallback((value: string) => setSearchTerm(value || ''), []);

    return (
        <Container>
            <PageHeader title="Agents Manager" />
            <Content>
                <Header>
                    <SearchForm
                        searchTerm={searchTerm}
                        onChange={onFilterChange}
                        onAddClick={() => openDrawer({
                            header: 'Create New Agent',
                        })}
                    />
                </Header>
                <StyledTable
                    data={agents}
                    keyField="objectID"
                    variant="listview"
                    isLoading={isLoadingAgents}
                >
                    <Column
                        header="Company Name"
                        field="companyName"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={StyledNavigationButtonColumn}
                        onClick={({ row }: { row: { objectID: string } }) => navigate(`/agents/${row.objectID}`)}
                    />
                    <Column
                        header="Agent Type"
                        field="type"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={AgentTypeColumn}
                        defaultWidth={180}
                    />
                    <Column
                        header="Language"
                        field="greetings"
                        headerAlignment="left"
                        cellAlignment="left"
                        component={AgentLanguagesColumn}
                        defaultWidth={100}
                    />
                    <Column
                        field="objectID"
                        cellAlignment="right"
                        component={AgentActionsColumn}
                        defaultWidth={100}
                    />
                </StyledTable>
                <RenderIf isTrue={totalRecords > ITEMS_PER_PAGE}>
                    <TableFooter>
                        <Pagination
                            pages={totalPages}
                            activePage={currentPage + 1}
                            onChange={(_, page) => goToPage(page - 1)}
                        />
                    </TableFooter>
                </RenderIf>
            </Content>
        </Container>
    );
};

export default AgentsList;
