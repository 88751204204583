import { useState } from 'react';
import {
    Button, ButtonIcon, Modal, RenderIf, Textarea,
} from 'react-rainbow-components';
import { Conversation } from 'data/firestore/session/conversation/types';
import { Message } from 'data/firestore/message/types';
import { MagicPencil, MinusCircle } from 'components/icons';
import isValidConversation from 'pages/App/Dataset/Conversation/helpers/isValidConversation';
import SentimentBadge from '../SentimentBadge';
import CognitiveLoadBadge from '../CognitiveLoadBadge';
import useSentimentAnalysis from '../../hooks/useSentimentAnalysis';
import useCognitiveLoad from '../../hooks/useCognitiveLoad';
import useIntent from '../../hooks/useIntent';
import HelpMeWriteModal from './helpMeWriteModal';
import {
    Container,
    RoleContainer,
    BadgeContainer,
    StyledTextarea,
    StyledBadge,
    MessageContainer,
    MetadataContainer,
    MoreDetailsContainer,
    DescriptionContainer,
    DescriptionText,
    DescriptionTitle,
    NativeButton,
    Actions,
    MagicPencilButton,
    ModalTitle,
    StyledModal,
    TimeBadge,
    Time,
    TimeBadgeContainer,
    ConversationActionsContainer,
    StyledButton,
    ImportButton,
} from './styled';

const importMessagesPlaceholder = `
assistant: Hello
user: Hi
assistant: How can I help you?
user: I need help with my order
`.trim();

interface ChatMessageProps {
    value: Conversation | Omit<Message, 'conversationId' | 'order'>;
    collectionPath?: string;
    readOnly?: boolean;
    actionsAlwaysVisible?: boolean;
    onChange?: (value: Conversation) => void;
    onRemove?: () => void;
    onAddMessageAfter?: () => void;
    onImportAfter?: (messages: Pick<Conversation | Message, 'role' | 'content'>[]) => void;
    onAddBuildingBlockAfter?: () => void;
}

const ChatMessage = (props: ChatMessageProps) => {
    const {
        value,
        collectionPath = '/message',
        onChange = () => {},
        onRemove = () => {},
        onAddMessageAfter = () => {},
        onImportAfter = () => {},
        onAddBuildingBlockAfter = () => {},
        readOnly = false,
        actionsAlwaysVisible = false,
    } = props;
    const {
        id, role, speechToText, performance,
    } = value as Conversation;
    const [content, setContent] = useState(value.content);
    const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(event.target.value);
    };
    const handleRoleChange = () => {
        if (!readOnly) onChange({ content, role: role === 'user' ? 'assistant' : 'user', id });
    };
    const triggerChange = () => {
        onChange({ content, role, id });
    };
    const onKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            triggerChange();
        }
    };
    const sentiment = useSentimentAnalysis(id, collectionPath);
    const cognitiveLoad = useCognitiveLoad(id, collectionPath);
    const intent = useIntent(id, collectionPath);
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const toggleMoreDetails = () => {
        setShowMoreDetails(!showMoreDetails);
    };
    const [isOpenHelpWrite, setIsOpenHelpWrite] = useState(false);
    const openHelpWriteModal = () => setIsOpenHelpWrite(true);
    const [conversationActionsVisible, setConversationActionsVisible] = useState(
        actionsAlwaysVisible,
    );
    const showConversationActions = () => setConversationActionsVisible(!readOnly);
    const hideConversationActions = () => setConversationActionsVisible(actionsAlwaysVisible);

    const [importing, setImporting] = useState(false);
    const [isOpenImportAfter, setIsOpenImportAfter] = useState(false);
    const openImportAfterModal = () => setIsOpenImportAfter(true);

    const [messagesToImport, setMessagesToImport] = useState('');
    const [messagesToImportError, setMessagesToImportError] = useState('');

    const importMessages = async () => {
        if (isValidConversation(messagesToImport)) {
            setMessagesToImportError('');
            const msgs = messagesToImport.split('\n').reduce(
                (acc: Pick<Conversation | Message, 'role' | 'content'>[], msg: string) => {
                    const [msgRole, msgContent] = msg.split(':');
                    if (!msgRole) return acc;
                    return [
                        ...acc,
                        { role: msgRole, content: msgContent } as Pick<Conversation | Message, 'role' | 'content'>,
                    ];
                },
                [],
            );
            setImporting(true);
            await onImportAfter(msgs);
            setIsOpenImportAfter(false);
            setMessagesToImport('');
            setImporting(false);
        } else {
            setMessagesToImportError('Invalid format');
        }
    };

    return (
        <Container
            role={role}
            onMouseEnter={showConversationActions}
            onMouseLeave={hideConversationActions}
        >
            <MessageContainer>
                <RoleContainer onClick={handleRoleChange}>
                    <BadgeContainer>
                        <RenderIf isTrue={role === 'user'}>
                            <StyledBadge label="User" borderRadius="semi-square" />
                        </RenderIf>
                        <RenderIf isTrue={role === 'assistant'}>
                            <StyledBadge label="Assistant" borderRadius="semi-square" />
                        </RenderIf>
                    </BadgeContainer>
                    <RenderIf isTrue={readOnly}>
                        <Time>0:00</Time>
                    </RenderIf>
                </RoleContainer>
                <StyledTextarea
                    label="Content"
                    value={content}
                    onChange={handleContentChange}
                    onBlur={triggerChange}
                    onKeyDown={onKeydown}
                    hideLabel
                    rows={1}
                    borderRadius="semi-rounded"
                    grow
                    readOnly={readOnly}
                />
                <RenderIf isTrue={!readOnly}>
                    <Actions>
                        <MagicPencilButton
                            icon={<MagicPencil />}
                            borderRadius="semi-rounded"
                            onClick={openHelpWriteModal}
                        />
                        <ButtonIcon onClick={onRemove} icon={<MinusCircle />} borderRadius="semi-rounded" />
                    </Actions>
                </RenderIf>
            </MessageContainer>
            <MetadataContainer>
                <RenderIf isTrue={role === 'user' && readOnly && speechToText?.confidence}>
                    <StyledBadge label={`Confidence: ${speechToText?.confidence.toFixed(2)}`} />
                </RenderIf>
                <RenderIf isTrue={intent}>
                    <StyledBadge label={`Intent: ${intent?.name}`} />
                </RenderIf>
                <NativeButton type="button" onClick={toggleMoreDetails}>
                    <CognitiveLoadBadge cognitiveLoad={cognitiveLoad} />
                </NativeButton>
                <SentimentBadge sentiment={sentiment} />
            </MetadataContainer>
            <RenderIf isTrue={!readOnly && conversationActionsVisible}>
                <ConversationActionsContainer>
                    <StyledButton
                        label="Add Message"
                        size="small"
                        variant="outline-brand"
                        borderRadius="semi-rounded"
                        onClick={onAddMessageAfter}
                    />
                    <StyledButton
                        label="Add Building Block"
                        size="small"
                        variant="outline-brand"
                        borderRadius="semi-rounded"
                        onClick={onAddBuildingBlockAfter}
                    />
                    <StyledButton
                        label="Import Conversation"
                        size="small"
                        variant="outline-brand"
                        borderRadius="semi-rounded"
                        onClick={openImportAfterModal}
                    />
                </ConversationActionsContainer>
            </RenderIf>
            <RenderIf isTrue={showMoreDetails}>
                <MoreDetailsContainer>
                    <DescriptionContainer>
                        <DescriptionTitle>
                            Why the congnitive load is:
                            {' '}
                            {cognitiveLoad?.cognitiveLoad}
                            ?
                        </DescriptionTitle>
                        <DescriptionText>
                            {cognitiveLoad?.why}
                        </DescriptionText>
                    </DescriptionContainer>
                </MoreDetailsContainer>
            </RenderIf>
            <StyledModal
                isOpen={isOpenHelpWrite}
                onRequestClose={() => setIsOpenHelpWrite(false)}
                title={<ModalTitle>Help me Write</ModalTitle>}
                size="medium"
                borderRadius="semi-rounded"
            >
                <HelpMeWriteModal
                    message={value}
                    onRequestClose={() => setIsOpenHelpWrite(false)}
                />
            </StyledModal>
            <Modal
                isOpen={isOpenImportAfter}
                onRequestClose={() => setIsOpenImportAfter(false)}
                title={<ModalTitle>Import Message</ModalTitle>}
                size="medium"
                borderRadius="semi-rounded"
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_medium"
                            label="Close"
                            variant="neutral"
                            borderRadius="semi-rounded"
                            onClick={() => setIsOpenImportAfter(false)}
                        />
                        <ImportButton
                            label="Import"
                            borderRadius="semi-rounded"
                            onClick={importMessages}
                            variant="brand"
                            isLoading={importing}
                        />
                    </div>
                )}
            >
                <Textarea
                    value={messagesToImport}
                    onChange={(e) => setMessagesToImport(e.target.value)}
                    placeholder={importMessagesPlaceholder}
                    rows={10}
                    error={messagesToImportError}
                    disabled={importing}
                />
            </Modal>
            <RenderIf isTrue={readOnly}>
                <TimeBadgeContainer>
                    <TimeBadge label={performance?.delay ? `${performance.delay.toFixed(2)}ms` : '0ms'} borderRadius="semi-square" variant="lightest" size="small" />
                    {!!(performance?.bargeInDelay) && (
                        <TimeBadge label={`Barge in: ${performance.bargeInDelay.toFixed(2)}ms`} borderRadius="semi-square" variant="warning" size="small" />
                    )}
                </TimeBadgeContainer>
            </RenderIf>
        </Container>
    );
};

export default ChatMessage;
