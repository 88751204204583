import styled from 'styled-components';
import { color } from 'theme';
import { RecordField } from '@rainbow-modules/record';
import {
    Badge, ButtonIcon, Modal, Textarea, Table,
} from 'react-rainbow-components';
import { EditNote } from 'components/icons';

export const Container = styled.div`
    background: ${color('background.main')};
    height: 100%;
    border-radius: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 20px;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    line-height: 18px;
    color: ${color('text.label')};
`;

export const SectionSubtitle = styled(RecordField)`
    flex-direction: row;
    gap: 8px;
    align-items: center;
    
    > span:last-child {
        color: ${color('text.label')};
        font-size: 12px;
    }
`;

export const RoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const BadgeContainer = styled.div`
    width: 100px;
`;

export const StyledBadge = styled(Badge)`
    flex-shrink: 0;
    color: ${color('brand.main')};
    background: ${color('brand.light')};
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0;
    gap: 8px;
    width: 100%;
`;

export const StyledTextarea = styled(Textarea)`
    flex-grow: 1;
`;

export const Actions = styled.div`
    display: flex;
    gap: 4px;
`;

export const MagicPencilButton = styled(ButtonIcon)`
    > svg {
        width: 20px !important;
        height: 20px !important;
    }
`;

export const StyledModal = styled(Modal)`
    background-color: ${color('background.secondary')};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px 0px;
`;

export const ModalTitle = styled.span`
    font-size: 22px;
    font-family: 'Lato Bold';
    color: ${color('text.main')};
    padding: 16px 20px;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 8px 0;
`;

export const Card = styled.div`
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${color('border.disabled')};
    background: ${color('background.main')};
`;

export const CardTitle = styled.h2`
    color: ${color('text.header')};
    font-size: 12px;
    line-height: 150%;
`;

export const CardContent = styled.p`
    color: ${color('text.main')};
    font-size: 14px;
    line-height: 150%;
`;

export const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 12px;
`;

export const TrashButton = styled(ButtonIcon)`
    height: 32px;
    width: 32px;
`;

export const ModalFooter = styled.footer`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
`;

export const ArrowButton = styled(ButtonIcon)`
    position: absolute;
    right: 4px;
    top: 3px;
`;

export const StyledCustomTextarea = styled(Textarea)`
    width: 100%;
`;

export const Icon = styled(EditNote)`
    margin-right: 8px;
`;

export const StyledTable = styled(Table)`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    margin-top: 12px;
`;
