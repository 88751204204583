import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { Buildingblocks } from "./types";

export type BuildingblocksUpdateService<T> = (
  datasetId: string,
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: BuildingblocksUpdateService<Buildingblocks> = async (
  datasetId,
  id,
  data
) => {
  const collectionRef = doc(db, `/dataset/${datasetId}/buildingBlocks/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
