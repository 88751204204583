import styled from 'styled-components';
import { ButtonIcon, Textarea } from 'react-rainbow-components';

export const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
`;

export const StyledInput = styled(Textarea)`
    position: relative;
    flex: 1;
    textarea {
        border-radius: 10px;
        box-sizing: border-box;
    }
`;

export const ButtonContainer = styled.span`
    height: 100%;
    right: 3px;
    position: absolute;
    line-height: 1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    width: 32px;
    height: 32px;
`;
