import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { Message } from "./types";

export type MessageUpdateService<T> = (
  id: string,
  doc: Partial<Omit<T, "id">>
) => Promise<void>;

const update: MessageUpdateService<Message> = async (id, data) => {
  const collectionRef = doc(db, `/message/${id}`);
  return updateDoc(collectionRef, {
    ...data,
    updatedAt: serverTimestamp(),
  });
};

export default update;
