import formatter from 'data/services/date/formatter';
import { StyledColumnWrapper } from './styled';

interface DateTimeColumnProps {
    value?: Date;
}

const DateTimeColumn = (props: DateTimeColumnProps) => {
    const { value } = props;
    if (!value) return null;
    return <StyledColumnWrapper>{formatter.format(value)}</StyledColumnWrapper>;
};

export default DateTimeColumn;
