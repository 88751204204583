import EmptyMessage from 'components/EmptyMessage';
import PhoneSquare from 'components/icons/phoneSquare';

const NotAvailableNumbersMessage = () => (
    <EmptyMessage
        icon={<PhoneSquare />}
        title="No phone numbers available"
        description='Sorry, there are no phone numbers available at this time. Please try another time or with a different "Area Code" or "State".'
    />
);

export default NotAvailableNumbersMessage;
