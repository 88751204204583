import { isEmpty } from '@rainbow-modules/validation';

const createDownloadLink = (url: string, fileName: string) => {
    if (isEmpty(url)) return;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

export default createDownloadLink;
