import { Outlet } from 'react-router-dom';
import TopBar from 'components/TopBar';
import {
    Container,
    Content,
} from './styled';

const App = () => (
    <Container>
        <TopBar />
        <Content>
            <Outlet />
        </Content>
    </Container>
);

export default App;
