import { Evaluations } from './types';

const answerValues = new Map<string | null, number>([
    ['Strongly disagree', 1],
    ['Disagree', 2],
    ['Neutral', 3],
    ['Agree', 4],
    ['Strongly agree', 5],
    [null, 0],
]);

function evaluateConversation(responses: Evaluations): string {
    let totalScore = 0;
    responses.forEach((response) => {
        const answerValue = answerValues.get(response.answer);
        if (answerValue) {
            totalScore += answerValue;
        }
    });

    const averageScore = totalScore / responses.length;

    if (averageScore <= 1.8) {
        return 'Very Poor';
    } if (averageScore <= 2.6) {
        return 'Poor';
    } if (averageScore <= 3.4) {
        return 'Average';
    } if (averageScore <= 4.2) {
        return 'Good';
    }
    return 'Excellent';
}

export default evaluateConversation;
