import { Button } from 'react-rainbow-components';
import { FooterProps } from './types';
import { FooterContainer } from './styled';

const Footer = ({
    formId,
    submitButtonLabel = 'Create',
    submitDisabled = false,
    onCancelClick = () => {},
}: FooterProps) => (
    <FooterContainer>
        <Button
            label="Close"
            variant="neutral"
            borderRadius="semi-rounded"
            className="rainbow-m-right_medium"
            onClick={onCancelClick}
        />
        <Button
            label={submitButtonLabel}
            variant="brand"
            form={formId}
            type="submit"
            borderRadius="semi-rounded"
            disabled={submitDisabled}
        />
    </FooterContainer>
);

export default Footer;
