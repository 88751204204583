import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

const formatIntlPhoneNumber = (phone: string = '', defaultCountryCode: CountryCode = 'US') => {
    if (!phone) return '';
    try {
        const phoneNumber = parsePhoneNumber(phone, defaultCountryCode);
        if (phoneNumber?.isValid()) {
            return `+${phoneNumber?.countryCallingCode} ${phoneNumber?.formatNational()}`;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', phone);
    }
    return phone;
};

export default formatIntlPhoneNumber;
