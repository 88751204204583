import { StyledColumnWrapper } from './styled';

interface LanguageColumnProps {
    value?: string[];
}

const LanguageColumn = (props: LanguageColumnProps) => {
    const { value } = props;
    if (!value) return null;
    const valueStr = (Array.isArray(value) ? value.join(',') : value).toUpperCase();

    return (
        <StyledColumnWrapper>
            {valueStr}
        </StyledColumnWrapper>
    );
};

export default LanguageColumn;
