import styled from 'styled-components';
import { Input, LoadingShape, Picklist } from 'react-rainbow-components';
import { color } from 'theme';

export const InputContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['$error'].includes(prop),
})<{ $error: boolean }>`
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    font-size: 0.75rem;
    background: ${color('background.main')};
    transition: opacity 0.1s linear, visibility 0.1s linear;
    border: 1px solid ${color('border.main')};
    margin: 0;

    ${(props) => props.$error && `
        border: 2px solid ${props.theme.rainbow.palette.error.main};
        background-clip: padding-box;
    `};
`;

export const Header = styled.div`
    display: flex;
    height: 45px;
    border-bottom: 1px solid ${color('border.divider')};
    align-items: center;
    padding: 0 0.75rem;
    
    span {
        font-family: Lato;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: ${color('text.main')};
        text-transform: unset;
    }
`;

export const ErrorText = styled.div`
    font-size: 0.875rem;
    margin-top: 0.5rem;
    align-self: start;
    color: ${color('error.main')};
`;

export const StyledItemContainer = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 0 .75rem;
    color: ${color('text.main')};
    flex: 1; 
    height: 60px;
`;

export const StyledInput = styled(Input)`
    display: inline;
    margin-bottom: 0;
    margin-left: 4px;
    div[id^="error-message-"] {
        display: none;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;

    &  > span:first-child {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: ${color('text.main')}
    }

    &  > span:last-child {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: ${color('text.header')}
    }
`;

export const StyledPicklist = styled(Picklist)`
    input {
        width: 160px;
    }
`;

export const StyledLoadingShape = styled(LoadingShape).withConfig({
    shouldForwardProp: (prop) => !['$width'].includes(prop),
})<{ $width?: string }>`
    margin: 8px 16px;
    display: flex;
    width: ${(props) => props.$width};

    &:first-child {
        margin-top: 14px;
    }

    &:last-child {
        margin-bottom: 14px;
    }
`;

export const EmptyMessage = styled.div`
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    flex: 1;
    height: 60px;
    align-items: center;
    justify-content: center;
    color: ${color('text.title')};
`;
