import {
    Button, Column, Modal, RenderIf, Table,
} from 'react-rainbow-components';
import {
    limit, orderBy, query, where,
} from 'firebase/firestore';
import { ArrowRight, ArrowLeft } from '@rainbow-modules/icons';
import DateTimeColumn from 'components/DateTimeColumn';
import UsageReportStatusColumn from 'components/UsageReportStatusColumn';
import UsageReportValueColumn from 'components/UsageReportValueColumn';
import useAgentUsageRecords from 'data/firestore/usagereports/useCollectionWithPagination';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { PageHeaderTitle } from 'components/styled';
import { ITEMS_PER_PAGE, SHOW_USAGE_RECORD_DETAILS_MODAL } from '../../../../../../constants';
import UsageRecordDetailsModal from './detailsModal';
import { TableFooter } from './styled';
import { ModalTitle } from '../CreateBillingLink/styled';

const UsageReports = ({
    agentId,
}:{
    agentId: string;
}) => {
    const {
        usageRecordId, ...usageRecordDetailsModalProps
    } = useConnectModal(SHOW_USAGE_RECORD_DETAILS_MODAL);
    const [openDetailsModal] = useOpenModal(SHOW_USAGE_RECORD_DETAILS_MODAL);

    const {
        isLoading: isLoadingUsageRecords,
        data: usageRecords = [],
        totalRecords,
        nextPage,
        prevPage,
        firstPage,
        hasMore,
    } = useAgentUsageRecords({
        disabled: !agentId,
        listQueryFn: (ref) => query(
            ref,
            where('agentId', '==', agentId),
            orderBy('periodEnd', 'desc'),
            limit(30),
        ),
        limit: ITEMS_PER_PAGE,
    });

    return (
        <>
            <PageHeaderTitle>Usage Report</PageHeaderTitle>
            <Table
                keyField="id"
                data={usageRecords}
                emptyTitle="No usage records were found"
                emptyDescription=""
                variant="listview"
                isLoading={isLoadingUsageRecords}
            >
                <Column
                    header="From"
                    field="periodStart"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={DateTimeColumn}
                    defaultWidth={300}
                />
                <Column
                    header="To"
                    field="periodEnd"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={DateTimeColumn}
                    defaultWidth={300}
                />
                <Column
                    header="Usage"
                    field="usage"
                    headerAlignment="left"
                    cellAlignment="left"
                    component={UsageReportValueColumn}
                    onClick={({ row }: { row: { id: string } }) => openDetailsModal({
                        usageRecordId: row?.id,
                    })}
                />
                <Column
                    header="Status"
                    field="status"
                    headerAlignment="left"
                    cellAlignment="right"
                    defaultWidth={120}
                    component={UsageReportStatusColumn}
                />
            </Table>
            <RenderIf isTrue={totalRecords > ITEMS_PER_PAGE}>
                <TableFooter>
                    <Button
                        variant="base"
                        size="small"
                        borderRadius="semi-rounded"
                        disabled={isLoadingUsageRecords || firstPage}
                        onClick={prevPage}
                    >
                        <ArrowLeft className="rainbow-m-right_small" />
                        Prev
                    </Button>
                    <Button
                        variant="base"
                        size="small"
                        borderRadius="semi-rounded"
                        disabled={isLoadingUsageRecords || !hasMore}
                        onClick={nextPage}
                    >
                        Next
                        <ArrowRight className="rainbow-m-left_small" />
                    </Button>
                </TableFooter>
            </RenderIf>
            <Modal
                {...usageRecordDetailsModalProps}
                size="large"
                borderRadius="semi-rounded"
                title={<ModalTitle>Usage Reported</ModalTitle>}
            >
                <UsageRecordDetailsModal usageRecordId={usageRecordId as string} />
            </Modal>
        </>
    );
};

export default UsageReports;
