import { HelpText } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const StyledColumnWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['$color'].includes(prop),
})<{ $color: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    background: ${(props) => color(props.$color)(props)};
    overflow: hidden;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
`;

export const StyledHelpText = styled(HelpText)`
    margin-left: 5px;
    width: 14px;
    height: 14px;

    > svg {
        margin-bottom: 3px;
        width: 14px;
        height: 14px;
    }

    > svg path {
        fill: white;
    }
`;
