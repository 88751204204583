import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Column, RenderIf, Pagination } from 'react-rainbow-components';
import DateTimeColumn from 'components/DateTimeColumn';
import SentimentColumn from 'components/SentimentColumn';
import LanguageColumn from 'components/LanguageColumn';
import useAlgoliaHistorySearch from 'hooks/useAlgoliaSearch';
import PageHeader from 'components/PageHeader';
import PhoneNumberColumn from 'components/PhoneNumberColumn';
import SessionIdColumn from './columns/sessionId';
import { ITEMS_PER_PAGE } from '../../../../constants';
import {
    Container,
    Content,
    StyledTable,
    TableFooter,
    TableContainer,
} from './styled';
import SearchForm from './form';
import { Value } from './types';

const buildAlgoliaFilters = (
    agents: string[] = [],
    dates: Date[] = [],
) => {
    const filters = [];

    if (agents.length > 0) {
        filters.push(`${agents.map((agentId) => `agentId:${agentId}`).join(' OR ')}`);
    }

    if (dates.length > 1) {
        const [from, to] = dates;
        filters.push(`createdAt:${from.getTime()} TO ${to.getTime()}`);
    }
    return filters.join(' AND ');
};

const ConversationsList = () => {
    const [filters, setFilters] = useSearchParams({});

    const selectedDate = useMemo(
        () => {
            const dates = [];

            const from = filters.get('from');
            if (from) {
                dates.push(new Date(Number.parseInt(from, 10)));
            }

            const to = filters.get('to');
            if (to) {
                dates.push(new Date(Number.parseInt(to, 10)));
            }

            return dates;
        },
        [filters],
    );

    const selectedAgents = useMemo(
        () => {
            const agents = filters.get('agents');
            if (agents) return agents.split(',');
            return [];
        },
        [filters],
    );

    const searchQuery = useMemo(
        () => filters.get('search') || '',
        [filters],
    );

    const currentPage = useMemo(
        () => {
            try {
                const pageNo = parseInt(filters.get('page') || '1', 10);
                return pageNo - 1;
            } catch (error) {
                // no catch
            }
            return 0;
        },
        [filters],
    );

    const {
        data: conversations,
        totalRecords,
        totalPages,
        isLoading: isLoadingConversations,
        goToPage,
    } = useAlgoliaHistorySearch({
        searchQuery,
        currentPage,
        filters: buildAlgoliaFilters(selectedAgents, selectedDate),
    });

    const changePage = useCallback(
        (nextPage: number) => {
            setFilters(
                (prevFilters) => {
                    if (nextPage === 0) {
                        prevFilters.delete('page');
                    } else {
                        prevFilters.set('page', (nextPage + 1).toString());
                    }
                    return prevFilters;
                },
            );
            goToPage(nextPage);
        },
        [setFilters, goToPage],
    );

    const onFilterChange = useCallback(
        (value: Value) => setFilters(
            (prevFilters) => {
                if (value.text) {
                    prevFilters.set('search', value.text);
                } else {
                    prevFilters.delete('search');
                }

                if (value.agents && value.agents.length > 0) {
                    prevFilters.set('agents', value.agents.join(','));
                } else {
                    prevFilters.delete('agents');
                }

                const [from, to] = value.date || [];
                if (from) {
                    prevFilters.set('from', `${from.getTime()}`);
                } else {
                    prevFilters.delete('from');
                }

                if (to) {
                    prevFilters.set('to', `${to.getTime()}`);
                } else {
                    prevFilters.delete('to');
                }

                changePage(0);

                return prevFilters;
            },
        ),
        [setFilters, changePage],
    );

    return (
        <Container>
            <PageHeader title="Conversations History" />
            <Content>
                <SearchForm
                    selectedDate={selectedDate}
                    selectedAgents={selectedAgents}
                    searchTerm={searchQuery}
                    onChange={onFilterChange}
                />
                <TableContainer>
                    <StyledTable
                        data={conversations}
                        keyField="objectID"
                        variant="listview"
                        isLoading={isLoadingConversations}
                    >
                        <Column
                            header="Conversation ID"
                            field="objectID"
                            headerAlignment="left"
                            cellAlignment="left"
                            component={SessionIdColumn}
                        />
                        <Column
                            header="Sentiment"
                            field="sentiment"
                            headerAlignment="left"
                            cellAlignment="left"
                            component={SentimentColumn}
                            defaultWidth={140}
                        />
                        <Column
                            header="Type"
                            field="direction"
                            headerAlignment="left"
                            cellAlignment="left"
                            defaultWidth={90}
                        />
                        <Column
                            header="Language"
                            field="language"
                            headerAlignment="left"
                            cellAlignment="left"
                            component={LanguageColumn}
                            defaultWidth={90}
                        />
                        <Column
                            header="Company"
                            field="companyName"
                            headerAlignment="left"
                            cellAlignment="left"
                            defaultWidth={120}
                        />
                        <Column
                            header="From"
                            field="from"
                            component={PhoneNumberColumn}
                            headerAlignment="left"
                            cellAlignment="left"
                            defaultWidth={145}
                        />
                        <Column
                            header="To"
                            field="to"
                            component={PhoneNumberColumn}
                            headerAlignment="left"
                            cellAlignment="left"
                            defaultWidth={145}
                        />
                        <Column
                            header="Duration"
                            field="duration"
                            headerAlignment="left"
                            cellAlignment="left"
                            defaultWidth={80}
                        />
                        <Column
                            header="Start time"
                            field="createdAt"
                            headerAlignment="left"
                            cellAlignment="left"
                            component={DateTimeColumn}
                            defaultWidth={170}
                        />
                        <Column
                            header="Status"
                            field="status"
                            headerAlignment="left"
                            cellAlignment="left"
                            defaultWidth={90}
                        />
                    </StyledTable>
                    <RenderIf isTrue={totalRecords > ITEMS_PER_PAGE}>
                        <TableFooter>
                            <Pagination
                                pages={totalPages}
                                activePage={currentPage + 1}
                                onChange={(_, page) => changePage(page - 1)}
                            />
                        </TableFooter>
                    </RenderIf>
                </TableContainer>
            </Content>
        </Container>
    );
};

export default ConversationsList;
