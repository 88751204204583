import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    height: fit-content;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background: rgba(227, 229, 237, 0.05);
`;

export const Text = styled.p`
    font-size: 14px;
    color: ${color('text.main')};
`;
