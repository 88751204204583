/* eslint-disable react/jsx-no-undef */
import { isEmpty } from '@rainbow-modules/validation';
import { UniversalForm } from '@rainbow-modules/forms';
import { FormContainer } from './styled';
import Fields from './fields';
import { GOOGLE_MAPS_API_KEY } from '../../../../constants';

interface FormProps {
    id?: string,
    initialValues?: Record<string, unknown>,
    onSubmit?: (values: Record<string, unknown>) => void,
}

const getPhoneValue = (value?: {
    countryCode?: string;
    isoCode?: string;
    phone?: string;
}) => (
    !isEmpty(value?.phone) ? `${value?.countryCode}${value?.phone}` : ''
);

const getHandOffCallTransferValueAsPercent = (value?: number, handOffPhone?: string) => {
    if (!isEmpty(handOffPhone) && value) {
        return value / 100;
    }
    return 0;
};

interface Geometry {
    location: Record<string, Function>,
}

const resolveTimezone = async (lat: number, lng: number) => {
    try {
        const timestamp = Math.floor(Date.now() / 1000);
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${GOOGLE_MAPS_API_KEY}`,
        );
        if (response.ok) {
            const { timeZoneId } = await response.json();
            return timeZoneId;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }

    return null;
};

export interface SpeechConfig {
    service: string;
    voice: string;
    config?: {
        model_id?: string;
        optimize_streaming_latency?: number;
    };
}

interface FormValues extends Record<string, unknown> {
    speechTimeout: number;
    companyName: string;
    companyAddress: Record<string, unknown>;
    agentType: Record<string, unknown>;
    handoffNumber?: Record<string, unknown>;
    supportedLanguages: Record<string, string>[];
    greetings: Record<string, string>;
    phoneNumbers: {
        id: string;
        language: Record<string, string>
    }[];
    model: Record<string, unknown>;
    temperature: number;
    speech: Record<string, SpeechConfig>;
}

const getLocation = (geometry?: Geometry) => {
    const lat = (
        typeof geometry?.location?.lat === 'function'
            ? geometry?.location?.lat()
            : geometry?.location?.lat
    );
    const lng = (
        typeof geometry?.location?.lng === 'function'
            ? geometry?.location?.lng()
            : geometry?.location?.lng
    );
    return { lat, lng };
};

const mapFormValues = async (currentValues: FormValues) => {
    const {
        companyAddress,
        agentType,
        handoffNumber,
        handoffCallTransferPercentage,
        speechTimeout,
        supportedLanguages = [],
        phoneNumbers,
        usedPhones,
        greetings,
        model,
        temperature,
        ...rest
    } = currentValues;

    const { lat, lng } = getLocation(companyAddress.geometry as Geometry);
    (companyAddress.geometry as Geometry).location = { lat, lng };

    return {
        ...rest,
        speechTimeout: Number(speechTimeout),
        greetings: supportedLanguages.reduce(
            (acc, language) => ({
                ...acc,
                [language.name]: (greetings as Record<string, string>)[language.name],
            }),
            {},
        ),
        type: agentType.value,
        handoffNumber: getPhoneValue(handoffNumber),
        handoffCallTransferPercentage: getHandOffCallTransferValueAsPercent(
            handoffCallTransferPercentage as number,
            handoffNumber?.phone as string,
        ),
        supportedLanguages: supportedLanguages.map(
            (language) => language.name,
        ),
        companyAddress: {
            addressInfo: companyAddress,
            formattedAddress: companyAddress.formatted_address,
            timezone: await resolveTimezone(lat, lng),
        },
        phoneNumbers: phoneNumbers.map((phoneNumber) => ({
            id: phoneNumber.id,
            language: phoneNumber.language.name,
        })),
        model,
        temperature: Number(temperature),
    };
};

const Form = ({ id, initialValues, onSubmit }: FormProps) => {
    const onSubmitMiddleware = async (formValues: Record<string, unknown>) => {
        if (onSubmit) onSubmit(await mapFormValues(formValues as FormValues));
    };

    return (
        <FormContainer>
            <UniversalForm
                id={id}
                onSubmit={onSubmitMiddleware}
                initialValues={initialValues}
            >
                <Fields />
            </UniversalForm>
        </FormContainer>
    );
};

export default Form;
