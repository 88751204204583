import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { ADD_MODIFY_AGENT_DRAWER } from '../../../../constants';
import AgentFormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';

const AddAgentForm = () => {
    const navigate = useNavigate();
    const [, closeDrawer] = useOpenModal(ADD_MODIFY_AGENT_DRAWER);
    const {
        mutateAsync: createAgent,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: '/agents',
        method: 'POST',
        server: process.env.REACT_APP_BASE_URL,
        onSuccess: (response) => {
            closeDrawer();
            navigate(`/agents/${response.id}`);
        },
    });

    const handleSubmit = useCallback(async (values: Record<string, unknown>) => {
        try {
            showAppSpinner();
            await createAgent({
                body: values,
            });
        } catch (error) {
            showAppMessage({
                variant: 'error',
                message: (error as Error).message,
                timeout: 5000,
            });
        }
        hideAppSpinner();
    }, [createAgent]);

    return (
        <Container>
            <Content>
                <Form
                    id="agent-form"
                    initialValues={{
                        speechTimeout: 1.2,
                        temperature: 0.5,
                        useTwilioStream: true,
                        callRecordingEnabled: true,
                        multilingual: false,
                        handoffCallTransferPercentage: 0,
                        agentType: {
                            name: 'Charles',
                            label: 'Charles',
                            value: 'Charles',
                        },
                    }}
                    onSubmit={handleSubmit}
                />
            </Content>
            <AgentFormFooter
                formId="agent-form"
                onCancelClick={closeDrawer}
            />
        </Container>
    );
};

export default AddAgentForm;
