import { IconProps } from './types';

const LowCognitiveLoad = ({ title = 'LowCognitiveLoad', className }: IconProps) => (
    <svg className={className} width={16} height={17} viewBox="0 0 16 17" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.99936 1.49556C5.17722 0.814057 6.54497 0.424316 8.00072 0.424316C9.45648 0.424316 10.8242 0.814057 12.0021 1.49556C12.4801 1.77214 12.6434 2.38389 12.3668 2.86192C12.0903 3.33996 11.4785 3.50326 11.0005 3.22667C10.1189 2.71658 9.09544 2.42432 8.00072 2.42432C6.906 2.42432 5.88258 2.71658 5.00097 3.22667C4.52294 3.50326 3.91119 3.33996 3.63461 2.86192C3.35802 2.38389 3.52132 1.77214 3.99936 1.49556Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.4376 4.05892C2.91564 4.33551 3.07895 4.94725 2.80236 5.42529C2.29227 6.3069 2 7.33032 2 8.42504C2 9.51976 2.29227 10.5432 2.80236 11.4248C3.07895 11.9028 2.91564 12.5146 2.4376 12.7912C1.95957 13.0677 1.34783 12.9044 1.07124 12.4264C0.389741 11.2485 0 9.88079 0 8.42504C0 6.96929 0.389741 5.60153 1.07124 4.42368C1.34783 3.94564 1.95957 3.78234 2.4376 4.05892Z" fill="currentColor" fillOpacity="0.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4994 4.05892C13.9774 3.78234 14.5891 3.94564 14.8657 4.42368C15.5472 5.60153 15.937 6.96929 15.937 8.42504C15.937 9.88079 15.5472 11.2486 14.8657 12.4264C14.5891 12.9044 13.9774 13.0677 13.4994 12.7912C13.0213 12.5146 12.858 11.9028 13.1346 11.4248C13.6447 10.5432 13.937 9.51976 13.937 8.42504C13.937 7.33032 13.6447 6.3069 13.1346 5.42529C12.858 4.94725 13.0213 4.33551 13.4994 4.05892Z" fill="currentColor" fillOpacity="0.5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.63461 14.1385C3.91119 13.6605 4.52294 13.4972 5.00097 13.7738C5.88258 14.2839 6.906 14.5761 8.00072 14.5761C9.09544 14.5761 10.1189 14.2839 11.0005 13.7738C11.4785 13.4972 12.0903 13.6605 12.3668 14.1385C12.6434 14.6166 12.4801 15.2283 12.0021 15.5049C10.8242 16.1864 9.45648 16.5761 8.00072 16.5761C6.54497 16.5761 5.17722 16.1864 3.99936 15.5049C3.52132 15.2283 3.35802 14.6166 3.63461 14.1385Z" fill="currentColor" fillOpacity="0.5" />
    </svg>
);

export default LowCognitiveLoad;
