import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    background: ${color('background.main')};
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.span`
    font-size: 15px;
    font-family: "Lato Bold";
`;

export const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-item: center;
`;

export const Content = styled.div`
    margin: 8px 20px 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
`;

export const ConversationsListHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

export const ConversationsListTitle = styled.h2`
    font-size: 15px;
    font-weight: 700;   
    color: ${color('text.main')};
    margin-left: 8px;
`;

export const ConversationsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const ConversationItemContainer = styled(Link)`
    border: 1px solid ${color('border.disabled')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px 4px 16px;
    border-radius: 10px;
    color: ${color('text.main')};
    background: ${color('background.main')};
    font-size: 15px;

    :hover,
    :active,
    :focus {
        color: ${color('text.main')};
        outline: none;
        text-decoration: none;
        background: ${color('background.secondary')};
    }
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 12px;
`;

export const GroupNameContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 4px 8px 4px 16px;
    cursor: pointer;
`;

export const GroupName = styled.span`
    background: ${color('background.highlight')};
    border-radius: 10px;
    padding: 4px 8px;
    text-align: center;
`;

export const GroupsHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    padding: 20px 22px 20px 16px;
`;

export const DateTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 12px;
`;

export const GroupsHeader = styled.h2`
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
`;

export const GroupsTotal = styled.h2`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
`;
