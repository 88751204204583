import styled from 'styled-components';
import { color } from 'theme';

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
    text-transform: capitalize;
`;

export const StyledDrawerTitle = styled.h1`
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    font-family: Lato;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: ${color('text.title')};
    margin: 0 1.25rem;
    padding: 1.375rem 0 1.325rem;
    display: block;
    box-sizing: border-box;
    line-height: normal;
`;
