import { NavigationButtonColumn } from '@rainbow-modules/listview';
import styled from 'styled-components';

export const StyledNavigationButtonColumn = styled(NavigationButtonColumn)`
    margin-left: 8px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
`;
