import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { AddService, EntityGet } from "../types";
import { Message } from "./types";
import get from "./get";

export type MessageAddService<T> = (
  doc: Omit<T, "id">
) => Promise<EntityGet<T>>;

const add: MessageAddService<Message> = async (data) => {
  const collectionRef = collection(db, `/message`);
  const now = serverTimestamp();
  const docRef = await addDoc(collectionRef, {
    ...data,
    createdAt: now,
    updatedAt: now,
  });
  return get(docRef.id) as Promise<EntityGet<Message>>;
};

export default add;
